import axios from '@service/ApiClient'

class leadsService {
    constructor(callback) {
        this.callback = callback
    }

    async listIndustries(payload) {
        return await axios.post('/Crm/Company/list_industries', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async saveLeads(payload) {
        return await axios.post('/Crm/Lead/save_lead', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async listLeads(payload) {
        return await axios.post('/Crm/Lead/list_lead', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateLeads(payload) {
        return await axios.post('/Crm/Lead/update_lead', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteLeads(payload) {
        return await axios.post('/Crm/Lead/delete_lead', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchLeadsById(payload) {
        return await axios.post('/Crm/Lead/list_lead_by_id', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchAddressLeadsById(payload) {
        return await axios.post('/Crm/Lead/get_regionallevels_by_addressid', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async convertLead(payload) {
        return await axios.post('/Crm/Lead/lead_convert', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchDashboardData(payload) {
        return await axios.post('/Crm/Lead/fetch_crm_dashboard_details', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchDashboardFeeds(payload) {
        return await axios.post('/Crm/Crm_dashboard/get_lead_activity_details_by_user_id', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchCurrentUserTask(payload) {
        return await axios.post('/Crm/Crm_dashboard/get_task_dtls_by_assigned_to', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchTodayTasks(payload) {
        return await axios.post('/Crm/Crm_dashboard/get_today_task_dtls', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchTasksByDate(payload) {
        return await axios.post('/Crm/Crm_dashboard/get_task_dtls_by_date_user_id', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchupcomingTasks(payload) {
        return await axios.post('/Crm/Crm_dashboard/list_upcoming_task', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchTeamMemberTasks(payload) {
        return await axios.post('/Crm/Crm_dashboard/get_team_task', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new leadsService();