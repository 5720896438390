import axios from '@service/ApiClient'

class advancePaymentService {
    constructor(callback) {
        this.callback = callback
    }

    async listEmployee(payload) {
        return await axios.post('Crm/User/list_user', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchLp(payload) {
        return await axios.post('Payroll/Salary_register/get_leavereport', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchSalarydetails(payload) {
        return await axios.post('Payroll/Salary_register/index', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
   async saveSalaryregister(payload) {
        return await axios.post('Payroll/Salary_register/save_salary_register', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async listSalaryregister(payload) {
        return await axios.post('Payroll/Salary_register/salary_register_list', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async emplistSalaryregister(payload) {
        return await axios.post('Payroll/Salary_register/emp_salary_register_list', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async salaryregisterView(payload) {
        return await axios.post('Payroll/Salary_register/salary_register_view', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async salarypayment(payload) {
        return await axios.post('Payroll/Salary_register/pay_salary_save', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async salaryadvancepayment(payload) {
        return await axios.post('Payroll/Salary_advance_payment/pay_salary_advance', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchadvancepayment(payload) {
        return await axios.post('Payroll/Salary_advance_payment/index', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async getadvanceamt(payload) {
        return await axios.post('Payroll/Salary_advance_payment/get_salary_amount', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new advancePaymentService();