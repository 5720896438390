
export const saveLeaveRequest = (payload) => {
    return {
        type: 'SAVE_LEAVE_REQUEST',
        payload
    };
};
export const saveLeaveRequestSuccess = (payload) => {
    return {
        type: 'SAVE_LEAVE_REQUEST_SUCCESS',
        payload
    };
};
export const saveLeaveRequestError = (payload) => {
    return {
        type: 'SAVE_LEAVE_REQUEST_ERROR',
        payload
    };
};

export const updateLeaveRequest = (payload) => {
    return {
        type: 'UPDATE_LEAVE_REQUEST',
        payload
    };
};
export const updateLeaveRequestSuccess = (payload) => {
    return {
        type: 'UPDATE_LEAVE_REQUEST_SUCCESS',
        payload
    };
};
export const updateLeaveRequestError = (payload) => {
    return {
        type: 'UPDATE_LEAVE_REQUEST_ERROR',
        payload
    };
};

export const fetchMyLeaveRequest = (payload) => {
    return {
        type: 'FETCH_MY_LEAVE_REQUEST',
        payload
    };
};
export const fetchMyLeaveRequestSuccess = (payload) => {
    return {
        type: 'FETCH_MY_LEAVE_REQUEST_SUCCESS',
        payload
    };
};
export const fetchMyLeaveRequestError = (payload) => {
    return {
        type: 'FETCH_MY_LEAVE_REQUEST_ERROR',
        payload
    };
};

export const fetchLeaveTypeForRequest = (payload) => {
    return {
        type: 'FETCH_LEAVE_TYPE_FOR_REQUEST',
        payload
    };
};
export const fetchLeaveTypeForRequestSuccess = (payload) => {
    return {
        type: 'FETCH_LEAVE_TYPE_FOR_REQUEST_SUCCESS',
        payload
    };
};
export const fetchLeaveTypeForRequestError = (payload) => {
    return {
        type: 'FETCH_LEAVE_TYPE_FOR_REQUEST_ERROR',
        payload
    };
};

export const cancelRequest = (payload) => {
    return {
        type: 'CANCEL_LEAVE_REQUEST',
        payload
    };
};
export const cancelRequestSuccess = (payload) => {
    return {
        type: 'CANCEL_LEAVE_REQUEST_SUCCESS',
        payload
    };
};
export const cancelRequestError = (payload) => {
    return {
        type: 'CANCEL_LEAVE_REQUEST_ERROR',
        payload
    };
};

export const fetchPmRequest = (payload) => {
    return {
        type: 'FETCH_PM_LEAVE_REQUEST',
        payload
    };
};
export const fetchPmRequestSuccess = (payload) => {
    return {
        type: 'FETCH_PM_LEAVE_REQUEST_SUCCESS',
        payload
    };
};
export const fetchPmRequestError = (payload) => {
    return {
        type: 'FETCH_PM_LEAVE_REQUEST_ERROR',
        payload
    };
};


export const approveRequest = (payload) => {
    return {
        type: 'APPROVE_LEAVE_REQUEST',
        payload
    };
};
export const approveRequestSuccess = (payload) => {
    return {
        type: 'APPROVE_LEAVE_REQUEST_SUCCESS',
        payload
    };
};
export const approveRequestError = (payload) => {
    return {
        type: 'APPROVE_LEAVE_REQUEST_ERROR',
        payload
    };
};


export const rejectRequest = (payload) => {
    return {
        type: 'REJECT_LEAVE_REQUEST',
        payload
    };
};
export const rejectRequestSuccess = (payload) => {
    return {
        type: 'REJECT_LEAVE_REQUEST_SUCCESS',
        payload
    };
};
export const rejectRequestError = (payload) => {
    return {
        type: 'REJECT_LEAVE_REQUEST_ERROR',
        payload
    };
};


export const handleLeaveParameters = (payload) => {
    return {
        type: 'CHECK_LEAVE_PARAMETERS',
        payload
    };
};
export const handleLeaveParametersSuccess = (payload) => {
    return {
        type: 'CHECK_LEAVE_PARAMETERS_SUCCESS',
        payload
    };
};
export const handleLeaveParametersError = (payload) => {
    return {
        type: 'CHECK_LEAVE_PARAMETERS_ERROR',
        payload
    };
};

export const fetchLeaveDashboard = (payload) => {
    return {
        type: 'FETCH_LEAVE_DASHBOARD',
        payload
    };
};
export const fetchLeaveDashboardSuccess = (payload) => {
    return {
        type: 'FETCH_LEAVE_DASHBOARD_SUCCESS',
        payload
    };
};
export const fetchLeaveDashboardError = (payload) => {
    return {
        type: 'FETCH_LEAVE_DASHBOARD_ERROR',
        payload
    };
};
