import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import industryMasterService from '@service/admin/masterService/industryService';
import {
  saveIndustrySuccess, saveIndustryError,
  listIndustrySuccess, listIndustryError,
  deleteIndustrySuccess, deleteIndustryError,
  editIndustrySuccess, editIndustryError
} from './action';

function* saveIndustry({ payload }) {
  try {
    const data = yield call(industryMasterService.saveIndustry, payload);

    yield put(saveIndustrySuccess(data));
  } catch (error) {
    yield put(saveIndustryError(error));
  } finally {
  }
}

function* listIndustry({ payload }) {
  try {
    const data = yield call(industryMasterService.listIndustry, payload);
    Array.isArray(data) ?
      yield put(listIndustrySuccess(data)) :
      yield put(listIndustrySuccess([]))
  } catch (error) {
    yield put(listIndustryError(error));
  } finally {
  }
}

function* deleteIndustry({ payload }) {
  try {
    const data = yield call(industryMasterService.deleteIndustry, payload);

    yield put(deleteIndustrySuccess(data));
  } catch (error) {
    yield put(deleteIndustryError(error));
  } finally {
  }
}

function* editIndustry({ payload }) {
  try {
    const data = yield call(industryMasterService.editIndustry, payload);

    yield put(editIndustrySuccess(data));
  } catch (error) {
    yield put(editIndustryError(error));
  } finally {
  }
}
export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_INDUSTRY, saveIndustry)]);
  yield all([takeEvery(actionTypes.LIST_INDUSTRY, listIndustry)]);
  yield all([takeEvery(actionTypes.DELETE_INDUSTRY, deleteIndustry)]);
  yield all([takeEvery(actionTypes.EDIT_INDUSTRY, editIndustry)]);


}