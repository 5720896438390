
export const actionTypes = {
    SAVE_PO: 'SAVE_PO',
    SAVE_PO_SUCCESS: 'SAVE_PO_SUCCESS',
    SAVE_PO_ERROR: 'SAVE_PO_ERROR',

    LIST_PO: 'LIST_PO',
    LIST_PO_SUCCESS: 'LIST_PO_SUCCESS',
    LIST_PO_ERROR: 'LIST_PO_ERROR',

    FETCH_PO_DATA_BY_ID: 'FETCH_PO_DATA_BY_ID',
    FETCH_PO_DATA_BY_ID_SUCCESS: 'FETCH_PO_DATA_BY_ID_SUCCESS',
    FETCH_PO_DATA_BY_ID_ERROR: 'FETCH_PO_DATA_BY_ID_ERROR',

    LIST_PR_FOR_PO: 'LIST_PR_FOR_PO',
    LIST_PR_FOR_PO_SUCCESS: 'LIST_PR_FOR_PO_SUCCESS',
    LIST_PR_FOR_PO_ERROR: 'LIST_PR_FOR_PO_ERROR',

    LIST_VENDOR_PR_FOR_PO: 'LIST_VENDOR_PR_FOR_PO',
    LIST_VENDOR_PR_FOR_PO_SUCCESS: 'LIST_VENDOR_PR_FOR_PO_SUCCESS',
    LIST_VENDOR_PR_FOR_PO_ERROR: 'LIST_VENDOR_PR_FOR_PO_ERROR',

    GET_PR_ITEMS_FOR_PO: 'GET_PR_ITEMS_FOR_PO',
    GET_PR_ITEMS_FOR_PO_SUCCESS: 'GET_PR_ITEMS_FOR_PO_SUCCESS',
    GET_PR_ITEMS_FOR_PO_ERROR: 'GET_PR_ITEMS_FOR_PO_ERROR',

    REMOVE_ITEM_FROM_PR: 'REMOVE_ITEM_FROM_PR',
    REMOVE_ITEM_FROM_PR_SUCCESS: 'REMOVE_ITEM_FROM_PR_SUCCESS',
    REMOVE_ITEM_FROM_PR_ERROR: 'REMOVE_ITEM_FROM_PR_ERROR',

    UPDATE_PO: 'UPDATE_PO',
    UPDATE_PO_SUCCESS: 'UPDATE_PO_SUCCESS',
    UPDATE_PO_ERROR: 'UPDATE_PO_ERROR',
};

export const savePo = (payload) => {
    return {
        type: actionTypes.SAVE_PO, payload
    };
};
export const savePoSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_PO_SUCCESS,
        payload
    };
};
export const savePoError = (payload) => {
    return {
        type: actionTypes.SAVE_PO_ERROR,
        payload
    };
};

export const listPo = (payload) => {
    return {
        type: actionTypes.LIST_PO, payload
    };
};
export const listPoSuccess = (payload) => {
    return {
        type: actionTypes.LIST_PO_SUCCESS,
        payload
    };
};
export const listPoError = (payload) => {
    return {
        type: actionTypes.LIST_PO_ERROR,
        payload
    };
};

export const fetchPoById = (payload) => {
    return {
        type: actionTypes.FETCH_PO_DATA_BY_ID, payload
    };
};
export const fetchPoByIdSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_PO_DATA_BY_ID_SUCCESS,
        payload
    };
};
export const fetchPoByIdError = (payload) => {
    return {
        type: actionTypes.FETCH_PO_DATA_BY_ID_ERROR,
        payload
    };
};

export const listPurchaseRequestPo = (payload) => {
    return {
        type: actionTypes.LIST_PR_FOR_PO, payload
    };
};
export const listPurchaseRequestPoSuccess = (payload) => {
    return {
        type: actionTypes.LIST_PR_FOR_PO_SUCCESS,
        payload
    };
};
export const listPurchaseRequestPoError = (payload) => {
    return {
        type: actionTypes.LIST_PR_FOR_PO_ERROR,
        payload
    };
};

export const listVendorPurchaseRequest = (payload) => {
    return {
        type: actionTypes.LIST_VENDOR_PR_FOR_PO, payload
    };
};
export const listVendorPurchaseRequestSuccess = (payload) => {
    return {
        type: actionTypes.LIST_VENDOR_PR_FOR_PO_SUCCESS,
        payload
    };
};
export const listVendorPurchaseRequestError = (payload) => {
    return {
        type: actionTypes.LIST_VENDOR_PR_FOR_PO_ERROR,
        payload
    };
};

export const getPrItemsForPo = (payload) => {
    return {
        type: actionTypes.GET_PR_ITEMS_FOR_PO, payload
    };
};
export const getPrItemsForPoSuccess = (payload) => {
    return {
        type: actionTypes.GET_PR_ITEMS_FOR_PO_SUCCESS,
        payload
    };
};
export const getPrItemsForPoError = (payload) => {
    return {
        type: actionTypes.GET_PR_ITEMS_FOR_PO_ERROR,
        payload
    };
};

export const removeItemFromPr = (payload) => {
    return {
        type: actionTypes.REMOVE_ITEM_FROM_PR, payload
    };
};
export const removeItemFromPrSuccess = (payload) => {
    return {
        type: actionTypes.REMOVE_ITEM_FROM_PR_SUCCESS,
        payload
    };
};
export const removeItemFromPrError = (payload) => {
    return {
        type: actionTypes.REMOVE_ITEM_FROM_PR_ERROR,
        payload
    };
};

export const updatePo = (payload) => {
    return {
        type: actionTypes.UPDATE_PO, payload
    };
};
export const updatePoSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_PO_SUCCESS,
        payload
    };
};
export const updatePoError = (payload) => {
    return {
        type: actionTypes.UPDATE_PO_ERROR,
        payload
    };
};

// save purchase order for equipment
export const savePurchaseOrder = (payload) => {
    return {
      type: 'SAVE_PURCHASE_ORDER',
      payload
    };
  };
  export const savePurchaseOrderSuccess = (payload) => {
    return {
      type: 'SAVE_PURCHASE_ORDER_SUCCESS',
      payload
    };
  };
  export const savePurchaseOrderError = (payload) => {
    return {
      type: 'SAVE_PURCHASE_ORDER_ERROR',
      payload
    };
  };