
import { actionTypes } from './action';

export const initialState = {
    saveIndustryValues: '',
    listIndustryValues: [],
    deleteIndustryvalues: '',
    editIndustryvalues: ''
}

const saveIndustryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_INDUSTRY:
            return { ...state, saveIndustryValues: '' }
        case actionTypes.SAVE_INDUSTRY_SUCCESS:
            return { ...state, saveIndustryValues: action.payload }
        case actionTypes.SAVE_INDUSTRY_ERROR:
            return { ...state, saveIndustryValues: '' }

        case actionTypes.LIST_INDUSTRY:
            return { ...state, listIndustryValues: [] }
        case actionTypes.LIST_INDUSTRY_SUCCESS:
            return {
                ...state,
                listIndustryValues: action.payload,
                saveIndustryValues: '',
                deleteIndustryvalues: '',
                editIndustryvalues: ''
            }
        case actionTypes.LIST_INDUSTRY_ERROR:
            return { ...state, listIndustryValues: [] }

        case actionTypes.DELETE_INDUSTRY:
            return { ...state, deleteIndustryvalues: '' }
        case actionTypes.DELETE_INDUSTRY_SUCCESS:
            return { ...state, deleteIndustryvalues: action.payload }
        case actionTypes.DELETE_INDUSTRY_ERROR:
            return { ...state, deleteIndustryvalues: 'error' }

        case actionTypes.EDIT_INDUSTRY:
            return { ...state, editIndustryvalues: '' }
        case actionTypes.EDIT_INDUSTRY_SUCCESS:
            return { ...state, editIndustryvalues: action.payload }
        case actionTypes.EDIT_INDUSTRY_ERROR:
            return { ...state, editIndustryvalues: '' }

        default:
            return state
    }
}

export default saveIndustryReducer