import axios from '../../ApiClient'
// import { siteUrl } from './config'

class meetingservice {
    constructor(callback) {
        this.callback = callback
    }

    async addMeeting(payload) {
        return await axios.post('Crm/Meeting/save_meeting', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchParticipants(payload) {
        return await axios.post('Crm/Contact/list_contacts_organizations', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchMeetinglist(payload) {
        return await axios.post('Crm/Meeting/list_meeting', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteMeeting(payload) {
        return await axios.post('Crm/Meeting/delete_meeting', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async getMeetingByID(payload) {
        return await axios.post('Crm/Meeting/get_meeting_by_id', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async editMeeting(payload) {
        return await axios.post('Crm/Meeting/update_meeting', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new meetingservice();
