
export const actionTypes = {
    SAVE_TASK: 'SAVE_TASK',
    SAVE_TASK_SUCCESS: 'SAVE_TASK_SUCCESS',
    SAVE_TASK_ERROR: 'SAVE_TASK_ERROR',

    LIST_COMPANYCONTACT: 'LIST_COMPANYCONTACT',
    LIST_COMPANYCONTACT_SUCCESS: 'LIST_COMPANYCONTACT_SUCCESS',
    LIST_COMPANYCONTACT_ERROR: 'LIST_COMPANYCONTACT_ERROR',

    SAVE_TASKACTIVITY: 'SAVE_TASKACTIVITY',
    SAVE_TASKACTIVITY_SUCCESS: 'SAVE_TASKACTIVITY_SUCCESS',
    SAVE_TASKACTIVITY_ERROR: 'SAVE_TASKACTIVITY_ERROR',

    LIST_TASKACTIVITY: 'LIST_TASKACTIVITY',
    LIST_TASKACTIVITY_SUCCESS: 'LIST_TASKACTIVITY_SUCCESS',
    LIST_TASKACTIVITY_ERROR: 'LIST_TASKACTIVITY_ERROR',

    UPDATE_TASKACTIVITY: 'UPDATE_TASKACTIVITY',
    UPDATE_TASKACTIVITY_SUCCESS: 'UPDATE_TASKACTIVITY_SUCCESS',
    UPDATE_TASKACTIVITY_ERROR: 'UPDATE_TASKACTIVITY_ERROR',

    DELETE_TASKACTIVITY: 'DELETE_TASKACTIVITY',
    DELETE_TASKACTIVITY_SUCCESS: 'DELETE_TASKACTIVITY_SUCCESS',
    DELETE_TASKACTIVITY_ERROR: 'DELETE_TASKACTIVITY_ERROR',

    LIST_TASK: 'LIST_TASK',
    LIST_TASK_SUCCESS: 'LIST_TASK_SUCCESS',
    LIST_TASK_ERROR: 'LIST_TASK_ERROR',

    LIST_SALESTYPE_DATA: 'LIST__SALESTYPE_DATA',
    LIST_SALESTYPE_DATA_SUCCESS: 'LIST__SALESTYPE_DATA_SUCCESS',
    LIST_SALESTYPE_DATA_ERROR: 'LIST__SALESTYPE_DATA_ERROR',

    PRE_POST_PONETASK: 'PRE_POST_PONETASK',
    PRE_POST_PONETASK_SUCCESS: 'PRE_POST_PONETASK_SUCCESS',
    PRE_POST_PONETASK_ERROR: 'PRE_POST_PONETASK_ERROR',

    COMPLETED_TASK_DETAILS: 'COMPLETED_TASK_DETAILS',
    COMPLETED_TASK_DETAILS_SUCCESS: 'COMPLETED_TASK_DETAILS_SUCCESS',
    COMPLETED_TASK_DETAILS_ERROR: 'COMPLETED_TASK_DETAILS_ERROR',

    FOLLOW_UP_TASK: 'FOLLOW_UP_TASK',
    FOLLOW_UP_TASK_SUCCESS: 'FOLLOW_UP_TASK_SUCCESS',
    FOLLOW_UP_TASK_ERROR: 'FOLLOW_UP_TASK_ERROR',

    GET_TASKDETAILS_BY_ID: 'GET_TASKDETAILS_BY_ID',
    GET_TASKDETAILS_BY_ID_SUCCESS: 'GET_TASKDETAILS_BY_ID_SUCCESS',
    GET_TASKDETAILS_BY_ID_ERROR: 'GET_TASKDETAILS_BY_ID_ERROR',

    UPDATE_TASKDETAILS: 'UPDATE_TASKDETAILS',
    UPDATE_TASKDETAILS_SUCCESS: 'UPDATE_TASKDETAILS_SUCCESS',
    UPDATE_TASKDETAILS_ERROR: 'UPDATE_TASKDETAILS_ERROR',

    GET_APPROVE_TASKDETAILS: 'GET_APPROVE_TASKDETAILS',
    GET_APPROVE_TASKDETAILS_SUCCESS: 'GET_APPROVE_TASKDETAILS_SUCCESS',
    GET_APPROVE_TASKDETAILS_ERROR: 'GET_APPROVE_TASKDETAILS_ERROR',

    DELETE_TASKDETAILS: 'DELETE_TASKDETAILS',
    DELETE_TASKDETAILS_SUCCESS: 'DELETE_TASKDETAILS_SUCCESS',
    DELETE_TASKDETAILS_ERROR: 'DELETE_TASKDETAILS_ERROR',

    APPROVE_TASKDETAILS: 'APPROVE_TASKDETAILS',
    APPROVE_TASKDETAILS_SUCCESS: 'APPROVE_TASKDETAILS_SUCCESS',
    APPROVE_TASKDETAILS_ERROR: 'APPROVE_TASKDETAILS_ERROR',

    REJECT_TASKDETAILS: 'REJECT_TASKDETAILS',
    REJECT_TASKDETAILS_SUCCESS: 'REJECT_TASKDETAILS_SUCCESS',
    REJECT_TASKDETAILS_ERROR: 'REJECT_TASKDETAILS_ERROR',
};

export const saveTask = (payload) => {
    return {
        type: actionTypes.SAVE_TASK, payload
    };
};
export const saveTaskSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_TASK_SUCCESS,
        payload
    };
};
export const saveTaskError = (payload) => {
    return {
        type: actionTypes.SAVE_TASK_ERROR,
        payload
    };
};

export const fetchTask = (payload) => {
    return {
        type: actionTypes.LIST_TASK, payload
    };
};
export const fetchTaskSuccess = (payload) => {
    return {
        type: actionTypes.LIST_TASK_SUCCESS,
        payload
    };
};
export const fetchTaskError = (payload) => {
    return {
        type: actionTypes.LIST_TASK_ERROR,
        payload
    };
};

export const listCompanyContact = () => {
    return {
        type: actionTypes.LIST_COMPANYCONTACT
    };
};
export const listCompanyContactSuccess = (payload) => {
    return {
        type: actionTypes.LIST_COMPANYCONTACT_SUCCESS,
        payload
    };
};
export const listCompanyContactError = (payload) => {
    return {
        type: actionTypes.LIST_COMPANYCONTACT_ERROR,
        payload
    };
};

export const saveTaskActivity = (payload) => {
    return {
        type: actionTypes.SAVE_TASKACTIVITY, payload
    };
};

export const saveTaskActivitySuccess = (payload) => {
    return {
        type: actionTypes.SAVE_TASKACTIVITY_SUCCESS,
        payload
    };
};
export const saveTaskActivityError = (payload) => {
    return {
        type: actionTypes.SAVE_TASKACTIVITY_ERROR,
        payload
    };
};

export const listTaskActivity = (payload) => {
    return {
        type: actionTypes.LIST_TASKACTIVITY,
        payload
    };
};
export const listTaskActivitySuccess = (payload) => {
    return {
        type: actionTypes.LIST_TASKACTIVITY_SUCCESS,
        payload
    };
};
export const listTaskActivityError = (payload) => {
    return {
        type: actionTypes.LIST_TASKACTIVITY_ERROR,
        payload
    };
};

export const updateTaskActivity = (payload) => {
    return {
        type: actionTypes.UPDATE_TASKACTIVITY, payload
    };
};

export const updateTaskActivitySuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_TASKACTIVITY_SUCCESS,
        payload
    };
};
export const updateTaskActivityError = (payload) => {
    return {
        type: actionTypes.UPDATE_TASKACTIVITY_ERROR,
        payload
    };
};

export const deleteTaskActivity = (payload) => {
    return {
        type: actionTypes.DELETE_TASKACTIVITY, payload
    };
};

export const deleteTaskActivitySuccess = (payload) => {
    return {
        type: actionTypes.DELETE_TASKACTIVITY_SUCCESS,
        payload
    };
};
export const deleteTaskActivityError = (payload) => {
    return {
        type: actionTypes.DELETE_TASKACTIVITY_ERROR,
        payload
    };
};

export const listSalesTypeData = (payload) => {
    return {
        type: actionTypes.LIST_SALESTYPE_DATA, payload
    };
};
export const listSalesTypeDataSuccess = (payload) => {
    return {
        type: actionTypes.LIST_SALESTYPE_DATA_SUCCESS,
        payload
    };
};
export const listSalesTypeDataError = (payload) => {
    return {
        type: actionTypes.LIST_SALESTYPE_DATA_ERROR,
        payload
    };
};

export const handlePrePostPoneTask = (payload) => {
    return {
        type: actionTypes.PRE_POST_PONETASK, payload
    };
};
export const handlePrePostPoneTaskSuccess = (payload) => {
    return {
        type: actionTypes.PRE_POST_PONETASK_SUCCESS,
        payload
    };
};
export const handlePrePostPoneTaskError = (payload) => {
    return {
        type: actionTypes.PRE_POST_PONETASK_ERROR,
        payload
    };
};

export const handleCompletedTaskDetails = (payload) => {
    return {
        type: actionTypes.COMPLETED_TASK_DETAILS, payload
    };
};
export const handleCompletedTaskDetailsSuccess = (payload) => {
    return {
        type: actionTypes.COMPLETED_TASK_DETAILS_SUCCESS,
        payload
    };
};
export const handleCompletedTaskDetailsError = (payload) => {
    return {
        type: actionTypes.COMPLETED_TASK_DETAILS_ERROR,
        payload
    };
};

export const handleFollowUpTask = (payload) => {
    return {
        type: actionTypes.FOLLOW_UP_TASK, payload
    };
};
export const handleFollowUpTaskSuccess = (payload) => {
    return {
        type: actionTypes.FOLLOW_UP_TASK_SUCCESS,
        payload
    };
};
export const handleFollowUpTaskError = (payload) => {
    return {
        type: actionTypes.FOLLOW_UP_TASK_ERROR,
        payload
    };
};

export const fetchTaskDetailsByID = (payload) => {
    return { type: actionTypes.GET_TASKDETAILS_BY_ID, payload };
};
export const fetchTaskDetailsByIDSuccess = (payload) => {
    return { type: actionTypes.GET_TASKDETAILS_BY_ID_SUCCESS, payload };
};
export const fetchTaskDetailsByIDError = (payload) => {
    return { type: actionTypes.GET_TASKDETAILS_BY_ID_ERROR, payload };
};

export const updateTaskDetails = (payload) => {
    return { type: actionTypes.UPDATE_TASKDETAILS, payload };
};
export const updateTaskDetailsSuccess = (payload) => {
    return { type: actionTypes.UPDATE_TASKDETAILS_SUCCESS, payload };
};
export const updateTaskDetailsError = (payload) => {
    return { type: actionTypes.UPDATE_TASKDETAILS_ERROR, payload };
};

export const handleGetApproveTaskDetails = (payload) => {
    return { type: actionTypes.GET_APPROVE_TASKDETAILS, payload };
};
export const handleGetApproveTaskDetailsSuccess = (payload) => {
    return { type: actionTypes.GET_APPROVE_TASKDETAILS_SUCCESS, payload };
};
export const handleGetApproveTaskDetailsError = (payload) => {
    return { type: actionTypes.GET_APPROVE_TASKDETAILS_ERROR, payload };
};

export const deleteTaskDetails = (payload) => {
    return { type: actionTypes.DELETE_TASKDETAILS, payload };
};
export const deleteTaskDetailsSuccess = (payload) => {
    return { type: actionTypes.DELETE_TASKDETAILS_SUCCESS, payload };
};
export const deleteTaskDetailsError = (payload) => {
    return { type: actionTypes.DELETE_TASKDETAILS_ERROR, payload };
};

export const approveTaskDetails = (payload) => {
    return { type: actionTypes.APPROVE_TASKDETAILS, payload };
};
export const approveTaskDetailsSuccess = (payload) => {
    return { type: actionTypes.APPROVE_TASKDETAILS_SUCCESS, payload };
};
export const approveTaskDetailsError = (payload) => {
    return { type: actionTypes.APPROVE_TASKDETAILS_ERROR, payload };
};

export const rejectTaskDetails = (payload) => {
    return { type: actionTypes.REJECT_TASKDETAILS, payload };
};
export const rejectTaskDetailsSuccess = (payload) => {
    return { type: actionTypes.REJECT_TASKDETAILS_SUCCESS, payload };
};
export const rejectTaskDetailsError = (payload) => {
    return { type: actionTypes.REJECT_TASKDETAILS_ERROR, payload };
};