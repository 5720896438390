import { actionTypes } from './action';
export const initialState = {
    saveParameterStatus: '',
    listParameter: [],
    updateParameterStatus: '',
    deleteParameterStatus: '',
}
const saveParameterReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_PARAMETER:
            return { ...state, saveParameterStatus: '' }
        case actionTypes.SAVE_PARAMETER_SUCCESS:
            return { ...state, saveParameterStatus: action.payload }
        case actionTypes.SAVE_PARAMETER_ERROR:
            return { ...state, saveParameterStatus: '' }

        case actionTypes.LIST_PARAMETER:
            return { ...state, listParameter: [] }
        case actionTypes.LIST_PARAMETER_SUCCESS:
            return { ...state, listParameter: action.payload, saveParameterStatus: '', updateParameterStatus: '', deleteParameterStatus: '' }
        case actionTypes.LIST_PARAMETER_ERROR:
            return { ...state, listParameter: [] }

        case actionTypes.UPDATE_PARAMETER:
            return { ...state, updateParameterStatus: '' }
        case actionTypes.UPDATE_PARAMETER_SUCCESS:
            return { ...state, updateParameterStatus: action.payload }
        case actionTypes.UPDATE_PARAMETER_ERROR:
            return { ...state, updateParameterStatus: '' }

        case actionTypes.DELETE_PARAMETER:
            return { ...state, deleteParameterStatus: '' }
        case actionTypes.DELETE_PARAMETER_SUCCESS:
            return { ...state, deleteParameterStatus: action.payload }
        case actionTypes.DELETE_PARAMETER_ERROR:
            return { ...state, deleteParameterStatus: '' }

        default:
            return state
    }
}
export default saveParameterReducer