import axios from '@service/ApiClient'
// import { siteUrl } from './config'

class unitMasterService {
    constructor(callback) {
        this.callback = callback
    }
    async saveUnit(payload) {
        return await axios.post('/Inventory/Unit/save_unit', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listUnit(payload) {
        return await axios.post('/Inventory/Unit/list_unit', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updateUnit(payload) {
        return await axios.post('/Inventory/Unit/update_unit', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteUnit(payload) {
        return await axios.post('/Inventory/Unit/delete_unit', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new unitMasterService();