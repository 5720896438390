import { takeEvery, put, call, all } from 'redux-saga/effects';
import expenseservice from '@service/admin/masterService/expenseservice';
import { actionTypes } from './action'
import {
    saveExpensetypeSuccess, saveExpensetypeError,
    listExpensetypeSuccess, listExpensetypeError,
    UpdateExpensetypeSuccess, UpdateExpensetypeError,
    DeleteExpensetypeSuccess, DeleteExpensetypeError,
    FetchTaskSuccess, FetchTaskError,
    SaveExpenseModuleSuccess, SaveExpenseModuleError,
    ListExpenseModuleSuccess, ListExpenseModuleError,
    DeleteExpenseModuleSuccess, DeleteExpenseModuleError,
    EditExpenseModuleSuccess, EditExpenseModuleError,
    UpdateExpenseModuleSuccess, UpdateExpenseModuleError
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'


function* saveExpensetype({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(expenseservice.saveExpensetype, payload);
        yield put(saveExpensetypeSuccess(data));
    } catch (error) {
        yield put(saveExpensetypeError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* listExpensetype({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(expenseservice.listExpensetype, payload);
        yield put(listExpensetypeSuccess(data));
    } catch (error) {
        yield put(listExpensetypeError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* UpdateExpensetype({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(expenseservice.UpdateExpensetype, payload);
        yield put(UpdateExpensetypeSuccess(data));
    } catch (error) {
        yield put(UpdateExpensetypeError(error));
    } finally {
        yield put(stopLoader());
    }
}
function* DeleteExpensetype({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(expenseservice.DeleteExpensetype, payload);
        yield put(DeleteExpensetypeSuccess(data));
    } catch (error) {
        yield put(DeleteExpensetypeError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* FetchTask({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(expenseservice.FetchTask, payload);
        yield put(FetchTaskSuccess(data));
    } catch (error) {
        yield put(FetchTaskError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* SaveExpenseModule({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(expenseservice.SaveExpenseModule, payload);
        yield put(SaveExpenseModuleSuccess(data));
    } catch (error) {
        yield put(SaveExpenseModuleError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* ListExpenseModule({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(expenseservice.ListExpenseModule, payload);
        yield put(ListExpenseModuleSuccess(data));
    } catch (error) {
        yield put(ListExpenseModuleError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* DeleteExpenseModule({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(expenseservice.DeleteExpenseModule, payload);
        yield put(DeleteExpenseModuleSuccess(data));
    } catch (error) {
        yield put(DeleteExpenseModuleError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* EditExpenseModule({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(expenseservice.EditExpenseModule, payload);
        Array.isArray(data) ?
            yield put(EditExpenseModuleSuccess(data)) :
            yield put(EditExpenseModuleSuccess([]))
    } catch (error) {
        yield put(EditExpenseModuleError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* UpdateExpenseModule({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(expenseservice.UpdateExpenseModule, payload);
        yield put(UpdateExpenseModuleSuccess(data));
    } catch (error) {
        yield put(UpdateExpenseModuleError(error));
    } finally {
        yield put(stopLoader());
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actionTypes.SAVE_EXPENSE, saveExpensetype)]);
    yield all([takeEvery(actionTypes.LIST_EXPENSE, listExpensetype)]);
    yield all([takeEvery(actionTypes.UPDATE_EXPENSE, UpdateExpensetype)]);
    yield all([takeEvery(actionTypes.DELETE_EXPENSE, DeleteExpensetype)]);
    yield all([takeEvery(actionTypes.FETCH_TASK, FetchTask)]);
    yield all([takeEvery(actionTypes.SAVE_EXPENSEMODULE, SaveExpenseModule)]);
    yield all([takeEvery(actionTypes.LIST_EXPENSEMODULE, ListExpenseModule)]);
    yield all([takeEvery(actionTypes.DELETE_EXPENSEMODULE, DeleteExpenseModule)]);
    yield all([takeEvery(actionTypes.EDIT_EXPENSEMODULE, EditExpenseModule)]);
    yield all([takeEvery(actionTypes.UPDATE_EXPENSEMODULE, UpdateExpenseModule)]);
}
