import { actionTypes } from './action';

export const initialState = {
    saveFamilyDetailsStatus: '',
    listFamilyDetails: [],
    updateFamilyDetailssStatus: '',
    deleteFamilyDetailsStatus: '',
}
const saveFamilyDetailsReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_FAMILYDETAILS:
            return { ...state, saveFamilyDetailsStatus: '' }
        case actionTypes.SAVE_FAMILYDETAILS_SUCCESS:
            return { ...state, saveFamilyDetailsStatus: action.payload }
        case actionTypes.SAVE_FAMILYDETAILS_ERROR:
            return { ...state, saveFamilyDetailsStatus: '' }

        case actionTypes.LIST_FAMILYDETAILS:
            return { ...state, listFamilyDetails: [] }
        case actionTypes.LIST_FAMILYDETAILS_SUCCESS:
            return { ...state, listFamilyDetails: action.payload }
        case actionTypes.LIST_FAMILYDETAILS_ERROR:
            return { ...state, listFamilyDetails: [] }

        case actionTypes.UPDATE_FAMILYDETAILS:
            return { ...state, updateFamilyDetailssStatus: '' }
        case actionTypes.UPDATE_FAMILYDETAILS_SUCCESS:
            return { ...state, updateFamilyDetailssStatus: action.payload }
        case actionTypes.UPDATE_FAMILYDETAILS_ERROR:
            return { ...state, updateFamilyDetailssStatus: '' }

        case actionTypes.DELETE_FAMILYDETAILS:
            return { ...state, deleteFamilyDetailsStatus: '' }
        case actionTypes.DELETE_FAMILYDETAILS_SUCCESS:
            return { ...state, deleteFamilyDetailsStatus: action.payload }
        case actionTypes.DELETE_FAMILYDETAILS_ERROR:
            return { ...state, deleteFamilyDetailsStatus: '' }

        default:
            return state
    }
}
export default saveFamilyDetailsReducer