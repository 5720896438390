import axios from '@service/ApiClient'
class bankDetailsService {
    constructor(callback) {
        this.callback = callback
    }
    async saveBankDetails(payload) {
        return await axios.post('/Crm/User/add_bank', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listBankDetails(payload) {
        return await axios.post('/Crm/User/list_bank', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateBankDetails(payload) {
        return await axios.post('/Crm/User/update_bank', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteBankDetails(payload) {
        return await axios.post('/Crm/User/delete_bank', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

}

export default new bankDetailsService();