
export const actionTypes = {
    SAVE_GENERAL_SETTINGS: 'SAVE_GENERAL_SETTINGS',
    SAVE_GENERAL_SETTINGS_SUCCESS: 'SAVE_GENERAL_SETTINGS_SUCCESS',
    SAVE_GENERAL_SETTINGS_ERROR: 'SAVE_GENERAL_SETTINGS_ERROR',
    LIST_GENERAL_SETTINGS: 'LIST_GENERAL_SETTINGS',
    LIST_GENERAL_SETTINGS_SUCCESS: 'LIST_GENERAL_SETTINGS_SUCCESS',
    LIST_GENERAL_SETTINGS_ERROR: 'LIST_GENERAL_SETTINGS_ERROR',
    UPDATE_GENERAL_SETTINGS: 'UPDATE_GENERAL_SETTINGS',
    UPDATE_GENERAL_SETTINGS_SUCCESS: 'UPDATE_GENERAL_SETTINGS_SUCCESS',
    UPDATE_GENERAL_SETTINGS_ERROR: 'UPDATE_GENERAL_SETTINGS_ERROR',
    LIST_AREANAME: 'LIST_AREANAME',
    LIST_AREANAME_SUCCESS: 'LIST_AREANAME_SUCCESS',
    LIST_AREANAME_ERROR: 'LIST_AREANAME_ERROR',
    LIST_TIMEZONE: 'LIST_TIMEZONE',
    LIST_TIMEZONE_SUCCESS: 'LIST_TIMEZONE_SUCCESS',
    LIST_TIMEZONE_ERROR: 'LIST_TIMEZONE_ERROR',
};

export const saveGeneralSettings = (payload) => {
    return {
        type: actionTypes.SAVE_GENERAL_SETTINGS, payload
    };
};
export const saveGeneralSettingsSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_GENERAL_SETTINGS_SUCCESS,
        payload
    };
};
export const saveGeneralSettingsError = (payload) => {
    return {
        type: actionTypes.SAVE_GENERAL_SETTINGS_ERROR,
        payload
    };
};

export const listGeneralSettings = (payload) => {
    return {
        type: actionTypes.LIST_GENERAL_SETTINGS,
        payload
    };
};
export const listGeneralSettingsSuccess = (payload) => {
    return {
        type: actionTypes.LIST_GENERAL_SETTINGS_SUCCESS,
        payload
    };
};
export const listGeneralSettingsError = (payload) => {
    return {
        type: actionTypes.LIST_GENERAL_SETTINGS_ERROR,
        payload
    };
};

export const updateGeneralSettings = (payload) => {
    return {
        type: actionTypes.UPDATE_GENERAL_SETTINGS,
        payload
    };
};
export const updateGeneralSettingsSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_GENERAL_SETTINGS_SUCCESS,
        payload
    };
};
export const updateGeneralSettingsError = (payload) => {
    return {
        type: actionTypes.UPDATE_GENERAL_SETTINGS_ERROR,
        payload
    };
};

export const listAreaName = (payload) => {
    return {
        type: actionTypes.LIST_AREANAME,
        payload
    };
};
export const listAreaNameSuccess = (payload) => {
    return {
        type: actionTypes.LIST_AREANAME_SUCCESS,
        payload
    };
};
export const listAreaNameError = (payload) => {
    return {
        type: actionTypes.LIST_AREANAME_ERROR,
        payload
    };
};

export const listTimeZone = (payload) => {
    return {
        type: actionTypes.LIST_TIMEZONE,
        payload
    };
};
export const listTimeZoneSuccess = (payload) => {
    return {
        type: actionTypes.LIST_TIMEZONE_SUCCESS,
        payload
    };
};
export const listTimeZoneError = (payload) => {
    return {
        type: actionTypes.LIST_TIMEZONE_ERROR,
        payload
    };
};


