
export const actionTypes = {
    SAVE_DEAL: 'SAVE_DEAL',
    SAVE_DEAL_SUCCESS: 'SAVE_DEAL_SUCCESS',
    SAVE_DEAL_ERROR: 'SAVE_DEAL_ERROR',

    UPDATE_DEAL: 'UPDATE_DEAL',
    UPDATE_DEAL_SUCCESS: 'UPDATE_DEAL_SUCCESS',
    UPDATE_DEAL_ERROR: 'UPDATE_DEAL_ERROR',

    DELETE_DEAL: 'DELETE_DEAL',
    DELETE_DEAL_SUCCESS: 'DELETE_DEAL_SUCCESS',
    DELETE_DEAL_ERROR: 'DELETE_DEAL_ERROR',

    FETCH_DEAL_DATA_BY_ID: 'FETCH_DEAL_DATA_BY_ID',
    FETCH_DEAL_DATA_BY_ID_SUCCESS: 'FETCH_DEAL_DATA_BY_ID_SUCCESS',
    FETCH_DEAL_DATA_BY_ID_ERROR: 'FETCH_DEAL_DATA_BY_ID_ERROR',

    LIST_DEAL: 'LIST_DEAL',
    LIST_DEAL_SUCCESS: 'LIST_DEAL_SUCCESS',
    LIST_DEAL_ERROR: 'LIST_DEAL_ERROR',

    LIST_STAGE: 'LIST_STAGE',
    LIST_STAGE_SUCCESS: 'LIST_STAGE_SUCCESS',
    LIST_STAGE_ERROR: 'LIST_STAGE_ERROR',

    GET_PROJECT_LIST: 'GET_PROJECT_LIST',
    GET_PROJECT_LIST_SUCCESS: 'GET_PROJECT_LIST_SUCCESS',
    GET_PROJECT_LIST_ERROR: 'GET_PROJECT_LIST_ERROR',
};

export const saveDeal = (payload) => {
    return {
        type: actionTypes.SAVE_DEAL, payload
    };
};
export const saveDealSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_DEAL_SUCCESS,
        payload
    };
};
export const saveDealError = (payload) => {
    return {
        type: actionTypes.SAVE_DEAL_ERROR,
        payload
    };
};

export const updateDeal = (payload) => {
    return {
        type: actionTypes.UPDATE_DEAL, payload
    };
};
export const updateDealSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_DEAL_SUCCESS,
        payload
    };
};
export const updateDealError = (payload) => {
    return {
        type: actionTypes.UPDATE_DEAL_ERROR,
        payload
    };
};

export const deleteDeal = (payload) => {
    return {
        type: actionTypes.DELETE_DEAL, payload
    };
};
export const deleteDealSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_DEAL_SUCCESS,
        payload
    };
};
export const deleteDealError = (payload) => {
    return {
        type: actionTypes.DELETE_DEAL_ERROR,
        payload
    };
};

export const fetchDealDataById = (payload) => {
    return {
        type: actionTypes.FETCH_DEAL_DATA_BY_ID, payload
    };
};
export const fetchDealDataByIdSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_DEAL_DATA_BY_ID_SUCCESS,
        payload
    };
};
export const fetchDealDataByIdError = (payload) => {
    return {
        type: actionTypes.FETCH_DEAL_DATA_BY_ID_ERROR,
        payload
    };
};

export const listDeal = (payload) => {
    return {
        type: actionTypes.LIST_DEAL, payload
    };
};
export const listDealSuccess = (payload) => {
    return {
        type: actionTypes.LIST_DEAL_SUCCESS,
        payload
    };
};
export const listDealError = (payload) => {
    return {
        type: actionTypes.LIST_DEAL_ERROR,
        payload
    };
};

export const listStage = (payload) => {
    return {
        type: actionTypes.LIST_STAGE, payload
    };
};
export const listStageSuccess = (payload) => {
    return {
        type: actionTypes.LIST_STAGE_SUCCESS,
        payload
    };
};
export const listStageError = (payload) => {
    return {
        type: actionTypes.LIST_STAGE_ERROR,
        payload
    };
};

export const getProjectList = (payload) => {
    return {
        type: actionTypes.GET_PROJECT_LIST, payload
    };
};
export const getProjectListSuccess = (payload) => {
    return {
        type: actionTypes.GET_PROJECT_LIST_SUCCESS,
        payload
    };
};
export const getProjectListError = (payload) => {
    return {
        type: actionTypes.GET_PROJECT_LIST_ERROR,
        payload
    };
};