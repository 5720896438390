
const initialState = {
    designationLi: '',
    updatestatus: '',
    deletestatus: '',
    editstatus: '',
    updatestat: '',
    addDesignationrating: '',
    updateDesignationrating: '',
    importdesignationStatus:''
}

const designationReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'FETCH_DESIGNATION':
            return { ...state, designationLi: '' }
        case 'FETCH_DESIGNATION_SUCCESS':
            return {
                ...state,
                designationLi: action.payload,
                updatestatus: '',
                deletestatus: '',
                updatestat: '',
                addDesignationrating: '',
                updateDesignationrating: ''
            }
        case 'FETCH_DESIGNATION_ERROR':
            return { ...state, designationLi: '' }

        case 'ADD_DESIGNATION':
            return { ...state, updatestatus: '' }
        case 'ADD_DESIGNATION_SUCCESS':
            return { ...state, updatestatus: 'SUCCESS' }
        case 'ADD_DESIGNATION_ERROR':
            return { ...state, updatestatus: 'ERROR' }

        case 'DELETE_DESIGNATION':
            return { ...state, deletestatus: '' }
        case 'DELETE_DESIGNATION_SUCCESS':
            return { ...state, deletestatus: 'SUCCESS' }
        case 'DELETE_DESIGNATION_ERROR':
            return { ...state, deletestatus: 'ERROR' }

        case 'ADD_DESIGNATION_RATING':
            return { ...state, addDesignationrating: '' }
        case 'ADD_DESIGNATION_RATING_SUCCESS':
            return { ...state, addDesignationrating: 'success' }
        case 'ADD_DESIGNATION_RATING_ERROR':
            return { ...state, addDesignationrating: 'error' }

        case 'UPDATE_DESIGNATION_RATING':
            return { ...state, updateDesignationrating: '' }
        case 'UPDATE_DESIGNATION_RATING_SUCCESS':
            return { ...state, updateDesignationrating: 'success' }
        case 'UPDATE_DESIGNATION_RATING_ERROR':
            return { ...state, updateDesignationrating: 'error' }

        case 'EDIT_DESIGNATION':
            return { ...state, editstatus: '' }
        case 'EDIT_DESIGNATION_SUCCESS':

            return { ...state, editstatus: 'SUCCESS' }
        case 'EDIT_DESIGNATION_ERROR':
            return { ...state, editstatus: 'ERROR' }

        case 'UPDATE_DESIGNATION':
            return { ...state, updatestat: '' }
        case 'UPDATE_DESIGNATION_SUCCESS':
            return { ...state, updatestat: 'SUCCESS' }
        case 'UPDATE_DESIGNATION_ERROR':
            return { ...state, updatestat: 'ERROR' }


        case 'IMPORT_DESIGNATION':
            return { ...state, importdesignationStatus: '' }
        case 'IMPORT_DESIGNATION_SUCCESS':
            return { ...state, importdesignationStatus: 'SUCCESS' }
        case 'IMPORT_DESIGNATION_ERROR':
            return { ...state, importdesignationStatus: 'ERROR' }


        default:
            return state
    }
}
export default designationReducer