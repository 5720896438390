import { combineReducers } from 'redux';
const initialState = {
    // LEAVE TYPE
    desigvalues: [],
    saveStatus: '',
    updateStatus: '',
    deleteStatus: '',
    listlevaeType: [],
    updatevalues: [],
    deletevalues: [],
    listlevaeidvalues: [],
    //rules
    leavevalues: [],
    ruleSaveStatus: '',
    ruleUpdateStatus: '',
    updataerulevalue: [],
}


const shiftReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'SAVE_LEAVETYPE':
            return { ...state, saveStatus: '' }
        case 'SAVE_LEAVETYPE_SUCCESS':
            return { ...state, saveStatus: action.payload }
        case 'SAVE_LEAVETYPE_ERROR':
            return { ...state, saveStatus: 'error' }

        case 'UPDATE_LEAVE_TYPE':
            return { ...state, updateStatus: '' }
        case 'UPDATE_LEAVE_TYPE_SUCCESS':
            return { ...state, updateStatus: action.payload }
        case 'UPDATE_LEAVE_TYPE_ERROR':
            return { ...state, updateStatus: 'error' }

        case 'FETCH_LEAVE':
            return { ...state, listlevaeType: [], saveStatus: '', updateStatus: '', deleteStatus: '', ruleSaveStatus: '', ruleUpdateStatus: '' }
        case 'FETCH_LEAVE_SUCCESS':
            return { ...state, listlevaeType: action.payload }
        case 'FETCH_LEAVE_ERROR':
            return { ...state, listlevaeType: [] }

        case 'DELETE_LEAVE':
            return { ...state, deleteStatus: '' }
        case 'DELETE_LEAVE_SUCCESS':
            return { ...state, deleteStatus: action.payload }
        case 'DELETE_LEAVE_ERROR':
            return { ...state, deleteStatus: 'error' }

        case 'SAVE_RULE':
            return { ...state, ruleSaveStatus: '' }
        case 'SAVE_RULE_SUCCESS':
            return { ...state, ruleSaveStatus: action.payload }
        case 'SAVE_RULE_ERROR':
            return { ...state, ruleSaveStatus: 'error' }

        case 'EDIT_RULE_SUCCESS ':
            return { ...state, ruleUpdateStatus: '' }
        case 'EDIT_RULE_SUCCESS':
            return { ...state, ruleUpdateStatus: action.payload }
        case 'EDIT_RULE_ERROR':
            return { ...state, ruleUpdateStatus: 'error' }
        default:
            return state
    }
}
export default shiftReducer