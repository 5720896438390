import axios from '@service/ApiClient'
// import { siteUrl } from './config'

class dealService {
    constructor(callback) {
        this.callback = callback
    }
    async saveDeal(payload) {
        return await axios.post('/Crm/Deal/save_deal', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listDeal(payload) {
        return await axios.post('/Crm/Deal/list_deal', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listStage(payload) {
        return await axios.post('/Crm/Deal/list_dealstage', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updateDeal(payload) {
        return await axios.post('/Crm/Deal/update_deal_details', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listStage(payload) {
        return await axios.post('/Crm/Deal/list_dealstage', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchDealDataById(payload) {
        return await axios.post('/Crm/Deal/list_deal_by_id', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteDeal(payload) {
        return await axios.post('/Crm/Deal/delete_deal', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async getProjectList(payload) {
        return await axios.post('/PMS/Project/list_projects', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new dealService();