
import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import unitService from '@service/admin/masterService/eventMasterService';
import {
  eventMasterContactSuccess, eventMasterContactError,
} from './action';

function* eventMasterContact({ payload }) {
  try {
    const data = yield call(unitService.eventMasterContact, payload);

    yield put(eventMasterContactSuccess(data));
  } catch (error) {
    yield put(eventMasterContactError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.EVENTMASTER_CONTACT, eventMasterContact)]);

}