
export const actionTypes = {
    SAVE_BRAND: 'SAVE_BRAND',
    SAVE_BRAND_SUCCESS: 'SAVE_BRAND_SUCCESS',
    SAVE_BRAND_ERROR: 'SAVE_BRAND_ERROR',
    LIST_BRAND: 'LIST_BRAND',
    LIST_BRAND_SUCCESS: 'LIST_BRAND_SUCCESS',
    LIST_BRAND_ERROR: 'LIST_BRAND_ERROR',
    UPDATE_BRAND: 'UPDATE_BRAND',
    UPDATE_BRAND_SUCCESS: 'UPDATE_BRAND_SUCCESS',
    UPDATE_BRAND_ERROR: 'UPDATE_BRAND_ERROR',
    DELETE_BRAND: 'DELETE_BRAND',
    DELETE_BRAND_SUCCESS: 'DELETE_BRAND_SUCCESS',
    DELETE_BRAND_ERROR: 'DELETE_BRAND_ERROR',
};

export const saveBrand = (payload) => {
    return {
        type: actionTypes.SAVE_BRAND, payload
    };
};
export const saveBrandSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_BRAND_SUCCESS,
        payload
    };
};
export const saveBrandError = (payload) => {
    return {
        type: actionTypes.SAVE_BRAND_ERROR,
        payload
    };
};

export const listBrand = (payload) => {

    return {
        type: actionTypes.LIST_BRAND, payload

    };
};
export const listBrandSuccess = (payload) => {
    return {
        type: actionTypes.LIST_BRAND_SUCCESS,
        payload
    };
};
export const listBrandError = (payload) => {
    return {
        type: actionTypes.LIST_BRAND_ERROR,
        payload
    };
};

export const updateBrand = (payload) => {
    return {
        type: actionTypes.UPDATE_BRAND, payload
    };
};
export const updateBrandSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_BRAND_SUCCESS,
        payload
    };
};
export const updateBrandError = (payload) => {
    return {
        type: actionTypes.UPDATE_BRAND_ERROR,
        payload
    };
};

export const deleteBrand = (payload) => {
    return {
        type: actionTypes.DELETE_BRAND, payload
    };
};
export const deleteBrandSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_BRAND_SUCCESS,
        payload
    };
};
export const deleteBrandError = (payload) => {
    return {
        type: actionTypes.DELETE_BRAND_ERROR,
        payload
    };
};
