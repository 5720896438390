import axios from '@service/ApiClient'

class companyservice {
    constructor(callback) {
        this.callback = callback
    }
    async saveCompany(payload) {
        return await axios.post('Crm/Company/save_company', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listCompany(payload) {
        return await axios.post('Crm/Company/list_companies', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteCompany(payload) {
        return await axios.post('Crm/Company/delete_company',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateCompany(payload) {
        return await axios.post('Crm/Company/update_company',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new companyservice();