import axios from '@service/ApiClient'
// import { siteUrl } from './config'

class quoteService {
    constructor(callback) {
        this.callback = callback
    }
    async listDealForQuote(payload) {
        return await axios.post('/Inventory/Quote/list_deal_for_quote', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async saveQuote(payload) {
        return await axios.post('/Inventory/Quote/save_quote', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async listQuote(payload) {
        return await axios.post('/Inventory/Quote/list_quote_detils', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchQuoteById(payload) {
        return await axios.post('/Inventory/Quote/get_quote_details_by_id', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateQuote(payload) {
        return await axios.post('/Inventory/Quote/update_quote', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async getTermsAndCondition(payload) {
        return await axios.post('/Inventory/Quote/general_terms_condition_keyword', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteQuote(payload) {
        return await axios.post('/Inventory/Quote/delete_quote', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new quoteService();