import axios from '@service/ApiClient'
// import { siteUrl } from './config'

class purchaseOrderService {
    constructor(callback) {
        this.callback = callback
    }
    async savePo(payload) {
        return await axios.post('/Inventory/Purchaseorder/save_purchase_order', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listPo(payload) {
        return await axios.post('Assetmanagement/Asset_master/purchaseorder_list', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchPoById(payload) {
        return await axios.post('Assetmanagement/Asset_master/purchaseorder_view', payload
        ).then((response) => {
            console.log('response.data',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listPurchaseRequestPo(payload) {
        return await axios.post('/Inventory/Purchaseorder/list_purchase_requisition_for_po', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listVendorPurchaseRequest(payload) {
        return await axios.post('/Inventory/Purchaseorder/get_pr_list_by_vendor', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async getPrItemsForPo(payload) {
        return await axios.post('/Inventory/Purchaseorder/get_remaining_pr_items', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updatePo(payload) {
        return await axios.post('/Inventory/Purchaseorder/update_purchase_order', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async savePurchaseOrder(payload) {
        return await axios.post('Assetmanagement/Asset_master/add_purchaseorder', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new purchaseOrderService();