import axios from '@service/ApiClient'

class industryMasterService {
    constructor(callback) {
        this.callback = callback
    }
    async saveIndustry(payload) {
        return await axios.post('/Crm/Industry/save_industry', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listIndustry(payload) {
        return await axios.post('/Crm/Industry/list_industry', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteIndustry(payload) {
        return await axios.post('/Crm/Industry/delete_industry', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async editIndustry(payload) {
        return await axios.post('/Crm/Industry/update_industry', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new industryMasterService();