import axios from '../../ApiClient'
// import { siteUrl } from './config'

class skillsetservice {
    constructor(callback) {
        this.callback = callback
    }
    async addSkillset(payload) {
        return await axios.post('/HR/Skillset_master/save_skills', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updateSkillset(payload) {
        return await axios.post('/HR/Skillset_master/update_skills', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteSkillset(payload) {
        return await axios.post('/HR/Skillset_master/delete_skills', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async AddSkillsetRating(payload) {
        return await axios.post('/HR/Skillset_master/save_skillrate', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async UpdateSkillsetRating(payload) {
        return await axios.post('/HR/Skillset_master/update_skillrate', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchSkillset(payload) {
        return await axios.post('/HR/Skillset_master/list_skills', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

}



export default new skillsetservice();