import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import brandMasterService from '@service/admin/masterService/brandMasterService';
import {
  saveBrandSuccess, saveBrandError,
  listBrandSuccess, listBrandError,
  updateBrandSuccess, updateBrandError,
  deleteBrandSuccess, deleteBrandError
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'

function* saveBrand({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(brandMasterService.saveBrand, payload);

    yield put(saveBrandSuccess(data));
  } catch (error) {
    yield put(saveBrandError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listBrand({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(brandMasterService.listBrand, payload);
    Array.isArray(data) ?
      yield put(listBrandSuccess(data)) :
      yield put(listBrandSuccess([]));
  } catch (error) {
    yield put(listBrandError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* updateBrand({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(brandMasterService.updateBrand, payload);
    yield put(updateBrandSuccess(data))
  } catch (error) {
    yield put(updateBrandError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* deleteBrand({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(brandMasterService.deleteBrand, payload);
    yield put(deleteBrandSuccess(data))
  } catch (error) {
    yield put(deleteBrandError(error));
  } finally {
    yield put(stopLoader());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_BRAND, saveBrand)]);
  yield all([takeEvery(actionTypes.LIST_BRAND, listBrand)]);
  yield all([takeEvery(actionTypes.UPDATE_BRAND, updateBrand)]);
  yield all([takeEvery(actionTypes.DELETE_BRAND, deleteBrand)]);
}