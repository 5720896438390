
import { actionTypes } from './action';

export const initialState = {
    saveStoreValues: '',
    listStoreValues: [],
    listSubStoreValues: [],
    deleteStoreValues: '',
    updateStoreValues: ''
}

const saveStoreReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_STORE:
            return { ...state, saveStoreValues: '' }
        case actionTypes.SAVE_STORE_SUCCESS:
            return { ...state, saveStoreValues: action.payload }
        case actionTypes.SAVE_STORE_ERROR:
            return { ...state, saveStoreValues: '' }

        case actionTypes.UPDATE_STORE:
            return { ...state, updateStoreValues: '' }
        case actionTypes.UPDATE_STORE_SUCCESS:
            return { ...state, updateStoreValues: action.payload }
        case actionTypes.UPDATE_STORE_ERROR:
            return { ...state, updateStoreValues: '' }

        case actionTypes.LIST_STORE:
            return { ...state, listStoreValues: [] }
        case actionTypes.LIST_STORE_SUCCESS:
            return {
                ...state,
                listStoreValues: action.payload,
                saveStoreValues: '',
                updateStoreValues: '',
                deleteStoreValues: ''
            }
        case actionTypes.LIST_STORE_ERROR:
            return { ...state, listStoreValues: [] }

        case actionTypes.LIST_SUBSTORE:
            return { ...state, listSubStoreValues: [] }
        case actionTypes.LIST_SUBSTORE_SUCCESS:
            return { ...state, listSubStoreValues: action.payload }
        case actionTypes.LIST_SUBSTORE_ERROR:
            return { ...state, listSubStoreValues: [] }

        case actionTypes.DELETE_STORE:
            return { ...state, deleteStoreValues: '' }
        case actionTypes.DELETE_STORE_SUCCESS:
            return { ...state, deleteStoreValues: action.payload }
        case actionTypes.DELETE_STORE_ERROR:
            return { ...state, deleteStoreValues: 'error' }

        default:
            return state
    }
}

export default saveStoreReducer