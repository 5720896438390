import { actionTypes } from './action';

export const initialState = {
    saveStatus: '',
    deleteStatus: '',
    updateStatus: '',
    quoteList: [],
    soList: [],
    soData: {}
}
const soReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LIST_QUOTE_FOR_SO:
            return { ...state, quoteList: [] }
        case actionTypes.LIST_QUOTE_FOR_SO_SUCCESS:
            return { ...state, quoteList: action.payload }
        case actionTypes.LIST_QUOTE_FOR_SO_ERROR:
            return { ...state, quoteList: [] }

        case actionTypes.SAVE_SO:
            return { ...state, saveStatus: '' }
        case actionTypes.SAVE_SO_SUCCESS:
            return { ...state, saveStatus: action.payload }
        case actionTypes.SAVE_SO_ERROR:
            return { ...state, saveStatus: 'error' }

        case actionTypes.UPDATE_SO:
            return { ...state, updateStatus: '' }
        case actionTypes.UPDATE_SO_SUCCESS:
            return { ...state, updateStatus: action.payload }
        case actionTypes.UPDATE_SO_ERROR:
            return { ...state, updateStatus: 'error' }

        case actionTypes.DELETE_SO:
            return { ...state, deleteStatus: '' }
        case actionTypes.DELETE_SO_SUCCESS:
            return { ...state, deleteStatus: action.payload }
        case actionTypes.DELETE_SO_ERROR:
            return { ...state, deleteStatus: 'error' }

        case actionTypes.LIST_SO:
            return { ...state, soList: [], saveStatus: '', deleteStatus: '', updateStatus: '' }
        case actionTypes.LIST_SO_SUCCESS:
            return { ...state, soList: action.payload }
        case actionTypes.LIST_SO_ERROR:
            return { ...state, soList: [] }

        case actionTypes.LIST_SO_BY_ID:
            return { ...state, soData: {} }
        case actionTypes.LIST_SO_BY_ID_SUCCESS:
            return { ...state, soData: action.payload }
        case actionTypes.LIST_SO_BY_ID_ERROR:
            return { ...state, soData: {} }

        default:
            return state
    }
}

export default soReducer
