import axios from '@service/ApiClient'
// import { siteUrl } from './config'

class quoteService {
    constructor(callback) {
        this.callback = callback
    }
    async listQuoteForSo(payload) {
        return await axios.post('/Inventory/SalesOrder/list_quote_for_so', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async saveSo(payload) {
        return await axios.post('/Inventory/SalesOrder/save_so', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteSo(payload) {
        return await axios.post('/Inventory/SalesOrder/delete_so', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateSo(payload) {
        return await axios.post('/Inventory/SalesOrder/update_sales_order', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async listSo(payload) {
        return await axios.post('/Inventory/SalesOrder/list_so_details', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchSoById(payload) {
        return await axios.post('/Inventory/SalesOrder/get_so_details_by_so_id', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new quoteService();