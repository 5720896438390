import axios from '@service/ApiClient'
class equipmentMasterService {
    constructor(callback) {
        this.callback = callback
    }
    async saveEquipmentMaster(payload) {
        return await axios.post('/Equipment/Equipment/equipment_master_save', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async technicanlistmaster(payload) {
        return await axios.post('/Crm/User/technician_list', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listEquipmentMaster(payload) {
        return await axios.post('/Equipment/Equipment/equipment_master_list', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateEquipmentMaster(payload) {
        return await axios.post('/Equipment/Equipment/equipment_master_update', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteEquipmentMaster(payload) {
        return await axios.post('/Equipment/Equipment/equipment_master_delete', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }


    async complaintRequestView(payload) {
        return await axios.post('Complaint/Complaint/complaint_view', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }


    async employeeListForComplaint(payload) {
        return await axios.post('Complaint/Complaint/employee_list', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }


    async assignTechnicianForComplaint(payload) {
        return await axios.post('Complaint/Complaint/assign_technician', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }


    async addInspectionForComplaint(payload) {
        return await axios.post('Complaint/Complaint/save_technician_inspection', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updateInspectionForComplaint(payload) {
        return await axios.post('Complaint/Complaint/update_technician_inspection', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listInspectionForComplaint(payload) {
        // console.log("payload",payload);
        return await axios.post('Complaint/Complaint/list_technician_inspection', payload
        ).then((response) => {
            // console.log("response",response);
            return response.data
        }).catch((error) => console.log("error",error));
    }

    async saveSparepartsForComplaint(payload) {
        return await axios.post('Complaint/Complaint/save_spare_parts', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listSparepartsForComplaint(payload) {
        return await axios.post('Complaint/Complaint/list_spare_parts_by_request_id', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async removeSparepartsForComplaint(payload) {
        return await axios.post('Complaint/Complaint/remove_spare_parts', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async changetechnicianstatusForComplaint(payload) {
        return await axios.post('Complaint/Complaint/update_task_status_by_technician', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async getTechnicianTaskByComplaintId(payload) {
        return await axios.post('Complaint/Complaint/get_technician_task_by_complaint_request', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async changeEquipmentComplaintRequestStatus(payload) {
        return await axios.post('Complaint/Complaint/update_complaint_status', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async Assetcomplaintsave(payload) {
        return await axios.post('Complaint/Complaint/asset_complaint_save', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    
    async listcomplaintrequest(payload) {
        // console.log('listcomplaintrequest payload',payload);
        return await axios.post('Complaint/Complaint/list_complaint_request', payload
        // return await axios.post('Report/Report/employee_task_report', payload
        ).then((response) => {
            // console.log('response listcomplaintrequest',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }


    async listassetcalibrationrooster(payload) {
        return await axios.post('Complaint/Complaint/list_asset_calibration_rooster', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    
    async listassetservicerooster(payload) {
        return await axios.post('/Complaint/Complaint/list_asset_service_rooster', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }


    async assetroosterviewidentification(payload) {
        return await axios.post('Complaint/Complaint/asset_rooster_view_by_identification_no', payload
        ).then((response) => {
            return response.data 
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async viewCalibrationDetails(payload) {
        return await axios.post('/Complaint/Complaint/asset_rooster_view_by_identification_no',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async viewServiceDetails(payload) {
        return await axios.post('/Complaint/Complaint/asset_rooster_view_by_identification_no',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async viewServiceRooster(payload) {
        return await axios.post('/Complaint/Complaint/asset_service_rooster_view',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }


    async fetchComplaintActivityLog(payload) {
        return await axios.post('Complaint/Complaint/task_history',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async assignedServiceVendors(payload) {
        return await axios.post('Complaint/Complaint/list_assigned_external_vendors',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async removeDepartmentForComplaint(payload) {
        return await axios.post('Complaint/Complaint/remove_department',
            payload,
        ).then((response) => {
            // console.log('response',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }


    async assignVendorToService(payload) {
        return await axios.post('Complaint/Complaint/assign_external_vendor',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async tasklist(payload) {
        return await axios.post('Crm/Task/get_all_task',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async saveDeliveryNote(payload) {
        // console.log('saveDeliveryNote pay',payload);
        return await axios.post('/Delivery_note/delivery_note_insert',
            payload,
        ).then((response) => {
            // console.log('saveDeliveryNote pay',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async saveReceiptNote(payload) {
        // console.log('saveReceiptNote pay',payload);
        return await axios.post('/Delivery_note/receipt_note_insert',
            payload,
        ).then((response) => {
            // console.log('saveReceiptNote pay',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async saveEquipmentAllocation(payload) {
        // console.log('saveEquipmentAllocation pay',payload);
        return await axios.post('Assetmanagement/Asset_master/equipment_allocation',
            payload,
        ).then((response) => {
            // console.log('saveEquipmentAllocation pay',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async listEquipmentAllocation(payload) {
        // console.log('listEquipmentAllocation pay',payload);
        return await axios.post('Assetmanagement/Asset_master/equipment_allocation_list',
            payload,
        ).then((response) => {
            // console.log('listEquipmentAllocation pay',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async ViewEquipmentAllocation(payload) {
        // console.log('ViewEquipmentAllocation pay',payload);
        return await axios.post('Assetmanagement/Asset_master/equipment_allocation_view',
            payload,
        ).then((response) => {
            // console.log('ViewEquipmentAllocation pay',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async ReturnEquipmentAllocation(payload) {
        // console.log('ReturnEquipmentAllocation pay',payload);
        return await axios.post('Assetmanagement/Asset_master/set_equipment_alocation_return',
            payload,
        ).then((response) => {
            // console.log('ReturnEquipmentAllocation pay',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async listDeliveryNote(payload) {
        // console.log('listDeliveryNote pay',payload);
        return await axios.post('/Delivery_note/list_delivery_note',
            payload,
        ).then((response) => {
            // console.log('listDeliveryNote pay',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async ViewDeliveryNote(payload) {
        // console.log('ViewDeliveryNote pay',payload);
        return await axios.post('/Delivery_note/delivery_note_view',
            payload,
        ).then((response) => {
            // console.log('ViewDeliveryNote pay',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async update_service_status_external_vendor(payload) {
        // console.log('update_service_status_external_vendor pay',payload);
        return await axios.post('Complaint/Complaint/update_service_status_external_vendor',
            payload,
        ).then((response) => {
            // console.log('update_service_status_external_vendor response',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchReceiptNote(payload) {
        // console.log('fetchReceiptNote pay',payload);
        return await axios.post('/Delivery_note/receipt_note_list',
            payload,
        ).then((response) => {
            // console.log('fetchReceiptNote response',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchTaskReport(payload) {
        // console.log('fetchTaskReport pay',payload);
        return await axios.post('Report/Report/employee_task_report', payload
        ).then((response) => {
            // console.log('fetchTaskReport response',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async departmentwisebarcode(payload) {
       
        return await axios.post('Assetmanagement/Asset_master/search_equipments_by_department', payload
        ).then((response) => {
            // console.log('departmentwisebarcode response',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async generatebarcodebulk(payload) {
        // console.log('generatebarcodebulk pay',payload);
        return await axios.post('Assetmanagement/Asset_master/generate_barcode_bulk', payload
        ).then((response) => {
            // console.log('generatebarcodebulk response',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

}

export default new equipmentMasterService();