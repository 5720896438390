
export const actionTypes = {
    
    SAVE_PARAMETER: 'SAVE_PARAMETER',
    SAVE_PARAMETER_SUCCESS: 'SAVE_PARAMETER_SUCCESS',
    SAVE_PARAMETER_ERROR: 'SAVE_PARAMETER_ERROR',
    LIST_PARAMETER: 'LIST_PARAMETER',
    LIST_PARAMETER_SUCCESS: 'LIST_PARAMETER_SUCCESS',
    LIST_PARAMETER_ERROR: 'LIST_PARAMETER_ERROR',
    UPDATE_PARAMETER: 'UPDATE_PARAMETER',
    UPDATE_PARAMETER_SUCCESS: 'UPDATE_PARAMETER_SUCCESS',
    UPDATE_PARAMETER_ERROR: 'UPDATE_PARAMETER_ERROR',
    DELETE_PARAMETER: 'DELETE_PARAMETER',
    DELETE_PARAMETER_SUCCESS: 'DELETE_PARAMETER_SUCCESS',
    DELETE_PARAMETER_ERROR: 'DELETE_PARAMETER_ERROR'
};

export const saveParameter = (payload) => {
    return {
        type: actionTypes.SAVE_PARAMETER, payload
    };
};
export const saveParameterSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_PARAMETER_SUCCESS,
        payload
    };
};
export const saveParameterError = (payload) => {
    return {
        type: actionTypes.SAVE_PARAMETER_ERROR,
        payload
    };
};

export const listParameter = (payload) => {
    return {
        type: actionTypes.LIST_PARAMETER, payload
    };
};
export const listParameterSuccess = (payload) => {
    return {
        type: actionTypes.LIST_PARAMETER_SUCCESS,
        payload
    }; 
};
export const listParameterError = (payload) => {
    return {
        type: actionTypes.LIST_PARAMETER_ERROR,
        payload
    };
};

export const updateParameter = (payload) => {
    return {
        type: actionTypes.UPDATE_PARAMETER, payload
    };
};
export const updateParameterSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_PARAMETER_SUCCESS,
        payload
    };
};
export const updateParameterError = (payload) => {
    return {
        type: actionTypes.UPDATE_PARAMETER_ERROR,
        payload
    };
};

export const deleteParameter = (payload) => {
    return {
        type: actionTypes.DELETE_PARAMETER, payload
    };
};
export const deleteParameterSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_PARAMETER_SUCCESS,
        payload
    };
};
export const deleteParameterError = (payload) => {
    return {
        type: actionTypes.DELETE_PARAMETER_ERROR,
        payload
    };
};