import axios from '@service/ApiClient'
// import { siteUrl } from './config'

class mrvService {
    constructor(callback) {
        this.callback = callback
    }

    async listPo(payload) {
        return await axios.post('Assetmanagement/Asset_master/purchaseorder_list_for_mrv', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchPoById(payload) {
        return await axios.post('Assetmanagement/Asset_master/purchaseorder_view', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async GenerateBarcode(payload) {
        return await axios.post('Assetmanagement/Asset_master/generate_barcode', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchMrvById(payload) {
        return await axios.post('Assetmanagement/Asset_master/view_mrv', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async checkBarcode(payload) {
        return await axios.post('Inventory/Mrv/barcode_checking_in_mrv', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listStoreMrv(payload) {
        return await axios.post('Inventory/Mrv/list_stores', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async saveMrv(payload) {
        return await axios.post('Assetmanagement/Asset_master/add_mrv', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listMrv(payload) {
        return await axios.post('Assetmanagement/Asset_master/mrv_list', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }


}

export default new mrvService();