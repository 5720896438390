import { actionTypes } from './action';

export const initialState = {
    saveSuccess: '',
    listRole: [],
    updateSuccess: '',
    deleteSuccess: '',
    listRoleAssignedMember: [],
    removeRoleAssignSuccess: '',
    permissionDetails: {},
    setPermission: '',
    isLoading: false
};

function reducer(state = initialState, action) {
    switch (action.type) {

        case actionTypes.SAVE_ROLE:
            return {
                ...state,
                error: null,
                isLoading: true,
                saveSuccess: ''
            };
        case actionTypes.SAVE_ROLE_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                saveSuccess: 'success'
            };
        case actionTypes.SAVE_ROLE_ERROR:
            return {
                ...state,
                error: action.data,
                isLoading: false,
                saveSuccess: 'error'
            };


        case actionTypes.LIST_ROLE:
            return {
                ...state,
                error: null,
                isLoading: true,
                listRole: []
            };
        case actionTypes.LIST_ROLE_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                listRole: action.data
            };
        case actionTypes.LIST_ROLE_ERROR:
            return {
                ...state,
                error: action.data,
                isLoading: false,
                listRole: []
            };


        case actionTypes.UPDATE_ROLE:
            return {
                ...state,
                error: null,
                isLoading: true,
                updateSuccess: ''
            };
        case actionTypes.UPDATE_ROLE_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                updateSuccess: 'success'
            };
        case actionTypes.UPDATE_ROLE_ERROR:
            return {
                ...state,
                error: action.data,
                isLoading: false,
                updateSuccess: 'error'
            };


        case actionTypes.DELETE_ROLE:
            return {
                ...state,
                error: null,
                isLoading: true,
                deleteSuccess: ''
            };
        case actionTypes.DELETE_ROLE_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                deleteSuccess: 'success'
            };
        case actionTypes.DELETE_ROLE_ERROR:
            return {
                ...state,
                error: action.data,
                isLoading: false,
                deleteSuccess: 'error'
            };


        case actionTypes.LIST_ROLE_ASSIGNED_MEMBER:
            return {
                ...state,
                error: null,
                isLoading: true,
                listRoleAssignedMember: []
            };
        case actionTypes.LIST_ROLE_ASSIGNED_MEMBER_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                listRoleAssignedMember: action.data
            };
        case actionTypes.LIST_ROLE_ASSIGNED_MEMBER_ERROR:
            return {
                ...state,
                error: action.data,
                isLoading: false,
                listRoleAssignedMember: []
            };


        case actionTypes.REMOVE_ROLE_ASSIGNED_MEMBER:
            return {
                ...state,
                error: null,
                isLoading: true,
                removeRoleAssignSuccess: ''
            };
        case actionTypes.REMOVE_ROLE_ASSIGNED_MEMBER_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                removeRoleAssignSuccess: 'success'
            };
        case actionTypes.REMOVE_ROLE_ASSIGNED_MEMBER_ERROR:
            return {
                ...state,
                error: action.data,
                isLoading: false,
                removeRoleAssignSuccess: 'error'
            };


        case actionTypes.PERMISSION_DETAILS:
            return {
                ...state,
                error: null,
                isLoading: true,
                permissionDetails: {}
            };
        case actionTypes.PERMISSION_DETAILS_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                permissionDetails: action.data
            };
        case actionTypes.PERMISSION_DETAILS_ERROR:
            return {
                ...state,
                error: action.data,
                isLoading: false,
                permissionDetails: {}
            };


        case actionTypes.SET_ROLE_PERMISSION:
            return {
                ...state,
                error: null,
                isLoading: true,
                setPermission: ''
            };
        case actionTypes.SET_ROLE_PERMISSION_SUCCESS:
            return {
                ...state,
                error: null,
                isLoading: false,
                setPermission: 'success'
            };
        case actionTypes.SET_ROLE_PERMISSION_ERROR:
            return {
                ...state,
                error: action.data,
                isLoading: false,
                setPermission: 'error'
            };

        default:
            return state;
    }
}

export default reducer;
