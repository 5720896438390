import axios from '@service/ApiClient'

class weekendMasterService {

    constructor(callback) {
        this.callback = callback
    }

    async saveWeekend(payload) {
        return await axios.post('/Organization/Weekend_master/save_weekend', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listWeekend(payload) {
        return await axios.post('/Organization/Weekend_master/list_weekend', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteWeekend(payload) {
        return await axios.post('/Organization/Weekend_master/delete_weekend', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updateWeekend(payload) {
        return await axios.post('/Organization/Weekend_master/update_weekend', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    
}

export default new weekendMasterService();