
import { actionTypes } from './action';

export const initialState = {
    saveProjectValue: '',
    listProjectValue: [],
    updateProjectValues: '',
    deleteprojectValues: ''
}
const listProjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_PROJECT:
            return { ...state, saveProjectValue: '' }
        case actionTypes.SAVE_PROJECT_SUCCESS:
            return { ...state, saveProjectValue: 'SUCCESS' }
        case actionTypes.SAVE_PROJECT_ERROR:
            return { ...state, saveProjectValue: 'ERROR' }

        case actionTypes.LIST_PROJECT:
            return { ...state, listProjectValue: [] }
        case actionTypes.LIST_PROJECT_SUCCESS:
            return { ...state, listProjectValue: action.payload }
        case actionTypes.LIST_PROJECT_ERROR:
            return { ...state, listProjectValue: [] }

        case actionTypes.UPDATE_PROJECT:
            return { ...state, updateProjectValues: '' }
        case actionTypes.UPDATE_PROJECT_SUCCESS:
            return { ...state, updateProjectValues: 'SUCCESS' }
        case actionTypes.UPDATE_PROJECT_ERROR:
            return { ...state, updateProjectValues: 'ERROR' }

        case actionTypes.DELETE_PROJECT:
            return { ...state, deleteprojectValues: '' }
        case actionTypes.DELETE_PROJECT_SUCCESS:
            return { ...state, deleteprojectValues: 'SUCCESS' }
        case actionTypes.DELETE_PROJECT_ERROR:
            return { ...state, deleteprojectValues: 'ERROR' }

        default:
            return state
    }
}
export default listProjectReducer