import { actionTypes } from './action';

export const initialState = {
    saveStatus: '',
    deleteStatus: '',
    updateStatus: '',
    poList: [],
    poData:'',
    prlist: [],
    prVendorList: [],
    prItemList: [],
    SavePoStatus:''
}
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_PO:
            return { ...state, saveStatus: '' }
        case actionTypes.SAVE_PO_SUCCESS:
            return { ...state, saveStatus: action.payload }
        case actionTypes.SAVE_PO_ERROR:
            return { ...state, saveStatus: 'error' }

        case actionTypes.LIST_PO:
            return { ...state, poList: [], saveStatus: '', deleteStatus: '', updateStatus: '' }
        case actionTypes.LIST_PO_SUCCESS:
            return { ...state, poList: action.payload }
        case actionTypes.LIST_PO_ERROR:
            return { ...state, poList: [] }

        case actionTypes.FETCH_PO_DATA_BY_ID:
            return { ...state, poData:'' }
        case actionTypes.FETCH_PO_DATA_BY_ID_SUCCESS:
            return { ...state, poData: action.payload }
        case actionTypes.FETCH_PO_DATA_BY_ID_ERROR:
            return { ...state, poData:'' }

        case actionTypes.LIST_PR_FOR_PO:
            return { ...state, prlist: [] }
        case actionTypes.LIST_PR_FOR_PO_SUCCESS:
            return { ...state, prlist: action.payload }
        case actionTypes.LIST_PR_FOR_PO_ERROR:
            return { ...state, prlist: [] }

        case actionTypes.LIST_VENDOR_PR_FOR_PO:
            return { ...state, prVendorList: [] }
        case actionTypes.LIST_VENDOR_PR_FOR_PO_SUCCESS:
            return { ...state, prVendorList: action.payload }
        case actionTypes.LIST_VENDOR_PR_FOR_PO_ERROR:
            return { ...state, prVendorList: [] }

        case actionTypes.GET_PR_ITEMS_FOR_PO:
            return { ...state, prItemList: [] }
        case actionTypes.GET_PR_ITEMS_FOR_PO_SUCCESS:
            return { ...state, prItemList: action.payload }
        case actionTypes.GET_PR_ITEMS_FOR_PO_ERROR:
            return { ...state, prItemList: [] }

        case actionTypes.REMOVE_ITEM_FROM_PR:
            console.log(state);
            return { ...state, prItemList: [] }
        case actionTypes.REMOVE_ITEM_FROM_PR_SUCCESS:
            return { ...state, prItemList: action.payload }
        case actionTypes.REMOVE_ITEM_FROM_PR_ERROR:
            return { ...state, prItemList: [] }

        case actionTypes.UPDATE_PO:
            console.log(state);
            return { ...state, updateStatus: '' }
        case actionTypes.UPDATE_PO_SUCCESS:
            return { ...state, updateStatus: action.payload }
        case actionTypes.UPDATE_PO_ERROR:
            return { ...state, updateStatus: 'error' }

        case 'SAVE_PURCHASE_ORDER':
            return { ...state, SavePoStatus: '' }
        case 'SAVE_PURCHASE_ORDER_SUCCESS':
            return { ...state, SavePoStatus: action.payload }
        case 'SAVE_PURCHASE_ORDER_ERROR':
            return { ...state, SavePoStatus: '' }

        default:
            return state
    }
}

export default userReducer
