
export const actionTypes = {
    
    FETCH_BU_PARAMETERS: 'FETCH_BU_PARAMETERS',
    FETCH_BU_PARAMETERS_SUCCESS: 'FETCH_BU_PARAMETERS_SUCCESS',
    FETCH_BU_PARAMETERS_ERROR: 'FETCH_BU_PARAMETERS_ERROR',
    LIST_SALARY_PARAMETER: 'LIST_SALARY_PARAMETER',
    LIST_SALARY_PARAMETER_SUCCESS: 'LIST_SALARY_PARAMETER_SUCCESS',
    LIST_SALARY_PARAMETER_ERROR: 'LIST_SALARY_PARAMETER_ERROR',
    SAVE_SALARY_PARAMETER: 'SAVE_SALARY_PARAMETER',
    SAVE_SALARY_PARAMETER_SUCCESS: 'SAVE_SALARY_PARAMETER_SUCCESS',
    SAVE_SALARY_PARAMETER_ERROR: 'SAVE_SALARY_PARAMETER_ERROR',
    FETCH_SALARY_PARAMETER: 'FETCH_SALARY_PARAMETER',
    FETCH_SALARY_PARAMETER_SUCCESS: 'FETCH_SALARY_PARAMETER_SUCCESS',
    FETCH_SALARY_PARAMETER_ERROR: 'FETCH_SALARY_PARAMETER_ERROR'
};

export const fetchBuParameter = (payload) => {
    return {
        type: actionTypes.FETCH_BU_PARAMETERS, payload
    };
};
export const fetchBuParameterSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_BU_PARAMETERS_SUCCESS,
        payload
    };
};
export const fetchBuParameterError = (payload) => {
    return {
        type: actionTypes.FETCH_BU_PARAMETERS_ERROR,
        payload
    };
};

export const listSalaryParameter = (payload) => {
    return {
        type: actionTypes.LIST_SALARY_PARAMETER, payload
    };
};
export const listSalaryParameterSuccess = (payload) => {
    return {
        type: actionTypes.LIST_SALARY_PARAMETER_SUCCESS,
        payload
    }; 
};
export const listSalaryParameterError = (payload) => {
    return {
        type: actionTypes.LIST_SALARY_PARAMETER_ERROR,
        payload
    };
};

export const saveSalaryParameter = (payload) => {
    return {
        type: actionTypes.SAVE_SALARY_PARAMETER, payload
    };
};
export const saveSalaryParameterSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_SALARY_PARAMETER_SUCCESS,
        payload
    }; 
};
export const saveSalaryParameterError = (payload) => {
    return {
        type: actionTypes.SAVE_SALARY_PARAMETER_ERROR,
        payload
    };
};

export const fetchSalaryParameter = (payload) => {
    return {
        type: actionTypes.FETCH_SALARY_PARAMETER, payload
    };
};
export const fetchSalaryParameterSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_SALARY_PARAMETER_SUCCESS,
        payload
    };
};
export const fetchSalaryParameterError = (payload) => {
    return {
        type: actionTypes.FETCH_SALARY_PARAMETER_ERROR,
        payload
    };
};

