import { actionTypes } from './action';

export const initialState = {
    saveStatus: '',
    deleteStatus: '',
    updateStatus: '',
    soList: [],
    invoiceList: [],
    storeList: [],
    invoiceData: {}
}
const soReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case actionTypes.LIST_SO_FOR_INVOICE:
            return { ...state, soList: []}
        case actionTypes.LIST_SO_FOR_INVOICE_SUCCESS:
            return { ...state, soList: action.payload }
        case actionTypes.LIST_SO_FOR_INVOICE_ERROR:
            return { ...state, soList: [] }

        case actionTypes.LIST_INVOICE:
            return { ...state, invoiceList: [] }
        case actionTypes.LIST_INVOICE_SUCCESS:
            return { ...state, invoiceList: action.payload }
        case actionTypes.LIST_INVOICE_ERROR:
            return { ...state, invoiceList: [] }

        case actionTypes.SAVE_INVOICE:
            return { ...state, saveStatus: '' }
        case actionTypes.SAVE_INVOICE_SUCCESS:
            return { ...state, saveStatus: action.payload }
        case actionTypes.SAVE_INVOICE_ERROR:
            return { ...state, saveStatus: 'error' }

        case actionTypes.FETCH_INVOICE_BY_ID:
            return { ...state, invoiceData: {} }
        case actionTypes.FETCH_INVOICE_BY_ID_SUCCESS:
            return { ...state, invoiceData: action.payload }
        case actionTypes.FETCH_INVOICE_BY_ID_ERROR:
            return { ...state, invoiceData: {} }

        default:
            return state
    }
}

export default soReducer
