import { actionTypes } from './action';

export const initialState = {
    industriesList: [],
    saveStatus: '',
    updateStatus: '',
    deleteStatus: '',
    leadsList: [],
    leadsData: {},
    leadsAddressData: {},
    convertStatus: '',
    dashboardData: {},
    dashboardFeedData: {},
    todayTaskList: [],
    todayUserTaskList: {},
    taskByDate: [],
    upComingTasks: [],
    teamTasks: [],
}

const listIndustriesReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.LIST_INDUSTRIES:
            return { ...state, industriesList: [] }
        case actionTypes.LIST_INDUSTRIES_SUCCESS:
            return { ...state, industriesList: action.payload }
        case actionTypes.LIST_INDUSTRIES_ERROR:
            return { ...state, industriesList: [] }

        case actionTypes.SAVE_LEADS:
            return { ...state, saveStatus: '' }
        case actionTypes.SAVE_LEADS_SUCCESS:
            return { ...state, saveStatus: action.payload }
        case actionTypes.SAVE_LEADS_ERROR:
            return { ...state, saveStatus: 'error' }

        case actionTypes.LIST_LEADS:
            return { ...state, leadsList: [], saveStatus: '', updateStatus: '', deleteStatus: '', convertStatus: '' }
        case actionTypes.LIST_LEADS_SUCCESS:
            return { ...state, leadsList: action.payload }
        case actionTypes.LIST_LEADS_ERROR:
            return { ...state, leadsList: [] }

        case actionTypes.UPDATE_LEADS:
            return { ...state, updateStatus: '' }
        case actionTypes.UPDATE_LEADS_SUCCESS:
            return { ...state, updateStatus: action.payload }
        case actionTypes.UPDATE_LEADS_ERROR:
            return { ...state, updateStatus: 'error' }

        case actionTypes.DELETE_LEADS:
            return { ...state, deleteStatus: '' }
        case actionTypes.DELETE_LEADS_SUCCESS:
            return { ...state, deleteStatus: action.payload }
        case actionTypes.DELETE_LEADS_ERROR:
            return { ...state, deleteStatus: 'error' }

        case actionTypes.FETCH_LEADS_BY_ID:
            return { ...state, leadsData: {} }
        case actionTypes.FETCH_LEADS_BY_ID_SUCCESS:
            return { ...state, leadsData: action.payload }
        case actionTypes.FETCH_LEADS_BY_ID_ERROR:
            return { ...state, leadsData: {} }

        case actionTypes.FETCH_ADDRESS_LEADS_BY_ID:
            return { ...state, leadsAddressData: {} }
        case actionTypes.FETCH_ADDRESS_LEADS_BY_ID_SUCCESS:
            return { ...state, leadsAddressData: action.payload }
        case actionTypes.FETCH_ADDRESS_LEADS_BY_ID_ERROR:
            return { ...state, leadsAddressData: {} }

        case actionTypes.CONVERT_LEAD:
            return { ...state, convertStatus: '' }
        case actionTypes.CONVERT_LEAD_SUCCESS:
            return { ...state, convertStatus: action.payload }
        case actionTypes.CONVERT_LEAD_ERROR:
            return { ...state, convertStatus: 'error' }

        case 'FETCH_DASHBOARD_DATA':
            return { ...state, dashboardData: {} }
        case 'FETCH_DASHBOARD_DATA_SUCCESS':
            return { ...state, dashboardData: action.payload }
        case 'FETCH_DASHBOARD_DATA_ERROR':
            return { ...state, dashboardData: {} }

        case 'FETCH_DASHBOARD_FEEDS':
            return { ...state, dashboardFeedData: {} }
        case 'FETCH_DASHBOARD_FEEDS_SUCCESS':
            return { ...state, dashboardFeedData: action.payload }
        case 'FETCH_DASHBOARD_FEEDS_ERROR':
            return { ...state, dashboardFeedData: {} }

        case 'FETCH_CURRENT_USER_TASK':
            return { ...state, todayUserTaskList: {} }
        case 'FETCH_CURRENT_USER_TASK_SUCCESS':
            return { ...state, todayUserTaskList: action.payload }
        case 'FETCH_CURRENT_USER_TASK_ERROR':
            return { ...state, todayUserTaskList: {} }

        case 'FETCHTODAY_TASKS':
            return { ...state, todayTaskList: [] }
        case 'FETCHTODAY_TASKS_SUCCESS':
            return { ...state, todayTaskList: action.payload }
        case 'FETCHTODAY_TASKS_ERROR':
            return { ...state, todayTaskList: [] }

        case 'FETCH_TASKS_BYDATE':
            return { ...state, taskByDate: [] }
        case 'FETCH_TASKS_BYDATE_SUCCESS':
            return { ...state, taskByDate: action.payload }
        case 'FETCH_TASKS_BYDATE_ERROR':
            return { ...state, taskByDate: [] }

        case 'FETCH_UPCOMING_TASKS':
            return { ...state, upComingTasks: [] }
        case 'FETCH_UPCOMING_TASKS_SUCCESS':
            return { ...state, upComingTasks: action.payload }
        case 'FETCH_UPCOMING_TASKS_ERROR':
            return { ...state, upComingTasks: [] }

        case 'FETCH_TEAM_MEMBER_TASKS':
            return { ...state, teamTasks: [] }
        case 'FETCH_TEAM_MEMBER_TASKS_SUCCESS':
            return { ...state, teamTasks: action.payload }
        case 'FETCH_TEAM_MEMBER_TASKS_ERROR':
            return { ...state, teamTasks: [] }

        default:
            return state
    }
}

export default listIndustriesReducer