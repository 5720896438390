import axios from "@service/ApiClient";

class contactservice {
  constructor(callback) {
    this.callback = callback;
  }
  async addBuildings(payload) {
    return await axios
      .post("Inventory/Location_master/insert_building_details", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async listbuildings(payload) {
    return await axios
      .post("Inventory/Location_master/list_building_details", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async listFloors(payload) {
    return await axios
      .post("Inventory/Location_master/list_floor_by_building_id", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async getDashboardData(payload) {
    return await axios
      .post("/Dashboard/Dashboard/dashboard", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async getTopAsset(payload) {
    return await axios
      .post("/Dashboard/Dashboard//top_high_value_assets", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async businessrule(payload) {
    return await axios
      .post("authentication/get_business_rule", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async saveFloor(payload) {
    return await axios
      .post("Inventory/Location_master/insert_floor_details", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async saveLocType(payload) {
    return await axios
      .post("Inventory/Location_master/insert_locationtype_details", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async listLocType(payload) {
    return await axios
      .post("Inventory/Location_master/list_location_type_details", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async saveLocationName(payload) {
    return await axios
      .post("Inventory/Location_master/insert_location_name", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async listLocationBuldingFloor(payload) {
    return await axios
      .post("Inventory/Location_master/get_location_building_floor", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async listLocationName(payload) {
    return await axios
      .post("Inventory/Location_master/get_location_all", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async deleteLocationName(payload) {
    return await axios
      .post("Inventory/Location_master/delete_location_name", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async updateLocationName(payload) {
    return await axios
      .post("Inventory/Location_master/update_location_name", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async addEquipmentCategory(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/add_asset_master", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async listEquipmentCategory(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/list_asset_master", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async getLocationByCatidOrFloorid(payload) {
    return await axios
      .post(
        "Assetmanagement/Asset_master/list_locations_by_bid_floorid",
        payload
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async saveEquipmentMaster(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/save_asset_register", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async deleteEquipmentCategory(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/delete_asset_master", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async listEquipmentMaster(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/list_asset_register", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async deleteEquipmentMaster(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/delete_asset_register", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async viewEquipmentCategory(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/view_asset_master", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async updateEquipmentCategory(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/update_asset_master", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async viewEquipmentMaster(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/view_asset_register", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async updateEquipmentMaster(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/update_asset_register", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async addComplaint(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/add_complaints", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async listComplaint(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/list_complaints", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async getEquipmentByNameOrUniqueid(payload) {
    return await axios
      .post(
        "Assetmanagement/Asset_master/get_equipment_by_name_or_uniqueid",
        payload
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async saveEquipmentComplaintRequest(payload) {
    console.log("payload", payload);
    return await axios
      .post("Complaint/Complaint/asset_complaint_save", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async listEquipmentComplaintRequest(payload) {
    return await axios
      .post("Complaint/Complaint/list_complaint_request", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async changeEquipmentComplaintRequestStatus(payload) {
    return await axios
      .post("Complaint/Complaint/update_complaint_status", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }

  async importlocationStatus(payload) {
    return await axios
      .post("Inventory/Location_master/import_location", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }

  async importcomplaint(payload) {
    // console.log("payload",payload);
    return await axios
      .post("Assetmanagement/Asset_master/import_complaints", payload)
      .then((response) => {
        // console.log('response',response)
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }

  async importequipmentcategory(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/import_equipment_category", payload)
      .then((response) => {
        // console.log('response',response)
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }

  async importequipmentmaster(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/import_equipment_master", payload)
      .then((response) => {
        console.log("response", response);
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }

  async equipmentmasterdetails(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/asset_register_view", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }

  async complaintlist(payload) {
    //
    return await axios
      .post("Complaint/Complaint/list_complaint_request", payload)
      .then((response) => {
        console.log("response", response);
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }

  async viewEquipmentDetailsByBarcode(payload) {
    // console.log("viewEquipmentDetailsByBarcode",payload);
    return await axios
      .post(
        "Dashboard/Dashboard/get_equipment_details_by_search_details",
        payload
      )
      .then((response) => {
        // console.log('response Barcode',response.data)
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async listComplaintbyEquipment(payload) {
    return await axios
      .post("Complaint/Complaint/list_complaints_by_equipment", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async EquipmentHistory(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/equipment_history", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async EquipmentCategoryView(payload) {
    // console.log("EquipmentCategoryView",payload);
    return await axios
      .post("Assetmanagement/Asset_master/equipment_category_view", payload)
      .then((response) => {
        // console.log('response EquipmentCategoryView',response.data)
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async CheckEquipment_ActivityExist(payload) {
    // console.log("CheckEquipment_ActivityExist",payload);
    return await axios
      .post(
        "Assetmanagement/Asset_master/check_equipment_activity_exist",
        payload
      )
      .then((response) => {
        // console.log('response CheckEquipment_ActivityExist',response.data)
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async updateEquipmentmaster(payload) {
    // console.log("updateEquipmentmaster",payload);
    return await axios
      .post("Assetmanagement/Asset_master/update_equipment_details", payload)
      .then((response) => {
        // console.log('response updateEquipmentmaster',response.data)
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }

  async fecthQctemplate(payload) {
    return await axios
      .post("Qc_master/Qc_master/index", payload)
      .then((response) => {
        // console.log('response fecthQctemplate',response.data)
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }

  async attachQc_inService(payload) {
    // console.log("attachQc_inService",payload);
    return await axios
      .post("Qc_master/Qc_master/attach_qc_with_equipment_master", payload)
      .then((response) => {
        // console.log('response attachQc_inService',response.data)
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }

  async save_qcDetails(payload) {
    // console.log("save_qcDetails",payload);
    return await axios
      .post("Complaint/Complaint/save_task_qc_details", payload)
      .then((response) => {
        // console.log('response save_qcDetails',response.data)
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async get_task_qc_details(payload) {
    // console.log("get_task_qc_details",payload);
    return await axios
      .post("Complaint/Complaint/get_task_qc_details", payload)
      .then((response) => {
        // console.log('response get_task_qc_details',response.data)
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async deleteqcmaster(payload) {
    // console.log("deleteqcmaster",payload);
    return await axios
      .post("Qc_master/Qc_master/delete_qc_master", payload)
      .then((response) => {
        // console.log('response deleteqcmaster',response.data)
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async GenerateBarcode(payload) {
    // console.log("GenerateBarcode",payload);
    return await axios
      .post("Assetmanagement/Asset_master/equipment_barcode", payload)
      .then((response) => {
        // console.log('response GenerateBarcode',response.data)
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }

  async ActiveInactive(payload) {
    return await axios
      .post(
        "Assetmanagement/Asset_master/set_equipment_active_or_inactive",
        payload
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async DeleteEquipmentMaster(payload) {
    return await axios
      .post("Assetmanagement/Asset_master/delete_equipment_master", payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
  }
  async CheckEquipmentActivityExist(payload) {
    console.log("payload", payload);
    return await axios
      .post(
        "Assetmanagement/Asset_master/check_equipment_activity_exist",
        payload
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log("err", error));
  }
}

export default new contactservice();
