import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import projectService from '@service/admin/pmsService/projectService';
import {
  saveProjectSuccess, saveProjectError,
  listProjectSuccess, listProjectError,
  updateProjectSuccess, updateProjectError,
  deleteProjectSuccess, deleteProjectError
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'

function* saveProject({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(projectService.saveProject, payload);
    yield put(saveProjectSuccess(data));
  } catch (error) {
    yield put(saveProjectError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listProject({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(projectService.listProject, payload);
    Array.isArray(data) ?
      yield put(listProjectSuccess(data)) :
      yield put(listProjectSuccess([]));
  } catch (error) {
    yield put(listProjectError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* updateProject({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(projectService.updateProject, payload);
    yield put(updateProjectSuccess(data));
  } catch (error) {
    yield put(updateProjectError(error));
  } finally {
    yield put(stopLoader());
  }
}
function* deleteProject({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(projectService.deleteProject, payload);
    yield put(deleteProjectSuccess(data));
  } catch (error) {
    yield put(deleteProjectError(error));
  } finally {
    yield put(stopLoader());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_PROJECT, saveProject)]);
  yield all([takeEvery(actionTypes.LIST_PROJECT, listProject)]);
  yield all([takeEvery(actionTypes.UPDATE_PROJECT, updateProject)]);
  yield all([takeEvery(actionTypes.DELETE_PROJECT, deleteProject)]);

}