//save meeting
export const saveMeeting = (payload) => {
    return {
        type: 'ADD_MEETING',
        payload
    };
};
export const saveMeetingSuccess = (payload) => {
    return {
        type: 'ADD_MEETING_SUCCESS',
        payload
    };
};
export const saveMeetingError = (payload) => {
    return {
        type: 'ADD_MEETING_ERROR',
        payload
    };
};

// fetching participants in sidebar-table
export const FetchParticipants = (payload) => {

    return {
        type: 'FETCH_PARTICIPANTS',
        payload
    };
};
export const FetchParticipantsSuccess = (payload) => {
    return {
        type: 'FETCH_PARTICIPANTS_SUCCESS',
        payload
    };
};
export const FetchParticipantsError = (payload) => {
    return {
        type: 'FETCH_PARTICIPANTS_ERROR',
        payload
    };

};

// list meeting

export const FetchMeeting = (payload) => {

    return {
        type: 'FETCH_MEETING',
        payload
    };
};
export const FetchMeetingSuccess = (payload) => {
    return {
        type: 'FETCH_MEETING_SUCCESS',
        payload
    };
};
export const FetchMeetingError = (payload) => {
    return {
        type: 'FETCH_MEETING_ERROR',
        payload
    };
};

export const removeMeeting = (payload) => {
    return {
        type: 'DELETE_MEETING', payload
    };
};
export const removeMeetingSuccess = (payload) => {
    return {
        type: 'DELETE_MEETING_SUCCESS', payload
    };
};
export const removeMeetingError = (payload) => {
    return {
        type: 'DELETE_MEETING_ERROR', payload
    };
};

export const fetchMeetingByID = (payload) => {
    return {
        type: 'FETCH_MEETING_BYID', payload
    };
};
export const fetchMeetingByIDSuccess = (payload) => {
    return {
        type: 'FETCH_MEETING_BYID_SUCCESS', payload
    };
};
export const fetchMeetingByIDError = (payload) => {
    return {
        type: 'FETCH_MEETING_BYID_ERROR', payload
    };
};

export const updateMeeting = (payload) => {
    return {
        type: 'UPDATE_MEETING',
        payload
    };
};
export const updateMeetingSuccess = (payload) => {
    return {
        type: 'UPDATE_MEETING_SUCCESS',
        payload
    };
};
export const updateMeetingError = (payload) => {
    return {
        type: 'UPDATE_MEETING_ERROR',
        payload
    };
};

