import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import invoiceService from '@service/admin/masterService/invoiceService';
import {
    listInvoiceSuccess, listInvoiceError,
    saveInvoiceSuccess, saveInvoiceError,
    listSoForInvoiceSuccess, listSoForInvoiceError,
    fetchInvoiceByIdSuccess, fetchInvoiceByIdError,
} from './action';
import { stopLoader, startLoader } from '@store/common/loader/action'

function* listSoForInvoice({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(invoiceService.listSoForInvoice, payload);
        yield put(listSoForInvoiceSuccess(data));
    } catch (error) {
        yield put(listSoForInvoiceError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* listInvoice({ payload }) {
    console.log('dsfsdfsdfsdfsdsd');
    try {
        yield put(startLoader());
        const data = yield call(invoiceService.listInvoice, payload);
        yield put(listInvoiceSuccess(data));
    } catch (error) {
        yield put(listInvoiceError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* saveInvoice({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(invoiceService.saveInvoice, payload);
        yield put(saveInvoiceSuccess(data));
    } catch (error) {
        yield put(saveInvoiceError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* fetchInvoiceById({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(invoiceService.fetchInvoiceById, payload);
        if (Object.keys(data).length > 0) {
            console.log(data);
            yield put(fetchInvoiceByIdSuccess({
                ...data,
                item_details: data.inv_items ? data.inv_items.map(item => {
                    return ({
                        ...item,
                        amount: (parseFloat(item.amount) * parseFloat(data.actual_rate)).toFixed(2),
                        discount_amount: (parseFloat(item.discount_amount) * parseFloat(data.actual_rate)).toFixed(2),
                        selling_price: (parseFloat(item.selling_price) * parseFloat(data.actual_rate)).toFixed(2),
                    })
                }) : [],
                work_details: data.work ? data.work.map(item => {
                    return ({
                        ...item,
                        charge: (parseFloat(item.charge) * parseFloat(data.actual_rate)).toFixed(2),
                        disc_amount: (parseFloat(item.disc_amount) * parseFloat(data.actual_rate)).toFixed(2),
                        wrktotal: (parseFloat(item.wrktotal) * parseFloat(data.actual_rate)).toFixed(2),
                    })
                }) : [],
                service_details: data.inv_ser ? data.inv_ser.map(item => {
                    return ({
                        ...item,
                        selling_price_ser: (parseFloat(item.selling_price_ser) * parseFloat(data.actual_rate)).toFixed(2),
                        discount_amount_ser: (parseFloat(item.discount_amount_ser) * parseFloat(data.actual_rate)).toFixed(2),
                        amount_ser: (parseFloat(item.amount_ser) * parseFloat(data.actual_rate)).toFixed(2),
                    })
                }) : [],
                project_details: data.inv_project ? data.inv_project.map(item => {
                    return ({
                        ...item,
                        selling_price_project: (parseFloat(item.selling_price_project) * parseFloat(data.actual_rate)).toFixed(2),
                        discount_amount_project: (parseFloat(item.discount_amount_project) * parseFloat(data.actual_rate)).toFixed(2),
                        amount_project: (parseFloat(item.amount_project) * parseFloat(data.actual_rate)).toFixed(2),
                        project_id: data.project_id || data.project_master_id
                    })
                }) : [],
                c_discount_amount: (parseFloat(data.c_discount_amount) * parseFloat(data.actual_rate)).toFixed(2),
                net_amount: (parseFloat(data.net_amount) * parseFloat(data.actual_rate)).toFixed(2),
                total_amount_products: (parseFloat(data.total_amount_products) * parseFloat(data.actual_rate)).toFixed(2),
                expected_revenue: (parseFloat(data.expected_revenue) * parseFloat(data.actual_rate)).toFixed(2),
            }));
        }
    } catch (error) {
        yield put(fetchInvoiceByIdError(error));
    } finally {
        yield put(stopLoader());
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actionTypes.LIST_SO_FOR_INVOICE, listSoForInvoice)]);
    yield all([takeEvery(actionTypes.LIST_INVOICE, listInvoice)]);
    yield all([takeEvery(actionTypes.SAVE_INVOICE, saveInvoice)]);
    yield all([takeEvery(actionTypes.FETCH_INVOICE_BY_ID, fetchInvoiceById)]);
}