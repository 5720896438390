import axios from '@service/ApiClient'

class currencyMasterService {
    constructor(callback) {
        this.callback = callback
    }
    async saveCurrency(payload) {
        return await axios.post('/Organization/Currency/save_currency', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listCurrency(payload) {
        return await axios.post('/Organization/Currency/list_currency', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteCurrency(payload) {
        return await axios.post('/Organization/Currency/delete_currency', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateCurrency(payload) {
        return await axios.post('/Organization/Currency/update_currency', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async saveCurrencyRate(payload) {
        return await axios.post('/Organization/Currency/save_currency_rate', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteCurrencyRate(payload) {
        return await axios.post('/Organization/Currency/delete_currency_rate', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateCurrencyRate(payload) {
        return await axios.post('/Organization/Currency/update_currency_rate', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async getDefaultCurrency(payload) {
        return await axios.post('/Organization/Currency/get_default_currency_by_businees_id', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new currencyMasterService();