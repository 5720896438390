import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import mrvService from '@service/admin/masterService/mrvService';
import {
    listPoSuccess, listPoError,
    fetchPoByIdSuccess, fetchPoByIdError,
    checkBarcodeSuccess, checkBarcodeError,
    listStoreMrvSuccess, listStoreMrvError,
    saveMrvSuccess, saveMrvError,
    listMrvSuccess, listMrvError,
    removeMrvSuccess, removeMrvError,
    fetchMrvByIdSuccess, fetchMrvByIdError,
    generateBarcodeSuccess,generateBarcodeError
} from './action';
import { stopLoader, startLoader } from '@store/common/loader/action'

function* listPo({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(mrvService.listPo, payload);
        Array.isArray(data) ?
            yield put(listPoSuccess(data)) :
            yield put(listPoSuccess([]));
    } catch (error) {
        yield put(listPoError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* fetchPoById({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(mrvService.fetchPoById, payload);
        yield put(fetchPoByIdSuccess(data));
    } catch (error) {
        yield put(fetchPoByIdError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* fetchMrvById({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(mrvService.fetchMrvById, payload);
        yield put(fetchMrvByIdSuccess(data));
    } catch (error) {
        yield put(fetchMrvByIdError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* checkBarcode({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(mrvService.checkBarcode, payload);
        yield put(checkBarcodeSuccess(data));
    } catch (error) {
        yield put(checkBarcodeError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* listStoreMrv({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(mrvService.listStoreMrv, payload);
        Array.isArray(data) ?
            yield put(listStoreMrvSuccess(data)) :
            yield put(listStoreMrvSuccess([]));
    } catch (error) {
        yield put(listStoreMrvError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* saveMrv({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(mrvService.saveMrv, payload);
        yield put(saveMrvSuccess(data));
    } catch (error) {
        yield put(saveMrvError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* listMrv({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(mrvService.listMrv, payload); 
        Array.isArray(data) ?
        yield put(listMrvSuccess(data)) :
        yield put(listMrvSuccess([]));
    } catch (error) {
        yield put(listMrvError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* removeMrv({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(mrvService.removeMrv, payload); 
        Array.isArray(data) ?
        yield put(removeMrvSuccess(data)) :
        yield put(removeMrvSuccess([]));
    } catch (error) {
        yield put(removeMrvError(error));
    } finally {
        yield put(stopLoader());
    }
}


function* GenerateBarcode({ payload }) {
    try {
      const data = yield call(mrvService.GenerateBarcode, payload);
      yield put(generateBarcodeSuccess(data));
    } catch (error) {
      yield put(generateBarcodeError(error));
    }
  }

export default function* rootSaga() {
    yield all([takeEvery(actionTypes.LIST_MRV_PO, listPo)]);
    yield all([takeEvery(actionTypes.FETCH_PO_BY_ID, fetchPoById)]);
    yield all([takeEvery(actionTypes.FETCH_MRV_BY_ID, fetchMrvById)]);
    yield all([takeEvery(actionTypes.CHECK_BARCODE, checkBarcode)]);
    yield all([takeEvery(actionTypes.LIST_STORE_MRV, listStoreMrv)]);
    yield all([takeEvery(actionTypes.SAVE_MRV, saveMrv)]);
    yield all([takeEvery(actionTypes.LIST_MRV, listMrv)]);
    yield all([takeEvery(actionTypes.DELETE_MRV, removeMrv)]);
    yield all([takeEvery('BARCODE_GENERATE', GenerateBarcode)]);
}