import { all } from 'redux-saga/effects'
import contacts from "./contacts/saga"
import user from "./user/saga"
import organization from './organization/saga'
import vendor from './vendor/saga'
import eventMaster from './eventMaster/saga'
import leads from './leads/saga'
import meeting from './meeting/saga'
import brochure from './brochure/saga'
import model from "./mastersettings/model/saga";
import manufacture from './mastersettings/manufacture/saga'
import skillset from './skillset/saga'
import servicemaster from './servicemaster/saga'
import taskmaster from './task/saga'
import department from './mastersettings/department/saga'
import designation from './mastersettings/designation/saga'
import qualification from './mastersettings/qualification/saga'
import salutation from './mastersettings/salutation/saga'
import unit from './mastersettings/unit/saga'
import category from './mastersettings/category/saga'
import purchaseRequest from './purchaseRequest/saga'
import role from './mastersettings/role/saga'
import item from './item/saga'
import brand from './mastersettings/brand/saga'
import industry from './mastersettings/industry/saga'
import purchaseOrder from './purchaseOrder/saga'
import termsandconditions from './mastersettings/termsAndConditions/saga'
import generalSetting from './mastersettings/generalSetting/saga'
import storeMaster from './mastersettings/storeMaster/saga'
import serviceRooster from './serviceRooster/saga'
import mrv from './mrv/saga'
import deal from './deal/saga'
import quote from './quote/saga'
import currency from './mastersettings/currencyMaster/saga'
import prefixrule from './mastersettings/prefixSettings/saga'
import dealstage from './mastersettings/dealStage/saga'
import identityDetails from './identityDetails/saga'
import qualificationDetails from './qualificationMaster/saga'
import employmentDetails from './employmentDetails/saga'
import bankDetails from './bankDetails/saga'
import familyDetails from './familyDetails/saga'
import salesOrder from './salesOrder/saga'
import deleveryOrder from './deleveryOrder/saga'
import projectType from './mastersettings/projectType/saga'
import project from './project/saga'
import invoice from './invoice/saga'
import directInvoice from './directInvoice/saga'
import hr from './mastersettings/hr/saga'
import shiftToEmployee from './mastersettings/shiftToEmployee/saga'
import timesheet from './timesheet/saga'
import leaveType from './leaveType/saga'
import leaveRequest from './leaveRequest/saga'
import parameterMaster from './payroll/addParameter/saga'
import salaryallowanceMaster from './payroll/salaryallowanceMaster/saga'
import advancePayment from './payroll/advancePayment/saga'
import salaryRegister from './payroll/salaryRegister/saga'
import expense from './mastersettings/expenseModule/saga'
import holidayMaster from './mastersettings/holidayMaster/saga'
import weekendMaster from './mastersettings/weekendMaster/saga'
import manufacturs from './manufacture/saga'
import equipment from './equipment/saga'
import asset from './asset/saga'
import report from './report/saga'
import equipmentTransfer from './EquipmentTransfer/saga'


export default function* commonSaga() {
    yield all([
        contacts(),
        user(),
        unit(),
        eventMaster(),
        organization(),
        vendor(),
        leads(),
        meeting(),
        brochure(),
        model(),
        manufacture(),
        skillset(),
        servicemaster(),
        taskmaster(),
        department(),
        designation(),
        qualification(),
        salutation(),
        purchaseRequest(),
        category(),
        role(),
        item(),
        brand(),
        purchaseOrder(),
        industry(),
        termsandconditions(),
        serviceRooster(),
        mrv(),
        generalSetting(),
        storeMaster(),
        deal(),
        currency(),
        quote(),
        prefixrule(),
        dealstage(),
        identityDetails(),
        qualificationDetails(),
        employmentDetails(),
        bankDetails(),
        familyDetails(),
        salesOrder(),
        deleveryOrder(),
        projectType(),
        invoice(),
        directInvoice(),
        project(),
        hr(),
        shiftToEmployee(),
        timesheet(),
        leaveType(),
        leaveRequest(),
        parameterMaster(),
        salaryallowanceMaster(),
        advancePayment(),
        salaryRegister(),
        expense(),
        holidayMaster(),
        weekendMaster(),
        manufacturs(),
        equipment(),
        asset(),
        report(),
        equipmentTransfer()
    ]);
}