import { takeEvery, put, call, all } from 'redux-saga/effects';
import salaryservice from '@service/admin/payrollService/advancePaymentService';
import {
  salaryadavncepaymentSuccess, salaryadavncepaymentError,
  fetchadavncepaymentSuccess, fetchadavncepaymentError,
  getadvancesalaryamountSuccess, getadvancesalaryamountError
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'


function* salaryadvancepayment({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(salaryservice.salaryadvancepayment, payload);
    yield put(salaryadavncepaymentSuccess(data));
  } catch (error) {
    yield put(salaryadavncepaymentError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* fetchadvancepayment({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(salaryservice.fetchadvancepayment, payload);
    yield put(fetchadavncepaymentSuccess(data));
  } catch (error) {
    yield put(fetchadavncepaymentError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* getadvanceamt({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(salaryservice.getadvanceamt, payload);
    yield put(getadvancesalaryamountSuccess(data));
  } catch (error) {
    yield put(getadvancesalaryamountError(error));
  } finally {
    yield put(stopLoader());
  }
}
export default function* rootSaga() {
  yield all([takeEvery('SALARY_ADVANCE_PAYMENT', salaryadvancepayment)]);
  yield all([takeEvery('FETCH_ADVANCE_PAYMENT', fetchadvancepayment)]);
  yield all([takeEvery('GET_ADVANCE_AMOUNT', getadvanceamt)]);
}