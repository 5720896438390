//LIST QUALIFICATION

export const FetchQualification = (payload) => {
    return {
        type: 'FETCH_QUALIFICATION',
        payload
    };
};
export const FetchQualificationSuccess = (payload) => {
    return {
        type: 'FETCH_QUALIFICATION_SUCCESS',
        payload
    };
};
export const FetchQualificationError = (payload) => {
    return {
        type: 'FETCH_QUALIFICATION_ERROR',
        payload
    };
};

//ADD QUALIFICATION

export const AddQualification = (payload) => {
    console.log('jj');
    return {
        type: 'ADD_QUALIFICATION',
        payload
    };
};
export const AddQualificationSuccess = (payload) => {
    console.log('jj',payload);

    return {
        type: 'ADD_QUALIFICATION_SUCCESS',
        payload
    };
};
export const AddQualificationError = (payload) => {
    return {
        type: 'ADD_QUALIFICATION_ERROR',
        payload
    };
};

//DELETE QUALIFICATION

export const DeleteQualification = (payload) => {
    console.log('aa');
    return {
        type: 'DELETE_QUALIFICATION',
        payload
    };
};
export const DeleteQualificationSuccess = (payload) => {
    console.log('aa');
    return {
        type: 'DELETE_QUALIFICATION_SUCCESS',
        payload
    };
};
export const DeleteQualificationError = (payload) => {
    return {
        type: 'DELETE_QUALIFICATION_ERROR',
        payload
    };
};

//EDIT QUALIFICATION

export const EditQualification = (payload) => {
    return {
        type: 'EDIT_QUALIFICATION',
        payload
    };
};
export const EditQualificationSuccess = (payload) => {

    return {
        type: 'EDIT_QUALIFICATION_SUCCESS',
        payload
    };
};
export const EditQualificationError = (payload) => {
    return {
        type: 'EDIT_QUALIFICATION_ERROR',
        payload
    };
};

//UPDATE QUALIFICATION

export const UpdateQualification = (payload) => {
    return {
        type: 'UPDATE_QUALIFICATION',
        payload
    };
};
export const UpdateQualificationSuccess = (payload) => {

    return {
        type: 'UPDATE_QUALIFICATION_SUCCESS',
        payload
    };
};
export const UpdateQualificationError = (payload) => {
    return {
        type: 'UPDATE_QUALIFICATION_ERROR',
        payload
    };
};