
import { actionTypes } from './action';

export const initialState = {
    saveQualificationsStatus: '',
    listQualificationsDetailsStatus: [],
    updateQualificationsStatus: '',
    deleteQualificationsStatus: '',
    listQualificationsStatus: [],

}
const saveQualiDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
       
        case actionTypes.SAVE_QUALIFICATIONDETAILS:
            return { ...state, saveQualificationsStatus: '' }
        case actionTypes.SAVE_QUALIFICATIONDETAILS_SUCCESS:
            return { ...state, saveQualificationsStatus: action.payload }
        case actionTypes.SAVE_QUALIFICATIONDETAILS_ERROR:
            return { ...state, saveQualificationsStatus: '' }

        case actionTypes.LIST_QUALIFICATIONDETAILS:
            return { ...state, listQualificationsDetailsStatus: [] }
        case actionTypes.LIST_QUALIFICATIONDETAILS_SUCCESS:
            return { ...state, listQualificationsDetailsStatus: action.payload }
        case actionTypes.LIST_QUALIFICATIONDETAILS_ERROR:
            return { ...state, listQualificationsDetailsStatus: [] }

        case actionTypes.UPDATE_QUALIFICATIONDETAILS:
            return { ...state, updateQualificationsStatus: '' }
        case actionTypes.UPDATE_QUALIFICATIONDETAILS_SUCCESS:
            return { ...state, updateQualificationsStatus: action.payload }
        case actionTypes.UPDATE_QUALIFICATIONDETAILS_ERROR:
            return { ...state, updateQualificationsStatus: '' }

        case actionTypes.DELETE_QUALIFICATIONDETAILS:
            return { ...state, deleteQualificationsStatus: '' }
        case actionTypes.DELETE_QUALIFICATIONDETAILS_SUCCESS:
            return { ...state, deleteQualificationsStatus: action.payload }
        case actionTypes.DELETE_QUALIFICATIONDETAILS_ERROR:
            return { ...state, deleteQualificationsStatus: '' }

            case actionTypes.LIST_QUALIFICATION:
                return { ...state, listQualificationsStatus: [] }
            case actionTypes.LIST_QUALIFICATION_SUCCESS:
                return { ...state, listQualificationsStatus: action.payload }
            case actionTypes.LIST_QUALIFICATION_ERROR:
                return { ...state, listQualificationsStatus: [] }
    
        default:
            return state
    }
}
export default saveQualiDetailsReducer