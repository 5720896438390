import { actionTypes } from './action';
export const initialState = {
    listbuparameters: [],
    listsalaryparameters: [],
    saveSalaryParameter: '',
    salaryalwnceParameterData: ''
}
const saveParameterReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_BU_PARAMETERS:
            return { ...state, listbuparameters: [], saveSalaryParameter: '', salaryalwnceParameterData: '' }
        case actionTypes.FETCH_BU_PARAMETERS_SUCCESS:
            return { ...state, listbuparameters: action.payload }
        case actionTypes.FETCH_BU_PARAMETERS_ERROR:
            return { ...state, listbuparameters: [] }

        case actionTypes.LIST_SALARY_PARAMETER:
            return { ...state, listsalaryparameters: [], listbuparameters: [], saveSalaryParameter: '', salaryalwnceParameterData: '' }
        case actionTypes.LIST_SALARY_PARAMETER_SUCCESS:
            return { ...state, listsalaryparameters: action.payload }
        case actionTypes.LIST_SALARY_PARAMETER_ERROR:
            return { ...state, listsalaryparameters: [] }

        case actionTypes.SAVE_SALARY_PARAMETER:
            return { ...state, saveSalaryParameter: '', listbuparameters: [], salaryalwnceParameterData: '' }
        case actionTypes.SAVE_SALARY_PARAMETER_SUCCESS:
            return { ...state, saveSalaryParameter: action.payload }
        case actionTypes.SAVE_SALARY_PARAMETER_ERROR:
            return { ...state, saveSalaryParameter: '' }

        case actionTypes.FETCH_SALARY_PARAMETER:
            return { ...state, salaryalwnceParameterData: '', listbuparameters: [], saveSalaryParameter: '' }
        case actionTypes.FETCH_SALARY_PARAMETER_SUCCESS:
            return { ...state, salaryalwnceParameterData: action.payload }
        case actionTypes.FETCH_SALARY_PARAMETER_ERROR:
            return { ...state, salaryalwnceParameterData: '' }

        default:
            return state
    }
}
export default saveParameterReducer