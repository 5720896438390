import { takeEvery, put, call, all } from 'redux-saga/effects';
import departmentservice from '@service/admin/masterService/departmentservice';
import {
  saveDepartmentSuccess, saveDepartmentError,
  listDepartmentSuccess,listDepartmentError,
  deleteDepartmentSuccess,deleteDepartmentError,
  updateDepartmentSuccess,updateDepartmentError,
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'


function* listDepartment({payload}) {
  try {
    yield put(startLoader());
    const data = yield call(departmentservice.listDepartment, payload);
    yield put(listDepartmentSuccess(data));
  } catch (error) {
    yield put(listDepartmentError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* saveDepartment({payload}) {
  try {
    yield put(startLoader());
    const data = yield call(departmentservice.saveDepartment, payload);
    yield put(saveDepartmentSuccess(data));
  } catch (error) {
    yield put(saveDepartmentError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* deleteDepartment({payload}) {
  try {
    yield put(startLoader());
    const data = yield call(departmentservice.deleteDepartment, payload);
    yield put(deleteDepartmentSuccess(data));
  } catch (error) {
    yield put(deleteDepartmentError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* updateDepartment({payload}) {
  try {
    yield put(startLoader());
    const data = yield call(departmentservice.updateDepartment, payload);
    yield put(updateDepartmentSuccess(data));
  } catch (error) {
    yield put(updateDepartmentError(error));
  } finally {
    yield put(stopLoader());
  }
}

export default function* rootSaga() {
  yield all([takeEvery('SAVE_DEPARTMENT', saveDepartment)]);
  yield all([takeEvery('LIST_DEPARTMENT', listDepartment)]);
  yield all([takeEvery('DELETE_DEPARTMENT', deleteDepartment)]);
  yield all([takeEvery('UPDATE_DEPARTMENT', updateDepartment)]);
}