
export const actionTypes = {
    SAVE_STORE: 'SAVE_STORE',
    SAVE_STORE_SUCCESS: 'SAVE_STORE_SUCCESS',
    SAVE_STORE_ERROR: 'SAVE_STORE_ERROR',
    LIST_STORE: 'LIST_STORE',
    LIST_STORE_SUCCESS: 'LIST_STORE_SUCCESS',
    LIST_STORE_ERROR: 'LIST_STORE_ERROR',
    LIST_SUBSTORE: 'LIST_SUBSTORE',
    LIST_SUBSTORE_SUCCESS: 'LIST_SUBSTORE_SUCCESS',
    LIST_SUBSTORE_ERROR: 'LIST_SUBSTORE_ERROR',
    DELETE_STORE: 'DELETE_STORE',
    DELETE_STORE_SUCCESS: 'DELETE_STORE_SUCCESS',
    DELETE_STORE_ERROR: 'DELETE_STORE_ERROR',
    UPDATE_STORE: 'UPDATE_STORE',
    UPDATE_STORE_SUCCESS: 'UPDATE_STORE_SUCCESS',
    UPDATE_STORE_ERROR: 'UPDATE_STORE_ERROR',
};

export const saveStore = (payload) => {
    return {
        type: actionTypes.SAVE_STORE, payload
    };
};
export const saveStoreSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_STORE_SUCCESS,
        payload
    };
};
export const saveStoreError = (payload) => {
    return {
        type: actionTypes.SAVE_STORE_ERROR,
        payload
    };
};

export const updateStore = (payload) => {
    return {
        type: actionTypes.UPDATE_STORE,
        payload
    };
};
export const updateStoreSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_STORE_SUCCESS,
        payload
    };
};
export const updateStoreError = (payload) => {
    return {
        type: actionTypes.UPDATE_STORE_ERROR,
        payload
    };
};

export const listStore = (payload) => {
    return {
        type: actionTypes.LIST_STORE,
        payload
    };
};
export const listStoreSuccess = (payload) => {
    return {
        type: actionTypes.LIST_STORE_SUCCESS,
        payload
    };
};
export const listStoreError = (payload) => {
    return {
        type: actionTypes.LIST_STORE_ERROR,
        payload
    };
};

export const listSubStore = (payload) => {
    return {
        type: actionTypes.LIST_SUBSTORE,
        payload
    };
};
export const listSubStoreSuccess = (payload) => {
    return {
        type: actionTypes.LIST_SUBSTORE_SUCCESS,
        payload
    };
};
export const listSubStoreError = (payload) => {
    return {
        type: actionTypes.LIST_SUBSTORE_ERROR,
        payload
    };
};

export const deleteStore = (payload) => {
    return {
        type: actionTypes.DELETE_STORE,
        payload
    };
};
export const deleteStoreSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_STORE_SUCCESS,
        payload
    };
};
export const deleteStoreError = (payload) => {
    return {
        type: actionTypes.DELETE_STORE_ERROR,
        payload
    };
};