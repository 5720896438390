import { takeEvery, put, call, all } from 'redux-saga/effects';
import taskService from '@service/admin/taskService/taskService';
import {
  actionTypes,
  saveTaskSuccess, saveTaskError,
  listCompanyContactSuccess, listCompanyContactError,
  saveTaskActivitySuccess, saveTaskActivityError,
  listTaskActivitySuccess, listTaskActivityError,
  fetchTaskSuccess, fetchTaskError,
  updateTaskActivitySuccess, updateTaskActivityError,
  deleteTaskActivitySuccess, deleteTaskActivityError,
  listSalesTypeDataSuccess, listSalesTypeDataError,
  handlePrePostPoneTaskSuccess, handlePrePostPoneTaskError,
  handleCompletedTaskDetailsSuccess, handleCompletedTaskDetailsError,
  handleFollowUpTaskSuccess, handleFollowUpTaskError,
  fetchTaskDetailsByIDSuccess, fetchTaskDetailsByIDError,
  updateTaskDetailsSuccess, updateTaskDetailsError,
  handleGetApproveTaskDetailsSuccess, handleGetApproveTaskDetailsError,
  deleteTaskDetailsSuccess, deleteTaskDetailsError,
  approveTaskDetailsSuccess, approveTaskDetailsError,
  rejectTaskDetailsSuccess, rejectTaskDetailsError
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'

function* saveTask({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(taskService.saveTask, payload);

    yield put(saveTaskSuccess(data));
  } catch (error) {
    yield put(saveTaskError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* fetchTask({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(taskService.fetchTask, payload);
    Array.isArray(data) ?
      yield put(fetchTaskSuccess(data)) :
      yield put(fetchTaskSuccess([]));
  } catch (error) {
    yield put(fetchTaskError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listCompanyContact() {
  try {
    yield put(startLoader());
    const data = yield call(taskService.listCompanyContact);
    Array.isArray(data) ?
      yield put(listCompanyContactSuccess(data)) :
      yield put(listCompanyContactSuccess([]));
  } catch (error) {
    yield put(listCompanyContactError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* saveTaskActivity({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(taskService.saveTaskActivity, payload);
    yield put(saveTaskActivitySuccess(data));
  } catch (error) {
    yield put(saveTaskActivityError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listTaskActivity({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(taskService.listTaskActivity, payload);
    Array.isArray(data) ?
      yield put(listTaskActivitySuccess(data)) :
      yield put(listTaskActivitySuccess([]));
  } catch (error) {
    yield put(listTaskActivityError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* updateTaskActivity({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(taskService.updateTaskActivity, payload);
    yield put(updateTaskActivitySuccess(data))
  } catch (error) {
    yield put(updateTaskActivityError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* deleteTaskActivity({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(taskService.deleteTaskActivity, payload);
    yield put(deleteTaskActivitySuccess(data))
  } catch (error) {
    yield put(deleteTaskActivityError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listSalesTypeData({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(taskService.listSalesTypeData, payload);
    Array.isArray(data) ?
      yield put(listSalesTypeDataSuccess(data)) :
      yield put(listSalesTypeDataSuccess([]));
  } catch (error) {
    yield put(listSalesTypeDataError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* handlePrePostPone({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(taskService.handlePrePostPone, payload);

    yield put(handlePrePostPoneTaskSuccess(data))
  } catch (error) {
    yield put(handlePrePostPoneTaskError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* handleCompletedTask({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(taskService.handleCompletedTask, payload);

    yield put(handleCompletedTaskDetailsSuccess(data))
  } catch (error) {
    yield put(handleCompletedTaskDetailsError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* handleFollowUp({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(taskService.handleFollowUp, payload);

    yield put(handleFollowUpTaskSuccess(data))
  } catch (error) {
    yield put(handleFollowUpTaskError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* getTaskDetailsByID({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(taskService.getTaskDetailsByID, payload);

    yield put(fetchTaskDetailsByIDSuccess(data))
  } catch (error) {
    yield put(fetchTaskDetailsByIDError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* updateTaskDetails({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(taskService.updateTaskDetails, payload);

    yield put(updateTaskDetailsSuccess(data))
  } catch (error) {
    yield put(updateTaskDetailsError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* handleGetApproveTask({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(taskService.handleGetApproveTask, payload);

    yield put(handleGetApproveTaskDetailsSuccess(data))
  } catch (error) {
    yield put(handleGetApproveTaskDetailsError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* deleteTask({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(taskService.deleteTask, payload);

    yield put(deleteTaskDetailsSuccess(data))
  } catch (error) {
    yield put(deleteTaskDetailsError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* approveTask({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(taskService.approveTask, payload);

    yield put(approveTaskDetailsSuccess(data))
  } catch (error) {
    yield put(approveTaskDetailsError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* rejectTask({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(taskService.rejectTask, payload);

    yield put(rejectTaskDetailsSuccess(data))
  } catch (error) {
    yield put(rejectTaskDetailsError(error));
  } finally {
    yield put(stopLoader());
  }
}


export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_TASK, saveTask)]);
  yield all([takeEvery(actionTypes.LIST_COMPANYCONTACT, listCompanyContact)]);
  yield all([takeEvery(actionTypes.LIST_TASK, fetchTask)]);
  yield all([takeEvery(actionTypes.SAVE_TASKACTIVITY, saveTaskActivity)]);
  yield all([takeEvery(actionTypes.LIST_TASKACTIVITY, listTaskActivity)]);
  yield all([takeEvery(actionTypes.UPDATE_TASKACTIVITY, updateTaskActivity)]);
  yield all([takeEvery(actionTypes.DELETE_TASKACTIVITY, deleteTaskActivity)]);
  yield all([takeEvery(actionTypes.LIST_SALESTYPE_DATA, listSalesTypeData)]);
  yield all([takeEvery(actionTypes.PRE_POST_PONETASK, handlePrePostPone)]);
  yield all([takeEvery(actionTypes.COMPLETED_TASK_DETAILS, handleCompletedTask)]);
  yield all([takeEvery(actionTypes.FOLLOW_UP_TASK, handleFollowUp)]);
  yield all([takeEvery(actionTypes.GET_TASKDETAILS_BY_ID, getTaskDetailsByID)]);
  yield all([takeEvery(actionTypes.UPDATE_TASKDETAILS, updateTaskDetails)]);
  yield all([takeEvery(actionTypes.GET_APPROVE_TASKDETAILS, handleGetApproveTask)]);
  yield all([takeEvery(actionTypes.DELETE_TASKDETAILS, deleteTask)]);
  yield all([takeEvery(actionTypes.APPROVE_TASKDETAILS, approveTask)]);
  yield all([takeEvery(actionTypes.REJECT_TASKDETAILS, rejectTask)]);

}