import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import bankDetailsService from '@service/admin/masterService/bankDetailsService';
import {
  saveBankDetailsSuccess, saveBankDetailsError,
  listBankDetailsSuccess, listBankDetailsError,
  updateBankDetailsSuccess, updateBankDetailsError,
  deleteBankDetailsSuccess, deleteBankDetailsError,
} from './action';

function* saveBankDetails({ payload }) {
  try {
    const data = yield call(bankDetailsService.saveBankDetails, payload);

    yield put(saveBankDetailsSuccess(data));
  } catch (error) {
    yield put(saveBankDetailsError(error));
  } finally {
  }
}

function* listBankDetails({ payload }) {
  try {
    const data = yield call(bankDetailsService.listBankDetails, payload);

    yield put(listBankDetailsSuccess(data));
  } catch (error) {
    yield put(listBankDetailsError(error));
  } finally {
  }
}

function* updateBankDetails({ payload }) {
  try {
    const data = yield call(bankDetailsService.updateBankDetails, payload);

    yield put(updateBankDetailsSuccess(data));
  } catch (error) {
    yield put(updateBankDetailsError(error));
  } finally {
  }
}

function* deleteBankDetails({ payload }) {
  try {
    const data = yield call(bankDetailsService.deleteBankDetails, payload);

    yield put(deleteBankDetailsSuccess(data));
  } catch (error) {
    yield put(deleteBankDetailsError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_BANKDETAILS, saveBankDetails)]);
  yield all([takeEvery(actionTypes.LIST_BANKDETAILS, listBankDetails)]);
  yield all([takeEvery(actionTypes.UPDATE_BANKDETAILS, updateBankDetails)]);
  yield all([takeEvery(actionTypes.DELETE_BANKDETAILS, deleteBankDetails)]);

}