export const saveEquipmentTransfer = (payload) => {
    return {
        type: 'SAVE_EQUIPMENT_TRANSFER',
        payload
    };
};
export const saveEquipmentTransferSuccess = (payload) => {
    return {
        type: 'SAVE_EQUIPMENT_TRANSFER_SUCCESS',
        payload
    };
};
export const saveEquipmentTransferError = (payload) => {
    return {
        type: 'SAVE_EQUIPMENT_TRANSFER_ERROR',
        payload
    };
};

export const listEquipmentTransfer = (payload) => {
    return {
        type: 'LIST_EQUIPMENT_TRANSFER',
        payload
    };
};
export const listEquipmentTransferSuccess = (payload) => {
    return {
        type: 'LIST_EQUIPMENT_TRANSFER_SUCCESS',
        payload
    };
};
export const listEquipmentTransferError = (payload) => {
    return {
        type: 'LIST_EQUIPMENT_TRANSFER_ERROR',
        payload
    };
};