import axios from '@service/ApiClient'
// import { siteUrl } from './config'

class taskService {
    constructor(callback) {
        this.callback = callback
    }
    async saveTask(payload) {
        return await axios.post('/Crm/Task/save_task', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchTask(payload) {
        return await axios.post('/Crm/Task/list_task', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listCompanyContact(payload) {
        return await axios.post('/Crm/Contact/list_contacts_organizations', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async saveTaskActivity(payload) {
        return await axios.post('/Crm/Task/save_activity', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listTaskActivity(payload) {
        return await axios.post('/Crm/Task/list_activity', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updateTaskActivity(payload) {
        return await axios.post('/Crm/Task/update_activity', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteTaskActivity(payload) {
        return await axios.post('/Crm/Task/delete_activity', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listSalesTypeData(payload) {
        return await axios.post('/Crm/Task/get_task_upon_type_details_by_contact_id', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async handlePrePostPone(payload) {
        await axios.post('/Crm/Task/update_task_preponeorpostpone', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async handleCompletedTask(payload) {
        await axios.post('/Crm/Task/complete_task', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async handleFollowUp(payload) {
        await axios.post('/Crm/Task/save_task_followup', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async getTaskDetailsByID(payload) {
        return await axios.post('/Crm/Task/get_task_details_by_task_id', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updateTaskDetails(payload) {
        return await axios.post('/Crm/Task/update_task_details', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async handleGetApproveTask(payload) {
        return await axios.post('/Crm/Task/send_for_approval', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteTask(payload) {
        return await axios.post('/Crm/Task/delete_task', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async approveTask(payload) {
        return await axios.post('/Crm/Task/approve_task', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async rejectTask(payload) {
        return await axios.post('/Crm/Task/reject_task', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new taskService();