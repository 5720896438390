
export const actionTypes = {

    SAVE_CATEGORY: 'SAVE_CATEGORY',
    SAVE_CATEGORY_SUCCESS: 'SAVE_CATEGORY_SUCCESS',
    SAVE_CATEGORY_ERROR: 'SAVE_CATEGORY_ERROR',

    LIST_CATEGORY: 'LIST_CATEGORY',
    LIST_CATEGORY_SUCCESS: 'LIST_CATEGORY_SUCCESS',
    LIST_CATEGORY_ERROR: 'LIST_CATEGORY_ERROR',

    LIST_SUBCATEGORY: 'LIST_SUBCATEGORY',
    LIST_SUBCATEGORY_SUCCESS: 'LIST_SUBCATEGORY_SUCCESS',
    LIST_SUBCATEGORY_ERROR: 'LIST_SUBCATEGORY_ERROR',

    LIST_ALL_CATEGORY: 'LIST_ALL_CATEGORY',
    LIST_ALL_CATEGORY_SUCCESS: 'LIST_ALL_CATEGORY_SUCCESS',
    LIST_ALL_CATEGORY_ERROR: 'LIST_ALL_CATEGORY_ERROR',

    DELETE_CATEGORY: 'DELETE_CATEGORY',
    DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_ERROR: 'DELETE_CATEGORY_ERROR',

    UPDATE_CATEGORY: 'UPDATE_CATEGORY',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_ERROR: 'UPDATE_CATEGORY_ERROR',
}

export const saveCategory = (payload) => {
    return { type: actionTypes.SAVE_CATEGORY, payload };
};
export const saveCategorySuccess = (payload) => {
    return { type: actionTypes.SAVE_CATEGORY_SUCCESS, payload };
};
export const saveCategoryError = (payload) => {
    return { type: actionTypes.SAVE_CATEGORY_ERROR, payload };
};

export const listCategory = (payload) => {
    return { type: 'LIST_CATEGORY', payload };
};
export const listCategorySuccess = (payload) => {
    return { type: 'LIST_CATEGORY_SUCCESS', payload };
};
export const listCategoryError = (payload) => {
    return { type: 'LIST_CATEGORY_ERROR', payload };
};

export const listSubCategory = (payload) => {
    return { type: 'LIST_SUBCATEGORY', payload };
};
export const listSubCategorySuccess = (payload) => {
    return { type: 'LIST_SUBCATEGORY_SUCCESS', payload };
};
export const listSubCategoryError = (payload) => {
    return { type: 'LIST_SUBCATEGORY_ERROR', payload };
};

export const listAllCategory = (payload) => {
    return { type: actionTypes.LIST_ALL_CATEGORY, payload };
};
export const listAllCategorySuccess = (payload) => {
    return { type: actionTypes.LIST_ALL_CATEGORY_SUCCESS, payload };
};
export const listAllCategoryError = (payload) => {
    return { type: actionTypes.LIST_ALL_CATEGORY_ERROR, payload };
};

export const deleteCategory = (payload) => {
    return { type: actionTypes.DELETE_CATEGORY, payload };
};
export const deleteCategorySuccess = (payload) => {
    return { type: actionTypes.DELETE_CATEGORY_SUCCESS, payload };
};
export const deleteCategoryError = (payload) => {
    return { type: actionTypes.DELETE_CATEGORY_ERROR, payload };
};

export const updateCategory = (payload) => {
    return { type: actionTypes.UPDATE_CATEGORY, payload };
};
export const updateCategorySuccess = (payload) => {
    return { type: actionTypes.UPDATE_CATEGORY_SUCCESS, payload };
};
export const updateCategoryError = (payload) => {
    return { type: actionTypes.UPDATE_CATEGORY_ERROR, payload };
};