// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from '@store/Auth/reducer'
import common from '@store/common/commonReducer'
import layout from '@store/layout/reducer'
import admin from '@store/admin/adminReducer'


const rootReducer = combineReducers({
  auth,
  layout,
  common,
  admin
})

export default rootReducer
