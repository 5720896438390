import { actionTypes } from './action';

export const initialState = {
    saveVendorValues: '',
    listVendorValues: '',
    saveStatus: '',
    updateStatus: '',
    deleteStatus: '',
    importvendor:''
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_VENDOR:
            return { ...state, saveStatus: '' }
        case actionTypes.SAVE_VENDOR_SUCCESS:
            return { ...state, saveStatus: action.payload }
        case actionTypes.SAVE_VENDOR_ERROR:
            return { ...state, saveStatus: 'error' }

        case actionTypes.LIST_VENDOR:
            return { ...state, listVendorValues: '', saveStatus: '', updateStatus: '', deleteStatus: '' }
        case actionTypes.LIST_VENDOR_SUCCESS:
            return { ...state, listVendorValues: action.payload }
        case actionTypes.LIST_VENDOR_ERROR:
            return { ...state, listVendorValues: '' }

        case actionTypes.UPDATE_VENDOR:
            return { ...state, updateStatus: '', }
        case actionTypes.UPDATE_VENDOR_SUCCESS:
            return { ...state, updateStatus: action.payload }
        case actionTypes.UPDATE_VENDOR_ERROR:
            return { ...state, updateStatus: 'error' }

        case actionTypes.DELETE_VENDOR:
            return { ...state, deleteStatus: '' }
        case actionTypes.DELETE_VENDOR_SUCCESS:
            return { ...state, deleteStatus: action.payload }
        case actionTypes.DELETE_VENDOR_ERROR:
            return { ...state, deleteStatus: 'error' }

            case 'IMPORT_VENDOR':
                return { ...state, importvendor: '' }
            case 'IMPORT_VENDOR_SUCCESS':
                return { ...state, importvendor: 'SUCCESS' }
            case 'IMPORT_VENDOR_ERROR':
                return { ...state, importvendor: 'ERROR' }
    

        default:
            return state
    }
}

export default userReducer
