import { actionTypes } from "./action";

export const initialState = {
  saveEqMasterStatus: "",
  listEqMaster: [],
  updateEqMasterStatus: "",
  deleteEqMasterStatus: "",
  complaintview:"",
  complaintlist:"",
  complainttechician:"",
  addinspection:"",
  updateinspection:"",
  listinspection:"",
  savespareparts:"",
  listspareparts:"",
  removespareparts:"",
  technicianstatus:"",
  techniciantask:"",
  equipmentcomplaintstatus:"",
  assetcomplaint:"",
  complaintrequest:"",
  calibrationrooster:"",
  servicerooster:"",
  assetroosterview:"",
  viewcalibration:"",
  viewservice:'',
  viewservicerooster:'',
  technicianlist:"",
  taskhistory:'',
  assignVendorStatus:'',
  removeDepartmentStatus:'',
  assignVendorList:'',
  tasklist:'',
  receiptnoteStatus: '',
  deliverynoteStatus: '',
  saveEquipmentAllocation:'',
  listEquipmentAllocation:'',
  viewEquipmentAllocation:'',
  returnEquipmentAllocation:'',
  deliverynoteList:'',
  deliverynoteListView:'',
  updateExternalVendorStatus:'',
  receiptListStatus:'',
  taskReportList:'',
  barcodeList:'',
  barcodebulkList:''
};
const equipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_EQUIPMENT_MASTER:
      return { ...state, saveEqMasterStatus: "" };
    case actionTypes.SAVE_EQUIPMENT_MASTER_SUCCESS:
      return { ...state, saveEqMasterStatus: action.payload };
    case actionTypes.SAVE_EQUIPMENT_MASTER_ERROR:
      return { ...state, saveEqMasterStatus: "" };

    case actionTypes.TECHINICAN_LIST_MASTER:
      return { ...state, technicianlist: "" };
    case actionTypes.TECHINICAN_LIST_MASTER_SUCCESS:
      return { ...state, technicianlist: action.payload };
    case actionTypes.TECHINICAN_LIST_MASTER_ERROR:
      return { ...state, technicianlist: "" };

    case actionTypes.LIST_EQUIPMENT_MASTER:
      return { ...state, listEqMaster: [] };
    case actionTypes.LIST_EQUIPMENT_MASTER_SUCCESS:
      return { ...state, listEqMaster: action.payload };
    case actionTypes.LIST_EQUIPMENT_MASTER_ERROR:
      return { ...state, listEqMaster: [] };

    case actionTypes.UPDATE_EQUIPMENT_MASTER:
      return { ...state, updateEqMasterStatus: "" };
    case actionTypes.UPDATE_EQUIPMENT_MASTER_SUCCESS:
      return { ...state, updateEqMasterStatus: action.payload };
    case actionTypes.UPDATE_EQUIPMENT_MASTER_ERROR:
      return { ...state, updateEqMasterStatus: "" };

    case actionTypes.DELETE_EQUIPMENT_MASTER:
      return { ...state, deleteEqMasterStatus: "" };
    case actionTypes.DELETE_EQUIPMENT_MASTER_SUCCESS:
      return { ...state, deleteEqMasterStatus: action.payload };
    case actionTypes.DELETE_EQUIPMENT_MASTER_ERROR:
      return { ...state, deleteEqMasterStatus: "" };

    case "COMPLAINT_REQUEST_VIEW":
      return { ...state, complaintview: "" };
    case "COMPLAINT_REQUEST_VIEW_SUCCESS":
      return { ...state, complaintview: action.payload };
    case "COMPLAINT_REQUEST_VIEW_ERROR":
      return { ...state, complaintview: "" };


    case "EMPLOYEE_LIST_FOR_COMPLAINT":
      return { ...state, complaintlist: "" };
    case "EMPLOYEE_LIST_FOR_COMPLAINT_SUCCESS":
      return { ...state, complaintlist: action.payload };
    case "EMPLOYEE_LIST_FOR_COMPLAINT_ERROR":
      return { ...state, complaintlist: "" };


    case "ASSIGN_TECHNICIAN_FOR_COMPLAINT":
      return { ...state, complainttechician: "" };
    case "ASSIGN_TECHNICIAN_FOR_COMPLAINT_SUCCESS":
      return { ...state, complainttechician: action.payload };
    case "ASSIGN_TECHNICIAN_FOR_COMPLAINT_ERROR":
      return { ...state, complainttechician: "" };


    case "ADD_INSPECTION_FOR_COMPLAINT":
      return { ...state, addinspection: "" };
    case "ADD_INSPECTION_FOR_COMPLAINT_SUCCESS":
      return { ...state, addinspection: action.payload };
    case "ADD_INSPECTION_FOR_COMPLAINT_ERROR":
      return { ...state, addinspection: "" };


    case "UPDATE_INSPECTION_FOR_COMPLAINT":
      return { ...state, updateinspection: "" };
    case "UPDATE_INSPECTION_FOR_COMPLAINT_SUCCESS":
      return { ...state, updateinspection: action.payload };
    case "UPDATE_INSPECTION_FOR_COMPLAINT_ERROR":
      return { ...state, updateinspection: "" };


    case "LIST_INSPECTION_FOR_COMPLAINT":
      return { ...state, listinspection: "" };
    case "LIST_INSPECTION_FOR_COMPLAINT_SUCCESS":
      return { ...state, listinspection: action.payload };
    case "LIST_INSPECTION_FOR_COMPLAINT_ERROR":
      return { ...state, listinspection: "" };

    case "SAVE_SPAREPARTS_FOR_COMPLAINT":
      return { ...state, savespareparts: "" };
    case "SAVE_SPAREPARTS_FOR_COMPLAINT_SUCCESS":
      return { ...state, savespareparts: action.payload };
    case "SAVE_SPAREPARTS_FOR_COMPLAINT_ERROR":
      return { ...state, savespareparts: "" };

    case "LIST_SPAREPARTS_FOR_COMPLAINT":
      return { ...state, listspareparts: "" };
    case "LIST_SPAREPARTS_FOR_COMPLAINT_SUCCESS":
      return { ...state, listspareparts: action.payload };
    case "LIST_SPAREPARTS_FOR_COMPLAINT_ERROR":
      return { ...state, listspareparts: "" };

    case "REMOVE_SPAREPARTS_FOR_COMPLAINT":
      return { ...state, removespareparts: "" };
    case "REMOVE_SPAREPARTS_FOR_COMPLAINT_SUCCESS":
      return { ...state, removespareparts: action.payload };
    case "REMOVE_SPAREPARTS_FOR_COMPLAINT_ERROR":
      return { ...state, removespareparts: "" };


    case "CHANGE_TECHNICIAN_STATUS_FOR_COMPLAINT":
      return { ...state, technicianstatus: "" };
    case "CHANGE_TECHNICIAN_STATUS_FOR_COMPLAINT_SUCCESS":
      return { ...state, technicianstatus: action.payload };
    case "CHANGE_TECHNICIAN_STATUS_FOR_COMPLAINT_ERROR":
      return { ...state, technicianstatus: "" };


    case "GET_TECHNICIAN_TASK_BY_COMPLAINT_ID":
      return { ...state, techniciantask: "" };
    case "GET_TECHNICIAN_TASK_BY_COMPLAINT_ID_SUCCESS":
      return { ...state, techniciantask: action.payload };
    case "GET_TECHNICIAN_TASK_BY_COMPLAINT_ID_ERROR":
      return { ...state, techniciantask: "" };


    case "CHANGE_EQUIPMENT_COMPLAINT_REQUEST_STATUS":
      return { ...state, equipmentcomplaintstatus: "" };
    case "CHANGE_EQUIPMENT_COMPLAINT_REQUEST_STATUS_SUCCESS":
      return { ...state, equipmentcomplaintstatus: action.payload };
    case "CHANGE_EQUIPMENT_COMPLAINT_REQUEST_STATUS_ERROR":
      return { ...state, equipmentcomplaintstatus: "" };


    case "ASSET_COMPLAINT_SAVE":
      return { ...state, assetcomplaint: "" };
    case "ASSET_COMPLAINT_SAVE_SUCCESS":
      return { ...state, assetcomplaint: action.payload };
    case "ASSET_COMPLAINT_SAVE_ERROR":
      return { ...state, assetcomplaint: "" };


    case "LIST_COMPLAINT_REQUEST":
      return { ...state, complaintrequest: "" };
    case "LIST_COMPLAINT_REQUEST_SUCCESS":
      return { ...state, complaintrequest: action.payload };
    case "LIST_COMPLAINT_REQUEST_ERROR":
      return { ...state, complaintrequest: "" };

    case "LIST_ASSET_CALIBRATION_ROOSTER":
      return { ...state, calibrationrooster: "" };
    case "LIST_ASSET_CALIBRATION_ROOSTER_SUCCESS":
      return { ...state, calibrationrooster: action.payload };
    case "LIST_ASSET_CALIBRATION_ROOSTER_ERROR":
      return { ...state, calibrationrooster: "" };

    case "LIST_ASSET_SERVICE_ROOSTER":
      return { ...state, servicerooster: "" };
    case "LIST_ASSET_SERVICE_ROOSTER_SUCCESS":
      return { ...state, servicerooster: action.payload };
    case "LIST_ASSET_SERVICE_ROOSTER_ERROR":
      return { ...state, servicerooster: "" };

    case "ASSET_ROOSTER_VIEW_IDENTIFICATION":
      return { ...state, assetroosterview: "" };
    case "ASSET_ROOSTER_VIEW_IDENTIFICATION_SUCCESS":
      return { ...state, assetroosterview: action.payload };
    case "ASSET_ROOSTER_VIEW_IDENTIFICATION_ERROR":
      return { ...state, assetroosterview: "" };


    case "VIEW_CALIBRATION_DETAILS":
      return { ...state, viewcalibration: "" };
    case "VIEW_CALIBRATION_DETAILS_SUCCESS":
      return { ...state, viewcalibration: action.payload };
    case "VIEW_CALIBRATION_DETAILS_ERROR":
      return { ...state, viewcalibration: "" };
      
    case "VIEW_SERVICE_DETAILS":
      return { ...state, viewservice: "" };
    case "VIEW_SERVICE_DETAILS_SUCCESS":
      return { ...state, viewservice: action.payload };
    case "VIEW_SERVICE_DETAILS_ERROR":
      return { ...state, viewservice: "" };


    case "VIEW_SERVICE_ROOSTER":
      return { ...state, viewservicerooster: "" };
    case "VIEW_SERVICE_ROOSTER_SUCCESS":
      return { ...state, viewservicerooster: action.payload };
    case "VIEW_SERVICE_ROOSTER_ERROR":
      return { ...state, viewservicerooster: "" };

    case "FETCH_COMPLAINT_ACTIVITY_LOG":
      return { ...state, taskhistory: "" };
    case "FETCH_COMPLAINT_ACTIVITY_LOG_SUCCESS":
      return { ...state, taskhistory: action.payload };
    case "FETCH_COMPLAINT_ACTIVITY_LOG_ERROR":
      return { ...state, taskhistory: "" };


    case "ASSIGNED_SERVICE_VENDORS":
      return { ...state,  assignVendorList: "" };
    case "ASSIGNED_SERVICE_VENDORS_SUCCESS":
      return { ...state, assignVendorList: action.payload };
    case "ASSIGNED_SERVICE_VENDORS_ERROR":
      return { ...state, assignVendorList: "" };

    case "REMOVE_DEPAETMENT_FOR_COMPLAINT":
      return { ...state, removeDepartmentStatus: "" };
    case "REMOVE_DEPAETMENT_FOR_COMPLAINT_SUCCESS":
      return { ...state, removeDepartmentStatus: action.payload };
    case "REMOVE_DEPAETMENT_FOR_COMPLAINT_ERROR":
      return { ...state, removeDepartmentStatus: "" };


    case "ASSIGN_VENDOR_TO_SERVICE":
      return { ...state, assignVendorStatus: "" };
    case "ASSIGN_VENDOR_TO_SERVICE_SUCCESS":
      return { ...state, assignVendorStatus: action.payload };
    case "ASSIGN_VENDOR_TO_SERVICE_ERROR":
      return { ...state, assignVendorStatus: "" };


    case "TASK_LIST":
      return { ...state, tasklist: "" };
    case "TASK_LIST_SUCCESS":
      return { ...state, tasklist: action.payload };
    case "TASK_LIST_ERROR":
      return { ...state, tasklist: "" };
      
       case "SAVE_DELIVERY_NOTE":
      return { ...state, deliverynoteStatus: "" };
    case "SAVE_DELIVERY_NOTE_SUCCESS":
      return { ...state, deliverynoteStatus: action.payload };
    case "SAVE_DELIVERY_NOTE_ERROR":
      return { ...state, deliverynoteStatus: "" };

    case "SAVE_RECEIPT_NOTE":
      return { ...state, receiptnoteStatus: "" };
    case "SAVE_RECEIPT_NOTE_SUCCESS":
      return { ...state, receiptnoteStatus: action.payload };
    case "SAVE_RECEIPT_NOTE_ERROR":
      return { ...state, receiptnoteStatus: "" };

    case "SAVE_EQUIPMENT_ALLOCATION":
      return { ...state, saveEquipmentAllocation: "" };
    case "SAVE_EQUIPMENT_ALLOCATION_SUCCESS":
      return { ...state, saveEquipmentAllocation: action.payload };
    case "SAVE_EQUIPMENT_ALLOCATION_ERROR":
      return { ...state, saveEquipmentAllocation: "" };

    case "LIST_EQUIPMENT_ALLOCATION":
      return { ...state, listEquipmentAllocation: "" };
    case "LIST_EQUIPMENT_ALLOCATION_SUCCESS":
      return { ...state, listEquipmentAllocation: action.payload };
    case "LIST_EQUIPMENT_ALLOCATION_ERROR":
      return { ...state, listEquipmentAllocation: "" };

    case "VIEW_EQUIPMENT_ALLOCATION":
      return { ...state, viewEquipmentAllocation: "" };
    case "VIEW_EQUIPMENT_ALLOCATION_SUCCESS":
      return { ...state, viewEquipmentAllocation: action.payload };
    case "VIEW_EQUIPMENT_ALLOCATION_ERROR":
      return { ...state, viewEquipmentAllocation: "" };

    case "RETURN_EQUIPMENT_ALLOCATION":
      return { ...state, returnEquipmentAllocation: "" };
    case "RETURN_EQUIPMENT_ALLOCATION_SUCCESS":
      return { ...state, returnEquipmentAllocation: action.payload };
    case "RETURN_EQUIPMENT_ALLOCATION_ERROR":
      return { ...state, returnEquipmentAllocation: "" };

    case "LIST_DELIVERY_NOTE":
      return { ...state, deliverynoteList: "" };
    case "LIST_DELIVERY_NOTE_SUCCESS":
      return { ...state, deliverynoteList: action.payload };
    case "LIST_DELIVERY_NOTE_ERROR":
      return { ...state, deliverynoteList: "" };

    case "VIEW_DELIVERY_NOTE":
      return { ...state, deliverynoteListView: "" };
    case "VIEW_DELIVERY_NOTE_SUCCESS":
      return { ...state, deliverynoteListView: action.payload };
    case "VIEW_DELIVERY_NOTE_ERROR":
      return { ...state, deliverynoteListView: "" };

    case "UPDATE_EXTERNAL_VENDOR_STATUS":
      return { ...state, updateExternalVendorStatus: "" };
    case "UPDATE_EXTERNAL_VENDOR_STATUS_SUCCESS":
      return { ...state, updateExternalVendorStatus: action.payload };
    case "UPDATE_EXTERNAL_VENDOR_STATUS_ERROR":
      return { ...state, updateExternalVendorStatus: "" };

    case "FETCH_RECEIPTNOTE":
      return { ...state, receiptListStatus: "" };
    case "FETCH_RECEIPTNOTE_SUCCESS":
      return { ...state, receiptListStatus: action.payload };
    case "FETCH_RECEIPTNOTE_ERROR":
      return { ...state, receiptListStatus: "" };

    case "FETCH_TASKREPORT":
      return { ...state, taskReportList: "" };
    case "FETCH_TASKREPORT_SUCCESS":
      return { ...state, taskReportList: action.payload };
    case "FETCH_TASKREPORT_ERROR":
      return { ...state, taskReportList: "" };

    case "DEPARTMENTWISE_BARCODE":
      return { ...state, barcodeList: "" };
    case "DEPARTMENTWISE_BARCODE_SUCCESS":
      return { ...state, barcodeList: action.payload };
    case "DEPARTMENTWISE_BARCODE_ERROR":
      return { ...state, barcodeList: "" };

    case "GENERATEBARCODEBULK":
      return { ...state, barcodebulkList: "" };
    case "GENERATEBARCODEBULK_SUCCESS":
      return { ...state, barcodebulkList: action.payload };
    case "GENERATEBARCODEBULK_ERROR":
      return { ...state, barcodebulkList: "" };

    default:
      return state;
  }
};
export default equipmentReducer;


