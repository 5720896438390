
export const actionTypes = {
    LIST_TERMS: 'LIST_TERMS',
    LIST_TERMS_SUCCESS: 'LIST_TERMS_SUCCESS',
    LIST_TERMS_ERROR: 'LIST_TERMS_ERROR',

    UPDATE_TERMS: 'UPDATE_TERMS',
    UPDATE_TERMS_SUCCESS: 'UPDATE_TERMS_SUCCESS',
    UPDATE_TERMS_ERROR: 'UPDATE_TERMS_ERROR',
};

export const updateTerms = (payload) => {
    return {
        type: actionTypes.UPDATE_TERMS, payload
    };
};
export const updateTermsSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_TERMS_SUCCESS,
        payload
    };
};
export const updateTermsError = (payload) => {
    return {
        type: actionTypes.UPDATE_TERMS_ERROR,
        payload
    };
};

export const listTerms = (payload) => {
    return {
        type: actionTypes.LIST_TERMS, payload
    };
};
export const listTermsSuccess = (payload) => {
    return {
        type: actionTypes.LIST_TERMS_SUCCESS,
        payload
    };
};
export const listTermsError = (payload) => {
    return {
        type: actionTypes.LIST_TERMS_ERROR,
        payload
    };
};
