import axios from '@service/ApiClient'
// import { siteUrl } from './config'

class brandMasterService {
    constructor(callback) {
        this.callback = callback
    }
    async saveBrand(payload) {
        return await axios.post('/Inventory/Item_brand/add_itembrand', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listBrand(payload) {
        return await axios.post('/Inventory/Item_brand/list_item_brands', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updateBrand(payload) {
        return await axios.post('/Inventory/Item_brand/update_itembrand', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteBrand(payload) {
        return await axios.post('/Inventory/Item_brand/delete_item_brand', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new brandMasterService();