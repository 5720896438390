import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
// ** Logo
import logo from '@src/assets/images/logo/assets_logo.png'

const SpinnerComponent = ({ showLoader }) => {
  const [displayLoader, toggleDisplayLoader] = useState(false);
  const isLoading = useSelector(state => state.common.loader.loader)
  useEffect(() => {
    if (showLoader) {
      toggleDisplayLoader(true);
    } else if (isLoading === true) {
      toggleDisplayLoader(true);
    } else if (isLoading === false) {
      toggleDisplayLoader(false);
    }
  }, [showLoader, isLoading]);

  return displayLoader ? (<div className='fallback-spinner vh-100'>
    {/* <img className='fallback-logo' width={64} height={6
      
      4} src={logo} alt='logo' /> */}
    <div className='loading'>
      <div className='effect-1 effects'></div>
      <div className='effect-2 effects'></div>
      <div className='effect-3 effects'></div>
    </div>
  </div>
  ) : null
}

export default SpinnerComponent
