
export const actionTypes = {
    SAVE_PROJECT: 'SAVE_PROJECT',
    SAVE_PROJECT_SUCCESS: 'SAVE_PROJECT_SUCCESS',
    SAVE_PROJECT_ERROR: 'SAVE_PROJECT_ERROR',
    LIST_PROJECT: 'LIST_PROJECT',
    LIST_PROJECT_SUCCESS: 'LIST_PROJECT_SUCCESS',
    LIST_PROJECT_ERROR: 'LIST_PROJECT_ERROR',
    UPDATE_PROJECT: 'UPDATE_PROJECT',
    UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
    UPDATE_PROJECT_ERROR: 'UPDATE_PROJECT_ERROR',
    DELETE_PROJECT: 'DELETE_PROJECT',
    DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
    DELETE_PROJECT_ERROR: 'DELETE_PROJECT_ERROR',
};

export const saveProject = (payload) => {
    return {
        type: actionTypes.SAVE_PROJECT, payload
    };
};
export const saveProjectSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_PROJECT_SUCCESS,
        payload
    };
};
export const saveProjectError = (payload) => {
    return {
        type: actionTypes.SAVE_PROJECT_ERROR,
        payload
    };
};

export const listProject = (payload) => {
    return {
        type: actionTypes.LIST_PROJECT, payload
    };
};
export const listProjectSuccess = (payload) => {
    return {
        type: actionTypes.LIST_PROJECT_SUCCESS,
        payload
    };
};
export const listProjectError = (payload) => {
    return {
        type: actionTypes.LIST_PROJECT_ERROR,
        payload
    };
};

export const updateProject = (payload) => {
    return {
        type: actionTypes.UPDATE_PROJECT, payload
    };
};
export const updateProjectSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_PROJECT_SUCCESS,
        payload
    };
};
export const updateProjectError = (payload) => {
    return {
        type: actionTypes.UPDATE_PROJECT_ERROR,
        payload
    };
};

export const deleteProject = (payload) => {
    return {
        type: actionTypes.DELETE_PROJECT, payload
    };
};
export const deleteProjectSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_PROJECT_SUCCESS,
        payload
    };
};
export const deleteProjectError = (payload) => {
    return {
        type: actionTypes.DELETE_PROJECT_ERROR,
        payload
    };
};
