import { actionTypes } from './action';

export const initialState = {
    listItemValue: [],
    saveStatus: "",
    updateStatus: '',
    deleteStatus: '',
    listPurchaseRequest: [],
    prData: {}
}
const listItemReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LIST_ITEM:
            return { ...state, listItemValue: [] }
        case actionTypes.LIST_ITEM_SUCCESS:
            return { ...state, listItemValue: action.payload }
        case actionTypes.LIST_ITEM_ERROR:
            return { ...state, listItemValue: [] }

        case actionTypes.SAVE_PURCHASEREQUEST:
            return { ...state, saveStatus: "" }
        case actionTypes.SAVE_PURCHASEREQUEST_SUCCESS:
            return { ...state, saveStatus: action.payload }
        case actionTypes.SAVE_PURCHASEREQUEST_ERROR:
            return { ...state, saveStatus: "error" }

        case actionTypes.LIST_PURCHASEREQUEST:
            return {
                ...state, listPurchaseRequest: [], deleteStatus: '', saveStatus: '', updateStatus: '',
            }
        case actionTypes.LIST_PURCHASEREQUEST_SUCCESS:
            return { ...state, listPurchaseRequest: action.payload }
        case actionTypes.LIST_PURCHASEREQUEST_ERROR:
            return { ...state, listPurchaseRequest: [] }

        case actionTypes.UPDATE_PURCHASEREQUEST:
            return { ...state, updateStatus: '' }
        case actionTypes.UPDATE_PURCHASEREQUEST_SUCCESS:
            return { ...state, updateStatus: action.payload }
        case actionTypes.UPDATE_PURCHASEREQUEST_ERROR:
            return { ...state, updateStatus: '' }

        case actionTypes.DELETE_PURCHASEREQUEST:
            return {
                ...state, deleteStatus: '',
            }
        case actionTypes.DELETE_PURCHASEREQUEST_SUCCESS:
            return { ...state, deleteStatus: action.payload }
        case actionTypes.DELETE_PURCHASEREQUEST_ERROR:
            return { ...state, deleteStatus: '' }

        case actionTypes.FETCH_PR_BY_ID:
            return { ...state, prData: {} }
        case actionTypes.FETCH_PR_BY_ID_SUCCESS:
            return { ...state, prData: action.payload }
        case actionTypes.FETCH_PR_BY_ID_ERROR:
            return { ...state, prData: {} }

        default:
            return state
    }
}
export default listItemReducer