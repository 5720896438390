import axios from '@service/ApiClient'

class holidayMasterService {
    constructor(callback) {
        this.callback = callback
    }
    async saveHoliday(payload) {
        return await axios.post('/Organization/Holiday_master/save_holiday', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async listHoliday(payload) {
        return await axios.post('/Organization/Holiday_master/list_holiday', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateHoliday(payload) {
        return await axios.post('/Organization/Holiday_master/update_holiday', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteHoliday(payload) {
        return await axios.post('/Organization/Holiday_master/delete_holiday', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new holidayMasterService();