import axios from '../../ApiClient'

class expenseservice {
    constructor(callback) {
        this.callback = callback
    }

    async saveExpensetype(payload) {
        return await axios.post('/Expense/Expense/save_expense_types',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async listExpensetype(payload) {
        return await axios.post('/Expense/Expense/list_expense_types',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async UpdateExpensetype(payload) {
        return await axios.post('/Expense/Expense/update_expense_type',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async DeleteExpensetype(payload) {
        return await axios.post('/Expense/Expense/delete_expense_type',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async FetchExpensetype(payload) {
        return await axios.post('/Expense/Expense/delete_expense_type',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async FetchTask(payload) {
        return await axios.post('/Crm/Task/list_task',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async SaveExpenseModule(payload) {
        return await axios.post('/Expense/Expense/save_employee_expense',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async ListExpenseModule(payload) {
        return await axios.post('/Expense/Expense/list_employee_expense',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async DeleteExpenseModule(payload) {
        return await axios.post('/Expense/Expense/delete_employee_expense',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async EditExpenseModule(payload) {
        return await axios.post('/Expense/Expense/edit_employee_expense',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async UpdateExpenseModule(payload) {
        return await axios.post('/Expense/Expense/update_employee_expense',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new expenseservice();