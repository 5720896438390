import { actionTypes } from './action';


export const initialState = {
    saveCategory: '',
    removeCategory: '',
    editCategory: '',
    categoryList: [],
    allCategoryList: [],
    subCategoryList: {},
    isLoading: false
}

const itemReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_CATEGORY:
            return { ...state, saveCategory: '', isLoading: true }
        case actionTypes.SAVE_CATEGORY_SUCCESS:
            return { ...state, saveCategory: 'success', isLoading: false }
        case actionTypes.SAVE_CATEGORY_ERROR:
            return { ...state, saveCategory: 'error', isLoading: false }

        case actionTypes.DELETE_CATEGORY:
            return { ...state, removeCategory: '', isLoading: true }
        case actionTypes.DELETE_CATEGORY_SUCCESS:
            return { ...state, removeCategory: 'success', isLoading: false }
        case actionTypes.DELETE_CATEGORY_ERROR:
            return { ...state, removeCategory: 'error', isLoading: false }

        case actionTypes.UPDATE_CATEGORY:
            return { ...state, editCategory: '', isLoading: true }
        case actionTypes.UPDATE_CATEGORY_SUCCESS:
            return { ...state, editCategory: 'success', isLoading: false }
        case actionTypes.UPDATE_CATEGORY_ERROR:
            return { ...state, editCategory: 'error', isLoading: false }

        case actionTypes.LIST_CATEGORY:
            return { ...state, categoryList: [], isLoading: true }
        case actionTypes.LIST_CATEGORY_SUCCESS:
            return { ...state, categoryList: action.payload, isLoading: false }
        case actionTypes.LIST_CATEGORY_ERROR:
            return { ...state, categoryList: [], isLoading: false }

        case actionTypes.LIST_SUBCATEGORY:
            return { ...state, subCategoryList: {}, isLoading: true }
        case actionTypes.LIST_SUBCATEGORY_SUCCESS:
            return { ...state, subCategoryList: action.payload, isLoading: false }
        case actionTypes.LIST_SUBCATEGORY_ERROR:
            return { ...state, subCategoryList: {}, isLoading: false }

        case actionTypes.LIST_ALL_CATEGORY:
            return { ...state, allCategoryList: [], isLoading: true }
        case actionTypes.LIST_ALL_CATEGORY_SUCCESS:
            return { ...state, allCategoryList: action.payload, isLoading: false }
        case actionTypes.LIST_ALL_CATEGORY_ERROR:
            return { ...state, allCategoryList: [], isLoading: false }

        default:
            return state
    }
}

export default itemReducer
