
export const actionTypes = {
    SAVE_QUALIFICATIONDETAILS: 'SAVE_QUALIFICATIONDETAILS',
    SAVE_QUALIFICATIONDETAILS_SUCCESS: 'SAVE_QUALIFICATIONDETAILS_SUCCESS',
    SAVE_QUALIFICATIONDETAILS_ERROR: 'SAVE_QUALIFICATIONDETAILS_ERROR',
    LIST_QUALIFICATIONDETAILS: 'LIST_QUALIFICATIONDETAILS',
    LIST_QUALIFICATIONDETAILS_SUCCESS: 'LIST_QUALIFICATIONDETAILS_SUCCESS',
    LIST_QUALIFICATIONDETAILS_ERROR: 'LIST_QUALIFICATIONDETAILS_ERROR',
    UPDATE_QUALIFICATIONDETAILS: 'UPDATE_QUALIFICATIONDETAILS',
    UPDATE_QUALIFICATIONDETAILS_SUCCESS: 'UPDATE_QUALIFICATIONDETAILS_SUCCESS',
    UPDATE_QUALIFICATIONDETAILS_ERROR: 'UPDATE_QUALIFICATIONDETAILS_ERROR',
    DELETE_QUALIFICATIONDETAILS: 'DELETE_QUALIFICATIONDETAILS',
    DELETE_QUALIFICATIONDETAILS_SUCCESS: 'DELETE_QUALIFICATIONDETAILS_SUCCESS',
    DELETE_QUALIFICATIONDETAILS_ERROR: 'DELETE_QUALIFICATIONDETAILS_ERROR',
    LIST_QUALIFICATION: 'LIST_QUALIFICATION',
    LIST_QUALIFICATION_SUCCESS: 'LIST_QUALIFICATION_SUCCESS',
    LIST_QUALIFICATION_ERROR: 'LIST_QUALIFICATION_ERROR',
};

export const saveQualificationDetails = (payload) => {
    return {
        type: actionTypes.SAVE_QUALIFICATIONDETAILS, payload
    };
};
export const saveQualificationDetailsSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_QUALIFICATIONDETAILS_SUCCESS,
        payload
    };
};
export const saveQualificationDetailsError = (payload) => {
    return {
        type: actionTypes.SAVE_QUALIFICATIONDETAILS_ERROR,
        payload
    };
};

export const listQualificationDetails = (payload) => {
    return {
        type: actionTypes.LIST_QUALIFICATIONDETAILS, payload
    };
};
export const listQualificationDetailsSuccess = (payload) => {
    return {
        type: actionTypes.LIST_QUALIFICATIONDETAILS_SUCCESS,
        payload
    };
};
export const listQualificationDetailsError = (payload) => {
    return {
        type: actionTypes.LIST_QUALIFICATIONDETAILS_ERROR,
        payload
    };
};

export const updateQualificationDetails = (payload) => {
    return {
        type: actionTypes.UPDATE_QUALIFICATIONDETAILS, payload
    };
};
export const updateQualificationDetailsSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_QUALIFICATIONDETAILS_SUCCESS,
        payload
    };
};
export const updateQualificationDetailsError = (payload) => {
    return {
        type: actionTypes.UPDATE_QUALIFICATIONDETAILS_ERROR,
        payload
    };
};

export const deleteQualificationDetails = (payload) => {
    return {
        type: actionTypes.DELETE_QUALIFICATIONDETAILS, payload
    };
};
export const deleteQualificationDetailsSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_QUALIFICATIONDETAILS_SUCCESS,
        payload
    };
};
export const deleteQualificationDetailsError = (payload) => {
    return {
        type: actionTypes.DELETE_QUALIFICATIONDETAILS_ERROR,
        payload
    };
};
export const listQualification = (payload) => {
    return {
        type: actionTypes.LIST_QUALIFICATION, payload
    };
};
export const listQualificationSuccess = (payload) => {
    return {
        type: actionTypes.LIST_QUALIFICATION_SUCCESS,
        payload
    };
};
export const listQualificationError = (payload) => {
    return {
        type: actionTypes.LIST_QUALIFICATION_ERROR,
        payload
    };
};