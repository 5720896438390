const initialState = {
    saveStatus: '',
    serviceList: '',
    deleteStatus:'',
    updateStats:'',
    deleteStatus:'',
}


const serviceReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'ADD_SERVICE':
            return { ...state, saveStatus: '' }
        case 'ADD_SERVICE_SUCCESS':
            return { ...state, saveStatus: action.payload }
        case 'ADD_SERVICE_ERROR':
            return { ...state, saveStatus: 'error' }

        case 'LIST_SERVICE':
            return { ...state, serviceList: [] }
        case 'LIST_SERVICE_SUCCESS':
            return { ...state, serviceList: action.payload }
        case 'LIST_SERVICE_ERROR':
            return { ...state, serviceList: [] }

        case 'DELETE_SERVICE':
            return { ...state, deleteStatus: '' }
        case 'DELETE_SERVICE_SUCCESS':
            return { ...state, deleteStatus: action.payload }
        case 'DELETE_SERVICE_ERROR':
            return { ...state, deleteStatus: 'error' }

        default:
            return state
    }
}
export default serviceReducer