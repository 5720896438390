import axios from '@service/ApiClient'

class projectTypeService {
    constructor(callback) {
        this.callback = callback
    }
    async saveProjectType(payload) {
        return await axios.post('/PMS/Project/save_project_type', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listProjectType(payload) {
        return await axios.post('/PMS/Project/list_project_type', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateProjectType(payload) {
        return await axios.post('/PMS/Project/update_project_type', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteProjectType(payload) {
        return await axios.post('/PMS/Project/delete_project_type', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new projectTypeService();