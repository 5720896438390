
import { actionTypes } from './action';

export const initialState = {
    saveUnitStatus: '',
    listUnitValue: [],
    updateStatus: '',
    deleteStatus: ''
}

const saveUnitReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_UNIT:
            return { ...state, saveUnitStatus: '' }
        case actionTypes.SAVE_UNIT_SUCCESS:
            return { ...state, saveUnitStatus: action.payload }
        case actionTypes.SAVE_UNIT_ERROR:
            return { ...state, saveUnitStatus: '' }

        case actionTypes.LIST_UNIT:
            return { ...state, listUnitValue: [] }
        case actionTypes.LIST_UNIT_SUCCESS:
            return {
                ...state,
                listUnitValue: action.payload,
                saveUnitStatus: '',
                updateStatus: '',
                deleteStatus: ''
            }
        case actionTypes.LIST_UNIT_ERROR:
            return { ...state, listUnitValue: [] }

        case actionTypes.UPDATE_UNIT:
            return { ...state, updateStatus: '' }
        case actionTypes.UPDATE_UNIT_SUCCESS:
            return { ...state, updateStatus: action.payload }
        case actionTypes.UPDATE_UNIT_ERROR:
            return { ...state, updateStatus: '' }

        case actionTypes.DELETE_UNIT:
            return { ...state, deleteStatus: '' }
        case actionTypes.DELETE_UNIT_SUCCESS:
            return { ...state, deleteStatus: action.payload }
        case actionTypes.DELETE_UNIT_ERROR:
            return { ...state, deleteStatus: 'error' }

        default:
            return state
    }
}

export default saveUnitReducer