// ** Redux Imports
import { combineReducers } from 'redux'

import contacts from './contacts/reducer'
import user from './user/reducer'
import eventMaster from './eventMaster/reducer'
import organization from './organization/reducer'
import vendor from './vendor/reducer'
import leads from './leads/reducer'
import meeting from './meeting/reducer';
import brochure from './brochure/reducer';
import model from './mastersettings/model/reducer'
import manufacture from './mastersettings/manufacture/reducer'
import skillset from './skillset/reducer'
import servicemaster from './servicemaster/reducer'
import taskmaster from './task/reducer'
import department from './mastersettings/department/reducer'
import designation from './mastersettings/designation/reducer'
import qualification from './mastersettings/qualification/reducer'
import salutation from './mastersettings/salutation/reducer'
import unit from './mastersettings/unit/reducer'
import category from './mastersettings/category/reducer'
import purchaseRequest from './purchaseRequest/reducer'
import purchaseOrder from './purchaseOrder/reducer'
import role from './mastersettings/role/reducer'
import item from './item/reducer'
import brand from './mastersettings/brand/reducer'
import industry from './mastersettings/industry/reducer'
import termsandconditions from './mastersettings/termsAndConditions/reducer'
import generalSetting from './mastersettings/generalSetting/reducer'
import storeMaster from './mastersettings/storeMaster/reducer'
import serviceRooster from './serviceRooster/reducer'
import mrv from './mrv/reducer'
import deal from './deal/reducer'
import currency from './mastersettings/currencyMaster/reducer'
import quote from './quote/reducer'
import prefixrule from './mastersettings/prefixSettings/reducer'
import dealstage from './mastersettings/dealStage/reducer'
import identityDetails from './identityDetails/reducer'
import qualificationDetails from './qualificationMaster/reducer'
import employmentDetails from './employmentDetails/reducer'
import bankDetails from './bankDetails/reducer'
import familyDetails from './familyDetails/reducer'
import salesOrder from './salesOrder/reducer'
import deleveryOrder from './deleveryOrder/reducer'
import invoice from './invoice/reducer'
import directInvoice from './directInvoice/reducer'
import projectType from './mastersettings/projectType/reducer'
import project from './project/reducer'
import hr from './mastersettings/hr/reducer'
import shiftToEmployee from './mastersettings/shiftToEmployee/reducer'
import timesheet from './timesheet/reducer'
import parameterMaster from './payroll/addParameter/reducer'
import leaveType from './leaveType/reducer'
import leaveRequest from './leaveRequest/reducer'
import salaryallowanceMaster from './payroll/salaryallowanceMaster/reducer'
import advancePayment from './payroll/advancePayment/reducer'
import salaryRegister from './payroll/salaryRegister/reducer'
import expense from './mastersettings/expenseModule/reducer'
import holidayMaster from './mastersettings/holidayMaster/reducer'
import weekendMaster from './mastersettings/weekendMaster/reducer'
import manufacturs from './manufacture/reducer'
import equipment from './equipment/reducer'
import asset from './asset/reducer'
import report from './report/reducer'
import equipmentTransfer from './EquipmentTransfer/reducer'

const adminReducer = combineReducers({
    contacts,
    user,
    unit,
    eventMaster,
    organization,
    vendor,
    leads,
    meeting,
    brochure,
    model,
    manufacture,
    skillset,
    servicemaster,
    taskmaster,
    department,
    designation,
    qualification,
    salutation,
    category,
    purchaseRequest,
    role,
    item,
    brand,
    purchaseOrder,
    industry,
    termsandconditions,
    serviceRooster,
    mrv,
    generalSetting,
    storeMaster,
    deal,
    currency,
    quote,
    prefixrule,
    dealstage,
    identityDetails,
    qualificationDetails,
    employmentDetails,
    bankDetails,
    familyDetails,
    salesOrder,
    deleveryOrder,
    projectType,
    project,
    invoice,
    directInvoice,
    hr,
    shiftToEmployee,
    timesheet,
    leaveType,
    leaveRequest,
    parameterMaster,
    salaryallowanceMaster,
    advancePayment,
    salaryRegister,
    expense,
    holidayMaster,
    weekendMaster,
    manufacturs,
    equipment,
    asset,
    report,
    equipmentTransfer
})

export default adminReducer