//SAVE DEPARTMENT
export const listDepartment = (payload) => {
    return {
        type: 'LIST_DEPARTMENT',
        payload
    };
};
export const listDepartmentSuccess = (payload) => {
    return {
        type: 'LIST_DEPARTMENT_SUCCESS',
        payload
    };
};
export const listDepartmentError = (payload) => {
    return {
        type: 'LIST_DEPARTMENT_ERROR',
        payload
    };
};

export const saveDepartment = (payload) => {
    return {
        type: 'SAVE_DEPARTMENT',
        payload
    };
};
export const saveDepartmentSuccess = (payload) => {
    return {
        type: 'SAVE_DEPARTMENT_SUCCESS',
        payload
    };
};
export const saveDepartmentError = (payload) => {
    return {
        type: 'SAVE_DEPARTMENT_ERROR',
        payload
    };
};

export const deleteDepartment = (payload) => {
    return {
        type: 'DELETE_DEPARTMENT',
        payload
    };
};
export const deleteDepartmentSuccess = (payload) => {
    return {
        type: 'DELETE_DEPARTMENT_SUCCESS',
        payload
    };
};
export const deleteDepartmentError = (payload) => {
    return {
        type: 'DELETE_DEPARTMENT_ERROR',
        payload
    };
};

export const updateDepartment = (payload) => {
    return {
        type: 'UPDATE_DEPARTMENT',
        payload
    };
};
export const updateDepartmentSuccess = (payload) => {
    return {
        type: 'UPDATE_DEPARTMENT_SUCCESS',
        payload
    };
};
export const updateDepartmentError = (payload) => {
    return {
        type: 'UPDATE_DEPARTMENT_ERROR',
        payload
    };
};
