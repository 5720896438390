import { actionTypes } from './action';


export const initialState = {
    itemList:'',
    itemListByCat:'',
    categoryList:'',
    subCategoryList:'',
    brandList:'',
    saveStatus: '',
    updateStatus: '',
    deleteStatus: '',
    itemByID: {},
    importitem:''
}

const itemReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_ITEM:
            return { ...state, saveStatus: '' }
        case actionTypes.SAVE_ITEM_SUCCESS:

            return { ...state, saveStatus: action.payload }
        case actionTypes.SAVE_ITEM_ERROR:
            return { ...state, saveStatus: 'error' }

        case actionTypes.LIST_ITEMS:
            return { ...state, itemList:'', saveStatus: '', deleteStatus: '', updateStatus: '' }
        case actionTypes.LIST_ITEMS_SUCCESS:
            return { ...state, itemList: action.payload }
        case actionTypes.LIST_ITEMS_ERROR:
            return { ...state, itemList:'' }

        case actionTypes.DELETE_ITEM:
            return { ...state, deleteStatus: '' }
        case actionTypes.DELETE_ITEM_SUCCESS:
            return { ...state, deleteStatus: action.payload }
        case actionTypes.DELETE_ITEM_ERROR:
            return { ...state, deleteStatus: 'error' }

        case actionTypes.UPDATE_ITEM:
            return { ...state, updateStatus: '', }
        case actionTypes.UPDATE_ITEM_SUCCESS:
            return { ...state, updateStatus: action.payload }
        case actionTypes.UPDATE_ITEM_ERROR:
            return { ...state, updateStatus: 'error' }

        case actionTypes.LIST_ITEM_CATEGORY:
            return { ...state, categoryList: '', }
        case actionTypes.LIST_ITEM_CATEGORY_SUCCESS:
            return { ...state, categoryList: action.payload }
        case actionTypes.LIST_ITEM_CATEGORY_ERROR:
            return { ...state, categoryList:'' }

        case actionTypes.LIST_ITEM_SUBCATEGORY:
            return { ...state, subCategoryList:'', }
        case actionTypes.LIST_ITEM_SUBCATEGORY_SUCCESS:
            return { ...state, subCategoryList: action.payload }
        case actionTypes.LIST_ITEM_SUBCATEGORY_ERROR:
            return { ...state, subCategoryList:'' }

        case actionTypes.LIST_ITEM_BRAND:
            return { ...state, brandList:'', }
        case actionTypes.LIST_ITEM_BRAND_SUCCESS:
            return { ...state, brandList: action.payload }
        case actionTypes.LIST_ITEM_BRAND_ERROR:
            return { ...state, brandList:'' }

        case actionTypes.FETCH_ITEM_BY_ID:
            return { ...state, itemByID: {}, }
        case actionTypes.FETCH_ITEM_BY_ID_SUCCESS:
            return { ...state, itemByID: action.payload }
        case actionTypes.FETCH_ITEM_BY_ID_ERROR:
            return { ...state, itemByID: {} }

        case actionTypes.GET_ITEM_BY_CATEGORY_ID:
            return { ...state, itemListByCat:'', }
        case actionTypes.GET_ITEM_BY_CATEGORY_ID_SUCCESS:
            return { ...state, itemListByCat: action.payload }
        case actionTypes.GET_ITEM_BY_CATEGORY_ID_ERROR:
            return { ...state, itemListByCat:'' }

            case 'IMPORT_ITEM':
                return { ...state, importitem: '' }
            case 'IMPORT_ITEM_SUCCESS':
                return { ...state, importitem: 'SUCCESS' }
            case 'IMPORT_ITEM_ERROR':
                return { ...state, importitem: 'ERROR' }

        default:
            return state
    }
}

export default itemReducer
