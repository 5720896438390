import { takeEvery, put, call, all } from 'redux-saga/effects';
import equipmenttransferservice from '@service/admin/masterService/equipmenttransferservice';
import {
  saveEquipmentTransferSuccess, saveEquipmentTransferError,
  listEquipmentTransferSuccess,listEquipmentTransferError
} from './action';


function* saveEquipmentTransfer({payload}) {
  try {
    // yield put(setLoader());
    const data = yield call(equipmenttransferservice.equipmenttransferAdd, payload);
    yield put(saveEquipmentTransferSuccess(data));
  } catch (error) {
    yield put(saveEquipmentTransferError(error));
  } 
  // finally {
  //   yield put(stopLoader());
  // }
}

function* listEquipmentTransfer({payload}) {
  try {
    // yield put(setLoader());
    const data = yield call(equipmenttransferservice.listEquipmentTransfer, payload);
    yield put(listEquipmentTransferSuccess(data));
  } catch (error) {
    yield put(listEquipmentTransferError(error));
  } 
  // finally {
  //   yield put(stopLoader());
  // }
}


export default function* rootSaga() {
  yield all([takeEvery('SAVE_EQUIPMENT_TRANSFER', saveEquipmentTransfer)]);
  yield all([takeEvery('LIST_EQUIPMENT_TRANSFER', listEquipmentTransfer)]);
}