import axios from '@service/ApiClient'

class eventMasterService {
    constructor(callback) {
        this.callback = callback
    }
    async eventMasterContact(payload) {
        return await axios.post('Crm/Contact/list_contacts',payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    } 
}

export default new eventMasterService();