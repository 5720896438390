// SHIFT MODULE
// list hr

export const ListShift = (payload) => {
    return {
         type: 'LIST_SHIFT' ,
         payload
     };
 };
 export const  ListShiftSuccess = (payload) => {
       return {
         type: 'LIST_SHIFT_SUCCESS',
         payload
     };
 };
 export const  ListShiftError = (payload) => {
     return {
         type: 'LIST_SHIFT_ERROR',
         payload
     };
 };

 // Save Shift

 export const SaveShift = (payload) => {
    return {
         type: 'SAVE_SHIFT' ,
         payload
     };
 };
 export const  SaveShiftSuccess = (payload) => {
       return {
         type: 'SAVE_SHIFT_SUCCESS',
         payload
     };
 };
 export const  SaveShiftError = (payload) => {
     return {
         type: 'SAVE_SHIFT_ERROR',
         payload
     };
 };

 // update shift

 export const UpdateShift = (payload) => {
    return {
         type: 'UPDATE_SHIFT' ,
         payload
     };
 };
 export const  UpdateShiftSuccess = (payload) => {
       return {
         type: 'UPDATE_SHIFT_SUCCESS',
         payload
     };
 };
 export const  UpdateShiftError = (payload) => {
     return {
         type: 'UPDATE_SHIFT_ERROR',
         payload
     };
 };

 // get shift by id

 export const ListShiftId = (payload) => {
    return {
         type: 'LIST_SHIFT_ID' ,
         payload
     };
 };
 export const  ListShiftIdSuccess = (payload) => {
       return {
         type: 'LIST_SHIFT_ID_SUCCESS',
         payload
     };
 };
 export const  ListShiftIdError = (payload) => {
     return {
         type: 'LIST_SHIFT_ID_ERROR',
         payload
     };
 };

 // Delete shift

 export const DeleteShift = (payload) => {
    return {
         type: 'DELETE_SHIFT' ,
         payload
     };
 };
 export const  DeleteShiftSuccess = (payload) => {
       return {
         type: 'DELETE_SHIFT_SUCCESS',
         payload
     };
 };
 export const  DeleteShiftError = (payload) => {
     return {
         type: 'DELETE_SHIFT_ERROR',
         payload
     };
 };

 // ADD NEW LEAVE TYPE MODULE

// Fetch desgination in leave type

// export const FetchDesignation = (payload) => {
//     return {
//          type: 'FETCH_DESIGNATION' ,
//          payload
//      };
//  };
//  export const  FetchDesignationSuccess = (payload) => {
//        return {
//          type: 'FETCH_DESIGNATION_SUCCESS',
//          payload
//      };
//  };
//  export const  FetchDesignationError = (payload) => {
//      return {
//          type: 'FETCH_DESIGNATION_ERROR',
//          payload
//      };
//  };

 // Save leave

//  export const SaveLeaveType = (payload) => {
//     return {
//          type: 'SAVE_LEAVETYPE' ,
//          payload
//      };
//  };
//  export const  SaveLeaveTypeSuccess = (payload) => {
//        return {
//          type: 'SAVE_LEAVETYPE_SUCCESS',
//          payload
//      };
//  };
//  export const  SaveLeaveTypeError = (payload) => {
//      return {
//          type: 'SAVE_LEAVETYPE_ERROR',
//          payload
//      };
//  };

// LIST leave by id
// export const FetchLeaveId = (payload) => {
//     return {
//          type: 'FETCH_LEAVE_ID' ,
//          payload
//      };
//  };
//  export const  FetchLeaveIdSuccess = (payload) => {
//        return {
//          type: 'FETCH_LEAVE_ID_SUCCESS',
//          payload
//      };
//  };
//  export const FetchLeaveIdError = (payload) => {
//      return {
//          type: 'FETCH_LEAVE_ID_ERROR',
//          payload
//      };
//  };


// update leave type

// export const UpdateLeavetype = (payload) => {
//     return {
//          type: 'UPDATE_LEAVE_TYPE' ,
//          payload
//      };
//  };
//  export const  UpdateLeavetypeSuccess = (payload) => {
//        return {
//          type: 'UPDATE_LEAVE_TYPE_SUCCESS',
//          payload
//      };
//  };
//  export const  UpdateLeavetypeError = (payload) => {
//      return {
//          type: 'UPDATE_LEAVE_TYPE_ERROR',
//          payload
//      };
//  };

 // list leave

//  export const FetchLeave = (payload) => {
//     return {
//          type: 'FETCH_LEAVE' ,
//          payload
//      };
//  };
//  export const  FetchLeaveSuccess = (payload) => {
//        return {
//          type: 'FETCH_LEAVE_SUCCESS',
//          payload
//      };
//  };
//  export const  FetchLeaveError = (payload) => {
//      return {
//          type: 'FETCH_LEAVE_ERROR',
//          payload
//      };
//  };

 // delete leave

//  export const DeleteLeave = (payload) => {
//     return {
//          type: 'DELETE_LEAVE' ,
//          payload
//      };
//  };
//  export const  DeleteLeaveSuccess = (payload) => {
//        return {
//          type: 'DELETE_LEAVE_SUCCESS',
//          payload
//      };
//  };
//  export const  DeleteLeaveError = (payload) => {
//      return {
//          type: 'DELETE_LEAVE_ERROR',
//          payload
//      };
//  };

 // __ADD RULES__

 // fetch leave type 
//  export const ListLeaveType = (payload) => {
//     return {
//          type: 'LIST_LEAVE_TYPE' ,
//          payload
//      };
//  };
//  export const  ListLeaveTypeSuccess = (payload) => {
//        return {
//          type: 'LIST_LEAVE_TYPE_SUCCESS',
//          payload
//      };
//  };
//  export const  ListLeaveTypeError = (payload) => {
//      return {
//          type: 'LIST_LEAVE_TYPE_ERROR',
//          payload
//      };
//  };

 
//  export const SaveRule = (payload) => {
//     return {
//          type: 'SAVE_RULE' ,
//          payload
//      };
//  };
//  export const  SaveRuleSuccess = (payload) => {
//        return {
//          type: 'SAVE_RULE_SUCCESS',
//          payload
//      };
//  };
//  export const  SaveRuleError = (payload) => {
//      return {
//          type: 'SAVE_RULE_ERROR',
//          payload
//      };
//  };
 
//  export const UpdateRule = (payload) => {
//     return {
//          type: 'UPDATE_RULE' ,
//          payload
//      };
//  };
//  export const  UpdateRuleSuccess = (payload) => {
//        return {
//          type: 'UPDATE_RULE_SUCCESS',
//          payload
//      };
//  };
//  export const  UpdateRuleError = (payload) => {
//      return {
//          type: 'UPDATE_RULE_ERROR',
//          payload
//      };
//  };

//  export const EditRule = (payload) => {
//     return {
//          type: 'EDIT_RULE' ,
//          payload
//      };
//  };
//  export const  EditRuleSuccess = (payload) => {
//        return {
//          type: 'EDIT_RULE_SUCCESS',
//          payload
//      };
//  };
//  export const  EditRuleError = (payload) => {
//      return {
//          type: 'EDIT_RULE_ERROR',
//          payload
//      };
//  };
 

 // Leave request

//  export const SaveLeaveRequest = (payload) => {
//     return {
//          type: 'SAVE_LEAVEREQUEST' ,
//          payload
//      };
//  };
//  export const  SaveLeaveRequestSuccess = (payload) => {
//        return {
//          type: 'SAVE_LEAVEREQUEST_SUCCESS',
//          payload
//      };
//  };
//  export const  SaveLeaveRequestError = (payload) => {
//      return {
//          type: 'SAVE_LEAVEREQUEST_ERROR',
//          payload
//      };
//  };

//  export const FetchLeaveRequest = (payload) => {
//     return {
//          type: 'FETCH_LEAVE_REQUEST' ,
//          payload
//      };
//  };
//  export const  FetchLeaveRequestSuccess = (payload) => {
//        return {
//          type: 'FETCH_LEAVE_REQUEST_SUCCESS',
//          payload
//      };
//  };
//  export const  FetchLeaveRequestError = (payload) => {
//      return {
//          type: 'FETCH_LEAVE_REQUEST_ERROR',
//          payload
//      };
//  };

//  export const FetchLeaveType = (payload) => {
//     return {
//          type: 'FETCH_LEAVETYPE' ,
//          payload
//      };
//  };
//  export const  FetchLeaveTypeSuccess = (payload) => {
//        return {
//          type: 'FETCH_LEAVETYPE_SUCCESS',
//          payload
//      };
//  };
//  export const  FetchLeaveTypeError = (payload) => {
//      return {
//          type: 'FETCH_LEAVETYPE_ERROR',
//          payload
//      };
//  };
//  export const EditLeaveType = (payload) => {
//     return {
//          type: 'EDIT_LEAVETYPE' ,
//          payload
//      };
//  };
//  export const  EditLeaveTypeSuccess = (payload) => {
//        return {
//          type: 'EDIT_LEAVETYPE_SUCCESS',
//          payload
//      };
//  };
//  export const  EditLeaveTypeError = (payload) => {
//      return {
//          type: 'EDIT_LEAVETYPE_ERROR',
//          payload
//      };
//  };

//  export const UpdateLeaveType = (payload) => {
//     return {
//          type: 'UPDATE_LEAVETYPE' ,
//          payload
//      };
//  };
//  export const  UpdateLeaveTypeSuccess = (payload) => {
//        return {
//          type: 'UPDATE_LEAVETYPE_SUCCESS',
//          payload
//      };
//  };
//  export const  UpdateLeaveTypeError = (payload) => {
//      return {
//          type: 'UPDATE_LEAVETYPE_ERROR',
//          payload
//      };
//  };
//  export const DeleteLeaveType = (payload) => {
//     return {
//          type: 'DELETE_LEAVETYPE' ,
//          payload
//      };
//  };
//  export const  DeleteLeaveTypeSuccess = (payload) => {
//        return {
//          type: 'DELETE_LEAVETYPE_SUCCESS',
//          payload
//      };
//  };
//  export const  DeleteLeaveTypeError = (payload) => {
//      return {
//          type: 'DELETE_LEAVETYPE_ERROR',
//          payload
//      };
//  };
//  export const CancelLeaveRequest = (payload) => {
//     return {
//          type: 'CANCEL_LEAVEREQUEST' ,
//          payload
//      };
//  };
//  export const  CancelLeaveRequestSuccess = (payload) => {
//        return {
//          type: 'CANCEL_LEAVEREQUEST_SUCCESS',
//          payload
//      };
//  };
//  export const  CancelLeaveRequestError = (payload) => {
//      return {
//          type: 'CANCEL_LEAVEREQUEST_ERROR',
//          payload
//      };
//  };
//  export const UpdateLeaveStatus = (payload) => {
//     return {
//          type: 'UPDATE_STATUS' ,
//          payload
//      };
//  };
//  export const  UpdateLeaveStatusSuccess = (payload) => {
//        return {
//          type: 'UPDATE_STATUS_SUCCESS',
//          payload
//      };
//  };
//  export const  UpdateLeaveStatusError = (payload) => {
//      return {
//          type: 'UPDATE_STATUS_ERROR',
//          payload
//      };
//  };

 // leave Approval

//  export const FetchRequest = (payload) => {
//     return {
//          type: 'FETCH_REQUEST' ,
//          payload
//      };
//  };
//  export const  FetchRequestSuccess = (payload) => {
//        return {
//          type: 'FETCH_REQUEST_SUCCESS',
//          payload
//      };
//  };
//  export const  FetchRequestError = (payload) => {
//      return {
//          type: 'FETCH_REQUEST_ERROR',
//          payload
//      };
//  };

//  export const ApproveRequest = (payload) => {
//     return {
//          type: 'APPROVE_REQUEST' ,
//          payload
//      };
//  };
//  export const  ApproveRequestSuccess = (payload) => {
//        return {
//          type: 'APPROVE_REQUEST_SUCCESS',
//          payload
//      };
//  };
//  export const  ApproveRequestError = (payload) => {
//      return {
//          type: 'APPROVE_REQUEST_ERROR',
//          payload
//      };
//  };

//  export const RejectRequest = (payload) => {
//     return {
//          type: 'REJECT_REQUEST' ,
//          payload
//      };
//  };
//  export const  RejectRequestSuccess = (payload) => {
//        return {
//          type: 'REJECT_REQUEST_SUCCESS',
//          payload
//      };
//  };
//  export const RejectRequestError = (payload) => {
//      return {
//          type: 'REJECT_REQUEST_ERROR',
//          payload
//      };
//  };