import { actionTypes } from './action';

export const initialState = {
    saveGeneralSettingsValues: '',
    listGeneralSettingsValues: {},
    updateGeneralSettingsValues: '',
    listAreaNameValues: '',
    listTimeZoneValues: []
}
const saveGeneralSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_GENERAL_SETTINGS:
            return { ...state, saveGeneralSettingsValues: '' }
        case actionTypes.SAVE_GENERAL_SETTINGS_SUCCESS:
            return { ...state, saveGeneralSettingsValues: action.payload }
        case actionTypes.SAVE_GENERAL_SETTINGS_ERROR:
            return { ...state, saveGeneralSettingsValues: '' }

        case actionTypes.LIST_GENERAL_SETTINGS:
            return { ...state, listGeneralSettingsValues: {} }
        case actionTypes.LIST_GENERAL_SETTINGS_SUCCESS:
            return {
                ...state,
                listGeneralSettingsValues: action.payload,
                saveGeneralSettingsValues: '',
                updateGeneralSettingsValues: ''
            }
        case actionTypes.LIST_GENERAL_SETTINGS_ERROR:
            return { ...state, listGeneralSettingsValues: {} }

        case actionTypes.UPDATE_GENERAL_SETTINGS:
            return { ...state, updateGeneralSettingsValues: '' }
        case actionTypes.UPDATE_GENERAL_SETTINGS_SUCCESS:
            return { ...state, updateGeneralSettingsValues: action.payload }
        case actionTypes.UPDATE_GENERAL_SETTINGS_ERROR:
            return { ...state, updateGeneralSettingsValues: '' }

        case actionTypes.LIST_AREANAME:
            return { ...state, listAreaNameValues: '' }
        case actionTypes.LIST_AREANAME_SUCCESS:
            return { ...state, listAreaNameValues: action.payload }
        case actionTypes.LIST_AREANAME_ERROR:
            return { ...state, listAreaNameValues: '' }

        case actionTypes.LIST_TIMEZONE:
            return { ...state, listTimeZoneValues: [] }
        case actionTypes.LIST_TIMEZONE_SUCCESS:
            return { ...state, listTimeZoneValues: action.payload }
        case actionTypes.LIST_TIMEZONE_ERROR:
            return { ...state, listTimeZoneValues: [] }

        default:
            return state
    }
}

export default saveGeneralSettingsReducer