
export const actionTypes = {

    // LIST_SO_FOR_INVOICE: 'LIST_SO_FOR_INVOICE',
    // LIST_SO_FOR_INVOICE_SUCCESS: 'LIST_SO_FOR_INVOICE_SUCCESS',
    // LIST_SO_FOR_INVOICE_ERROR: 'LIST_SO_FOR_INVOICE_ERROR',

    // LIST_SO_FOR_DO: 'LIST_SO_FOR_DO',
    // LIST_SO_FOR_DO_SUCCESS: 'LIST_SO_FOR_DO_SUCCESS',
    // LIST_SO_FOR_DO_ERROR: 'LIST_SO_FOR_DO_ERROR',

    SAVE_DIRECT_INVOICE: 'SAVE_DIRECT_INVOICE',
    SAVE_DIRECT_INVOICE_SUCCESS: 'SAVE_DIRECT_INVOICE_SUCCESS',
    SAVE_DIRECT_INVOICE_ERROR: 'SAVE_DIRECT_INVOICE_ERROR',

    // GET_BARCODE_STATUS_DO: 'GET_BARCODE_STATUS_DO',
    // GET_BARCODE_STATUS_DO_SUCCESS: 'GET_BARCODE_STATUS_DO_SUCCESS',
    // GET_BARCODE_STATUS_DO_ERROR: 'GET_BARCODE_STATUS_DO_ERROR',



    // LIST_DO_BY_ID: 'LIST_DO_BY_ID',
    // LIST_DO_BY_ID_SUCCESS: 'LIST_DO_BY_ID_SUCCESS',
    // LIST_DO_BY_ID_ERROR: 'LIST_DO_BY_ID_ERROR',

    // UPDATE_DO: 'UPDATE_DO',
    // UPDATE_DO_SUCCESS: 'UPDATE_DO_SUCCESS',
    // UPDATE_DO_ERROR: 'UPDATE_DO_ERROR',

    // DELETE_DO: 'DELETE_DO',
    // DELETE_DO_SUCCESS: 'DELETE_DO_SUCCESS',
    // DELETE_DO_ERROR: 'DELETE_DO_ERROR',
};

// export const listSoForInvoice = (payload) => {
//     return {
//         type: actionTypes.LIST_SO_FOR_INVOICE, payload
//     };
// };
// export const listSoForInvoiceSuccess = (payload) => {
//     return {
//         type: actionTypes.LIST_SO_FOR_INVOICE_SUCCESS,
//         payload
//     };
// };
// export const listSoForInvoiceError = (payload) => {
//     return {
//         type: actionTypes.LIST_SO_FOR_INVOICE_ERROR,
//         payload
//     };
// };
// export const listSoForDo = (payload) => {
//     console.log(payload);
//     return {
//         type: actionTypes.LIST_SO_FOR_DO, payload
//     };
// };
// export const listSoForDoSuccess = (payload) => {
//     return {
//         type: actionTypes.LIST_SO_FOR_DO_SUCCESS,
//         payload
//     };
// };
// export const listSoForDoError = (payload) => {
//     return {
//         type: actionTypes.LIST_SO_FOR_DO_ERROR,
//         payload
//     };
// };

export const saveDirectInvoice = (payload) => {
    return {
        type: actionTypes.SAVE_DIRECT_INVOICE, payload
    };
};
export const saveDirectInvoiceSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_DIRECT_INVOICE_SUCCESS,
        payload
    };
};
export const saveDirectInvoiceError = (payload) => {
    return {
        type: actionTypes.SAVE_DIRECT_INVOICE_ERROR,
        payload
    };
};

// export const getBarcodeStatusDo = (payload) => {
//     return {
//         type: actionTypes.GET_BARCODE_STATUS_DO, payload
//     };
// };
// export const getBarcodeStatusDoSuccess = (payload) => {
//     return {
//         type: actionTypes.GET_BARCODE_STATUS_DO_SUCCESS,
//         payload
//     };
// };
// export const getBarcodeStatusDoError = (payload) => {
//     return {
//         type: actionTypes.GET_BARCODE_STATUS_DO_ERROR,
//         payload
//     };
// };

// export const updateDo = (payload) => {
//     console.log(payload);
//     return {
//         type: actionTypes.UPDATE_DO, payload
//     };
// };
// export const updateDoSuccess = (payload) => {
//     return {
//         type: actionTypes.UPDATE_DO_SUCCESS,
//         payload
//     };
// };
// export const updateDoError = (payload) => {
//     return {
//         type: actionTypes.UPDATE_DO_ERROR,
//         payload
//     };
// };

// export const deleteDo = (payload) => {
//     console.log(payload);
//     return {
//         type: actionTypes.DELETE_DO, payload
//     };
// };
// export const deleteDoSuccess = (payload) => {
//     return {
//         type: actionTypes.DELETE_DO_SUCCESS,
//         payload
//     };
// };
// export const deleteDoError = (payload) => {
//     return {
//         type: actionTypes.DELETE_DO_ERROR,
//         payload
//     };
// };



// export const fetchDoById = (payload) => {
//     return {
//         type: actionTypes.LIST_DO_BY_ID, payload
//     };
// };
// export const fetchDoByIdSuccess = (payload) => {
//     return {
//         type: actionTypes.LIST_DO_BY_ID_SUCCESS,
//         payload
//     };
// };
// export const fetchDoByIdError = (payload) => {
//     return {
//         type: actionTypes.LIST_DO_BY_ID_ERROR,
//         payload
//     };
// };