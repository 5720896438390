export const actionTypes = {
    FETCH_SERVICE_MASTER: 'FETCH_SERVICE_MASTER',
    FETCH_SERVICE_MASTER_SUCCESS: 'FETCH_SERVICE_MASTER_SUCCESS',
    FETCH_SERVICE_MASTER_ERROR: 'FETCH_SERVICE_MASTER_ERROR',

    SAVE_SERVICE_ROOSTER: 'SAVE_SERVICE_ROOSTER',
    SAVE_SERVICE_ROOSTER_SUCCESS: 'SAVE_SERVICE_ROOSTER_SUCCESS',
    SAVE_SERVICE_ROOSTER_ERROR: 'SAVE_SERVICE_ROOSTER_ERROR',

    FETCH_SERVICE_ROOSTER: 'FETCH_SERVICE_ROOSTER',
    FETCH_SERVICE_ROOSTER_SUCCESS: 'FETCH_SERVICE_ROOSTER_SUCCESS',
    FETCH_SERVICE_ROOSTER_ERROR: 'FETCH_SERVICE_ROOSTER_ERROR',

    DELETE_SERVICE_ROOSTER: 'DELETE_SERVICE_ROOSTER',
    DELETE_SERVICE_ROOSTER_SUCCESS: 'DELETE_SERVICE_ROOSTER_SUCCESS',
    DELETE_SERVICE_ROOSTER_ERROR: 'DELETE_SERVICE_ROOSTER_ERROR',

    ASSIGN_EMPLOYEE_SERVICE: 'ASSIGN_EMPLOYEE_SERVICE',
    ASSIGN_EMPLOYEE_SERVICE_SUCCESS: 'ASSIGN_EMPLOYEE_SERVICE_SUCCESS',
    ASSIGN_EMPLOYEE_SERVICE_ERROR: 'ASSIGN_EMPLOYEE_SERVICE_ERROR',
}

export const fetchServiceMaster = (payload) => {
    return { type: actionTypes.FETCH_SERVICE_MASTER, payload }
}
export const fetchServiceMasterSuccess = (payload) => {
    return { type: actionTypes.FETCH_SERVICE_MASTER_SUCCESS, payload }
}
export const fetchServiceMasterError = (payload) => {
    return { type: actionTypes.FETCH_SERVICE_MASTER_ERROR, payload }
}

export const handleServiceRooster = (payload) => {
    return { type: actionTypes.SAVE_SERVICE_ROOSTER, payload }
}
export const handleServiceRoosterSuccess = (payload) => {
    return { type: actionTypes.SAVE_SERVICE_ROOSTER_SUCCESS, payload }
}
export const handleServiceRoosterError = (payload) => {
    return { type: actionTypes.SAVE_SERVICE_ROOSTER_ERROR, payload }
}

export const fetchServiceRooster = (payload) => {
    return { type: actionTypes.FETCH_SERVICE_ROOSTER, payload }
}
export const fetchServiceRoosterSuccess = (payload) => {
    return { type: actionTypes.FETCH_SERVICE_ROOSTER_SUCCESS, payload }
}
export const fetchServiceRoosterError = (payload) => {
    return { type: actionTypes.FETCH_SERVICE_ROOSTER_ERROR, payload }
}

export const removeServiceRooster = (payload) => {
    return { type: actionTypes.DELETE_SERVICE_ROOSTER, payload }
}
export const removeServiceRoosterSuccess = (payload) => {
    return { type: actionTypes.DELETE_SERVICE_ROOSTER_SUCCESS, payload }
}
export const removeServiceRoosterError = (payload) => {
    return { type: actionTypes.DELETE_SERVICE_ROOSTER_ERROR, payload }
}

export const assignEmployeeService = (payload) => {
    return { type: actionTypes.ASSIGN_EMPLOYEE_SERVICE, payload }
}
export const assignEmployeeServiceSuccess = (payload) => {
    return { type: actionTypes.ASSIGN_EMPLOYEE_SERVICE_SUCCESS, payload }
}
export const assignEmployeeServiceError = (payload) => {
    return { type: actionTypes.ASSIGN_EMPLOYEE_SERVICE_ERROR, payload }
}