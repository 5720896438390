
export const actionTypes = {
    SAVE_UNIT: 'SAVE_UNIT',
    SAVE_UNIT_SUCCESS: 'SAVE_UNIT_SUCCESS',
    SAVE_UNIT_ERROR: 'SAVE_UNIT_ERROR',
    LIST_UNIT: 'LIST_UNIT',
    LIST_UNIT_SUCCESS: 'LIST_UNIT_SUCCESS',
    LIST_UNIT_ERROR: 'LIST_UNIT_ERROR',
    UPDATE_UNIT: 'UPDATE_UNIT',
    UPDATE_UNIT_SUCCESS: 'UPDATE_UNIT_SUCCESS',
    UPDATE_UNIT_ERROR: 'UPDATE_UNIT_ERROR',
    DELETE_UNIT: 'DELETE_UNIT',
    DELETE_UNIT_SUCCESS: 'DELETE_UNIT_SUCCESS',
    DELETE_UNIT_ERROR: 'DELETE_UNIT_ERROR',

};


export const saveUnit = (payload) => {
    return {
        type: actionTypes.SAVE_UNIT, payload
    };
};
export const saveUnitSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_UNIT_SUCCESS,
        payload
    };
};
export const saveUnitError = (payload) => {
    return {
        type: actionTypes.SAVE_UNIT_ERROR,
        payload
    };
};

export const listUnit = (payload) => {
    return {
        type: actionTypes.LIST_UNIT,
        payload
    };
};
export const listUnitSuccess = (payload) => {
    return {
        type: actionTypes.LIST_UNIT_SUCCESS,
        payload
    };
};
export const listUnitError = (payload) => {
    return {
        type: actionTypes.LIST_UNIT_ERROR,
        payload
    };
};

export const updateUnit = (payload) => {
    return {
        type: actionTypes.UPDATE_UNIT, payload
    };
};
export const updateUnitSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_UNIT_SUCCESS,
        payload
    };
};
export const updateUnitError = (payload) => {
    return {
        type: actionTypes.UPDATE_UNIT_ERROR,
        payload
    };
};

export const deleteUnit = (payload) => {
    return {
        type: actionTypes.DELETE_UNIT, payload
    };
};
export const deleteUnitSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_UNIT_SUCCESS,
        payload
    };
};
export const deleteUnitError = (payload) => {
    return {
        type: actionTypes.DELETE_UNIT_ERROR,
        payload
    };
};
