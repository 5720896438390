import { actionTypes } from './action';
export const initialState = {
    saveBankDetailsStatus: '',
    listBankDetails: [],
    updateBankDetailssStatus: '',
    deleteBankDetailsStatus: '',
}
const saveBankDetailsReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_BANKDETAILS:
            return { ...state, saveBankDetailsStatus: '' }
        case actionTypes.SAVE_BANKDETAILS_SUCCESS:
            return { ...state, saveBankDetailsStatus: action.payload }
        case actionTypes.SAVE_BANKDETAILS_ERROR:
            return { ...state, saveBankDetailsStatus: '' }

        case actionTypes.LIST_BANKDETAILS:
            return { ...state, listBankDetails: [] }
        case actionTypes.LIST_BANKDETAILS_SUCCESS:
            return { ...state, listBankDetails: action.payload }
        case actionTypes.LIST_BANKDETAILS_ERROR:
            return { ...state, listBankDetails: [] }

        case actionTypes.UPDATE_BANKDETAILS:
            return { ...state, updateBankDetailssStatus: '' }
        case actionTypes.UPDATE_BANKDETAILS_SUCCESS:
            return { ...state, updateBankDetailssStatus: action.payload }
        case actionTypes.UPDATE_BANKDETAILS_ERROR:
            return { ...state, updateBankDetailssStatus: '' }

        case actionTypes.DELETE_BANKDETAILS:
            return { ...state, deleteBankDetailsStatus: '' }
        case actionTypes.DELETE_BANKDETAILS_SUCCESS:
            return { ...state, deleteBankDetailsStatus: action.payload }
        case actionTypes.DELETE_BANKDETAILS_ERROR:
            return { ...state, deleteBankDetailsStatus: '' }

        default:
            return state
    }
}
export default saveBankDetailsReducer