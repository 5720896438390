
import { actionTypes } from './action';

export const initialState = {
    saveIdentityDetailsStatus: '',
    listIdentiyDetailsStatus: [],
    updateIdentityDetailssStatus: '',
    deleteIdentityDetailsStatus: '',

}
const saveIdentityDetailsReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_IDENTITYDETAILS:
            return { ...state, saveIdentityDetailsStatus: '' }
        case actionTypes.SAVE_IDENTITYDETAILS_SUCCESS:
            return { ...state, saveIdentityDetailsStatus: action.payload }
        case actionTypes.SAVE_IDENTITYDETAILS_ERROR:
            return { ...state, saveIdentityDetailsStatus: '' }

        case actionTypes.LIST_IDENTITYDETAILS:
            return { ...state, listIdentiyDetailsStatus: [] }
        case actionTypes.LIST_IDENTITYDETAILS_SUCCESS:
            return { ...state, listIdentiyDetailsStatus: action.payload }
        case actionTypes.LIST_IDENTITYDETAILS_ERROR:
            return { ...state, listIdentiyDetailsStatus: [] }

        case actionTypes.UPDATE_IDENTITYDETAILS:
            return { ...state, updateIdentityDetailssStatus: '' }
        case actionTypes.UPDATE_IDENTITYDETAILS_SUCCESS:
            return { ...state, updateIdentityDetailssStatus: action.payload }
        case actionTypes.UPDATE_IDENTITYDETAILS_ERROR:
            return { ...state, updateIdentityDetailssStatus: '' }

        case actionTypes.DELETE_IDENTITYDETAILS:
            return { ...state, deleteIdentityDetailsStatus: '' }
        case actionTypes.DELETE_IDENTITYDETAILS_SUCCESS:
            return { ...state, deleteIdentityDetailsStatus: action.payload }
        case actionTypes.DELETE_IDENTITYDETAILS_ERROR:
            return { ...state, deleteIdentityDetailsStatus: '' }

       

        default:
            return state
    }
}
export default saveIdentityDetailsReducer