import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import termsAndConditionsService from '@service/admin/masterService/termsAndConditionsService';
import {
  updateTermsSuccess, updateTermsError,
  listTermsSuccess, listTermsError
} from './action';

function* updateTerms({ payload }) {
  try {
    const data = yield call(termsAndConditionsService.updateTerms, payload);

    yield put(updateTermsSuccess(data));
  } catch (error) {
    yield put(updateTermsError(error));
  } finally {
  }
}

function* listTerms({ payload }) {
  try {
    const data = yield call(termsAndConditionsService.listTerms, payload);
    Array.isArray(data) ?
      yield put(listTermsSuccess(data)) :
      yield put(listTermsError([]));
  } catch (error) {
    yield put(listTermsError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.UPDATE_TERMS, updateTerms)]);
  yield all([takeEvery(actionTypes.LIST_TERMS, listTerms)]);
}