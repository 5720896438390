import axios from '@service/ApiClient'
// import { siteUrl } from './config'

class quoteService {
    constructor(callback) {
        this.callback = callback
    }
    async listSoForDo(payload) {
        return await axios.post('/Inventory/Delivery_order/list_salesorder', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async saveDo(payload) {
        return await axios.post('/Inventory/Delivery_order/save_deliveryorder', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async getBarcodeStatusDo(payload) {
        return await axios.post('/Inventory/Delivery_order/get_barcodepopup_common', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteSo(payload) {
        return await axios.post('/Inventory/SalesOrder/delete_so', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateSo(payload) {
        return await axios.post('/Inventory/SalesOrder/update_sales_order', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async listDo(payload) {
        return await axios.post('/Inventory/Delivery_order/deliveryorder_list', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchDoById(payload) {
        return await axios.post('/Inventory/Delivery_order/get_deliveryorder_by_id', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new quoteService();