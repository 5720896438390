import { combineReducers } from 'redux';
const initialState = {
    qualificationLi: [],
    savestatus: '',
    deletestatus: '',
    editstatus: '',
    updatestatus: ''
}

const QualificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_QUALIFICATION':
            return { ...state, qualificationLi: [] }
        case 'FETCH_QUALIFICATION_SUCCESS':
            return { ...state, qualificationLi: action.payload, savestatus: '', deletestatus: '', updatestatus: '', editstatus: '' }
        case 'FETCH_QUALIFICATION_ERROR':
            return { ...state, qualificationLi: [] }

        case 'ADD_QUALIFICATION':
            return { ...state, savestatus: '' }
        case 'ADD_QUALIFICATION_SUCCESS':
            return { ...state, savestatus: 'SUCCESS' }
        case 'ADD_QUALIFICATION_ERROR':
            return { ...state, savestatus: 'ERROR' }

        case 'DELETE_QUALIFICATION':
            return { ...state, deletestatus: '' }
        case 'DELETE_QUALIFICATION_SUCCESS':
            return { ...state, deletestatus: 'SUCCESS' }
        case 'DELETE_QUALIFICATION_ERROR':
            return { ...state, deletestatus: 'ERROR' }

        case 'EDIT_QUALIFICATION':
            return { ...state, editstatus: '' }
        case 'EDIT_QUALIFICATION_SUCCESS':
            return { ...state, editstatus: 'SUCCESS' }
        case 'EDIT_QUALIFICATION_ERROR':
            return { ...state, editstatus: 'ERROR' }

        case 'UPDATE_QUALIFICATION':
            return { ...state, updatestatus: '' }
        case 'UPDATE_QUALIFICATION_SUCCESS':
            return { ...state, updatestatus: 'SUCCESS' }
        case 'UPDATE_QUALIFICATION_ERROR':
            return { ...state, updatestatus: 'ERROR' }

        default:
            return state
    }
}
export default QualificationReducer