

export const SaveLeaveType = (payload) => {
    return {
        type: 'SAVE_LEAVETYPE',
        payload
    };
};
export const SaveLeaveTypeSuccess = (payload) => {
    return {
        type: 'SAVE_LEAVETYPE_SUCCESS',
        payload
    };
};
export const SaveLeaveTypeError = (payload) => {
    return {
        type: 'SAVE_LEAVETYPE_ERROR',
        payload
    };
};


export const UpdateLeavetype = (payload) => {
    return {
         type: 'UPDATE_LEAVE_TYPE' ,
         payload
     };
 };
 export const  UpdateLeavetypeSuccess = (payload) => {
       return {
         type: 'UPDATE_LEAVE_TYPE_SUCCESS',
         payload
     };
 };
 export const  UpdateLeavetypeError = (payload) => {
     return {
         type: 'UPDATE_LEAVE_TYPE_ERROR',
         payload
     };
 };


export const FetchLeave = (payload) => {
    return {
        type: 'FETCH_LEAVE',
        payload
    };
};
export const FetchLeaveSuccess = (payload) => {
    return {
        type: 'FETCH_LEAVE_SUCCESS',
        payload
    };
};
export const FetchLeaveError = (payload) => {
    return {
        type: 'FETCH_LEAVE_ERROR',
        payload
    };
};


export const DeleteLeave = (payload) => {
    return {
        type: 'DELETE_LEAVE',
        payload
    };
};
export const DeleteLeaveSuccess = (payload) => {
    return {
        type: 'DELETE_LEAVE_SUCCESS',
        payload
    };
};
export const DeleteLeaveError = (payload) => {
    return {
        type: 'DELETE_LEAVE_ERROR',
        payload
    };
};


 export const SaveRule = (payload) => {
    return {
         type: 'SAVE_RULE' ,
         payload
     };
 };
 export const  SaveRuleSuccess = (payload) => {
       return {
         type: 'SAVE_RULE_SUCCESS',
         payload
     };
 };
 export const  SaveRuleError = (payload) => {
     return {
         type: 'SAVE_RULE_ERROR',
         payload
     };
 };


 export const EditRule = (payload) => {
    return {
         type: 'EDIT_RULE' ,
         payload
     };
 };
 export const  EditRuleSuccess = (payload) => {
       return {
         type: 'EDIT_RULE_SUCCESS',
         payload
     };
 };
 export const  EditRuleError = (payload) => {
     return {
         type: 'EDIT_RULE_ERROR',
         payload
     };
 };
