
import { actionTypes } from './action';

export const initialState = {
    saveWeekendStatus: '',
    deleteWeekendStatus: '',
    updateWeekendStatus: '',
    listWeekendStatus: []
}

const saveWeekendReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_WEEKEND:
            return { ...state, saveWeekendStatus: '' }
        case actionTypes.SAVE_WEEKEND_SUCCESS:
            return { ...state, saveWeekendStatus: action.payload }
        case actionTypes.SAVE_WEEKEND_ERROR:
            return { ...state, saveWeekendStatus: '' }

        case actionTypes.LIST_WEEKEND:
            return { ...state, listWeekendStatus: [] }
        case actionTypes.LIST_WEEKEND_SUCCESS:
            return { ...state, listWeekendStatus: action.payload }
        case actionTypes.LIST_WEEKEND_ERROR:
            return { ...state, listWeekendStatus: [] }

        case actionTypes.DELETE_WEEKEND:
            return { ...state, deleteWeekendStatus: '' }
        case actionTypes.DELETE_WEEKEND_SUCCESS:
            return { ...state, deleteWeekendStatus: action.payload }
        case actionTypes.DELETE_WEEKEND_ERROR:
            return { ...state, deleteWeekendStatus: '' }

        case actionTypes.UPDATE_WEEKEND:
            return { ...state, updateWeekendStatus: '' }
        case actionTypes.UPDATE_WEEKEND_SUCCESS:
            return { ...state, updateWeekendStatus: action.payload }
        case actionTypes.UPDATE_WEEKEND_ERROR:
            return { ...state, updateWeekendStatus: '' }

        default:
            return state
    }
}

export default saveWeekendReducer