import { actionTypes } from './action';
export const initialState = {
    listEmployees: [],
    listShift: [],
    saveShiftStatus: ''
}
const shiftToEmployee = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.ASSIGN_EMPLOYEE_SHIFT:
            return { ...state, saveShiftStatus: '' }
        case actionTypes.ASSIGN_EMPLOYEE_SHIFT_SUCCESS:
            return { ...state, saveShiftStatus: action.payload }
        case actionTypes.ASSIGN_EMPLOYEE_SHIFT_ERROR:
            return { ...state, saveShiftStatus: '' }

        case actionTypes.LIST_EMPLOYEES:
            return { ...state, listEmployees: [] }
        case actionTypes.LIST_EMPLOYEES_SUCCESS:
            return { ...state, listEmployees: action.payload }
        case actionTypes.LIST_EMPLOYEES_ERROR:
            return { ...state, listEmployees: [] }

        // case actionTypes.LIST_SHIFT:
        //     return { ...state, listShift: [] }
        // case actionTypes.LIST_SHIFT_SUCCESS:
        //     return { ...state, listShift: action.payload }
        // case actionTypes.LIST_SHIFT_ERROR:
        //     return { ...state, listShift: [] }

        default:
            return state
    }
}
export default shiftToEmployee