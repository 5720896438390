import axios from '@service/ApiClient'

class contactservice {
    constructor(callback) {
        this.callback = callback
    }
    async saveContact(payload) {
        return await axios.post('Crm/Contact/save_contact', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async assignContact(payload) {
        return await axios.post('Crm/Contact/list_employees',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listOrganization(payload) {
        return await axios.post('Crm/Company/list_companies', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async listContact(payload) {
        return await axios.post('Crm/Contact/list_contacts',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteContact(payload) {
        return await axios.post('Crm/Contact/delete_contact_organization',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateContact(payload) {
        return await axios.post('Crm/Contact/update_contact_organization',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchContactsById(payload) {
        return await axios.post('/Crm/Contact/list_contact_by_id', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async saveCrmFunnelData(payload) {
        return await axios.post('/Crm/Crm_funnel/insert_crm_dashboard_funnel_data', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchCrmFunnelData(payload) {
        return await axios.post('/Crm/Crm_funnel/list_funnel_details', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new contactservice();