import { actionTypes } from './action';


export const initialState = {
    organizationList: [],
    error: null,
    saveStatus: '',
    deleteStatus:'',
    updateStatus:'',
}

const organizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LIST_ORGANISATION:
            return { ...state, organizationList: [], error: null,saveStatus: '', deleteStatus:'',updateStatus:'', }
        case actionTypes.LIST_ORGANISATION_SUCCESS:
            return { ...state, organizationList: action.payload, error: null }
        case actionTypes.LIST_ORGANISATION_ERROR:
            return { ...state, organizationList: [], error: action.payload }

        case actionTypes.DELETE_ORGANISATION:
            return { ...state, deleteStatus: '', error: null, }
        case actionTypes.DELETE_ORGANISATION_SUCCESS:
            return { ...state, deleteStatus: action.payload, error: null }
        case actionTypes.DELETE_ORGANISATION_ERROR:
            return { ...state, deleteStatus: '', error: action.payload }

        case actionTypes.SAVE_COMPANY:
            return { ...state, saveStatus: '', error: null, }
        case actionTypes.SAVE_COMPANY_SUCCESS:
            return { ...state, saveStatus: action.payload, error: null }
        case actionTypes.SAVE_COMPANY_ERROR:
            return { ...state, saveStatus: '', error: action.payload }

        case actionTypes.UPDATE_COMPANY:
            return { ...state, updateStatus: '', error: null, }
        case actionTypes.UPDATE_COMPANY_SUCCESS:
            return { ...state, updateStatus: action.payload, error: null }
        case actionTypes.UPDATE_COMPANY_ERROR:
            return { ...state, updateStatus: '', error: action.payload }

        default:
            return state
    }
}

export default organizationReducer
