import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import identityDetailsService from '@service/admin/masterService/identityDetailsService';
import {
  saveIdentityDetailsSuccess, saveIdentityDetailsError,
  listIdentityDetailsSuccess, listIdentityDetailsError,
  updateIdentityDetailsSuccess, updateIdentityDetailsError,
  deleteIdentityDetailsSuccess, deleteIdentityDetailsError,
} from './action';

function* saveIdentityDetails({ payload }) {
  try {
    const data = yield call(identityDetailsService.saveIdentityDetails, payload);

    yield put(saveIdentityDetailsSuccess(data));
  } catch (error) {
    yield put(saveIdentityDetailsError(error));
  } finally {
  }
}

function* listIdentityDetails({ payload }) {
  try {
    const data = yield call(identityDetailsService.listIdentityDetails, payload);

    yield put(listIdentityDetailsSuccess(data));
  } catch (error) {
    yield put(listIdentityDetailsError(error));
  } finally {
  }
}

function* updateIdentityDetails({ payload }) {
  try {
    const data = yield call(identityDetailsService.updateIdentityDetails, payload);

    yield put(updateIdentityDetailsSuccess(data));
  } catch (error) {
    yield put(updateIdentityDetailsError(error));
  } finally {
  }
}
function* deleteIdentityDetails({ payload }) {
  try {
    const data = yield call(identityDetailsService.deleteIdentityDetails, payload);

    yield put(deleteIdentityDetailsSuccess(data));
  } catch (error) {
    yield put(deleteIdentityDetailsError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_IDENTITYDETAILS, saveIdentityDetails)]);
  yield all([takeEvery(actionTypes.LIST_IDENTITYDETAILS, listIdentityDetails)]);
  yield all([takeEvery(actionTypes.UPDATE_IDENTITYDETAILS, updateIdentityDetails)]);
  yield all([takeEvery(actionTypes.DELETE_IDENTITYDETAILS, deleteIdentityDetails)]);

}