import { actionTypes } from './action';
export const initialState = {
    lpList: [],
    salaryData: {},
    saveStatus: '',
    listSalaryregister: [],
    empSalaryregister: [],
    salaryregieterview: [],
    paymentStatus: '',
    adavancepaymentstatus: [],
    fetchadpayment: [],
    getadvanceamt: {},
    employeeList: []
}
const salaryReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_LP:
            return { ...state, lpList: [] }
        case actionTypes.FETCH_LP_SUCCESS:
            return { ...state, lpList: action.payload }
        case actionTypes.FETCH_LP_ERROR:
            return { ...state, lpList: [] }

        case actionTypes.FETCH_SALARY_DETAILS:
            return { ...state, salaryData: {}, saveStatus: '',paymentStatus:'' }
        case actionTypes.FETCH_SALARY_DETAILS_SUCCESS:
            return { ...state, salaryData: action.payload }
        case actionTypes.FETCH_SALARY_DETAILS_ERROR:
            return { ...state, salaryData: {} }

        case actionTypes.SAVE_SALARY:
            return { ...state, saveStatus: '' }
        case actionTypes.SAVE_SALARY_SUCCESS:
            return { ...state, saveStatus: action.payload }
        case actionTypes.SAVE_SALARY_ERROR:
            return { ...state, saveStatus: 'error' }

        case actionTypes.LIST_SALARY:
            return { ...state, listSalaryregister: [],paymentStatus:'' }
        case actionTypes.LIST_SALARY_SUCCESS:
            return { ...state, listSalaryregister: action.payload }
        case actionTypes.LIST_SALARY_ERROR:
            return { ...state, listSalaryregister: [] }

        case actionTypes.EMP_LIST_SALARY:
            return { ...state, empSalaryregister: [] }
        case actionTypes.EMP_LIST_SALARY_SUCCESS:
            return { ...state, empSalaryregister: action.payload }
        case actionTypes.EMP_LIST_SALARY_ERROR:
            return { ...state, empSalaryregister: [] }

        case actionTypes.SALARYREGISTER_VIEW:
            return { ...state, salaryregieterview: [] }
        case actionTypes.SALARYREGISTER_VIEW_SUCCESS:
            return { ...state, salaryregieterview: action.payload }
        case actionTypes.SALARYREGISTER_VIEW_ERROR:
            return { ...state, salaryregieterview: [] }

        case actionTypes.SALARY_PAYMENT:
            return { ...state, paymentStatus: '' }
        case actionTypes.SALARY_PAYMENT_SUCCESS:
            return { ...state, paymentStatus: action.payload }
        case actionTypes.SALARY_PAYMENT_ERROR:
            return { ...state, paymentStatus: 'error' }

        case actionTypes.LIST_EMPLOYEE:
            return { ...state, employeeList: [] }
        case actionTypes.LIST_EMPLOYEE_SUCCESS:
            return { ...state, employeeList: action.payload }
        case actionTypes.LIST_EMPLOYEE_ERROR:
            return { ...state, employeeList: [] }

        default:
            return state
    }
}
export default salaryReducer