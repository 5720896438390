
export const initialState = {
  userData: {},
  isAuthenticated: false,
  error: null,
  signup: '',
  isSignupPage: false,
  isEmailVerified: '',
  setCredential: false,
  menuList: [],
  saveTokenStatus:''
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, userData: {}, isAuthenticated: false, error: null, signup: '' }
    case 'LOGIN_SUCCESS':
      return { ...state, userData: action.data, isAuthenticated: true, signup: '' }
    case 'LOGIN_ERROR':
      return { ...state, userData: {}, error: action.error, signup: '' }
    case 'LOGOUT':
      return { ...state, userData: {}, isAuthenticated: false, error: null, signup: '' }
    case 'SIGNUP':
      return { ...state, signup: '', userData: {}, isAuthenticated: false, error: null, }
    case 'SIGNUP_SUCCESS':
      return { ...state, signup: action.data, userData: {}, isAuthenticated: true }
    case 'SIGNUP_ERROR':
      return { ...state, signup: '', userData: {}, error: 'error' }
    case 'CLEAR_DATA':
      return { ...state, signup: '', userData: {}, isAuthenticated: false, error: null }
    case 'SET_SIGNUP':
      return { ...state, signup: '', userData: {}, isAuthenticated: false, error: null, isSignupPage: true }
    case 'VERIFY_EMAIL_AUTH':
      return { ...state, signup: '', userData: {}, isAuthenticated: false, error: null, isEmailVerified: '' }
    case 'VERIFY_EMAIL_AUTH_SUCCESS':
      return { ...state, signup: '', userData: {}, isAuthenticated: false, isEmailVerified: action.data }
    case 'VERIFY_EMAIL_AUTH_ERROR':
      return { ...state, signup: '', userData: {}, error: 'error', isEmailVerified: '' }
    case 'VERIFY_USER_EMAIL_AUTH':
      return { ...state, signup: '', userData: {}, isAuthenticated: false, error: null, isEmailVerified: '' }
    case 'VERIFY_USER_EMAIL_AUTH_SUCCESS':
      return { ...state, signup: '', userData: {}, isAuthenticated: false, isEmailVerified: action.data }
    case 'VERIFY_USER_EMAIL_AUTH_ERROR':
      return { ...state, signup: '', userData: {}, error: 'error', isEmailVerified: '' }
    case 'SAVE_CREDENTIAL':
      return { ...state, setCredential: false, error: null }
    case 'SAVE_CREDENTIAL_SUCCESS':
      return { ...state, setCredential: action.data }
    case 'SAVE_CREDENTIAL_ERROR':
      return { ...state, error: 'error', setCredential: false }
    case 'GET_MENU_LISTS':
      return { ...state, menuList: [], error: null }
    case 'GET_MENU_LISTS_SUCCESS':
      return { ...state, menuList: action.data }
    case 'GET_MENU_LISTS_ERROR':
      return { ...state, error: 'error', menuList: [] }
    case 'UPDATE_LOGO_SETTING':
      return { ...state, userData: { ...state.userData, branch_logo: action.data } }

    case 'SAVE_PUSHNOTIFICATION_TOKEN_SUCCESS':
      return { ...state, saveTokenStatus: action.payload };
    case 'SAVE_PUSHNOTIFICATION_TOKEN':
      return { ...state, saveTokenStatus: '' };
    case 'SAVE_PUSHNOTIFICATION_TOKEN_ERROR':
      return { ...state, saveTokenStatus: '' };

    default:
      return state
  }
}

export default reducer;