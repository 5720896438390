import { ReactSortable } from 'react-sortablejs';
import { actionTypes } from './action';

export const initialState = {
    complaint: [],
    sparepart: [],
    amcexpiry: [],
    departmentsummary: [],
    auditreport: [],
    listequipment: [],
    equipmentcategory: [],
    equipmentlocation: [],
    dnReport:'',
    rnReport:'',
    qcReport:'',
}

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COMPLAINT_REPORT:
            return { ...state, complaint: [] }
        case actionTypes.COMPLAINT_REPORT_SUCCESS:
            return { ...state, complaint: action.payload }
        case actionTypes.COMPLAINT_REPORT_ERROR:
            return { ...state, complaint: [] }

        case actionTypes.SPARE_PARTS_REPORT:
            return { ...state, sparepart: [] }
        case actionTypes.SPARE_PARTS_REPORT_SUCCESS:
            return { ...state, sparepart: action.payload }
        case actionTypes.SPARE_PARTS_REPORT_ERROR:
            return { ...state, sparepart: [] }

        case actionTypes.AMC_EXPIRY_REPORT:
            return { ...state, amcexpiry: [] }
        case actionTypes.AMC_EXPIRY_REPORT_SUCCESS:
            return { ...state, amcexpiry: action.payload }
        case actionTypes.AMC_EXPIRY_REPORT_ERROR:
            return { ...state, amcexpiry: [] }


        case actionTypes.DEPARTMENT_SUMMARY_REPORT:
            return { ...state, departmentsummary: [] }
        case actionTypes.DEPARTMENT_SUMMARY_REPORT_SUCCESS:
            return { ...state, departmentsummary: action.payload }
        case actionTypes.DEPARTMENT_SUMMARY_REPORT_ERROR:
            return { ...state, departmentsummary: [] }


        case actionTypes.EQUIPMENT_AUDIT_REPORT:
            return { ...state, auditreport: [] }
        case actionTypes.EQUIPMENT_AUDIT_REPORT_SUCCESS:
            return { ...state, auditreport: action.payload }
        case actionTypes.EQUIPMENT_AUDIT_REPORT_ERROR:
            return { ...state, auditreport: [] }

        case actionTypes.LIST_EQUIPMENT:
            return { ...state, listequipment: [] }
        case actionTypes.LIST_EQUIPMENT_SUCCESS:
            return { ...state, listequipment: action.payload }
        case actionTypes.LIST_EQUIPMENT_ERROR:
            return { ...state, listequipment: [] }


        case actionTypes.EQUIPMENT_CATEGORY:
            return { ...state, equipmentcategory: [] }
        case actionTypes.EQUIPMENT_CATEGORY_SUCCESS:
            return { ...state, equipmentcategory: action.payload }
        case actionTypes.EQUIPMENT_CATEGORY_ERROR:
            return { ...state, equipmentcategory: [] }


        case actionTypes.EQUIPMENT_LOCATION:
            return { ...state, equipmentlocation: [] }
        case actionTypes.EQUIPMENT_LOCATION_SUCCESS:
            return { ...state, equipmentlocation: action.payload }
        case actionTypes.EQUIPMENT_LOCATION_ERROR:
            return { ...state, equipmentlocation: [] }

        case 'EXTERNAL_DN_REPORT':
            return { ...state, dnReport: '' }
        case 'EXTERNAL_DN_REPORT_SUCCESS':
            return { ...state, dnReport:action.payload  }
        case 'EXTERNAL_DN_REPORT_ERROR':
            return { ...state, dnReport: 'ERROR' }

        case 'EXTERNAL_RN_REPORT':
            return { ...state, rnReport: '' }
        case 'EXTERNAL_RN_REPORT_SUCCESS':
            return { ...state, rnReport:action.payload  }
        case 'EXTERNAL_RN_REPORT_ERROR':
            return { ...state, rnReport: 'ERROR' }

        case 'QC_REPORT':
            return { ...state, qcReport: '' }
        case 'QC_REPORT_SUCCESS':
            return { ...state, qcReport:action.payload  }
        case 'QC_REPORT_ERROR':
            return { ...state, qcReport: 'ERROR' }



        default:
            return state
    }
}

export default reportReducer


