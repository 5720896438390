import axios from '../../ApiClient'

class qualificationservice {
    constructor(callback) {
        this.callback = callback
    }

    async fetch_qualification(payload) {
        return await axios.post('/Organization/Qualification/list_qualification',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async save_qualification(payload) {
        return await axios.post('/Organization/Qualification/save_qualification',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async delete_qualification(payload) {
        return await axios.post('/Organization/Qualification/delete_qualification',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async edit_qualification(payload) {
        return await axios.post('/Organization/Qualification/edit_qualification',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async update_qualification(payload) {
        return await axios.post('/Organization/Qualification/update_qualification',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new qualificationservice();