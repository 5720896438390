
import { actionTypes } from './action';

export const initialState = {
    saveHolidayValues: '',
    listHolidayValues: [],
    updateHolidayValues: '',
    deleteHolidayValues: '',
    selectedEvent: {},
}
const saveHolidayReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_EVENTS:
            return { ...state, selectedEvent: action.payload }

        case actionTypes.SELECT_EVENTS_CLEAR:
            return { ...state, selectedEvent: {} }

        case actionTypes.SAVE_HOLIDAY:
            return { ...state, saveHolidayValues: '' }
        case actionTypes.SAVE_HOLIDAY_SUCCESS:
            return { ...state, saveHolidayValues: action.payload }
        case actionTypes.SAVE_HOLIDAY_ERROR:
            return { ...state, saveHolidayValues: '' }

        case actionTypes.LIST_HOLIDAY:
            return { ...state, listHolidayValues: [] }
        case actionTypes.LIST_HOLIDAY_SUCCESS:
            let data = action.payload.length > 0 && action.payload.map(item => {
                return {
                    title: item.name,
                    start: JSON.parse(item.selected_date)[0],
                    extendedProps: {
                        id: item.id,
                        description: item.description,
                        repeat: item.repeat
                    }
                }
            })
            return { ...state, listHolidayValues: data }
        case actionTypes.LIST_HOLIDAY_ERROR:
            return { ...state, listHolidayValues: [] }

        case actionTypes.UPDATE_HOLIDAY:
            return { ...state, updateHolidayValues: '' }
        case actionTypes.UPDATE_HOLIDAY_SUCCESS:
            return { ...state, updateHolidayValues: action.payload }
        case actionTypes.UPDATE_HOLIDAY_ERROR:
            return { ...state, updateHolidayValues: '' }

        case actionTypes.DELETE_HOLIDAY:
            return { ...state, deleteHolidayValues: '' }
        case actionTypes.DELETE_HOLIDAY_SUCCESS:
            return { ...state, deleteHolidayValues: action.payload }
        case actionTypes.DELETE_HOLIDAY_ERROR:
            return { ...state, deleteHolidayValues: '' }

        default:
            return state
    }
}
export default saveHolidayReducer