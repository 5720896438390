import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import storeMasterService from '@service/admin/masterService/storeMasterService';
import {
  saveStoreSuccess, saveStoreError,
  listStoreSuccess, listStoreError,
  listSubStoreSuccess, listSubStoreError,
  deleteStoreSuccess, deleteStoreError,
  updateStoreSuccess, updateStoreError
} from './action';

function* saveStore({ payload }) {
  try {
    const data = yield call(storeMasterService.saveStore, payload);

    yield put(saveStoreSuccess(data));
  } catch (error) {
    yield put(saveStoreError(error));
  } finally {
  }
}

function* updateStore({ payload }) {
  try {
    const data = yield call(storeMasterService.updateStore, payload);

    yield put(updateStoreSuccess(data));
  } catch (error) {
    yield put(updateStoreError(error));
  } finally {
  }
}

function* listStore({ payload }) {
  try {
    const data = yield call(storeMasterService.listStore, payload);

    yield put(listStoreSuccess(Array.isArray(data) ? data : []));
  } catch (error) {
    yield put(listStoreError(error));
  } finally {
  }
}

function* listSubStore({ payload }) {
  try {
    const data = yield call(storeMasterService.listSubStore, payload);

    yield put(listSubStoreSuccess(Array.isArray(data) ? data : []));
  } catch (error) {
    yield put(listSubStoreError(error));
  } finally {
  }
}

function* deleteStore({ payload }) {
  try {
    const data = yield call(storeMasterService.deleteStore, payload);

    yield put(deleteStoreSuccess(data));
  } catch (error) {
    yield put(deleteStoreError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_STORE, saveStore)]);
  yield all([takeEvery(actionTypes.LIST_STORE, listStore)]);
  yield all([takeEvery(actionTypes.LIST_SUBSTORE, listSubStore)]);
  yield all([takeEvery(actionTypes.DELETE_STORE, deleteStore)]);
  yield all([takeEvery(actionTypes.UPDATE_STORE, updateStore)]);


}