const initialState = {
    modelvalues: '',
    modellistvalues: [],
    editstatus: [],
    deletestatus: []

}

const modelReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'ADD_MODEL':
            return { ...state, modelvalues: '' }
        case 'ADD_MODEL_SUCCESS':
            return { ...state, modelvalues: action.payload }
        case 'ADD_MODEL_ERROR':
            return { ...state, modelvalues: '' }

        case 'FETCH_MODEL':
            return { ...state, modellistvalues: [] }
        case 'FETCH_MODEL_SUCCESS':
            return { ...state, modellistvalues: action.payload, modelvalues: '', editstatus: '', deletestatus: '' }
        case 'FETCH_MODEL_ERROR':
            return { ...state, modellistvalues: [] }

        case 'EDIT_MODEL':
            return { ...state, editstatus: '' }
        case 'EDIT_MODEL_SUCCESS':
            return { ...state, editstatus: 'SUCCESS' }
        case 'EDIT_MODEL_ERROR':
            return { ...state, editstatus: 'ERROR' }

        case 'DELETE_MODEL':
            return { ...state, deletestatus: '' }
        case 'DELETE_MODEL_SUCCESS':
            return { ...state, deletestatus: 'SUCCESS' }
        case 'DELETE_MODEL_ERROR':
            return { ...state, deletestatus: 'ERROR' }

        default:
            return state
    }
}
export default modelReducer