import { takeEvery, put, call, all } from 'redux-saga/effects';
import contactservice from '@service/admin/masterService/contactservice';
import companyservice from '@service/admin/masterService/companyService';
import { actionTypes } from './action'
import {
    listOrganizationSuccess, listOrganizationError,
    deleteCompanySuccess, deleteCompanyError,
    saveCompanySuccess, saveCompanyError,
    updateCompanySuccess, updateCompanyError,
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'

function* listOrganization({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(companyservice.listCompany, payload);
        Array.isArray(data) ?
            yield put(listOrganizationSuccess(data)) :
            yield put(listOrganizationError([]));
    } catch (error) {
        yield put(listOrganizationError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* deleteCompany({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(companyservice.deleteCompany, payload);

        yield put(deleteCompanySuccess(data));
    } catch (error) {
        yield put(deleteCompanyError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* saveCompany({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(companyservice.saveCompany, payload);

        yield put(saveCompanySuccess(data));
    } catch (error) {
        yield put(saveCompanyError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* updateCompany({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(companyservice.updateCompany, payload);

        yield put(updateCompanySuccess(data));
    } catch (error) {
        yield put(updateCompanyError(error));
    } finally {
        yield put(stopLoader());
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actionTypes.LIST_ORGANISATION, listOrganization)]);
    yield all([takeEvery(actionTypes.DELETE_ORGANISATION, deleteCompany)]);
    yield all([takeEvery(actionTypes.SAVE_COMPANY, saveCompany)]);
    yield all([takeEvery(actionTypes.UPDATE_COMPANY, updateCompany)]);

}