const initialState = {
    skillsetvalues: '',
    listskillsetvalues: [],
    updateskillsetvalues: '',
    deleteskillsetvalues: '',
    addskillsetratingvalues: '',
    updateskillsetratingvalues: '',
}

const skillsetReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_SKILLSET':
            return { ...state, skillsetvalues: '' }
        case 'ADD_SKILLSET_SUCCESS':
            return { ...state, skillsetvalues: 'success' }
        case 'ADD_SKILLSET_ERROR':
            return { ...state, skillsetvalues: 'error' }

        case 'UPDATE_SKILLSET':
            return { ...state, updateskillsetvalues: '' }
        case 'UPDATE_SKILLSET_SUCCESS':
            return { ...state, updateskillsetvalues: 'success' }
        case 'UPDATE_SKILLSET_ERROR':
            return { ...state, updateskillsetvalues: 'error' }

        case 'DELETE_SKILLSET':
            return { ...state, deleteskillsetvalues: '' }
        case 'DELETE_SKILLSET_SUCCESS':
            return { ...state, deleteskillsetvalues: 'success' }
        case 'DELETE_SKILLSET_ERROR':
            return { ...state, deleteskillsetvalues: 'error' }

        case 'ADD_SKILLSET_RATING':
            return { ...state, addskillsetratingvalues: '' }
        case 'ADD_SKILLSET_RATING_SUCCESS':
            return { ...state, addskillsetratingvalues: 'success' }
        case 'ADD_SKILLSET_RATING_ERROR':
            return { ...state, addskillsetratingvalues: 'error' }

        case 'UPDATE_SKILLSET_RATING':
            return { ...state, updateskillsetratingvalues: '' }
        case 'UPDATE_SKILLSET_RATING_SUCCESS':
            return { ...state, updateskillsetratingvalues: 'success' }
        case 'UPDATE_SKILLSET_RATING_ERROR':
            return { ...state, updateskillsetratingvalues: 'error' }

        case 'FETCH_SKILLSET':
            return {
                ...state,
                listskillsetvalues: [],
                skillsetvalues: '',
                updateskillsetvalues: '',
                deleteskillsetvalues: '',
                addskillsetratingvalues: '',
                updateskillsetratingvalues: ''
            }
        case 'FETCH_SKILLSET_SUCCESS':
            return { ...state, listskillsetvalues: action.payload }
        case 'FETCH_SKILLSET_ERROR':
            return { ...state, listskillsetvalues: [] }

        default:
            return state
    }
}
export default skillsetReducer