import { takeEvery, put, call, all } from 'redux-saga/effects';
import leaveService from '@service/admin/masterService/leaveService';

import {

  SaveLeaveTypeSuccess, SaveLeaveTypeError,
  UpdateLeavetypeSuccess, UpdateLeavetypeError,
  FetchLeaveSuccess, FetchLeaveError,
  DeleteLeaveSuccess, DeleteLeaveError,
  // FetchLeaveIdSuccess,FetchLeaveIdError,
  // FetchLeaveTypeSuccess,FetchLeaveTypeError,
  // EditLeaveTypeSuccess,EditLeaveTypeError,
  // UpdateLeaveTypeSuccess,UpdateLeaveTypeError,
  // DeleteLeaveTypeSuccess,DeleteLeaveTypeError,
  // ListLeaveTypeSuccess,ListLeaveTypeError,
  SaveRuleSuccess, SaveRuleError,
  // UpdateRuleSuccess,UpdateRuleError,
  EditRuleSuccess, EditRuleError,

} from './action';
import { stopLoader, startLoader } from '@store/common/loader/action'

// Save leave

function* SaveLeaveType({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leaveService.saveLeaveType, payload);
    console.log('data', data);
    yield put(SaveLeaveTypeSuccess(data));
  } catch (error) {
    yield put(SaveLeaveTypeError(error));
  } finally {
    yield put(stopLoader());
  }
}

// // update leave
function* UpdateLeavetype({ payload }) {
  try {
    yield put(startLoader());

    const data = yield call(leaveService.updateLeaveType, payload);
    yield put(UpdateLeavetypeSuccess(data));
  } catch (error) {
    yield put(UpdateLeavetypeError(error));
  } finally {
    yield put(stopLoader());
  }
}

// list leave 

function* FetchLeave({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leaveService.fetchLeaveType, payload);
    yield put(FetchLeaveSuccess(data));
  } catch (error) {
    yield put(FetchLeaveError(error));
  } finally {
    yield put(stopLoader());
  }
}
// list by id

// function* FetchLeaveId({payload}) {
//   try {

//     const data = yield call(leaveService.fetchLeaveid,payload);
//      yield put(FetchLeaveIdSuccess(data));
//   } catch (error) {
//     yield put(FetchLeaveIdError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }
// delete leave

function* DeleteLeave({ payload }) {
  try {
    yield put(startLoader());

    const data = yield call(leaveService.deleteLeave, payload);
    yield put(DeleteLeaveSuccess(data));
  } catch (error) {
    yield put(DeleteLeaveError(error));
  } finally {
    yield put(stopLoader());
  }
}
// RULES
// fetch leavetype
// function* ListLeaveType({payload}) {
//   try {

//     const data = yield call(leaveService.listLeavetype,payload);
//      yield put(ListLeaveTypeSuccess(data));
//   } catch (error) {
//     yield put(ListLeaveTypeError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }
function* SaveRule({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leaveService.saveRule, payload);
    yield put(SaveRuleSuccess(data));
  } catch (error) {
    yield put(SaveRuleError(error));
  } finally {
    yield put(stopLoader());
  }
}

// function* UpdateRule({payload}) {
//   try {

//     const data = yield call(leaveService.updateRule,payload);
//      yield put(UpdateRuleSuccess(data));
//   } catch (error) {
//     yield put(UpdateRuleError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

function* EditRule({ payload }) {
  try {

    const data = yield call(leaveService.editRule, payload);
    yield put(EditRuleSuccess(data));
  } catch (error) {
    yield put(EditRuleError(error));
  } finally {
    yield put(stopLoader());
  }
}

// function* FetchLeaveType({payload}) {
//   try {

//     const data = yield call(leaveService.fetchLeavetype,payload);
//      yield put(FetchLeaveTypeSuccess(data));
//   } catch (error) {
//     yield put(FetchLeaveTypeError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// function* EditLeaveType({payload}) {
//   try {

//     const data = yield call(leaveService.editLeavetype,payload);
//      yield put(EditLeaveTypeSuccess(data));
//   } catch (error) {
//     yield put(EditLeaveTypeError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// function* UpdateLeaveType({payload}) {
//   try {

//     const data = yield call(leaveService.updateLeavetype,payload);
//      yield put(UpdateLeaveTypeSuccess(data));
//   } catch (error) {
//     yield put(UpdateLeaveTypeError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// function* DeleteLeaveType({payload}) {
//   try {

//     const data = yield call(leaveService.deleteLeavetype,payload);
//      yield put(DeleteLeaveTypeSuccess(data));
//   } catch (error) {
//     yield put(DeleteLeaveTypeError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

export default function* rootSaga() {

  yield all([takeEvery('SAVE_LEAVETYPE', SaveLeaveType)]);
  yield all([takeEvery('UPDATE_LEAVE_TYPE', UpdateLeavetype)]);
  yield all([takeEvery('FETCH_LEAVE', FetchLeave)]);
  // yield all([takeEvery('FETCH_LEAVE_ID',FetchLeaveId)]);
  yield all([takeEvery('DELETE_LEAVE', DeleteLeave)]);
  // yield all([takeEvery('LIST_LEAVE_TYPE',ListLeaveType)]);
  yield all([takeEvery('SAVE_RULE', SaveRule)]);
  // yield all([takeEvery('UPDATE_RULE',UpdateRule)]);
  yield all([takeEvery('EDIT_RULE', EditRule)]);
  // yield all([takeEvery('FETCH_LEAVETYPE',FetchLeaveType)]);
  // yield all([takeEvery('EDIT_LEAVETYPE',EditLeaveType)]);
  // yield all([takeEvery('UPDATE_LEAVETYPE',UpdateLeaveType)]);
  // yield all([takeEvery('DELETE_LEAVETYPE',DeleteLeaveType)]);
}