
import { actionTypes } from './action';

export const initialState = {
    updateStatus: "",
    listStatus: []
}

const updateTermsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_TERMS:
            return { ...state, updateStatus: "" }
        case actionTypes.UPDATE_TERMS_SUCCESS:
            return { ...state, updateStatus: action.payload }
        case actionTypes.UPDATE_TERMS_ERROR:
            return { ...state, updateStatus: "" }

        case actionTypes.LIST_TERMS:
            return { ...state, listStatus: [] }
        case actionTypes.LIST_TERMS_SUCCESS:
            return { ...state, listStatus: action.payload, updateStatus: "" }
        case actionTypes.LIST_TERMS_ERROR:
            return { ...state, listStatus: [] }

        default:
            return state
    }
}

export default updateTermsReducer