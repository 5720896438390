export const actionTypes = {

    SAVE_ROLE: 'SAVE_ROLE',
    SAVE_ROLE_SUCCESS: 'SAVE_ROLE_SUCCESS',
    SAVE_ROLE_ERROR: 'SAVE_ROLE_ERROR',

    LIST_ROLE: 'LIST_ROLE',
    LIST_ROLE_SUCCESS: 'LIST_ROLE_SUCCESS',
    LIST_ROLE_ERROR: 'LIST_ROLE_ERROR',
    
    UPDATE_ROLE: 'UPDATE_ROLE',
    UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
    UPDATE_ROLE_ERROR: 'UPDATE_ROLE_ERROR',

    DELETE_ROLE: 'DELETE_ROLE',
    DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
    DELETE_ROLE_ERROR: 'DELETE_ROLE_ERROR',
    
    LIST_ROLE_ASSIGNED_MEMBER: 'LIST_ROLE_ASSIGNED_MEMBER',
    LIST_ROLE_ASSIGNED_MEMBER_SUCCESS: 'LIST_ROLE_ASSIGNED_MEMBER_SUCCESS',
    LIST_ROLE_ASSIGNED_MEMBER_ERROR: 'LIST_ROLE_ASSIGNED_MEMBER_ERROR',

    REMOVE_ROLE_ASSIGNED_MEMBER: 'REMOVE_ROLE_ASSIGNED_MEMBER',
    REMOVE_ROLE_ASSIGNED_MEMBER_SUCCESS: 'REMOVE_ROLE_ASSIGNED_MEMBER_SUCCESS',
    REMOVE_ROLE_ASSIGNED_MEMBER_ERROR: 'REMOVE_ROLE_ASSIGNED_MEMBER_ERROR',
    
    PERMISSION_DETAILS: 'PERMISSION_DETAILS',
    PERMISSION_DETAILS_SUCCESS: 'PERMISSION_DETAILS_SUCCESS',
    PERMISSION_DETAILS_ERROR: 'PERMISSION_DETAILS_ERROR',

    SET_ROLE_PERMISSION: 'SET_ROLE_PERMISSION',
    SET_ROLE_PERMISSION_SUCCESS: 'SET_ROLE_PERMISSION_SUCCESS',
    SET_ROLE_PERMISSION_ERROR: 'SET_ROLE_PERMISSION_ERROR',

};

export function saveRole(payload) {
    return { type: actionTypes.SAVE_ROLE, payload };
}
export function saveRoleSuccess(data) {
    return { type: actionTypes.SAVE_ROLE_SUCCESS, data };
}
export function saveRoleError(error) {
    return { type: actionTypes.SAVE_ROLE_ERROR, error };
}



export function fetchRole(payload) {
    return { type: actionTypes.LIST_ROLE, payload };
}
export function fetchRoleSuccess(data) {
    return { type: actionTypes.LIST_ROLE_SUCCESS, data };
}
export function fetchRoleError(error) {
    return { type: actionTypes.LIST_ROLE_ERROR, error };
}


export function updateRole(payload) {
    return { type: actionTypes.UPDATE_ROLE, payload };
}
export function updateRoleSuccess(data) {
    return { type: actionTypes.UPDATE_ROLE_SUCCESS, data };
}
export function updateRoleError(error) {
    return { type: actionTypes.UPDATE_ROLE_ERROR, error };
}


export function deleteRole(payload) {
    return { type: actionTypes.DELETE_ROLE, payload };
}
export function deleteRoleSuccess(data) {
    return { type: actionTypes.DELETE_ROLE_SUCCESS, data };
}
export function deleteRoleError(error) {
    return { type: actionTypes.DELETE_ROLE_ERROR, error };
}


export function fetchRoleAssignedMember(payload) {
    return { type: actionTypes.LIST_ROLE_ASSIGNED_MEMBER, payload };
}
export function fetchRoleAssignedMemberSuccess(data) {
    return { type: actionTypes.LIST_ROLE_ASSIGNED_MEMBER_SUCCESS, data };
}
export function fetchRoleAssignedMemberError(error) {
    return { type: actionTypes.LIST_ROLE_ASSIGNED_MEMBER_ERROR, error };
}


export function removeRoleAssignedMember(payload) {
    return { type: actionTypes.REMOVE_ROLE_ASSIGNED_MEMBER, payload };
}
export function removeRoleAssignedMemberSuccess(data) {
    return { type: actionTypes.REMOVE_ROLE_ASSIGNED_MEMBER_SUCCESS, data };
}
export function removeRoleAssignedMemberError(error) {
    return { type: actionTypes.REMOVE_ROLE_ASSIGNED_MEMBER_ERROR, error };
}


export function fetchPermissionDetails(payload) {
    return { type: actionTypes.PERMISSION_DETAILS, payload };
}
export function fetchPermissionDetailsSuccess(data) {
    return { type: actionTypes.PERMISSION_DETAILS_SUCCESS, data };
}
export function fetchPermissionDetailsError(error) {
    return { type: actionTypes.PERMISSION_DETAILS_ERROR, error };
}


export function setRolePermission(payload) {
    return { type: actionTypes.SET_ROLE_PERMISSION, payload };
}
export function setRolePermissionSuccess(data) {
    return { type: actionTypes.SET_ROLE_PERMISSION_SUCCESS, data };
}
export function setRolePermissionError(error) {
    return { type: actionTypes.SET_ROLE_PERMISSION_ERROR, error };
}