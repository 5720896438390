import { takeEvery, put, call, all } from 'redux-saga/effects';
import masterservice from '@service/admin/serviceMaster/masterservice';
import {
  AddServiceSuccess, AddServiceError,
  listServiceSuccess, listServiceError,
  deleteServiceSuccess, deleteServiceError,
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'



function* AddService({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(masterservice.addService, payload);
    yield put(AddServiceSuccess(data));
  } catch (error) {
    yield put(AddServiceError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listService({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(masterservice.listService, payload);
    Array.isArray(data) ?
      yield put(listServiceSuccess(data)) :
      yield put(listServiceSuccess([]));
  } catch (error) {
    yield put(listServiceError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* deleteService({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(masterservice.deleteService, payload);

    yield put(deleteServiceSuccess(data))
  } catch (error) {
    yield put(deleteServiceError(error));
  } finally {
    yield put(stopLoader());
  }
}

export default function* rootSaga() {
  yield all([takeEvery('ADD_SERVICE', AddService)]);
  yield all([takeEvery('LIST_SERVICE', listService)]);
  yield all([takeEvery('DELETE_SERVICE', deleteService)]);
}