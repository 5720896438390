import axios from '@service/ApiClient'
class parameterService {
    constructor(callback) {
        this.callback = callback
    }

    async saveParameter(payload) {
        return await axios.post('/Payroll/Parametermaster/insert_parameter_bu_wise', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listParameter(payload) {
        return await axios.post('/Payroll/Parametermaster/get_all_bu_parameters', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updateParameter(payload) {
        return await axios.post('/Payroll/Parametermaster/update_parameter_bu_wise', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteParameter(payload) {
        return await axios.post('/Payroll/Parametermaster/delete_bu_parameter', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new parameterService();