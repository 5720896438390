import axios from '@service/ApiClient'
class shiftToEmployeeService {
    constructor(callback) {
        this.callback = callback
    }
    
    async assignShift(payload) {
        return await axios.post('/Crm/User/save_emp_shift_tracking', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listEmployees(payload) {
        return await axios.post('/Crm/User/list_employees_names', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listShift(payload) {
        return await axios.post('/HR/Shiftdetails/list_shift', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new shiftToEmployeeService();