import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import listItemService from '@service/admin/masterService/purchaseRequestService';
import {
  listItemSuccess, listItemError,
  savePurchaseRequestSuccess, savePurchaseRequestError,
  listPurchaseRequestSuccess, listPurchaseRequestError,
  updatePurchaseRequestSuccess, updatePurchaseRequestError,
  deletePurchaseRequestSuccess, deletePurchaseRequestError,
  fetchPrByIdSuccess, fetchPrByIdError,
} from './action';

import { stopLoader, startLoader } from '@store/common/loader/action'

function* listItem({ payload }) {
  try {
    yield put(startLoader());

    const data = yield call(listItemService.listItem, payload);
    Array.isArray(data) ?
      yield put(listItemSuccess(data)) :
      yield put(listItemError([]));
  } catch (error) {
    yield put(listItemError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* savePurchaseRequest({ payload }) {
  try {
    yield put(startLoader());

    const data = yield call(listItemService.savePurchaseRequest, payload);

    yield put(savePurchaseRequestSuccess(data));
  } catch (error) {
    yield put(savePurchaseRequestError(error));
  } finally {
    yield put(stopLoader());

  }
}

function* listPurchaseRequest({ payload }) {
  try {
    yield put(startLoader());

    const data = yield call(listItemService.listPurchaseRequest, payload);

    Array.isArray(data) ?
      yield put(listPurchaseRequestSuccess(data)) :
      yield put(listPurchaseRequestError([]));
  } catch (error) {
    yield put(listPurchaseRequestError(error));
  } finally {
    yield put(stopLoader());

  }
}

function* updatePurchaseRequest({ payload }) {
  try {
    yield put(startLoader());

    const data = yield call(listItemService.updatePurchaseRequest, payload);

    yield put(updatePurchaseRequestSuccess(data));
  } catch (error) {
    yield put(updatePurchaseRequestError(error));
  } finally {
    yield put(stopLoader());

  }
}

function* deletePurchaseRequest({ payload }) {
  try {
    yield put(startLoader());

    const data = yield call(listItemService.deletePurchaseRequest, payload);

    yield put(deletePurchaseRequestSuccess(data));
  } catch (error) {
    yield put(deletePurchaseRequestError(error));
  } finally {
    yield put(stopLoader());

  }
}

function* fetchPrById({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(listItemService.fetchPrById, payload);
    yield put(fetchPrByIdSuccess(data));
  } catch (error) {
    yield put(fetchPrByIdError(error));
  } finally {
    yield put(stopLoader());

  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.LIST_ITEM, listItem)]);
  yield all([takeEvery(actionTypes.SAVE_PURCHASEREQUEST, savePurchaseRequest)]);
  yield all([takeEvery(actionTypes.LIST_PURCHASEREQUEST, listPurchaseRequest)]);
  yield all([takeEvery(actionTypes.UPDATE_PURCHASEREQUEST, updatePurchaseRequest)]);
  yield all([takeEvery(actionTypes.DELETE_PURCHASEREQUEST, deletePurchaseRequest)]);
  yield all([takeEvery(actionTypes.FETCH_PR_BY_ID, fetchPrById)]);
}