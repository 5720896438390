import axios from '@service/ApiClient'

class itemService {
    constructor(callback) {
        this.callback = callback
    }

    async saveCategory(payload) {
        return await axios.post('/Inventory/Item_category/add_category',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listCategory(payload) {
        return await axios.post('/Inventory/Item_category/fetch_parent_sub_categories',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listSubCategory(payload) {
        return await axios.post('/Inventory/Item_category/list_subcategory',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listAllCategories(payload) {
        return await axios.post('/Inventory/Item_category/fetch_all_categories',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async removeCategory(payload) {
        return await axios.post('/Inventory/Item_category/delete_item_category',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updateCategory(payload) {
        return await axios.post('/Inventory/Item_category/update_item_category',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new itemService();