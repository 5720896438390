const initialState = {
    participantsvalues: [],
    meetingvalues: '',
    listingvalues: [],
    deleteMeeting: '',
    updateMeeting: '',
    meetingByID: {},
    error: false
}


const meetingReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'ADD_MEETING':
            return { ...state, meetingvalues: '', error: false }
        case 'ADD_MEETING_SUCCESS':
            return { ...state, meetingvalues: action.payload, error: false }
        case 'ADD_MEETING_ERROR':
            return { ...state, meetingvalues: 'error', error: true }

        case 'UPDATE_MEETING':
            return { ...state, updateMeeting: '', error: false }
        case 'UPDATE_MEETING_SUCCESS':
            return { ...state, updateMeeting: action.payload, error: false }
        case 'UPDATE_MEETING_ERROR':
            return { ...state, updateMeeting: 'error', error: true }



        case 'FETCH_PARTICIPANTS':
            return { ...state, participantsvalues: [], error: false }
        case 'FETCH_PARTICIPANTS_SUCCESS':
            return { ...state, participantsvalues: action.payload, error: false }
        case 'FETCH_PARTICIPANTS_ERROR':
            return { ...state, participantsvalues: [], error: true }


        case 'FETCH_MEETING':
            return { ...state, listingvalues: [], error: false,meetingvalues: '', updateMeeting: '', deleteMeeting: '' }
        case 'FETCH_MEETING_SUCCESS':
            return { ...state, listingvalues: action.payload, error: false }
        case 'FETCH_MEETING_ERROR':
            return { ...state, listingvalues: [], error: true }

        case 'FETCH_MEETING_BYID':
            return { ...state, meetingByID: {}, error: false }
        case 'FETCH_MEETING_BYID_SUCCESS':
            return { ...state, meetingByID: action.payload, error: false }
        case 'FETCH_MEETING_BYID_ERROR':
            return { ...state, meetingByID: {}, error: true }


        case 'DELETE_MEETING':
            return { ...state, deleteMeeting: '', error: false }
        case 'DELETE_MEETING_SUCCESS':
            return { ...state, deleteMeeting: 'success', error: false }
        case 'DELETE_MEETING_ERROR':
            return { ...state, deleteMeeting: 'error', error: true }

        default:
            return state
    }
}
export default meetingReducer