import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import quoteService from '@service/admin/masterService/quoteService';
import {
    listDealForQuoteSuccess, listDealForQuoteError,
    saveQuoteSuccess, saveQuoteError,
    listQuoteSuccess, listQuoteError,
    fetchQuoteByIdSuccess, fetchQuoteByIdError,
    updateQuoteSuccess, updateQuoteError,
    getTermsAndConditionSuccess, getTermsAndConditionError,
    deleteQuoteSuccess, deleteQuoteError,
} from './action';
import { stopLoader, startLoader } from '@store/common/loader/action'


function* listDealForQuote({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(quoteService.listDealForQuote, payload);
        yield put(listDealForQuoteSuccess(data));
        Array.isArray(data) ?
            yield put(listDealForQuoteSuccess(data)) :
            yield put(listDealForQuoteSuccess([]));
    } catch (error) {
        yield put(listDealForQuoteError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* saveQuote({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(quoteService.saveQuote, payload);
        yield put(saveQuoteSuccess(data));
    } catch (error) {
        yield put(saveQuoteError(error));
    } finally {
        yield put(stopLoader());
    }
}


function* listQuote({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(quoteService.listQuote, payload);
        Array.isArray(data) ?
            yield put(listQuoteSuccess(data)) :
            yield put(listQuoteSuccess([]));
    } catch (error) {
        yield put(listQuoteError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* fetchQuoteById({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(quoteService.fetchQuoteById, payload);
        if (Object.keys(data).length > 0) {
            yield put(fetchQuoteByIdSuccess({
                ...data,
                item_details: data.item_details ? data.item_details.map(item => {
                    return ({
                        ...item,
                        amount: (parseFloat(item.amount) * parseFloat(data.actual_rate)).toFixed(2),
                        discount_amount: (parseFloat(item.discount_amount) * parseFloat(data.actual_rate)).toFixed(2),
                        selling_price: (parseFloat(item.selling_price) * parseFloat(data.actual_rate)).toFixed(2),
                    })
                }) : [],
                work_details: data.work_details ? data.work_details.map(item => {
                    return ({
                        ...item,
                        charge: (parseFloat(item.charge) * parseFloat(data.actual_rate)).toFixed(2),
                        disc_amount: (parseFloat(item.disc_amount) * parseFloat(data.actual_rate)).toFixed(2),
                        wrktotal: (parseFloat(item.wrktotal) * parseFloat(data.actual_rate)).toFixed(2),
                    })
                }) : [],
                service_details: data.service_details ? data.service_details.map(item => {
                    return ({
                        ...item,
                        selling_price_ser: (parseFloat(item.selling_price_ser) * parseFloat(data.actual_rate)).toFixed(2),
                        discount_amount_ser: (parseFloat(item.discount_amount_ser) * parseFloat(data.actual_rate)).toFixed(2),
                        amount_ser: (parseFloat(item.amount_ser) * parseFloat(data.actual_rate)).toFixed(2),
                    })
                }) : [],
                project_details: data.project_details ? data.project_details.map(item => {
                    return ({
                        ...item,
                        selling_price_project: (parseFloat(item.selling_price_project) * parseFloat(data.actual_rate)).toFixed(2),
                        discount_amount_project: (parseFloat(item.discount_amount_project) * parseFloat(data.actual_rate)).toFixed(2),
                        amount_project: (parseFloat(item.amount_project) * parseFloat(data.actual_rate)).toFixed(2),
                        project_id: data.project_id || data.project_master_id
                    })
                }) : [],
                c_discount_amount: (parseFloat(data.c_discount_amount) * parseFloat(data.actual_rate)).toFixed(2),
                net_amount: (parseFloat(data.net_amount) * parseFloat(data.actual_rate)).toFixed(2),
                total_amount_products: (parseFloat(data.total_amount_products) * parseFloat(data.actual_rate)).toFixed(2),
                expected_revenue: (parseFloat(data.expected_revenue) * parseFloat(data.actual_rate)).toFixed(2),
            }));
        }
        // yield put(fetchQuoteByIdSuccess(data));
    } catch (error) {
        yield put(fetchQuoteByIdError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* updateQuote({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(quoteService.updateQuote, payload);
        yield put(updateQuoteSuccess(data));
    } catch (error) {
        yield put(updateQuoteError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* getTermsAndCondition({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(quoteService.getTermsAndCondition, payload);
        yield put(getTermsAndConditionSuccess(data));
    } catch (error) {
        yield put(getTermsAndConditionError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* deleteQuote({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(quoteService.deleteQuote, payload);
        yield put(deleteQuoteSuccess(data));
    } catch (error) {
        yield put(deleteQuoteError(error));
    } finally {
        yield put(stopLoader());
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actionTypes.LIST_DEAL_FOR_QUOTE, listDealForQuote)]);
    yield all([takeEvery(actionTypes.SAVE_QUOTE, saveQuote)]);
    yield all([takeEvery(actionTypes.LIST_QUOTE, listQuote)]);
    yield all([takeEvery(actionTypes.LIST_QUOTE_BY_ID, fetchQuoteById)]);
    yield all([takeEvery(actionTypes.UPDATE_QUOTE, updateQuote)]);
    yield all([takeEvery(actionTypes.GET_TERMS_AND_CONDITION, getTermsAndCondition)]);
    yield all([takeEvery(actionTypes.DELETE_QUOTE, deleteQuote)]);
}