import { actionTypes } from "./action"

export const initialStates = {
    listServiceMaster: [],
    saveServiceRooster: '',
    listRooster: [],
    deleteRooster: '',
    assignEmpService: '',
    isLoading: false
}

const reducer = (state = initialStates, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SERVICE_MASTER:
            return { ...state, listServiceMaster: [], isLoading: true }
        case actionTypes.FETCH_SERVICE_MASTER_SUCCESS:
            return { ...state, listServiceMaster: action.payload, isLoading: false }
        case actionTypes.FETCH_SERVICE_MASTER_ERROR:
            return { ...state, listServiceMaster: [], isLoading: false }

        case actionTypes.SAVE_SERVICE_ROOSTER:
            return { ...state, saveServiceRooster: '', isLoading: true }
        case actionTypes.SAVE_SERVICE_ROOSTER_SUCCESS:
            return { ...state, saveServiceRooster: 'success', isLoading: false }
        case actionTypes.SAVE_SERVICE_ROOSTER_ERROR:
            return { ...state, saveServiceRooster: 'error', isLoading: false }

        case actionTypes.FETCH_SERVICE_ROOSTER:
            return { ...state, listRooster: [], isLoading: true }
        case actionTypes.FETCH_SERVICE_ROOSTER_SUCCESS:
            return { ...state, listRooster: action.payload, isLoading: false }
        case actionTypes.FETCH_SERVICE_ROOSTER_ERROR:
            return { ...state, listRooster: [], isLoading: false }

        case actionTypes.DELETE_SERVICE_ROOSTER:
            return { ...state, deleteRooster: '', isLoading: true }
        case actionTypes.DELETE_SERVICE_ROOSTER_SUCCESS:
            return { ...state, deleteRooster: action.payload, isLoading: false }
        case actionTypes.DELETE_SERVICE_ROOSTER_ERROR:
            return { ...state, deleteRooster: 'error', isLoading: false }

        case actionTypes.ASSIGN_EMPLOYEE_SERVICE:
            return { ...state, assignEmpService: '', isLoading: true }
        case actionTypes.ASSIGN_EMPLOYEE_SERVICE_SUCCESS:
            return { ...state, assignEmpService: 'success', isLoading: false }
        case actionTypes.ASSIGN_EMPLOYEE_SERVICE_ERROR:
            return { ...state, assignEmpService: 'error', isLoading: false }

        default:
            return state
    }
}

export default reducer