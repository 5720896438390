//save manufacture

export const AddManufacture = (payload) => {
    return {
         type: 'ADD_MANUFACTURE' ,
         payload
     };
 };
 export const  AddManufactureSuccess = (payload) => {
       return {
         type: 'ADD_MANUFACTURE_SUCCESS',
         payload
     };
 };
 export const  AddManufactureError = (payload) => {
     return {
         type: 'ADD_MANUFACTURE_ERROR',
         payload
     };
 };

 
 //list manufacture

 export const FetcManufacture = (payload) => {

    return {
        type: 'FETCH_MANUFACTURE',
        payload

    };
};
export const   FetchManufactureSuccess = (payload) => {
    return {
        type: 'FETCH_MANUFACTURE_SUCCESS',
        payload
    };
};
export const   FetchManufactureError = (payload) => {
    return {
        type: 'FETCH_MANUFACTURE_ERROR',
        payload
    };
};

//update manufacture

export const EditManufacture = (payload) =>{
    return {
        type: 'EDIT_MANUFACTURE' ,
        payload
    };
};
export const EditManufactureSuccess = (payload) =>{
    return {
        type: 'EDIT_MANUFACTURE_SUCCESS' ,
        payload
    };
};
export const EditManufactureError = (payload) =>{
    return {
        type: 'EDIT_MANUFACTURE_ERROR' ,
        payload
    };
};

//delete manufacture

export const DeleteManufacture = (payload) =>{
    return {
        type: 'DELETE_MANUFACTURE' ,
        payload
    };
};
export const DeleteManufactureSuccess = (payload) =>{
    return {
        type: 'DELETE_MANUFACTURE_SUCCESS' ,
        payload
    };
};
export const DeleteManufactureError = (payload) =>{
    return {
        type: 'DELETE_MANUFACTURE_ERROR' ,
        payload
    };
};