import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import deleveryOrderService from '@service/admin/masterService/deleveryOrderService';
import {
    listSoForDoSuccess, listSoForDoError,
    saveDoSuccess, saveDoError,
    listDoSuccess, listDoError,
    fetchDoByIdSuccess, fetchDoByIdError,
    updateDoSuccess, updateDoError,
    deleteDoSuccess, deleteDoError,
    getBarcodeStatusDoSuccess, getBarcodeStatusDoError,
} from './action';
import { stopLoader, startLoader } from '@store/common/loader/action'


function* listSoForDso({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(deleveryOrderService.listSoForDo, payload);
        yield put(listSoForDoSuccess(data));
    } catch (error) {
        yield put(listSoForDoError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* saveDo({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(deleveryOrderService.saveDo, payload);
        yield put(saveDoSuccess(data));
    } catch (error) {
        yield put(saveDoError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* getBarcodeStatusDo({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(deleveryOrderService.getBarcodeStatusDo, payload);
        yield put(getBarcodeStatusDoSuccess(data));
    } catch (error) {
        yield put(getBarcodeStatusDoError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* updateDo({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(deleveryOrderService.updateDo, payload);
        yield put(updateDoSuccess(data));
    } catch (error) {
        yield put(updateDoError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* deleteDo({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(deleveryOrderService.deleteDo, payload);
        yield put(deleteDoSuccess(data));
    } catch (error) {
        yield put(deleteDoError(error));
    } finally {
        yield put(stopLoader());
    }
}


function* listDo({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(deleveryOrderService.listDo, payload);
        yield put(listDoSuccess(data));
    } catch (error) {
        yield put(listDoError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* fetchDoById({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(deleveryOrderService.fetchDoById, payload);
        if (Object.keys(data).length > 0) {
            yield put(fetchDoByIdSuccess({
                ...data,
                item_details: data.item_details ? data.item_details.map(item => {
                    return ({
                        ...item,
                        amount: (parseFloat(item.amount) * parseFloat(data.actual_rate)).toFixed(2),
                        discount_amount: (parseFloat(item.discount_amount) * parseFloat(data.actual_rate)).toFixed(2),
                        selling_price: (parseFloat(item.selling_price) * parseFloat(data.actual_rate)).toFixed(2),
                    })
                }) : [],
                work_details: data.work_details ? data.work_details.map(item => {
                    return ({
                        ...item,
                        charge: (parseFloat(item.charge) * parseFloat(data.actual_rate)).toFixed(2),
                        disc_amount: (parseFloat(item.disc_amount) * parseFloat(data.actual_rate)).toFixed(2),
                        wrktotal: (parseFloat(item.wrktotal) * parseFloat(data.actual_rate)).toFixed(2),
                    })
                }) : [],
                service_details: data.service_details ? data.service_details.map(item => {
                    return ({
                        ...item,
                        selling_price_ser: (parseFloat(item.selling_price_ser) * parseFloat(data.actual_rate)).toFixed(2),
                        discount_amount_ser: (parseFloat(item.discount_amount_ser) * parseFloat(data.actual_rate)).toFixed(2),
                        amount_ser: (parseFloat(item.amount_ser) * parseFloat(data.actual_rate)).toFixed(2),
                    })
                }) : [],
                project_details: data.project_details ? data.project_details.map(item => {
                    return ({
                        ...item,
                        selling_price_project: (parseFloat(item.selling_price_project) * parseFloat(data.actual_rate)).toFixed(2),
                        discount_amount_project: (parseFloat(item.discount_amount_project) * parseFloat(data.actual_rate)).toFixed(2),
                        amount_project: (parseFloat(item.amount_project) * parseFloat(data.actual_rate)).toFixed(2),
                        project_id: data.project_id || data.project_master_id
                    })
                }) : [],
                c_discount_amount: (parseFloat(data.c_discount_amount) * parseFloat(data.actual_rate)).toFixed(2),
                net_amount: (parseFloat(data.net_amount) * parseFloat(data.actual_rate)).toFixed(2),
                total_amount_products: (parseFloat(data.total_amount_products) * parseFloat(data.actual_rate)).toFixed(2),
                expected_revenue: (parseFloat(data.expected_revenue) * parseFloat(data.actual_rate)).toFixed(2),
            }));
        }
    } catch (error) {
        yield put(fetchDoByIdError(error));
    } finally {
        yield put(stopLoader());
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actionTypes.LIST_SO_FOR_DO, listSoForDso)]);
    yield all([takeEvery(actionTypes.SAVE_DO, saveDo)]);
    yield all([takeEvery(actionTypes.GET_BARCODE_STATUS_DO, getBarcodeStatusDo)]);
    yield all([takeEvery(actionTypes.LIST_DO, listDo)]);
    yield all([takeEvery(actionTypes.LIST_DO_BY_ID, fetchDoById)]);
    yield all([takeEvery(actionTypes.UPDATE_DO, updateDo)]);
    yield all([takeEvery(actionTypes.DELETE_DO, deleteDo)]);
}