
export const actionTypes = {

    LIST_SO_FOR_INVOICE: 'LIST_SO_FOR_INVOICE',
    LIST_SO_FOR_INVOICE_SUCCESS: 'LIST_SO_FOR_INVOICE_SUCCESS',
    LIST_SO_FOR_INVOICE_ERROR: 'LIST_SO_FOR_INVOICE_ERROR',

    LIST_INVOICE: 'LIST_INVOICE',
    LIST_INVOICE_SUCCESS: 'LIST_INVOICE_SUCCESS',
    LIST_INVOICE_ERROR: 'LIST_INVOICE_ERROR',

    SAVE_INVOICE: 'SAVE_INVOICE',
    SAVE_INVOICE_SUCCESS: 'SAVE_INVOICE_SUCCESS',
    SAVE_INVOICE_ERROR: 'SAVE_INVOICE_ERROR',

    FETCH_INVOICE_BY_ID: 'FETCH_INVOICE_BY_ID',
    FETCH_INVOICE_BY_ID_SUCCESS: 'FETCH_INVOICE_BY_ID_SUCCESS',
    FETCH_INVOICE_BY_ID_ERROR: 'FETCH_INVOICE_BY_ID_ERROR',
};

export const listSoForInvoice = (payload) => {
    return {
        type: actionTypes.LIST_SO_FOR_INVOICE, payload
    };
};
export const listSoForInvoiceSuccess = (payload) => {
    return {
        type: actionTypes.LIST_SO_FOR_INVOICE_SUCCESS,
        payload
    };
};
export const listSoForInvoiceError = (payload) => {
    return {
        type: actionTypes.LIST_SO_FOR_INVOICE_ERROR,
        payload
    };
};

export const listInvoice = (payload) => {
    return {
        type: actionTypes.LIST_INVOICE, payload
    };
};
export const listInvoiceSuccess = (payload) => {
    return {
        type: actionTypes.LIST_INVOICE_SUCCESS,
        payload
    };
};
export const listInvoiceError = (payload) => {
    return {
        type: actionTypes.LIST_INVOICE_ERROR,
        payload
    };
};

export const saveInvoice = (payload) => {
    return {
        type: actionTypes.SAVE_INVOICE, payload
    };
};
export const saveInvoiceSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_INVOICE_SUCCESS,
        payload
    };
};
export const saveInvoiceError = (payload) => {
    return {
        type: actionTypes.SAVE_INVOICE_ERROR,
        payload
    };
};


export const fetchInvoiceById = (payload) => {
    return {
        type: actionTypes.FETCH_INVOICE_BY_ID, payload
    };
};
export const fetchInvoiceByIdSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_INVOICE_BY_ID_SUCCESS,
        payload
    };
};
export const fetchInvoiceByIdError = (payload) => {
    return {
        type: actionTypes.FETCH_INVOICE_BY_ID_ERROR,
        payload
    };
};