import axios from '../../ApiClient'

class masterservice {
    constructor(callback) {
        this.callback = callback
    }

    async addService(payload) {
        return await axios.post('HR/Service_master/save_service', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listService(payload) {
        return await axios.post('Service/Servicerooster/list_servicemaster', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteService(payload) {
        return await axios.post('HR/Service_master/delete_service_master', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }



    async listServiceMaster(payload) {
        return await axios.post('Service/Servicerooster/list_servicemaster', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async saveServiceRooster(payload) {
        await axios.post('Service/Servicerooster/add_service_rooster', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listServiceRooster(payload) {
        return await axios.post('Service/Servicerooster/list_servicerooster', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteServiceRooster(payload) {
        return await axios.post('Service/Servicerooster/delete_service_rooster', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async assignEmployeeService(payload) {
        await axios.post('Service/Servicerooster/assign_employee_to_service_task', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new masterservice();
