import { takeEvery, put, call, all } from 'redux-saga/effects';
import salutationservice from '@service/admin/masterService/salutationservice';


import {
    FetchSalutationSuccess, FetchSalutationError,
    AddSalutationSuccess, AddSalutationError,
    DeleteSalutationSuccess, DeleteSalutationError,
    EditSalutationSuccess, EditSalutationError,
    UpdateSalutationSuccess, UpdateSalutationError
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'


function* FetchSalutation({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(salutationservice.fetch_salutation, payload);
        yield put(FetchSalutationSuccess(data));
    } catch (error) {
        yield put(FetchSalutationError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* AddSalutation({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(salutationservice.save_salutation, payload);
        yield put(AddSalutationSuccess(data));
    } catch (error) {
        yield put(AddSalutationError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* DeleteSalutation({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(salutationservice.delete_salutation, payload);
        yield put(DeleteSalutationSuccess(data));
    } catch (error) {
        yield put(DeleteSalutationError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* EditSalutation({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(salutationservice.edit_salutation, payload);
        yield put(EditSalutationSuccess(data));
    } catch (error) {
        yield put(EditSalutationError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* UpdateSalutation({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(salutationservice.update_salutation, payload);
        yield put(UpdateSalutationSuccess(data));
    } catch (error) {
        yield put(UpdateSalutationError(error));
    } finally {
        yield put(stopLoader());
    }
}

export default function* rootSaga() {
    yield all([takeEvery('FETCH_SALUTATION', FetchSalutation)]);
    yield all([takeEvery('ADD_SALUTATION', AddSalutation)]);
    yield all([takeEvery('DELETE_SALUTATION', DeleteSalutation)]);
    yield all([takeEvery('EDIT_SALUTATION', EditSalutation)]);
    yield all([takeEvery('UPDATE_SALUTATION', UpdateSalutation)]);
}