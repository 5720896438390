import { actionTypes } from './action';

export const initialState = {
    saveEmploymentDetailsStatus: '',
    listEmploymentDetails: [],
    updateEmploymentDetailssStatus: '',
    deleteEmploymentDetailsStatus: '',
}
const saveEmploymentDetailsReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_EMPLOYMENTDETAILS:
            return { ...state, saveEmploymentDetailsStatus: '' }
        case actionTypes.SAVE_EMPLOYMENTDETAILS_SUCCESS:
            return { ...state, saveEmploymentDetailsStatus: action.payload }
        case actionTypes.SAVE_EMPLOYMENTDETAILS_ERROR:
            return { ...state, saveEmploymentDetailsStatus: '' }

        case actionTypes.LIST_EMPLOYMENTDETAILS:
            return { ...state, listEmploymentDetails: [] }
        case actionTypes.LIST_EMPLOYMENTDETAILS_SUCCESS:
            return { ...state, listEmploymentDetails: action.payload }
        case actionTypes.LIST_EMPLOYMENTDETAILS_ERROR:
            return { ...state, listEmploymentDetails: [] }

        case actionTypes.UPDATE_EMPLOYMENTDETAILS:
            return { ...state, updateEmploymentDetailssStatus: '' }
        case actionTypes.UPDATE_EMPLOYMENTDETAILS_SUCCESS:
            return { ...state, updateEmploymentDetailssStatus: action.payload }
        case actionTypes.UPDATE_EMPLOYMENTDETAILS_ERROR:
            return { ...state, updateEmploymentDetailssStatus: '' }

        case actionTypes.DELETE_EMPLOYMENTDETAILS:
            return { ...state, deleteEmploymentDetailsStatus: '' }
        case actionTypes.DELETE_EMPLOYMENTDETAILS_SUCCESS:
            return { ...state, deleteEmploymentDetailsStatus: action.payload }
        case actionTypes.DELETE_EMPLOYMENTDETAILS_ERROR:
            return { ...state, deleteEmploymentDetailsStatus: '' }

        default:
            return state
    }
}
export default saveEmploymentDetailsReducer