import { actionTypes } from './action';

export const initialState = {
    getEmpShiftDetails: [],
    getWrkHrDependency: [],
    getLeaveTypes: [],
    saveTimesheet: '',
    getTimesheet: [],
    resendTimesheet: {},
    multiApproveTimesheet: '',
    singleApproveTimesheet: '',
    getResendTimesheet: [],
    getRejectedTimesheet: [],
    rejectTimeshet: '',
    pmLeaveRequestStatus: '',
    timesheetDetailView: {},
    employeesByPM: [],
    timeslotReview: [],
    bulkAttendanceLabourDetails: {},
    saveLabourAttendance: '',
    clockinDetails: [],
    currentClockinDetails: [],
    clockinStatus: '',
    clockoutStatus: '',
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SHIFT_DETAILS_BY_EMP:
            return { ...state, getEmpShiftDetails: [] }

        case actionTypes.GET_SHIFT_DETAILS_BY_EMP_SUCCESS:
            return { ...state, getEmpShiftDetails: action.payload }

        case actionTypes.GET_SHIFT_DETAILS_BY_EMP_ERROR:
            return { ...state, getEmpShiftDetails: [] }

        case actionTypes.GET_WORK_HOUR_DEPENDENCY:
            return { ...state, getWrkHrDependency: [] }

        case actionTypes.GET_WORK_HOUR_DEPENDENCY_SUCCESS:
            return { ...state, getWrkHrDependency: action.payload }

        case actionTypes.GET_WORK_HOUR_DEPENDENCY_ERROR:
            return { ...state, getWrkHrDependency: [] }

        case actionTypes.GET_LEAVE_TYPES:
            return { ...state, getLeaveTypes: [] }

        case actionTypes.GET_LEAVE_TYPES_SUCCESS:
            return { ...state, getLeaveTypes: action.payload }

        case actionTypes.GET_LEAVE_TYPES_ERROR:
            return { ...state, getLeaveTypes: [] }

        case actionTypes.SAVE_TIMESHEET:
            return { ...state, saveTimesheet: '' }

        case actionTypes.SAVE_TIMESHEET_SUCCESS:
            return { ...state, saveTimesheet: action.payload }

        case actionTypes.SAVE_TIMESHEET_ERROR:
            return { ...state, saveTimesheet: '' }

        case actionTypes.GET_TIMESHEET:
            return { ...state, getTimesheet: [] }

        case actionTypes.GET_TIMESHEET_SUCCESS:
            return { ...state, getTimesheet: action.payload }

        case actionTypes.GET_TIMESHEET_ERROR:
            return { ...state, getTimesheet: [] }

        case actionTypes.RESEND_TIMESHEET:
            return { ...state, resendTimesheet: {} }

        case actionTypes.RESEND_TIMESHEET_SUCCESS:
            return { ...state, resendTimesheet: action.payload }

        case actionTypes.RESEND_TIMESHEET_ERROR:
            return { ...state, resendTimesheet: {} }

        case actionTypes.MULTIPLE_APPROVE_TIMESHEET:
            return { ...state, multiApproveTimesheet: '' }

        case actionTypes.MULTIPLE_APPROVE_TIMESHEET_SUCCESS:
            return { ...state, multiApproveTimesheet: 'success' }

        case actionTypes.MULTIPLE_APPROVE_TIMESHEET_ERROR:
            return { ...state, multiApproveTimesheet: '' }

        case actionTypes.SINGLE_APPROVE_TIMESHEET:
            return { ...state, singleApproveTimesheet: '' }

        case actionTypes.SINGLE_APPROVE_TIMESHEET_SUCCESS:
            return { ...state, singleApproveTimesheet: 'success' }

        case actionTypes.SINGLE_APPROVE_TIMESHEET_ERROR:
            return { ...state, singleApproveTimesheet: '' }

        case actionTypes.GET_RESEND_TIMESHEET:
            return { ...state, getResendTimesheet: [] }

        case actionTypes.GET_RESEND_TIMESHEET_SUCCESS:
            return { ...state, getResendTimesheet: action.payload }

        case actionTypes.GET_RESEND_TIMESHEET_ERROR:
            return { ...state, getResendTimesheet: [] }

        case actionTypes.GET_REJECTED_TIMESHEET:
            return { ...state, getRejectedTimesheet: [] }

        case actionTypes.GET_REJECTED_TIMESHEET_SUCCESS:
            return { ...state, getRejectedTimesheet: action.payload }

        case actionTypes.GET_REJECTED_TIMESHEET_ERROR:
            return { ...state, getRejectedTimesheet: [] }

        case actionTypes.REJECT_TIMESHEET:
            return { ...state, rejectTimeshet: '' }

        case actionTypes.REJECT_TIMESHEET_SUCCESS:
            return { ...state, rejectTimeshet: 'success' }

        case actionTypes.REJECT_TIMESHEET_ERROR:
            return { ...state, rejectTimeshet: '' }

        case actionTypes.PM_ADD_LEAVE_REQUEST:
            return { ...state, pmLeaveRequestStatus: '' }

        case actionTypes.PM_ADD_LEAVE_REQUEST_SUCCESS:
            return { ...state, pmLeaveRequestStatus: action.payload }

        case actionTypes.PM_ADD_LEAVE_REQUEST_ERROR:
            return { ...state, pmLeaveRequestStatus: '' }

        case actionTypes.TIMESHEET_DETAILED_VIEW:
            return { ...state, timesheetDetailView: {} }

        case actionTypes.TIMESHEET_DETAILED_VIEW_SUCCESS:
            return { ...state, timesheetDetailView: action.payload }

        case actionTypes.TIMESHEET_DETAILED_VIEW_ERROR:
            return { ...state, timesheetDetailView: {} }

        case actionTypes.GET_EMPLOYEE_BY_PM:
            return { ...state, employeesByPM: [] }

        case actionTypes.GET_EMPLOYEE_BY_PM_SUCCESS:
            return { ...state, employeesByPM: action.payload }

        case actionTypes.GET_EMPLOYEE_BY_PM_ERROR:
            return { ...state, employeesByPM: [] }

        case actionTypes.TIMESLOT_REVIEW:
            return { ...state, timeslotReview: [] }

        case actionTypes.TIMESLOT_REVIEW_SUCCESS:
            return { ...state, timeslotReview: action.payload }

        case actionTypes.TIMESLOT_REVIEW_ERROR:
            return { ...state, timeslotReview: [] }

        case actionTypes.BULK_ATTENDANCE_LABOUR_DETAILS:
            return { ...state, bulkAttendanceLabourDetails: {} }

        case actionTypes.BULK_ATTENDANCE_LABOUR_DETAILS_SUCCESS:
            return { ...state, bulkAttendanceLabourDetails: action.payload }

        case actionTypes.BULK_ATTENDANCE_LABOUR_DETAILS_ERROR:
            return { ...state, bulkAttendanceLabourDetails: {} }

        case actionTypes.SAVE_LABOUR_BULK_ATTENDANCE:
            return { ...state, saveLabourAttendance: '' }
        case actionTypes.SAVE_LABOUR_BULK_ATTENDANCE_SUCCESS:
            return { ...state, saveLabourAttendance: 'success' }
        case actionTypes.SAVE_LABOUR_BULK_ATTENDANCE_ERROR:
            return { ...state, saveLabourAttendance: 'error' }

        case actionTypes.CLOCKIN_COCKOUT_DETAILS:
            return { ...state, clockinDetails: [] }
        case actionTypes.CLOCKIN_COCKOUT_DETAILS_SUCCESS:
            return { ...state, clockinDetails: action.payload }
        case actionTypes.CLOCKIN_COCKOUT_DETAILS_ERROR:
            return { ...state, clockinDetails: [] }

        case actionTypes.CURRENT_CLOCKIN_COCKOUT_DETAILS:
            return { ...state, currentClockinDetails: [] }
        case actionTypes.CURRENT_CLOCKIN_COCKOUT_DETAILS_SUCCESS:
            return { ...state, currentClockinDetails: action.payload }
        case actionTypes.CURRENT_CLOCKIN_COCKOUT_DETAILS_ERROR:
            return { ...state, currentClockinDetails: [] }

        case actionTypes.HANDLE_CLOCK_IN:
            return { ...state, clockinStatus: '' }
        case actionTypes.HANDLE_CLOCK_IN_SUCCESS:
            return { ...state, clockinStatus: 'success' }
        case actionTypes.HANDLE_CLOCK_IN_ERROR:
            return { ...state, clockinStatus: 'error' }

        case actionTypes.HANDLE_CLOCK_OUT:
            return { ...state, clockoutStatus: '' }
        case actionTypes.HANDLE_CLOCK_OUT_SUCCESS:
            return { ...state, clockoutStatus: 'success' }
        case actionTypes.HANDLE_CLOCK_OUT_ERROR:
            return { ...state, clockoutStatus: 'error' }

        default:
            return state;
    }
}

export default reducer;