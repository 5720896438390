
export const actionTypes = {
    SAVE_VENDOR: 'SAVE_VENDOR',
    SAVE_VENDOR_SUCCESS: 'SAVE_VENDOR_SUCCESS',
    SAVE_VENDOR_ERROR: 'SAVE_VENDOR_ERROR',

    LIST_VENDOR: 'LIST_VENDOR',
    LIST_VENDOR_SUCCESS: 'LIST_VENDOR_SUCCESS',
    LIST_VENDOR_ERROR: 'LIST_VENDOR_ERROR',

    UPDATE_VENDOR: 'UPDATE_VENDOR',
    UPDATE_VENDOR_SUCCESS: 'UPDATE_VENDOR_SUCCESS',
    UPDATE_VENDOR_ERROR: 'UPDATE_VENDOR_ERROR',
    
    DELETE_VENDOR: 'DELETE_VENDOR',
    DELETE_VENDOR_SUCCESS: 'DELETE_VENDOR_SUCCESS',
    DELETE_VENDOR_ERROR: 'DELETE_VENDOR_ERROR',
};

export const saveVendor = (payload) => {
    return {
        type: actionTypes.SAVE_VENDOR, payload
    };
};
export const saveVendorSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_VENDOR_SUCCESS,
        payload
    };
};
export const saveVendorError = (payload) => {
    return {
        type: actionTypes.SAVE_VENDOR_ERROR,
        payload
    };
};

export const listVendor = (payload) => {
    return {
        type: actionTypes.LIST_VENDOR,payload
    };
};
export const listVendorSuccess = (payload) => {
    return {
        type: actionTypes.LIST_VENDOR_SUCCESS,
        payload
    };
};
export const listVendorError = (payload) => {
    return {
        type: actionTypes.LIST_VENDOR_ERROR,
        payload
    };
};

export const updateVendor = (payload) => {
    return {
        type: actionTypes.UPDATE_VENDOR,payload
    };
};
export const updateVendorSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_VENDOR_SUCCESS,
        payload
    };
};
export const updateVendorError = (payload) => {
    return {
        type: actionTypes.UPDATE_VENDOR_ERROR,
        payload
    };
};

export const deleteVendor = (payload) => {
    return {
        type: actionTypes.DELETE_VENDOR,payload
    };
};
export const deleteVendorSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_VENDOR_SUCCESS,
        payload
    };
};
export const deleteVendorError = (payload) => {
    return {
        type: actionTypes.DELETE_VENDOR_ERROR,
        payload
    };
};


export const importvendor = (payload) => {
    return {
        type: 'IMPORT_VENDOR',
        payload
    };
};
export const importvendorSuccess = (payload) => {
    
    return {
        type: 'IMPORT_VENDOR_SUCCESS',
        payload
    };
};
export const importvendorError = (payload) => {
    return {
        type: 'IMPORT_VENDOR_ERROR',
        payload
    };
};