
export const actionTypes = {
    LIST_MRV_PO: 'LIST_MRV_PO',
    LIST_MRV_PO_SUCCESS: 'LIST_MRV_PO_SUCCESS',
    LIST_MRV_PO_ERROR: 'LIST_MRV_PO_ERROR',

    FETCH_PO_BY_ID: 'FETCH_PO_BY_ID',
    FETCH_PO_BY_ID_SUCCESS: 'FETCH_PO_BY_ID_SUCCESS',
    FETCH_PO_BY_ID_ERROR: 'FETCH_PO_BY_ID_ERROR',

    FETCH_MRV_BY_ID: 'FETCH_MRV_BY_ID',
    FETCH_MRV_BY_ID_SUCCESS: 'FETCH_MRV_BY_ID_SUCCESS',
    FETCH_MRV_BY_ID_ERROR: 'FETCH_MRV_BY_ID_ERROR',

    CHECK_BARCODE: 'CHECK_BARCODE',
    CHECK_BARCODE_SUCCESS: 'CHECK_BARCODE_SUCCESS',
    CHECK_BARCODE_ERROR: 'CHECK_BARCODE_ERROR',

    LIST_STORE_MRV: 'LIST_STORE_MRV',
    LIST_STORE_MRV_SUCCESS: 'LIST_STORE_MRV_SUCCESS',
    LIST_STORE_MRV_ERROR: 'LIST_STORE_MRV_ERROR',

    SAVE_MRV: 'SAVE_MRV',
    SAVE_MRV_SUCCESS: 'SAVE_MRV_SUCCESS',
    SAVE_MRV_ERROR: 'SAVE_MRV_ERROR',

    LIST_MRV: 'LIST_MRV',
    LIST_MRV_SUCCESS: 'LIST_MRV_SUCCESS',
    LIST_MRV_ERROR: 'LIST_MRV_ERROR',

    DELETE_MRV: 'DELETE_MRV',
    DELETE_MRV_SUCCESS: 'DELETE_MRV_SUCCESS',
    DELETE_MRV_ERROR: 'DELETE_MRV_ERROR',
};

export const listPo = (payload) => {
    return {
        type: actionTypes.LIST_MRV_PO, payload
    };
};
export const listPoSuccess = (payload) => {
    return {
        type: actionTypes.LIST_MRV_PO_SUCCESS,
        payload
    };
};
export const listPoError = (payload) => {
    return {
        type: actionTypes.LIST_MRV_PO_ERROR,
        payload
    };
};

export const fetchPoById = (payload) => {
    return {
        type: actionTypes.FETCH_PO_BY_ID, payload
    };
};
export const fetchPoByIdSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_PO_BY_ID_SUCCESS,
        payload
    };
};
export const fetchPoByIdError = (payload) => {
    return {
        type: actionTypes.FETCH_PO_BY_ID_ERROR,
        payload
    };
};

export const fetchMrvById = (payload) => {
    return {
        type: actionTypes.FETCH_MRV_BY_ID, payload
    };
};
export const fetchMrvByIdSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_MRV_BY_ID_SUCCESS,
        payload
    };
};
export const fetchMrvByIdError = (payload) => {
    return {
        type: actionTypes.FETCH_MRV_BY_ID_ERROR,
        payload
    };
};

export const checkBarcode = (payload) => {
    return {
        type: actionTypes.CHECK_BARCODE, payload
    };
};
export const checkBarcodeSuccess = (payload) => {
    return {
        type: actionTypes.CHECK_BARCODE_SUCCESS,
        payload
    };
};
export const checkBarcodeError = (payload) => {
    return {
        type: actionTypes.CHECK_BARCODE_ERROR,
        payload
    };
};

export const listStoreMrv = (payload) => {
    return {
        type: actionTypes.LIST_STORE_MRV, payload
    };
};
export const listStoreMrvSuccess = (payload) => {
    return {
        type: actionTypes.LIST_STORE_MRV_SUCCESS,
        payload
    };
};
export const listStoreMrvError = (payload) => {
    return {
        type: actionTypes.LIST_STORE_MRV_ERROR,
        payload
    };
};

export const saveMrv = (payload) => {
    return {
        type: actionTypes.SAVE_MRV, payload
    };
};
export const saveMrvSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_MRV_SUCCESS,
        payload
    };
};
export const saveMrvError = (payload) => {
    return {
        type: actionTypes.SAVE_MRV_ERROR,
        payload
    };
};

export const listMrv = (payload) => {
    return {
        type: actionTypes.LIST_MRV, payload
    };
};
export const listMrvSuccess = (payload) => {
    return {
        type: actionTypes.LIST_MRV_SUCCESS,
        payload
    };
};
export const listMrvError = (payload) => {
    return {
        type: actionTypes.LIST_MRV_ERROR,
        payload
    };
};

export const removeMrv = (payload) => {
    return {
        type: actionTypes.DELETE_MRV, payload
    };
};
export const removeMrvSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_MRV_SUCCESS,
        payload
    };
};
export const removeMrvError = (payload) => {
    return {
        type: actionTypes.DELETE_MRV_ERROR,
        payload
    };
};

// barcode generate
export const generateBarcode = (payload) => {
    return {
      type: 'BARCODE_GENERATE',
      payload
    };
  };
  export const generateBarcodeSuccess = (payload) => {
    return {
      type: 'BARCODE_GENERATE_SUCCESS',
      payload
    };
  };
  export const generateBarcodeError = (payload) => {
    return {
      type: 'BARCODE_GENERATE_ERROR',
      payload
    };
  };