
export const actionTypes = {
    SAVE_IDENTITYDETAILS: 'SAVE_IDENTITYDETAILS',
    SAVE_IDENTITYDETAILS_SUCCESS: 'SAVE_IDENTITYDETAILS_SUCCESS',
    SAVE_IDENTITYDETAILS_ERROR: 'SAVE_IDENTITYDETAILS_ERROR',
    LIST_IDENTITYDETAILS: 'LIST_IDENTITYDETAILS',
    LIST_IDENTITYDETAILS_SUCCESS: 'LIST_IDENTITYDETAILS_SUCCESS',
    LIST_IDENTITYDETAILS_ERROR: 'LIST_IDENTITYDETAILS_ERROR',
    UPDATE_IDENTITYDETAILS: 'UPDATE_IDENTITYDETAILS',
    UPDATE_IDENTITYDETAILS_SUCCESS: 'UPDATE_IDENTITYDETAILS_SUCCESS',
    UPDATE_IDENTITYDETAILS_ERROR: 'UPDATE_IDENTITYDETAILS_ERROR',
    DELETE_IDENTITYDETAILS: 'DELETE_IDENTITYDETAILS',
    DELETE_IDENTITYDETAILS_SUCCESS: 'DELETE_IDENTITYDETAILS_SUCCESS',
    DELETE_IDENTITYDETAILS_ERROR: 'DELETE_IDENTITYDETAILS_ERROR',
    // LIST_QUALIFICATION: 'LIST_QUALIFICATION',
    // LIST_QUALIFICATION_SUCCESS: 'LIST_QUALIFICATION_SUCCESS',
    // LIST_QUALIFICATION_ERROR: 'LIST_QUALIFICATION_ERROR',
};

export const saveIdentityDetails = (payload) => {
    return {
        type: actionTypes.SAVE_IDENTITYDETAILS, payload
    };
};
export const saveIdentityDetailsSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_IDENTITYDETAILS_SUCCESS,
        payload
    };
};
export const saveIdentityDetailsError = (payload) => {
    return {
        type: actionTypes.SAVE_SAVE_IDENTITYDETAILS_ERROR,
        payload
    };
};

export const listIdentityDetails = (payload) => {
    return {
        type: actionTypes.LIST_IDENTITYDETAILS, payload
    };
};
export const listIdentityDetailsSuccess = (payload) => {
    return {
        type: actionTypes.LIST_IDENTITYDETAILS_SUCCESS,
        payload
    };
};
export const listIdentityDetailsError = (payload) => {
    return {
        type: actionTypes.LIST_IDENTITYDETAILS_ERROR,
        payload
    };
};

export const updateIdentityDetails = (payload) => {
    return {
        type: actionTypes.UPDATE_IDENTITYDETAILS, payload
    };
};
export const updateIdentityDetailsSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_IDENTITYDETAILS_SUCCESS,
        payload
    };
};
export const updateIdentityDetailsError = (payload) => {
    return {
        type: actionTypes.UPDATE_IDENTITYDETAILS_ERROR,
        payload
    };
};

export const deleteIdentityDetails = (payload) => {
    return {
        type: actionTypes.DELETE_IDENTITYDETAILS, payload
    };
};
export const deleteIdentityDetailsSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_IDENTITYDETAILS_SUCCESS,
        payload
    };
};
export const deleteIdentityDetailsError = (payload) => {
    return {
        type: actionTypes.DELETE_IDENTITYDETAILS_ERROR,
        payload
    };
};
// export const listQualification = (payload) => {
//     return {
//         type: actionTypes.LIST_QUALIFICATION, payload
//     };
// };
// export const listQualificationSuccess = (payload) => {
//     return {
//         type: actionTypes.LIST_QUALIFICATION_SUCCESS,
//         payload
//     };
// };
// export const listQualificationError = (payload) => {
//     return {
//         type: actionTypes.LIST_QUALIFICATION_ERROR,
//         payload
//     };
// };