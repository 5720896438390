import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import dealService from '@service/admin/masterService/dealService';
import {
    saveDealSuccess, saveDealError,
    listDealSuccess, listDealError,
    listStageSuccess, listStageError,
    updateDealSuccess, updateDealError,
    fetchDealDataByIdSuccess, fetchDealDataByIdError,
    deleteDealSuccess, deleteDealError,
    getProjectListSuccess, getProjectListError,
} from './action';
import { stopLoader, startLoader } from '@store/common/loader/action'


function* saveDeal({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(dealService.saveDeal, payload);
        yield put(saveDealSuccess(data));
    } catch (error) {
        yield put(saveDealError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* fetchDealDataById({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(dealService.fetchDealDataById, payload);
        if (Object.keys(data).length > 0) {
            yield put(fetchDealDataByIdSuccess({
                ...data,
                items_array: data.items_array ? data.items_array.map(item => {
                    return ({
                        ...item,
                        amount: (parseFloat(item.amount) * parseFloat(data.actual_rate)).toFixed(2),
                        discount_amount: (parseFloat(item.discount_amount) * parseFloat(data.actual_rate)).toFixed(2),
                        selling_price: (parseFloat(item.selling_price) * parseFloat(data.actual_rate)).toFixed(2),
                    })
                }) : [],
                work_array: data.work_array ? data.work_array.map(item => {
                    return ({
                        ...item,
                        charge: (parseFloat(item.charge) * parseFloat(data.actual_rate)).toFixed(2),
                        disc_amount: (parseFloat(item.disc_amount) * parseFloat(data.actual_rate)).toFixed(2),
                        wrktotal: (parseFloat(item.wrktotal) * parseFloat(data.actual_rate)).toFixed(2),
                    })
                }):[],
                service_array: data.service_array ? data.service_array.map(item => {
                    return ({
                        ...item,
                        selling_price_ser: (parseFloat(item.selling_price_ser) * parseFloat(data.actual_rate)).toFixed(2),
                        discount_amount_ser: (parseFloat(item.discount_amount_ser) * parseFloat(data.actual_rate)).toFixed(2),
                        amount_ser: (parseFloat(item.amount_ser) * parseFloat(data.actual_rate)).toFixed(2),
                    })
                }):[],
                project_array: data.project_array ? data.project_array.map(item => {
                    return ({
                        ...item,
                        selling_price_project: (parseFloat(item.selling_price_project) * parseFloat(data.actual_rate)).toFixed(2),
                        discount_amount_project: (parseFloat(item.discount_amount_project) * parseFloat(data.actual_rate)).toFixed(2),
                        amount_project: (parseFloat(item.amount_project) * parseFloat(data.actual_rate)).toFixed(2),
                        project_id: data.project_id || data.project_master_id
                    })
                }):[],
                c_discount_amount: (parseFloat(data.c_discount_amount) * parseFloat(data.actual_rate)).toFixed(2),
                net_amount: (parseFloat(data.net_amount) * parseFloat(data.actual_rate)).toFixed(2),
                total_amount_products: (parseFloat(data.total_amount_products) * parseFloat(data.actual_rate)).toFixed(2),
                expected_revenue: (parseFloat(data.expected_revenue) * parseFloat(data.actual_rate)).toFixed(2),
            }));
        }
        // yield put(fetchDealDataByIdSuccess(data));
    } catch (error) {
        yield put(fetchDealDataByIdError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* updateDeal({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(dealService.updateDeal, payload);
        yield put(updateDealSuccess(data));
    } catch (error) {
        yield put(updateDealError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* listDeal({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(dealService.listDeal, payload);
        Array.isArray(data) ?
            yield put(listDealSuccess(data)) :
            yield put(listDealSuccess([]));
    } catch (error) {
        yield put(listDealError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* listStage({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(dealService.listStage, payload);
        Array.isArray(data) ?
            yield put(listStageSuccess(data)) :
            yield put(listStageSuccess([]));
    } catch (error) {
        yield put(listStageError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* deleteDeal({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(dealService.deleteDeal, payload);
        yield put(deleteDealSuccess(data));
    } catch (error) {
        yield put(deleteDealError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* getProjectList({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(dealService.getProjectList, payload);
        yield put(getProjectListSuccess(data));
    } catch (error) {
        yield put(getProjectListError(error));
    } finally {
        yield put(stopLoader());
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actionTypes.SAVE_DEAL, saveDeal)]);
    yield all([takeEvery(actionTypes.LIST_DEAL, listDeal)]);
    yield all([takeEvery(actionTypes.LIST_STAGE, listStage)]);
    yield all([takeEvery(actionTypes.UPDATE_DEAL, updateDeal)]);
    yield all([takeEvery(actionTypes.FETCH_DEAL_DATA_BY_ID, fetchDealDataById)]);
    yield all([takeEvery(actionTypes.DELETE_DEAL, deleteDeal)]);
    yield all([takeEvery(actionTypes.GET_PROJECT_LIST, getProjectList)]);
}