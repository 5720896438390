import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import prefixSettingsService from '@service/admin/masterService/prefixSettingsService';
import {
  listPrefixSuccess, listPrefixError,
  updatePrefixSuccess, updatePrefixError
} from './action';

function* listPrefix({ payload }) {
  try {
    const data = yield call(prefixSettingsService.listPrefix, payload);
    Array.isArray(data) ?
      yield put(listPrefixSuccess(data)) :
      yield put(listPrefixSuccess([]));
  } catch (error) {
    yield put(listPrefixError(error));
  } finally {
  }
}

function* updatePrefix({ payload }) {
  try {
    const data = yield call(prefixSettingsService.updatePrefix, payload);

    yield put(updatePrefixSuccess(data));
  } catch (error) {
    yield put(updatePrefixError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.UPDATE_PREFIX, updatePrefix)]);
  yield all([takeEvery(actionTypes.LIST_PREFIX, listPrefix)]);

}