export const actionTypes = {
    GET_SHIFT_DETAILS_BY_EMP: 'GET_SHIFT_DETAILS_BY_EMP',
    GET_SHIFT_DETAILS_BY_EMP_SUCCESS: 'GET_SHIFT_DETAILS_BY_EMP_SUCCESS',
    GET_SHIFT_DETAILS_BY_EMP_ERROR: 'GET_SHIFT_DETAILS_BY_EMP_ERROR',

    GET_WORK_HOUR_DEPENDENCY: 'GET_WORK_HOUR_DEPENDENCY',
    GET_WORK_HOUR_DEPENDENCY_SUCCESS: 'GET_WORK_HOUR_DEPENDENCY_SUCCESS',
    GET_WORK_HOUR_DEPENDENCY_ERROR: 'GET_WORK_HOUR_DEPENDENCY_ERROR',

    GET_LEAVE_TYPES: 'GET_LEAVE_TYPES',
    GET_LEAVE_TYPES_SUCCESS: 'GET_LEAVE_TYPES_SUCCESS',
    GET_LEAVE_TYPES_ERROR: 'GET_LEAVE_TYPES_ERROR',

    SAVE_TIMESHEET: 'SAVE_TIMESHEET',
    SAVE_TIMESHEET_SUCCESS: 'SAVE_TIMESHEET_SUCCESS',
    SAVE_TIMESHEET_ERROR: 'SAVE_TIMESHEET_ERROR',

    GET_TIMESHEET: 'GET_TIMESHEET',
    GET_TIMESHEET_SUCCESS: 'GET_TIMESHEET_SUCCESS',
    GET_TIMESHEET_ERROR: 'GET_TIMESHEET_ERROR',

    RESEND_TIMESHEET: 'RESEND_TIMESHEET',
    RESEND_TIMESHEET_SUCCESS: 'RESEND_TIMESHEET_SUCCESS',
    RESEND_TIMESHEET_ERROR: 'RESEND_TIMESHEET_ERROR',

    MULTIPLE_APPROVE_TIMESHEET: 'MULTIPLE_APPROVE_TIMESHEET',
    MULTIPLE_APPROVE_TIMESHEET_SUCCESS: 'MULTIPLE_APPROVE_TIMESHEET_SUCCESS',
    MULTIPLE_APPROVE_TIMESHEET_ERROR: 'MULTIPLE_APPROVE_TIMESHEET_ERROR',

    SINGLE_APPROVE_TIMESHEET: 'SINGLE_APPROVE_TIMESHEET',
    SINGLE_APPROVE_TIMESHEET_SUCCESS: 'SINGLE_APPROVE_TIMESHEET_SUCCESS',
    SINGLE_APPROVE_TIMESHEET_ERROR: 'SINGLE_APPROVE_TIMESHEET_ERROR',

    GET_RESEND_TIMESHEET: 'GET_RESEND_TIMESHEET',
    GET_RESEND_TIMESHEET_SUCCESS: 'GET_RESEND_TIMESHEET_SUCCESS',
    GET_RESEND_TIMESHEET_ERROR: 'GET_RESEND_TIMESHEET_ERROR',

    GET_REJECTED_TIMESHEET: 'GET_REJECTED_TIMESHEET',
    GET_REJECTED_TIMESHEET_SUCCESS: 'GET_REJECTED_TIMESHEET_SUCCESS',
    GET_REJECTED_TIMESHEET_ERROR: 'GET_REJECTED_TIMESHEET_ERROR',

    REJECT_TIMESHEET: 'REJECT_TIMESHEET',
    REJECT_TIMESHEET_SUCCESS: 'REJECT_TIMESHEET_SUCCESS',
    REJECT_TIMESHEET_ERROR: 'REJECT_TIMESHEET_ERROR',

    PM_ADD_LEAVE_REQUEST: 'PM_ADD_LEAVE_REQUEST',
    PM_ADD_LEAVE_REQUEST_SUCCESS: 'PM_ADD_LEAVE_REQUEST_SUCCESS',
    PM_ADD_LEAVE_REQUEST_ERROR: 'PM_ADD_LEAVE_REQUEST_ERROR',

    TIMESHEET_DETAILED_VIEW: 'TIMESHEET_DETAILED_VIEW',
    TIMESHEET_DETAILED_VIEW_SUCCESS: 'TIMESHEET_DETAILED_VIEW_SUCCESS',
    TIMESHEET_DETAILED_VIEW_ERROR: 'TIMESHEET_DETAILED_VIEW_ERROR',

    GET_EMPLOYEE_BY_PM: 'GET_EMPLOYEE_BY_PM',
    GET_EMPLOYEE_BY_PM_SUCCESS: 'GET_EMPLOYEE_BY_PM_SUCCESS',
    GET_EMPLOYEE_BY_PM_ERROR: 'GET_EMPLOYEE_BY_PM_ERROR',

    TIMESLOT_REVIEW: 'TIMESLOT_REVIEW',
    TIMESLOT_REVIEW_SUCCESS: 'TIMESLOT_REVIEW_SUCCESS',
    TIMESLOT_REVIEW_ERROR: 'TIMESLOT_REVIEW_ERROR',

    BULK_ATTENDANCE_LABOUR_DETAILS: 'BULK_ATTENDANCE_LABOUR_DETAILS',
    BULK_ATTENDANCE_LABOUR_DETAILS_SUCCESS: 'BULK_ATTENDANCE_LABOUR_DETAILS_SUCCESS',
    BULK_ATTENDANCE_LABOUR_DETAILS_ERROR: 'BULK_ATTENDANCE_LABOUR_DETAILS_ERROR',

    SAVE_LABOUR_BULK_ATTENDANCE: 'SAVE_LABOUR_BULK_ATTENDANCE',
    SAVE_LABOUR_BULK_ATTENDANCE_SUCCESS: 'SAVE_LABOUR_BULK_ATTENDANCE_SUCCESS',
    SAVE_LABOUR_BULK_ATTENDANCE_ERROR: 'SAVE_LABOUR_BULK_ATTENDANCE_ERROR',

    CLOCKIN_COCKOUT_DETAILS: 'CLOCKIN_COCKOUT_DETAILS',
    CLOCKIN_COCKOUT_DETAILS_SUCCESS: 'CLOCKIN_COCKOUT_DETAILS_SUCCESS',
    CLOCKIN_COCKOUT_DETAILS_ERROR: 'CLOCKIN_COCKOUT_DETAILS_ERROR',

    CURRENT_CLOCKIN_COCKOUT_DETAILS: 'CURRENT_CLOCKIN_COCKOUT_DETAILS',
    CURRENT_CLOCKIN_COCKOUT_DETAILS_SUCCESS: 'CURRENT_CLOCKIN_COCKOUT_DETAILS_SUCCESS',
    CURRENT_CLOCKIN_COCKOUT_DETAILS_ERROR: 'CURRENT_CLOCKIN_COCKOUT_DETAILS_ERROR',

    HANDLE_CLOCK_IN: 'HANDLE_CLOCK_IN',
    HANDLE_CLOCK_IN_SUCCESS: 'HANDLE_CLOCK_IN_SUCCESS',
    HANDLE_CLOCK_IN_ERROR: 'HANDLE_CLOCK_IN_ERROR',

    HANDLE_CLOCK_OUT: 'HANDLE_CLOCK_OUT',
    HANDLE_CLOCK_OUT_SUCCESS: 'HANDLE_CLOCK_OUT_SUCCESS',
    HANDLE_CLOCK_OUT_ERROR: 'HANDLE_CLOCK_OUT_ERROR',
};

export const getShiftDetailsByEmp = (payload) => {
    return {
        type: actionTypes.GET_SHIFT_DETAILS_BY_EMP,
        payload
    }
}

export const getShiftDetailsByEmpSuccess = (payload) => {
    return {
        type: actionTypes.GET_SHIFT_DETAILS_BY_EMP_SUCCESS,
        payload
    }
}

export const getShiftDetailsByEmpError = (payload) => {
    return {
        type: actionTypes.GET_SHIFT_DETAILS_BY_EMP_ERROR,
        payload
    }
}

export const fetchWrkHrDependancy = (payload) => {
    return {
        type: actionTypes.GET_WORK_HOUR_DEPENDENCY,
        payload
    }
}

export const fetchWrkHrDependancySuccess = (payload) => {
    return {
        type: actionTypes.GET_WORK_HOUR_DEPENDENCY_SUCCESS,
        payload
    }
}

export const fetchWrkHrDependancyError = (payload) => {
    return {
        type: actionTypes.GET_WORK_HOUR_DEPENDENCY_ERROR,
        payload
    }
}

export const fetchLeaveTypes = (payload) => {
    return {
        type: actionTypes.GET_LEAVE_TYPES,
        payload
    }
}

export const fetchLeaveTypesSuccess = (payload) => {
    return {
        type: actionTypes.GET_LEAVE_TYPES_SUCCESS,
        payload
    }
}

export const fetchLeaveTypesError = (payload) => {
    return {
        type: actionTypes.GET_LEAVE_TYPES_ERROR,
        payload
    }
}

export const handleTimesheet = (payload) => {
    return {
        type: actionTypes.SAVE_TIMESHEET,
        payload
    }
}

export const handleTimesheetSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_TIMESHEET_SUCCESS,
        payload
    }
}

export const handleTimesheetError = (payload) => {
    return {
        type: actionTypes.SAVE_TIMESHEET_ERROR,
        payload
    }
}

export const fetchTimesheet = (payload) => {
    return {
        type: actionTypes.GET_TIMESHEET,
        payload
    }
}

export const fetchTimesheetSuccess = (payload) => {
    return {
        type: actionTypes.GET_TIMESHEET_SUCCESS,
        payload
    }
}

export const fetchTimesheetError = (payload) => {
    return {
        type: actionTypes.GET_TIMESHEET_ERROR,
        payload
    }
}

export const resendTimesheet = (payload) => {
    return {
        type: actionTypes.RESEND_TIMESHEET,
        payload
    }
}

export const resendTimesheetSuccess = (payload) => {
    return {
        type: actionTypes.RESEND_TIMESHEET_SUCCESS,
        payload
    }
}

export const resendTimesheetError = (payload) => {
    return {
        type: actionTypes.RESEND_TIMESHEET_ERROR,
        payload
    }
}

export const multipleApproveTimesheet = (payload) => {
    return {
        type: actionTypes.MULTIPLE_APPROVE_TIMESHEET,
        payload
    }
}

export const multipleApproveTimesheetSuccess = (payload) => {
    return {
        type: actionTypes.MULTIPLE_APPROVE_TIMESHEET_SUCCESS,
        payload
    }
}

export const multipleApproveTimesheetError = (payload) => {
    return {
        type: actionTypes.MULTIPLE_APPROVE_TIMESHEET_ERROR,
        payload
    }
}

export const singleApproveTimesheet = (payload) => {
    return {
        type: actionTypes.SINGLE_APPROVE_TIMESHEET,
        payload
    }
}

export const singleApproveTimesheetSuccess = (payload) => {
    return {
        type: actionTypes.SINGLE_APPROVE_TIMESHEET_SUCCESS,
        payload
    }
}

export const singleApproveTimesheetError = (payload) => {
    return {
        type: actionTypes.SINGLE_APPROVE_TIMESHEET_ERROR,
        payload
    }
}

export const fetchResendTimesheet = (payload) => {
    return {
        type: actionTypes.GET_RESEND_TIMESHEET,
        payload
    }
}

export const fetchResendTimesheetSuccess = (payload) => {
    return {
        type: actionTypes.GET_RESEND_TIMESHEET_SUCCESS,
        payload
    }
}

export const fetchResendTimesheetError = (payload) => {
    return {
        type: actionTypes.GET_RESEND_TIMESHEET_ERROR,
        payload
    }
}

export const fetchRejectedTimesheet = (payload) => {
    return {
        type: actionTypes.GET_REJECTED_TIMESHEET,
        payload
    }
}

export const fetchRejectedTimesheetSuccess = (payload) => {
    return {
        type: actionTypes.GET_REJECTED_TIMESHEET_SUCCESS,
        payload
    }
}

export const fetchRejectedTimesheetError = (payload) => {
    return {
        type: actionTypes.GET_REJECTED_TIMESHEET_ERROR,
        payload
    }
}

export const rejectTimesheet = (payload) => {
    return {
        type: actionTypes.REJECT_TIMESHEET,
        payload
    }
}

export const rejectTimesheetSuccess = (payload) => {
    return {
        type: actionTypes.REJECT_TIMESHEET_SUCCESS,
        payload
    }
}

export const rejectTimesheetError = (payload) => {
    return {
        type: actionTypes.REJECT_TIMESHEET_ERROR,
        payload
    }
}

export const handlepmAddLeaveRequest = (payload) => {
    return {
        type: actionTypes.PM_ADD_LEAVE_REQUEST,
        payload
    }
}

export const handlepmAddLeaveRequestSuccess = (payload) => {
    return {
        type: actionTypes.PM_ADD_LEAVE_REQUEST_SUCCESS,
        payload
    }
}

export const handlepmAddLeaveRequestError = (payload) => {
    return {
        type: actionTypes.PM_ADD_LEAVE_REQUEST_ERROR,
        payload
    }
}

export const handleTimesheetDetailedView = (payload) => {
    return {
        type: actionTypes.TIMESHEET_DETAILED_VIEW,
        payload
    }
}

export const handleTimesheetDetailedViewSuccess = (payload) => {
    return {
        type: actionTypes.TIMESHEET_DETAILED_VIEW_SUCCESS,
        payload
    }
}

export const handleTimesheetDetailedViewError = (payload) => {
    return {
        type: actionTypes.TIMESHEET_DETAILED_VIEW_ERROR,
        payload
    }
}

export const fetchEmployeeByPM = (payload) => {
    return {
        type: actionTypes.GET_EMPLOYEE_BY_PM,
        payload
    }
}

export const fetchEmployeeByPMSuccess = (payload) => {
    return {
        type: actionTypes.GET_EMPLOYEE_BY_PM_SUCCESS,
        payload
    }
}

export const fetchEmployeeByPMError = (payload) => {
    return {
        type: actionTypes.GET_EMPLOYEE_BY_PM_ERROR,
        payload
    }
}

export const handleTimeslotReview = (payload) => {
    return {
        type: actionTypes.TIMESLOT_REVIEW,
        payload
    }
}

export const handleTimeslotReviewSuccess = (payload) => {
    return {
        type: actionTypes.TIMESLOT_REVIEW_SUCCESS,
        payload
    }
}

export const handleTimeslotReviewError = (payload) => {
    return {
        type: actionTypes.TIMESLOT_REVIEW_ERROR,
        payload
    }
}

export const handleBulkAttendanceLabourDetails = (payload) => {
    return {
        type: actionTypes.BULK_ATTENDANCE_LABOUR_DETAILS,
        payload
    }
}

export const handleBulkAttendanceLabourDetailsSuccess = (payload) => {
    return {
        type: actionTypes.BULK_ATTENDANCE_LABOUR_DETAILS_SUCCESS,
        payload
    }
}

export const handleBulkAttendanceLabourDetailsError = (payload) => {
    return {
        type: actionTypes.BULK_ATTENDANCE_LABOUR_DETAILS_ERROR,
        payload
    }
}

export const handleLabourBulkAttendance = (payload) => {
    return {
        type: actionTypes.SAVE_LABOUR_BULK_ATTENDANCE,
        payload
    }
}

export const handleLabourBulkAttendanceSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_LABOUR_BULK_ATTENDANCE_SUCCESS,
        payload
    }
}

export const handleLabourBulkAttendanceError = (payload) => {
    return {
        type: actionTypes.SAVE_LABOUR_BULK_ATTENDANCE_ERROR,
        payload
    }
}

export const getCurrentClockinDetails = (payload) => {
    return {
        type: actionTypes.CURRENT_CLOCKIN_COCKOUT_DETAILS,
        payload
    }
}
export const getCurrentClockinDetailsSuccess = (payload) => {
    return {
        type: actionTypes.CURRENT_CLOCKIN_COCKOUT_DETAILS_SUCCESS,
        payload
    }
}
export const getCurrentClockinDetailsError = (payload) => {
    return {
        type: actionTypes.CURRENT_CLOCKIN_COCKOUT_DETAILS_ERROR,
        payload
    }
}

export const getClockinDetails = (payload) => {
    return {
        type: actionTypes.CLOCKIN_COCKOUT_DETAILS,
        payload
    }
}
export const getClockinDetailsSuccess = (payload) => {
    return {
        type: actionTypes.CLOCKIN_COCKOUT_DETAILS_SUCCESS,
        payload
    }
}
export const getClockinDetailsError = (payload) => {
    return {
        type: actionTypes.CLOCKIN_COCKOUT_DETAILS_ERROR,
        payload
    }
}

export const handleClockin = (payload) => {
    return {
        type: actionTypes.HANDLE_CLOCK_IN,
        payload
    }
}
export const handleClockinSuccess = (payload) => {
    return {
        type: actionTypes.HANDLE_CLOCK_IN_SUCCESS,
        payload
    }
}
export const handleClockinError = (payload) => {
    return {
        type: actionTypes.HANDLE_CLOCK_IN_ERROR,
        payload
    }
}

export const handleClockout = (payload) => {
    return {
        type: actionTypes.HANDLE_CLOCK_OUT,
        payload
    }
}
export const handleClockoutSuccess = (payload) => {
    return {
        type: actionTypes.HANDLE_CLOCK_OUT_SUCCESS,
        payload
    }
}
export const handleClockoutError = (payload) => {
    return {
        type: actionTypes.HANDLE_CLOCK_OUT_ERROR,
        payload
    }
}