import { combineReducers } from 'redux';
const initialState = {
    listvalues: [],
    shiftvalues: '',
    editstatus: '',
    shiftidvalues: {},
    deletestatus: '',
    // LEAVE TYPE
    desigvalues: [],
    savedesigvalues: [],
    listlevaevalues: [],
    updatevalues: [],
    deletevalues: [],
    listlevaeidvalues: [],
    //rules
    leavevalues: [],
    rulevalues: [],
    editrulevalue: [],
    updataerulevalue: [],
    //leaverequest
    leaverequest: [],
    leaverequeststatus: [],
    leavetypevalues: [],
    editleavevalues: [],
    updateleavestatus: [],
    deleteleavestatus: [],
    cancelleavestatus: [],
    updaterequeststatus: [],
    //leave approval
    requestStatus: [],
    approveStatus: [],
    rejectStatus: []
}


const shiftReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'LIST_SHIFT':
            return { ...state, listvalues: [] }
        case 'LIST_SHIFT_SUCCESS':
            return { ...state, listvalues: action.payload }
        case 'LIST_SHIFT_ERROR':
            return { ...state, listvalues: [] }

        case 'SAVE_SHIFT':
            return { ...state, shiftvalues: '' }
        case 'SAVE_SHIFT_SUCCESS':
            return { ...state, shiftvalues: 'success' }
        case 'SAVE_SHIFT_ERROR':
            return { ...state, shiftvalues: 'error' }

        case 'UPDATE_SHIFT':
            return { ...state, editstatus: '' }
        case 'UPDATE_SHIFT_SUCCESS':
            console.log('update SHIFT SUCCESS');
            return { ...state, editstatus: 'success' }
        case 'UPDATE_SHIFT_ERROR':
            return { ...state, editstatus: 'error' }

        case 'LIST_SHIFT_ID':
            return { ...state, shiftidvalues: {} }
        case 'LIST_SHIFT_ID_SUCCESS':
            return { ...state, shiftidvalues: action.payload }
        case 'LIST_SHIFT_ID_ERROR':
            return { ...state, shiftidvalues: {} }

        case 'DELETE_SHIFT':
            return { ...state, deletestatus: '' }
        case 'DELETE_SHIFT_SUCCESS':
            return { ...state, deletestatus: 'success' }
        case 'DELETE_SHIFT_ERROR':
            return { ...state, deletestatus: 'error' }

        // HR / Leave type

        // case 'FETCH_DESIGNATION':
        //     return { ...state, desigvalues: [] }
        // case 'FETCH_DESIGNATION_SUCCESS':
        //     return { ...state, desigvalues: action.payload }
        // case 'FETCH_DESIGNATION_ERROR':
        //     return { ...state, desigvalues: [] }

        // case 'SAVE_LEAVETYPE':
        //     return { ...state, savedesigvalues: [] }
        // case 'SAVE_LEAVETYPE_SUCCESS':
        //     return { ...state, savedesigvalues: action.payload }
        // case 'SAVE_LEAVETYPE_ERROR':
        //     return { ...state, savedesigvalues: [] }

        // case 'FETCH_LEAVE_ID':
        //     return { ...state, listlevaeidvalues: [] }
        // case 'FETCH_LEAVE_ID_SUCCESS':
        //     return { ...state, listlevaeidvalues: action.payload }
        // case 'FETCH_LEAVE_ID_ERROR':
        //     return { ...state, listlevaeidvalues: [] }

        // case 'UPDATE_LEAVE_TYPE':
        //     return { ...state, updatevalues: [] }
        // case 'UPDATE_LEAVE_TYPE_SUCCESS':
        //     return { ...state, updatevalues: action.payload }
        // case 'UPDATE_LEAVE_TYPE_ERROR':
        //     return { ...state, updatevalues: [] }

        // case 'FETCH_LEAVE':
        //     return { ...state, listlevaevalues: [], rulevalues: '', editrulevalue: '', updataerulevalue: '' }
        // case 'FETCH_LEAVE_SUCCESS':
        //     return { ...state, listlevaevalues: action.payload }
        // case 'FETCH_LEAVE_ERROR':
        //     return { ...state, listlevaevalues: [] }

        // case 'DELETE_LEAVE':
        //     return { ...state, deletevalues: [] }
        // case 'DELETE_LEAVE_SUCCESS':
        //     return { ...state, deletevalues: action.payload }
        // case 'DELETE_LEAVE_ERROR':
        //     return { ...state, deletevalues: [] }

        // RULEs    
        // case 'LIST_LEAVE_TYPE':
        //     return { ...state, leavevalues: [] }
        // case 'LIST_LEAVE_TYPE_SUCCESS':
        //     return { ...state, leavevalues: action.payload }
        // case 'LIST_LEAVE_TYPE_ERROR':
        //     return { ...state, leavevalues: [] }

        // case 'SAVE_RULE':
        //     return { ...state, rulevalues: [] }
        // case 'SAVE_RULE_SUCCESS':
        //     return { ...state, rulevalues: action.payload }
        // case 'SAVE_RULE_ERROR':
        //     return { ...state, rulevalues: [] }

        // case 'UPDATE_RULE':
        //     return { ...state, editrulevalue: [] }
        // case 'UPDATE_RULE_SUCCESS':
        //     return { ...state, editrulevalue: action.payload }
        // case 'UPDATE_RULE_ERROR':
        //     return { ...state, editrulevalue: [] }

        // case 'EDIT_RULE':
        //     return { ...state, updataerulevalue: [] }
        // case 'EDIT_RULE_SUCCESS':
        //     return { ...state, updataerulevalue: action.payload }
        // case 'EDIT_RULE_ERROR':
        //     return { ...state, updataerulevalue: [] }
        // leaverequest

        // case 'SAVE_LEAVEREQUEST':
        //     return { ...state, leaverequest: [] }
        // case 'SAVE_LEAVEREQUEST_SUCCESS':
        //     return { ...state, leaverequest: action.payload }
        // case 'SAVE_LEAVEREQUEST_ERROR':
        //     return { ...state, leaverequest: [] }

        // case 'FETCH_LEAVE_REQUEST':
        //     return { ...state, leaverequeststatus: [] }
        // case 'FETCH_LEAVE_REQUEST_SUCCESS':
        //     return { ...state, leaverequeststatus: action.payload }
        // case 'FETCH_LEAVE_REQUEST_ERROR':
        //     return { ...state, leaverequeststatus: [] }

        // case 'FETCH_LEAVETYPE':
        //     return { ...state, leavetypevalues: [], leaverequest: '', updateleavestatus: '', deleteleavestatus: '', cancelleavestatus: '' }
        // case 'FETCH_LEAVETYPE_SUCCESS':
        //     return { ...state, leavetypevalues: action.payload }
        // case 'FETCH_LEAVETYPE_ERROR':
        //     return { ...state, leavetypevalues: [] }

        // case 'EDIT_LEAVETYPE':
        //     return { ...state, editleavevalues: [] }
        // case 'EDIT_LEAVETYPE_SUCCESS':
        //     return { ...state, editleavevalues: action.payload }
        // case 'EDIT_LEAVETYPE_ERROR':
        //     return { ...state, editleavevalues: [] }

        // case 'UPDATE_LEAVETYPE':
        //     return { ...state, updateleavestatus: [] }
        // case 'UPDATE_LEAVETYPE_SUCCESS':
        //     return { ...state, updateleavestatus: action.payload }
        // case 'UPDATE_LEAVETYPE_ERROR':
        //     return { ...state, updateleavestatus: [] }

        // case 'DELETE_LEAVETYPE':
        //     return { ...state, deleteleavestatus: [] }
        // case 'DELETE_LEAVETYPE_SUCCESS':
        //     return { ...state, deleteleavestatus: action.payload }
        // case 'DELETE_LEAVETYPE_ERROR':
        //     return { ...state, deleteleavestatus: [] }

        // case 'CANCEL_LEAVEREQUEST':
        //     return { ...state, cancelleavestatus: [] }
        // case 'CANCEL_LEAVEREQUEST_SUCCESS':
        //     return { ...state, cancelleavestatus: action.payload }
        // case 'CANCEL_LEAVEREQUEST_ERROR':
        //     return { ...state, cancelleavestatus: [] }

        // case 'UPDATE_STATUS':
        //     return { ...state, updaterequeststatus: [] }
        // case 'UPDATE_STATUS_SUCCESS':
        //     return { ...state, updaterequeststatus: action.payload }
        // case 'UPDATE_STATUS_ERROR':
        //     return { ...state, updaterequeststatus: [] }

        // //  leave approval
        // case 'FETCH_REQUEST':
        //     return { ...state, requestStatus: [] }
        // case 'FETCH_REQUEST_SUCCESS':
        //     return { ...state, requestStatus: action.payload }
        // case 'FETCH_REQUEST_ERROR':
        //     return { ...state, requestStatus: [] }

        // case 'APPROVE_REQUEST':
        //     return { ...state, approveStatus: [] }
        // case 'APPROVE_REQUEST_SUCCESS':
        //     return { ...state, approveStatus: action.payload }
        // case 'APPROVE_REQUEST_ERROR':
        //     return { ...state, approveStatus: [] }

        // case 'REJECT_REQUEST':
        //     return { ...state, rejectStatus: [] }
        // case 'REJECT_REQUEST_SUCCESS':
        //     return { ...state, rejectStatus: action.payload }
        // case 'REJECT_REQUEST_ERROR':
        //     return { ...state, rejectStatus: [] }


        default:
            return state
    }
}
export default combineReducers({
    shiftlist: shiftReducer

});