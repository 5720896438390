import axios from '@service/ApiClient'

class itemService {
    constructor(callback) {
        this.callback = callback
    }
    async saveItem(payload) {
        return await axios.post('Inventory/Item/add_item', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listItem(payload) {
        // console.log("vinishith",payload);
        return await axios.post('Inventory/Item/list_items',payload
        
        ).then((response) => {
            // console.log("response",response);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteItem(payload) {
        return await axios.post('Inventory/Item/delete_item',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateItem(payload) {
        return await axios.post('Inventory/Item/update_item',payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listCategory(payload) {
        return await axios.post('/Inventory/Item_category/list_item_parent_category',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listSubCategory(payload) {
        return await axios.post('/Inventory/Item_category/list_subcategory',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listBrand(payload) {
        return await axios.post('/Inventory/Item_brand/list_item_brands',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchItemByID(payload) {
        return await axios.post('/Inventory/Item/edit_item',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async getitemByCategoryId(payload) {
        return await axios.post('/Inventory/Item/get_items_by_category_id',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async importitem(payload) {
        return await axios.post('/Inventory/Item/import_item',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new itemService();