import { actionTypes } from './action';

export const initialState = {
    saveStatus: '',
    deleteStatus: '',
    updateStatus: '',
    soList: [],
    storeList: [],
    doData: {}
}
const soReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case actionTypes.SAVE_DIRECT_INVOICE:
            return { ...state, saveStatus: ''}
        case actionTypes.SAVE_DIRECT_INVOICE_SUCCESS:
            return { ...state, saveStatus: action.payload }
        case actionTypes.SAVE_DIRECT_INVOICE_ERROR:
            return { ...state, saveStatus: 'error' }

        // case actionTypes.LIST_SO_FOR_DO:
        //     return { ...state, soList: [] }
        // case actionTypes.LIST_SO_FOR_DO_SUCCESS:
        //     return { ...state, soList: action.payload }
        // case actionTypes.LIST_SO_FOR_DO_ERROR:
        //     return { ...state, soList: [] }

        // case actionTypes.SAVE_INVOICE:
        //     return { ...state, saveStatus: '' }
        // case actionTypes.SAVE_INVOICE_SUCCESS:
        //     return { ...state, saveStatus: action.payload }
        // case actionTypes.SAVE_INVOICE_ERROR:
        //     return { ...state, saveStatus: 'error' }

        // case actionTypes.GET_BARCODE_STATUS_DO:
        //     return { ...state, storeList: [] }
        // case actionTypes.GET_BARCODE_STATUS_DO_SUCCESS:
        //     return { ...state, storeList: action.payload }
        // case actionTypes.GET_BARCODE_STATUS_DO_ERROR:
        //     return { ...state, storeList: [] }

        // case actionTypes.UPDATE_DO:
        //     return { ...state, updateStatus: '' }
        // case actionTypes.UPDATE_DO_SUCCESS:
        //     return { ...state, updateStatus: action.payload }
        // case actionTypes.UPDATE_DO_ERROR:
        //     return { ...state, updateStatus: 'error' }

        // case actionTypes.DELETE_DO:
        //     return { ...state, deleteStatus: '' }
        // case actionTypes.DELETE_DO_SUCCESS:
        //     return { ...state, deleteStatus: action.payload }
        // case actionTypes.DELETE_DO_ERROR:
        //     return { ...state, deleteStatus: 'error' }

       
        // case actionTypes.LIST_DO_BY_ID:
        //     return { ...state, doData: {} }
        // case actionTypes.LIST_DO_BY_ID_SUCCESS:
        //     return { ...state, doData: action.payload }
        // case actionTypes.LIST_DO_BY_ID_ERROR:
        //     return { ...state, doData: {} }

        default:
            return state
    }
}

export default soReducer
