import axios from '@service/ApiClient'
class employmentDetailsService {
    constructor(callback) {
        this.callback = callback
    }
    async saveEmploymentDetails(payload) {
        return await axios.post('/Crm/User/add_employee_employment', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listEmploymentDetails(payload) {
        return await axios.post('/Crm/User/list_employee_employement', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateEmploymentDetails(payload) {
        return await axios.post('/Crm/User/update_employee_employment', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteEmploymentDetails(payload) {
        return await axios.post('/Crm/User/delete_employee_employement', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

}

export default new employmentDetailsService();