
export const actionTypes = {
    
    LIST_SO_FOR_DO: 'LIST_SO_FOR_DO',
    LIST_SO_FOR_DO_SUCCESS: 'LIST_SO_FOR_DO_SUCCESS',
    LIST_SO_FOR_DO_ERROR: 'LIST_SO_FOR_DO_ERROR',
    
    SAVE_DO: 'SAVE_DO',
    SAVE_DO_SUCCESS: 'SAVE_DO_SUCCESS',
    SAVE_DO_ERROR: 'SAVE_DO_ERROR',
    
    GET_BARCODE_STATUS_DO: 'GET_BARCODE_STATUS_DO',
    GET_BARCODE_STATUS_DO_SUCCESS: 'GET_BARCODE_STATUS_DO_SUCCESS',
    GET_BARCODE_STATUS_DO_ERROR: 'GET_BARCODE_STATUS_DO_ERROR',

    LIST_DO: 'LIST_DO',
    LIST_DO_SUCCESS: 'LIST_DO_SUCCESS',
    LIST_DO_ERROR: 'LIST_DO_ERROR',

    LIST_DO_BY_ID: 'LIST_DO_BY_ID',
    LIST_DO_BY_ID_SUCCESS: 'LIST_DO_BY_ID_SUCCESS',
    LIST_DO_BY_ID_ERROR: 'LIST_DO_BY_ID_ERROR',

    UPDATE_DO: 'UPDATE_DO',
    UPDATE_DO_SUCCESS: 'UPDATE_DO_SUCCESS',
    UPDATE_DO_ERROR: 'UPDATE_DO_ERROR',

    DELETE_DO: 'DELETE_DO',
    DELETE_DO_SUCCESS: 'DELETE_DO_SUCCESS',
    DELETE_DO_ERROR: 'DELETE_DO_ERROR',
};


export const listSoForDo = (payload) => {
    console.log(payload);
    return {
        type: actionTypes.LIST_SO_FOR_DO, payload
    };
};
export const listSoForDoSuccess = (payload) => {
    return {
        type: actionTypes.LIST_SO_FOR_DO_SUCCESS,
        payload
    };
};
export const listSoForDoError = (payload) => {
    return {
        type: actionTypes.LIST_SO_FOR_DO_ERROR,
        payload
    };
};

export const saveDo = (payload) => {
    return {
        type: actionTypes.SAVE_DO, payload
    };
};
export const saveDoSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_DO_SUCCESS,
        payload
    };
};
export const saveDoError = (payload) => {
    return {
        type: actionTypes.SAVE_DO_ERROR,
        payload
    };
};

export const getBarcodeStatusDo = (payload) => {
    return {
        type: actionTypes.GET_BARCODE_STATUS_DO, payload
    };
};
export const getBarcodeStatusDoSuccess = (payload) => {
    return {
        type: actionTypes.GET_BARCODE_STATUS_DO_SUCCESS,
        payload
    };
};
export const getBarcodeStatusDoError = (payload) => {
    return {
        type: actionTypes.GET_BARCODE_STATUS_DO_ERROR,
        payload
    };
};

export const updateDo = (payload) => {
    console.log(payload);
    return {
        type: actionTypes.UPDATE_DO, payload
    };
};
export const updateDoSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_DO_SUCCESS,
        payload
    };
};
export const updateDoError = (payload) => {
    return {
        type: actionTypes.UPDATE_DO_ERROR,
        payload
    };
};

export const deleteDo = (payload) => {
    console.log(payload);
    return {
        type: actionTypes.DELETE_DO, payload
    };
};
export const deleteDoSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_DO_SUCCESS,
        payload
    };
};
export const deleteDoError = (payload) => {
    return {
        type: actionTypes.DELETE_DO_ERROR,
        payload
    };
};

export const listDo = (payload) => {
    return {
        type: actionTypes.LIST_DO, payload
    };
};
export const listDoSuccess = (payload) => {
    return {
        type: actionTypes.LIST_DO_SUCCESS,
        payload
    };
};
export const listDoError = (payload) => {
    return {
        type: actionTypes.LIST_DO_ERROR,
        payload
    };
};

export const fetchDoById = (payload) => {
    return {
        type: actionTypes.LIST_DO_BY_ID, payload
    };
};
export const fetchDoByIdSuccess = (payload) => {
    return {
        type: actionTypes.LIST_DO_BY_ID_SUCCESS,
        payload
    };
};
export const fetchDoByIdError = (payload) => {
    return {
        type: actionTypes.LIST_DO_BY_ID_ERROR,
        payload
    };
};