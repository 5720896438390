import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import parameterService from '@service/admin/payrollService/addParameterService';
import {
  saveParameterSuccess, saveParameterError,
  listParameterSuccess, listParameterError,
  updateParameterSuccess, updateParameterError,
  deleteParameterSuccess, deleteParameterError,
} from './action';

function* saveParameter({ payload }) {
  try {
    const data = yield call(parameterService.saveParameter, payload);

    yield put(saveParameterSuccess(data));
  } catch (error) {
    yield put(saveParameterError(error));
  } finally {
  }
}

function* listParameter({ payload }) {
  try {
    const data = yield call(parameterService.listParameter, payload);
    Array.isArray(data) ?
      yield put(listParameterSuccess(data)) :
      yield put(listParameterSuccess([]));
  } catch (error) {
    yield put(listParameterError(error));
  } finally {
  }
}

function* updateParameter({ payload }) {
  try {
    const data = yield call(parameterService.updateParameter, payload);

    yield put(updateParameterSuccess(data));
  } catch (error) {
    yield put(updateParameterError(error));
  } finally {
  }
}

function* deleteParameter({ payload }) {
  try {
    const data = yield call(parameterService.deleteParameter, payload);

    yield put(deleteParameterSuccess(data));
  } catch (error) {
    yield put(deleteParameterError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_PARAMETER, saveParameter)]);
  yield all([takeEvery(actionTypes.LIST_PARAMETER, listParameter)]);
  yield all([takeEvery(actionTypes.UPDATE_PARAMETER, updateParameter)]);
  yield all([takeEvery(actionTypes.DELETE_PARAMETER, deleteParameter)]);
}