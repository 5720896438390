
import { actionTypes } from './action';

export const initialState = {
    saveTaskValue: '',
    listTasks: [],
    listCompanyContactValue: [],
    saveTaskActiValue: '',
    listTaskActiValue: [],
    updateTaskActiValue: '',
    deleteTaskActiValue: '',
    listSalesTypeData: '',
    prepostPoneTask: '',
    completedTask: '',
    followUpTask: '',
    taskDetailsByID: {},
    updateTaskDetails: '',
    getApproveTask: '',
    deleteTask: '',
    approveTask: '',
    rejectTask: '',
}

const saveTaskReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_TASK:
            return { ...state, saveTaskValue: '' }
        case actionTypes.SAVE_TASK_SUCCESS:
            return { ...state, saveTaskValue: action.payload }
        case actionTypes.SAVE_TASK_ERROR:
            return { ...state, saveTaskValue: '' }

        case actionTypes.UPDATE_TASKDETAILS:
            return { ...state, updateTaskDetails: '' }
        case actionTypes.UPDATE_TASKDETAILS_SUCCESS:
            return { ...state, updateTaskDetails: action.payload }
        case actionTypes.UPDATE_TASKDETAILS_ERROR:
            return { ...state, updateTaskDetails: '' }

        case actionTypes.LIST_TASK:
            return { ...state, listTasks: [] }
        case actionTypes.LIST_TASK_SUCCESS:
            return { ...state, listTasks: action.payload }
        case actionTypes.LIST_TASK_ERROR:
            return { ...state, listTasks: [] }

        case actionTypes.LIST_COMPANYCONTACT:
            return { ...state, listCompanyContactValue: [] }
        case actionTypes.LIST_COMPANYCONTACT_SUCCESS:
            return { ...state, listCompanyContactValue: action.payload }
        case actionTypes.LIST_COMPANYCONTACT_ERROR:
            return { ...state, listCompanyContactValue: [] }

        case actionTypes.SAVE_TASKACTIVITY:
            return { ...state, saveTaskActiValue: '' }
        case actionTypes.SAVE_TASKACTIVITY_SUCCESS:
            return { ...state, saveTaskActiValue: action.payload }
        case actionTypes.SAVE_TASKACTIVITY_ERROR:
            return { ...state, saveTaskActiValue: '' }

        case actionTypes.LIST_TASKACTIVITY:
            return { ...state, listTaskActiValue: [] }
        case actionTypes.LIST_TASKACTIVITY_SUCCESS:
            return { ...state, listTaskActiValue: action.payload, saveTaskActiValue: '', updateTaskActiValue: '', deleteTaskActiValue: '' }
        case actionTypes.LIST_TASKACTIVITY_ERROR:
            return { ...state, listTaskActiValue: [] }

        case actionTypes.UPDATE_TASKACTIVITY:
            return { ...state, updateTaskActiValue: '' }
        case actionTypes.UPDATE_TASKACTIVITY_SUCCESS:
            return { ...state, updateTaskActiValue: action.payload }
        case actionTypes.UPDATE_TASKACTIVITY_ERROR:
            return { ...state, updateTaskActiValue: '' }

        case actionTypes.DELETE_TASKACTIVITY:
            return { ...state, deleteTaskActiValue: '' }
        case actionTypes.DELETE_TASKACTIVITY_SUCCESS:
            return { ...state, deleteTaskActiValue: action.payload }
        case actionTypes.DELETE_TASKACTIVITY_ERROR:
            return { ...state, deleteTaskActiValue: '' }

        case actionTypes.LIST_SALESTYPE_DATA:
            return { ...state, listSalesTypeData: [] }
        case actionTypes.LIST_SALESTYPE_DATA_SUCCESS:
            return { ...state, listSalesTypeData: action.payload }
        case actionTypes.LIST_SALESTYPE_DATA_ERROR:
            return { ...state, listSalesTypeData: [] }

        case actionTypes.PRE_POST_PONETASK:
            return { ...state, prepostPoneTask: '' }
        case actionTypes.PRE_POST_PONETASK_SUCCESS:
            return { ...state, prepostPoneTask: 'success' }
        case actionTypes.PRE_POST_PONETASK_ERROR:
            return { ...state, prepostPoneTask: 'error' }

        case actionTypes.COMPLETED_TASK_DETAILS:
            return { ...state, completedTask: '' }
        case actionTypes.COMPLETED_TASK_DETAILS_SUCCESS:
            return { ...state, completedTask: 'success' }
        case actionTypes.COMPLETED_TASK_DETAILS_ERROR:
            return { ...state, completedTask: 'error' }

        case actionTypes.FOLLOW_UP_TASK:
            return { ...state, followUpTask: '' }
        case actionTypes.FOLLOW_UP_TASK_SUCCESS:
            return { ...state, followUpTask: 'success' }
        case actionTypes.FOLLOW_UP_TASK_ERROR:
            return { ...state, followUpTask: 'error' }

        case actionTypes.GET_TASKDETAILS_BY_ID:
            return { ...state, taskDetailsByID: {} }
        case actionTypes.GET_TASKDETAILS_BY_ID_SUCCESS:
            return { ...state, taskDetailsByID: action.payload }
        case actionTypes.GET_TASKDETAILS_BY_ID_ERROR:
            return { ...state, taskDetailsByID: {} }

        case actionTypes.GET_APPROVE_TASKDETAILS:
            return { ...state, getApproveTask: '' }
        case actionTypes.GET_APPROVE_TASKDETAILS_SUCCESS:
            return { ...state, getApproveTask: action.payload }
        case actionTypes.GET_APPROVE_TASKDETAILS_ERROR:
            return { ...state, getApproveTask: '' }

        case actionTypes.DELETE_TASKDETAILS:
            return { ...state, deleteTask: '' }
        case actionTypes.DELETE_TASKDETAILS_SUCCESS:
            return { ...state, deleteTask: action.payload }
        case actionTypes.DELETE_TASKDETAILS_ERROR:
            return { ...state, deleteTask: '' }

        case actionTypes.APPROVE_TASKDETAILS:
            return { ...state, approveTask: '' }
        case actionTypes.APPROVE_TASKDETAILS_SUCCESS:
            return { ...state, approveTask: action.payload }
        case actionTypes.APPROVE_TASKDETAILS_ERROR:
            return { ...state, approveTask: '' }

        case actionTypes.REJECT_TASKDETAILS:
            return { ...state, rejectTask: '' }
        case actionTypes.REJECT_TASKDETAILS_SUCCESS:
            return { ...state, rejectTask: 'success' }
        case actionTypes.REJECT_TASKDETAILS_ERROR:
            return { ...state, rejectTask: 'error' }

        default:
            return state
    }
}

export default saveTaskReducer