import { actionTypes } from './action';

export const initialState = {
    saveStatus: '',
    deleteStatus: '',
    updateStatus: '',
    dealList: [],
    quoteList: [],
    stageList: [],
    quoteData: {},
    termsAndCondition: {}
}
const dealReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LIST_DEAL_FOR_QUOTE:
            return { ...state, dealList: [] }
        case actionTypes.LIST_DEAL_FOR_QUOTE_SUCCESS:
            return { ...state, dealList: action.payload }
        case actionTypes.LIST_DEAL_FOR_QUOTE_ERROR:
            return { ...state, dealList: [] }

        case actionTypes.SAVE_QUOTE:
            return { ...state, saveStatus: '' }
        case actionTypes.SAVE_QUOTE_SUCCESS:
            return { ...state, saveStatus: action.payload }
        case actionTypes.SAVE_QUOTE_ERROR:
            return { ...state, saveStatus: 'error' }

        case actionTypes.LIST_QUOTE:
            return { ...state, quoteList: [], saveStatus: '', deleteStatus: '', updateStatus: '' }
        case actionTypes.LIST_QUOTE_SUCCESS:
            return { ...state, quoteList: action.payload }
        case actionTypes.LIST_QUOTE_ERROR:
            return { ...state, quoteList: [] }

        case actionTypes.LIST_QUOTE_BY_ID:
            return { ...state, quoteData: {} }
        case actionTypes.LIST_QUOTE_BY_ID_SUCCESS:
            return { ...state, quoteData: action.payload }
        case actionTypes.LIST_QUOTE_BY_ID_ERROR:
            return { ...state, quoteData: {} }

        case actionTypes.UPDATE_QUOTE:
            return { ...state, updateStatus: '' }
        case actionTypes.UPDATE_QUOTE_SUCCESS:
            return { ...state, updateStatus: action.payload }
        case actionTypes.UPDATE_QUOTE_ERROR:
            return { ...state, updateStatus: 'error' }

        case actionTypes.GET_TERMS_AND_CONDITION:
            return { ...state, termsAndCondition: {} }
        case actionTypes.GET_TERMS_AND_CONDITION_SUCCESS:
            return { ...state, termsAndCondition: action.payload }
        case actionTypes.GET_TERMS_AND_CONDITION_ERROR:
            return { ...state, termsAndCondition: {} }

        case actionTypes.DELETE_QUOTE:
            return { ...state, deleteStatus: '' }
        case actionTypes.DELETE_QUOTE_SUCCESS:
            return { ...state, deleteStatus: action.payload }
        case actionTypes.DELETE_QUOTE_ERROR:
            return { ...state, deleteStatus: 'error' }

        default:
            return state
    }
}

export default dealReducer
