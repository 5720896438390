
export const actionTypes = {
    SAVE_HOLIDAY: 'SAVE_HOLIDAY',
    SAVE_HOLIDAY_SUCCESS: 'SAVE_HOLIDAY_SUCCESS',
    SAVE_HOLIDAY_ERROR: 'SAVE_HOLIDAY_ERROR',
    LIST_HOLIDAY: 'LIST_HOLIDAY',
    LIST_HOLIDAY_SUCCESS: 'LIST_HOLIDAY_SUCCESS',
    LIST_HOLIDAY_ERROR: 'LIST_HOLIDAY_ERROR',
    UPDATE_HOLIDAY: 'UPDATE_HOLIDAY',
    UPDATE_HOLIDAY_SUCCESS: 'UPDATE_HOLIDAY_SUCCESS',
    UPDATE_HOLIDAY_ERROR: 'UPDATE_HOLIDAY_ERROR',
    DELETE_HOLIDAY: 'DELETE_HOLIDAY',
    DELETE_HOLIDAY_SUCCESS: 'DELETE_HOLIDAY_SUCCESS',
    DELETE_HOLIDAY_ERROR: 'DELETE_HOLIDAY_ERROR',
    SELECT_EVENTS: 'SELECT_EVENTS',
    SELECT_EVENTS_CLEAR: 'SELECT_EVENTS_CLEAR',
};

export const saveHoliday = (payload) => {
    return {
        type: actionTypes.SAVE_HOLIDAY, payload
    };
};
export const saveHolidaySuccess = (payload) => {
    return {
        type: actionTypes.SAVE_HOLIDAY_SUCCESS,
        payload
    };
};
export const saveHolidayError = (payload) => {
    return {
        type: actionTypes.SAVE_HOLIDAY_ERROR,
        payload
    };
};

export const selectEvent = (payload) => {
    return {
        type: actionTypes.SELECT_EVENTS, payload
    };
};

export const selectEventClear = (payload) => {
    return {
        type: actionTypes.SELECT_EVENTS_CLEAR,
        payload
    };
};

export const listHoliday = (payload) => {
    return {
        type: actionTypes.LIST_HOLIDAY, payload
    };
};
export const listHolidaySuccess = (payload) => {
    return {
        type: actionTypes.LIST_HOLIDAY_SUCCESS,
        payload
    };
};
export const listHolidayError = (payload) => {
    return {
        type: actionTypes.LIST_HOLIDAY_ERROR,
        payload
    };
};

export const updateHoliday = (payload) => {
    return {
        type: actionTypes.UPDATE_HOLIDAY, payload
    };
};
export const updateHolidaySuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_HOLIDAY_SUCCESS,
        payload
    };
};
export const updateHolidayError = (payload) => {
    return {
        type: actionTypes.UPDATE_HOLIDAY_ERROR,
        payload
    };
};

export const deleteHoliday = (payload) => {
    return {
        type: actionTypes.DELETE_HOLIDAY, payload
    };
};
export const deleteHolidaySuccess = (payload) => {
    return {
        type: actionTypes.DELETE_HOLIDAY_SUCCESS,
        payload
    };
};
export const deleteHolidayError = (payload) => {
    return {
        type: actionTypes.DELETE_HOLIDAY_ERROR,
        payload
    };
};
