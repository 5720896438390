
export const actionTypes = {

    LIST_INDUSTRIES: 'LIST_INDUSTRIES',
    LIST_INDUSTRIES_SUCCESS: 'LIST_INDUSTRIES_SUCCESS',
    LIST_INDUSTRIES_ERROR: 'LIST_INDUSTRIES_ERROR',

    SAVE_LEADS: 'SAVE_LEADS',
    SAVE_LEADS_SUCCESS: 'SAVE_LEADS_SUCCESS',
    SAVE_LEADS_ERROR: 'SAVE_LEADS_ERROR',

    LIST_LEADS: 'LIST_LEADS',
    LIST_LEADS_SUCCESS: 'LIST_LEADS_SUCCESS',
    LIST_LEADS_ERROR: 'SAVE_LEADSLIST_LEADS_ERROR',

    UPDATE_LEADS: 'UPDATE_LEADS',
    UPDATE_LEADS_SUCCESS: 'UPDATE_LEADS_SUCCESS',
    UPDATE_LEADS_ERROR: 'SAVE_LEADSUPDATE_LEADS_ERROR',

    DELETE_LEADS: 'DELETE_LEADS',
    DELETE_LEADS_SUCCESS: 'DELETE_LEADS_SUCCESS',
    DELETE_LEADS_ERROR: 'DELETE_LEADS_ERROR',

    FETCH_LEADS_BY_ID: 'FETCH_LEADS_BY_ID',
    FETCH_LEADS_BY_ID_SUCCESS: 'FETCH_LEADS_BY_ID_SUCCESS',
    FETCH_LEADS_BY_ID_ERROR: 'FETCH_LEADS_BY_ID_ERROR',

    FETCH_ADDRESS_LEADS_BY_ID: 'FETCH_ADDRESS_LEADS_BY_ID',
    FETCH_ADDRESS_LEADS_BY_ID_SUCCESS: 'FETCH_ADDRESS_LEADS_BY_ID_SUCCESS',
    FETCH_ADDRESS_LEADS_BY_ID_ERROR: 'FETCH_ADDRESS_LEADS_BY_ID_ERROR',

    CONVERT_LEAD: 'CONVERT_LEAD',
    CONVERT_LEAD_SUCCESS: 'CONVERT_LEAD_SUCCESS',
    CONVERT_LEAD_ERROR: 'CONVERT_LEAD_ERROR',
};

export const listIndustries = (payload) => {
    return {
        type: actionTypes.LIST_INDUSTRIES, payload
    };
};
export const listIndustriesSuccess = (payload) => {
    return {
        type: actionTypes.LIST_INDUSTRIES_SUCCESS,
        payload
    };
};
export const listIndustriesError = (payload) => {
    return {
        type: actionTypes.LIST_INDUSTRIES_ERROR,
        payload
    };
};

export const saveLeads = (payload) => {
    return {
        type: actionTypes.SAVE_LEADS,
        payload
    };
};
export const saveLeadsSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_LEADS_SUCCESS,
        payload
    };
};
export const saveLeadsError = (payload) => {
    return {
        type: actionTypes.SAVE_LEADS_ERROR,
        payload
    };
};

export const updateLeads = (payload) => {
    return {
        type: actionTypes.UPDATE_LEADS,
        payload
    };
};
export const updateLeadsSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_LEADS_SUCCESS,
        payload
    };
};
export const updateLeadsError = (payload) => {
    return {
        type: actionTypes.UPDATE_LEADS_ERROR,
        payload
    };
};

export const deleteLeads = (payload) => {
    return {
        type: actionTypes.DELETE_LEADS,
        payload
    };
};
export const deleteLeadsSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_LEADS_SUCCESS,
        payload
    };
};
export const deleteLeadsError = (payload) => {
    return {
        type: actionTypes.DELETE_LEADS_ERROR,
        payload
    };
};

export const listLeads = (payload) => {
    return {
        type: actionTypes.LIST_LEADS, payload
    };
};
export const listLeadsSuccess = (payload) => {
    return {
        type: actionTypes.LIST_LEADS_SUCCESS,
        payload
    };
};
export const listLeadsError = (payload) => {
    return {
        type: actionTypes.LIST_LEADS_ERROR,
        payload
    };
};

export const fetchLeadsById = (payload) => {
    return {
        type: actionTypes.FETCH_LEADS_BY_ID, payload
    };
};
export const fetchLeadsByIdSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LEADS_BY_ID_SUCCESS,
        payload
    };
};
export const fetchLeadsByIdError = (payload) => {
    return {
        type: actionTypes.FETCH_LEADS_BY_ID_ERROR,
        payload
    };
};

export const fetchAddressLeadsById = (payload) => {
    return {
        type: actionTypes.FETCH_ADDRESS_LEADS_BY_ID, payload
    };
};
export const fetchAddressLeadsByIdSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_ADDRESS_LEADS_BY_ID_SUCCESS,
        payload
    };
};
export const fetchAddressLeadsByIdError = (payload) => {
    return {
        type: actionTypes.FETCH_ADDRESS_LEADS_BY_ID_ERROR,
        payload
    };
};

export const convertLead = (payload) => {
    return {
        type: actionTypes.CONVERT_LEAD, payload
    };
};
export const convertLeadSuccess = (payload) => {
    return {
        type: actionTypes.CONVERT_LEAD_SUCCESS,
        payload
    };
};
export const convertLeadError = (payload) => {
    return {
        type: actionTypes.CONVERT_LEAD_ERROR,
        payload
    };
};

export const fetchDashboardData = (payload) => {
    return {
        type: 'FETCH_DASHBOARD_DATA', payload
    };
};
export const fetchDashboardDataSuccess = (payload) => {
    return {
        type: 'FETCH_DASHBOARD_DATA_SUCCESS',
        payload
    };
};
export const fetchDashboardDataError = (payload) => {
    return {
        type: 'FETCH_DASHBOARD_DATA_ERROR',
        payload
    };
};

export const fetchDashboardFeeds = (payload) => {
    return {
        type: 'FETCH_DASHBOARD_FEEDS', payload
    };
};
export const fetchDashboardFeedsSuccess = (payload) => {
    return {
        type: 'FETCH_DASHBOARD_FEEDS_SUCCESS',
        payload
    };
};
export const fetchDashboardFeedsError = (payload) => {
    return {
        type: 'FETCH_DASHBOARD_FEEDS_ERROR',
        payload
    };
};

export const fetchCurrentUserTask = (payload) => {
    return {
        type: 'FETCH_CURRENT_USER_TASK', payload
    };
};
export const fetchCurrentUserTaskSuccess = (payload) => {
    return {
        type: 'FETCH_CURRENT_USER_TASK_SUCCESS',
        payload
    };
};
export const fetchCurrentUserTaskError = (payload) => {
    return {
        type: 'FETCH_CURRENT_USER_TASK_ERROR',
        payload
    };
};

export const fetchTodayTasks = (payload) => {
    return {
        type: 'FETCHTODAY_TASKS', payload
    };
};
export const fetchTodayTasksSuccess = (payload) => {
    return {
        type: 'FETCHTODAY_TASKS_SUCCESS',
        payload
    };
};
export const fetchTodayTasksError = (payload) => {
    return {
        type: 'FETCHTODAY_TASKS_ERROR',
        payload
    };
};

export const fetchTasksByDate = (payload) => {
    return {
        type: 'FETCH_TASKS_BYDATE', payload
    };
};
export const fetchTasksByDateSuccess = (payload) => {
    return {
        type: 'FETCH_TASKS_BYDATE_SUCCESS',
        payload
    };
};
export const fetchTasksByDateError = (payload) => {
    return {
        type: 'FETCH_TASKS_BYDATE_ERROR',
        payload
    };
};

export const fetchupcomingTasks = (payload) => {
    return {
        type: 'FETCH_UPCOMING_TASKS', payload
    };
};
export const fetchupcomingTasksSuccess = (payload) => {
    return {
        type: 'FETCH_UPCOMING_TASKS_SUCCESS',
        payload
    };
};
export const fetchupcomingTasksError = (payload) => {
    return {
        type: 'FETCH_UPCOMING_TASKS_ERROR',
        payload
    };
};

export const fetchTeamMemberTasks = (payload) => {
    return {
        type: 'FETCH_TEAM_MEMBER_TASKS', payload
    };
};
export const fetchTeamMemberTasksSuccess = (payload) => {
    return {
        type: 'FETCH_TEAM_MEMBER_TASKS_SUCCESS',
        payload
    };
};
export const fetchTeamMemberTasksError = (payload) => {
    return {
        type: 'FETCH_TEAM_MEMBER_TASKS_ERROR',
        payload
    };
};