import { actionTypes } from './action';


export const initialState = {
    saveStatus: '',
    listStatus: [],
    updateStatus: '',
    deleteStatus: '',
    taskstatus: [],
    saveExpensemodule: [],
    listExpensemodule: [],
    deleteExpensemodule: [],
    editExpensemodule: [],
    updateExpensemodule: []
}

const expenseReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_EXPENSE:
            return { ...state, saveStatus: '' }
        case actionTypes.SAVE_EXPENSE_SUCCESS:
            return { ...state, saveStatus: action.payload }
        case actionTypes.SAVE_EXPENSE_ERROR:
            return { ...state, saveStatus: 'error' }

        case actionTypes.LIST_EXPENSE:
            return { ...state, listStatus: [] }
        case actionTypes.LIST_EXPENSE_SUCCESS:
            return { ...state, listStatus: action.payload }
        case actionTypes.LIST_EXPENSE_ERROR:
            return { ...state, listStatus: [] }

        case actionTypes.UPDATE_EXPENSE:
            return { ...state, updateStatus: '' }
        case actionTypes.UPDATE_EXPENSE_SUCCESS:
            return { ...state, updateStatus: 'success' }
        case actionTypes.UPDATE_EXPENSE_ERROR:
            return { ...state, updateStatus: '' }

        case actionTypes.DELETE_EXPENSE:
            return { ...state, deleteStatus: '', saveStatus: '', deleteStatus: '', updateStatus: '' }
        case actionTypes.DELETE_EXPENSE_SUCCESS:
            return { ...state, deleteStatus: action.payload }
        case actionTypes.DELETE_EXPENSE_ERROR:
            return { ...state, deleteStatus: 'error' }



        case actionTypes.FETCH_TASK:
            return { ...state, taskstatus: [] }
        case actionTypes.FETCH_TASK_SUCCESS:
            return { ...state, taskstatus: action.payload }
        case actionTypes.FETCH_TASK_ERROR:
            return { ...state, taskstatus: [] }

        case actionTypes.SAVE_EXPENSEMODULE:
            return { ...state, saveExpensemodule: '', }
        case actionTypes.SAVE_EXPENSEMODULE_SUCCESS:
            return { ...state, saveExpensemodule: action.payload }
        case actionTypes.SAVE_EXPENSEMODULE_ERROR:
            return { ...state, saveExpensemodule: 'error' }

        case actionTypes.LIST_EXPENSEMODULE:
            return { ...state, listExpensemodule: '', }
        case actionTypes.LIST_EXPENSEMODULE_SUCCESS:
            return { ...state, listExpensemodule: action.payload }
        case actionTypes.LIST_EXPENSEMODULE_ERROR:
            return { ...state, listExpensemodule: 'error' }

        case actionTypes.DELETE_EXPENSEMODULE:
            return { ...state, deleteExpensemodule: '', }
        case actionTypes.DELETE_EXPENSEMODULE_SUCCESS:
            return { ...state, deleteExpensemodule: action.payload }
        case actionTypes.DELETE_EXPENSEMODULE_ERROR:
            return { ...state, deleteExpensemodule: 'error' }

        case actionTypes.EDIT_EXPENSEMODULE:
            return { ...state, editExpensemodule: '', }
        case actionTypes.EDIT_EXPENSEMODULE_SUCCESS:
            return { ...state, editExpensemodule: action.payload }
        case actionTypes.EDIT_EXPENSEMODULE_ERROR:
            return { ...state, editExpensemodule: 'error' }

        case actionTypes.UPDATE_EXPENSEMODULE:
            return { ...state, updateExpensemodule: '', }
        case actionTypes.UPDATE_EXPENSEMODULE_SUCCESS:
            return { ...state, updateExpensemodule: action.payload }
        case actionTypes.UPDATE_EXPENSEMODULE_ERROR:
            return { ...state, updateExpensemodule: 'error' }

        default:
            return state
    }
}

export default expenseReducer
