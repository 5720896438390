import axios from '@service/ApiClient'
// import { siteUrl } from './config'

class termsAndConditionsService {
    constructor(callback) {
        this.callback = callback
    }
    async updateTerms(payload) {
        return await axios.post('/Inventory/Termsandconditions/update_termsandconditions', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listTerms(payload) {
        return await axios.post('/Inventory/Termsandconditions/fetch_termsandconditions', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new termsAndConditionsService();