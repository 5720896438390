import { takeEvery, put, call, all } from 'redux-saga/effects';
import AuthenticationService from '@service/AuthenticationService';
import {
  handleLoginSuccess, handleLoginError,
  handleLogoutSuccess, handleLogoutError,
  handleSignUpError, handleSignUpSuccess,
  verifyEmailAuthSuccess, verifyEmailAuthError,
  getMenuListsSuccess, getMenuListsError,
  saveCredentialSuccess, saveCredentialError,
  verifyUserEmailAuthSuccess, verifyUserEmailAuthError,
  savePushNotificationTokenSucess, savePushNotificationTokenError, savePushNotificationToken
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBjN3Jp2L8oAePy-DBziNFRUPgHdoTsxWQ",
  authDomain: "eassets-622fc.firebaseapp.com",
  projectId: "eassets-622fc",
  storageBucket: "eassets-622fc.appspot.com",
  messagingSenderId: "905000510369",
  appId: "1:905000510369:web:b875fa303aedbb0d4e61e0",
  measurementId: "G-KX13QPP3CQ"
};

// initializseApp(firebaseConfig);

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const currentToken = localStorage.getItem('firebase_tocken')

function* handleLogin({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(AuthenticationService.handleLogin, payload);
    if (data && data.data && data.status) {
      console.log('data1', data);
      yield put(handleLoginSuccess(data.data));
      console.log('data2', data);
      yield put(savePushNotificationToken({
        business_unit_id: data.data.business_unit_id,
        organization_id: data.data.organization_id,
        pin: data.data.pin,
        role: data.data.emp_role,
        token_number: currentToken,
      }));
      console.log('data3', data);

    } else if (data === 0) {
      yield put(handleLoginError(data))
    } else {
      yield put(handleLoginError('error'));
    }
  } catch (error) {
    yield put(handleLoginError(error));
  } finally {
    yield put(stopLoader());
  }
}
// function* handleLogin({ payload }) {
//   try {
//     yield put(startLoader());
//     const data = yield call(AuthenticationService.handleLogin, payload);
//     if (data && data.data && data.status) {
//       yield put(handleLoginSuccess(data.data));
//     } else if (data === 0) {
//       yield put(handleLoginError(data))
//     } else {
//       yield put(handleLoginError('error'));
//     }
//   } catch (error) {
//     yield put(handleLoginError(error));
//   } finally {
//     yield put(stopLoader());
//   }
// }

function* handleLogout() {
  try {
    localStorage.removeItem('auth');
  } catch (error) {
  } finally {
  }
}

function* handleSignUp({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(AuthenticationService.handleSignUp, payload);
    if (data == 1) {
      yield put(handleSignUpSuccess(data));
    } else {
      yield put(handleSignUpSuccess(0));
    }
  } catch (error) {
    yield put(handleSignUpError(0));
  } finally {
    yield put(stopLoader());
  }
}

function* verifyEmailAuth({ payload }) {
  try {
    yield put(startLoader());
    const result = yield call(AuthenticationService.verifyEmailAuth, payload);
    yield put(verifyEmailAuthSuccess(result));
  } catch (error) {
    yield put(verifyEmailAuthError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* setCredential({ payload }) {
  try {
    yield put(startLoader());
    const result = yield call(AuthenticationService.setCredential, payload);
    yield put(saveCredentialSuccess(result));
  } catch (error) {
    yield put(saveCredentialError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* verifyUserEmailAuth({ payload }) {
  try {
    yield put(startLoader());
    const result = yield call(AuthenticationService.verifyUserEmailAuth, payload);
    yield put(verifyUserEmailAuthSuccess(result));
  } catch (error) {
    yield put(verifyUserEmailAuthError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* getMenuLists({ payload }) {
  try {
    yield put(startLoader());
    const result = yield call(AuthenticationService.getMenuLists, payload);
    yield put(getMenuListsSuccess(result));
  } catch (error) {
    yield put(getMenuListsError(error));
  } finally {
    yield put(stopLoader());
  }
}
function* savePushNotificationTokenSaga({ payload }) {
  try {
    yield put(startLoader());
    console.log("notificationAll payload", payload);
    const result = yield call(AuthenticationService.savePushNotificationToken, payload);
    console.log("notificationAll result", result);
    yield put(savePushNotificationTokenSucess(result));
  } catch (error) {
    yield put(savePushNotificationTokenError(error));
  } finally {
    yield put(stopLoader());
  }
}


// function* savePushNotificationTokenSaga({ payload }) {
//   try {
//     yield put(startLoader());
//     console.log("notificationAll saga", payload);
//     const data = yield call(AuthenticationService.Saga, payload);
//     console.log("notificationAll data saga", data);
//     yield put(savePushNotificationTokenSucess(data));
//   } catch (error) {
//     yield put(savePushNotificationTokenError(error));
//   }
//   finally {
//     yield put(stopLoader([]));
//   }
// }
export default function* rootSaga() {
  yield all([takeEvery('LOGIN', handleLogin)]);
  yield all([takeEvery('LOGOUT', handleLogout)]);
  yield all([takeEvery('SIGNUP', handleSignUp)]);
  yield all([takeEvery('VERIFY_EMAIL_AUTH', verifyEmailAuth)]);
  yield all([takeEvery('SAVE_CREDENTIAL', setCredential)]);
  yield all([takeEvery('VERIFY_USER_EMAIL_AUTH', verifyUserEmailAuth)]);
  yield all([takeEvery('GET_MENU_LISTS', getMenuLists)]);
  yield all([takeEvery('SAVE_PUSHNOTIFICATION_TOKEN', savePushNotificationTokenSaga)]);

}
