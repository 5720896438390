export const actionTypes = {
    
    COMPLAINT_REPORT: 'COMPLAINT_REPORT',
    COMPLAINT_REPORT_SUCCESS: 'COMPLAINT_REPORT_SUCCESS',
    COMPLAINT_REPORT_ERROR: 'COMPLAINT_REPORT_ERROR',

    SPARE_PARTS_REPORT: 'SPARE_PARTS_REPORT',
    SPARE_PARTS_REPORT_SUCCESS: 'SPARE_PARTS_REPORT_SUCCESS',
    SPARE_PARTS_REPORT_ERROR: 'SPARE_PARTS_REPORT_ERROR',

    AMC_EXPIRY_REPORT: 'AMC_EXPIRY_REPORT',
    AMC_EXPIRY_REPORT_SUCCESS: 'AMC_EXPIRY_REPORT_SUCCESS',
    AMC_EXPIRY_REPORT_ERROR: 'AMC_EXPIRY_REPORT_ERROR',

    DEPARTMENT_SUMMARY_REPORT: 'DEPARTMENT_SUMMARY_REPORT',
    DEPARTMENT_SUMMARY_REPORT_SUCCESS: 'DEPARTMENT_SUMMARY_REPORT_SUCCESS',
    DEPARTMENT_SUMMARY_REPORT_ERROR: 'DEPARTMENT_SUMMARY_REPORT_ERROR',

    EQUIPMENT_AUDIT_REPORT: 'EQUIPMENT_AUDIT_REPORT',
    EQUIPMENT_AUDIT_REPORT_SUCCESS: 'EQUIPMENT_AUDIT_REPORT_SUCCESS',
    EQUIPMENT_AUDIT_REPORT_ERROR: 'EQUIPMENT_AUDIT_REPORT_ERROR',
    
    LIST_EQUIPMENT: 'LIST_EQUIPMENT',
    LIST_EQUIPMENT_SUCCESS: 'LIST_EQUIPMENT_SUCCESS',
    LIST_EQUIPMENT_ERROR: 'LIST_EQUIPMENT_ERROR',

    EQUIPMENT_CATEGORY: 'EQUIPMENT_CATEGORY',
    EQUIPMENT_CATEGORY_SUCCESS: 'EQUIPMENT_CATEGORY_SUCCESS',
    EQUIPMENT_CATEGORY_ERROR: 'EQUIPMENT_CATEGORY_ERROR',

    EQUIPMENT_LOCATION: 'EQUIPMENT_LOCATION',
    EQUIPMENT_LOCATION_SUCCESS: 'EQUIPMENT_LOCATION_SUCCESS',
    EQUIPMENT_LOCATION_ERROR: 'EQUIPMENT_LOCATION_ERROR',
};

export const complaintreport = (payload) => {
    console.log(payload);
    return {
        type: actionTypes.COMPLAINT_REPORT, payload
    };
};
export const complaintreportSuccess = (payload) => {
    return {
        type: actionTypes.COMPLAINT_REPORT_SUCCESS,
        payload
    };
};
export const complaintreportError = (payload) => {
    return {
        type: actionTypes.COMPLAINT_REPORT_ERROR,
        payload
    };
};

export const sparepartsreport =( payload) =>{
    return{
        type:actionTypes.SPARE_PARTS_REPORT,payload
    };
};
export const sparepartsreportSuccess =( payload) =>{
    return{
        type:actionTypes.SPARE_PARTS_REPORT_SUCCESS,payload
    };
};
export const sparepartsreportError =( payload) =>{
    return{
        type:actionTypes.SPARE_PARTS_REPORT_ERROR,payload
    };
};


export const amcexpiryreport =( payload) =>{
    return{
        type:actionTypes.AMC_EXPIRY_REPORT,payload
    };
};
export const amcexpiryreportSuccess =( payload) =>{
    return{
        type:actionTypes.AMC_EXPIRY_REPORT_SUCCESS,payload
    };
};
export const amcexpiryreportError =( payload) =>{
    return{
        type:actionTypes.AMC_EXPIRY_REPORT_ERROR,payload
    };
};


export const departmentsummaryreport =( payload) =>{
    return{
        type:actionTypes.DEPARTMENT_SUMMARY_REPORT,payload
    };
};
export const departmentsummaryreportSuccess =( payload) =>{
    return{
        type:actionTypes.DEPARTMENT_SUMMARY_REPORT_SUCCESS,payload
    };
};
export const departmentsummaryreportError =( payload) =>{
    return{
        type:actionTypes.DEPARTMENT_SUMMARY_REPORT_ERROR,payload
    };
};

export const equipmentauditreport =( payload) =>{
    return{
        type:actionTypes.EQUIPMENT_AUDIT_REPORT,payload
    };
};
export const equipmentauditreportSuccess =( payload) =>{
    return{
        type:actionTypes.EQUIPMENT_AUDIT_REPORT_SUCCESS,payload
    };
};
export const equipmentauditreportError =( payload) =>{
    return{
        type:actionTypes.EQUIPMENT_AUDIT_REPORT_ERROR,payload
    };
};



export const listEquipment = (payload) => {
    console.log(payload);
    return {
        type: actionTypes.LIST_EQUIPMENT, payload
    };
};
export const listEquipmentSuccess = (payload) => {
    return {
        type: actionTypes.LIST_EQUIPMENT_SUCCESS,
        payload
    };
};
export const listEquipmentError = (payload) => {
    return {
        type: actionTypes.LIST_EQUIPMENT_ERROR,
        payload
    };
};
export const Equipmentcategory = (payload) => {
    console.log(payload);
    return {
        type: actionTypes.EQUIPMENT_CATEGORY, payload
    };
};
export const EquipmentcategorySuccess = (payload) => {
    return {
        type: actionTypes.EQUIPMENT_CATEGORY_SUCCESS,
        payload
    };
};
export const EquipmentcategoryError = (payload) => {
    return {
        type: actionTypes.EQUIPMENT_CATEGORY_ERROR,
        payload
    };
};

export const Equipmentlocation = (payload) => {
    console.log(payload);
    return {
        type: actionTypes.EQUIPMENT_LOCATION, payload
    };
};
export const EquipmentlocationSuccess = (payload) => {
    return {
        type: actionTypes.EQUIPMENT_LOCATION_SUCCESS,
        payload
    };
};
export const EquipmentlocationError = (payload) => {
    return {
        type: actionTypes.EQUIPMENT_LOCATION_ERROR,
        payload
    };
};

// external dn report
export const ExternalDnReport = (payload) => {
    return {
        type: 'EXTERNAL_DN_REPORT',
        payload
    };
};
export const ExternalDnReportSuccess = (payload) => {
    return {
        type: 'EXTERNAL_DN_REPORT_SUCCESS',
        payload
    };
};
export const ExternalDnReportError = (payload) => {
    return {
        type: 'EXTERNAL_DN_REPORT_ERROR',
        payload
    };
};
// external rn repport
export const ExternalRnReport = (payload) => {
    return {
        type: 'EXTERNAL_RN_REPORT',
        payload
    };
};
export const ExternalRnReportSuccess = (payload) => {
    return {
        type: 'EXTERNAL_RN_REPORT_SUCCESS',
        payload
    };
};
export const ExternalRnReportError = (payload) => {
    return {
        type: 'EXTERNAL_RN_REPORT_ERROR',
        payload
    };
};

// qc report
export const QcReport = (payload) => {
    return {
        type: 'QC_REPORT',
        payload
    };
};
export const QcReportSuccess = (payload) => {
    return {
        type: 'QC_REPORT_SUCCESS',
        payload
    };
};
export const QcReportError = (payload) => {
    return {
        type: 'QC_REPORT_ERROR',
        payload
    };
};


