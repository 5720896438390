
export const actionTypes = {
    LIST_ITEMS: 'LIST_ITEMS',
    LIST_ITEMS_SUCCESS: 'LIST_ITEMS_SUCCESS',
    LIST_ITEMS_ERROR: 'LIST_ITEMS_ERROR',

    SAVE_MANUFACTURE_ITEMS: 'SAVE_MANUFACTURE_ITEMS',
    SAVE_MANUFACTURE_ITEMS_SUCCESS: 'SAVE_MANUFACTURE_ITEMS_SUCCESS',
    SAVE_MANUFACTURE_ITEMS_ERROR: 'SAVE_MANUFACTURE_ITEMS_ERROR',

    LIST_MANUFACTURE_ITEMS: 'LIST_MANUFACTURE_ITEMS',
    LIST_MANUFACTURE_ITEMS_SUCCESS: 'LIST_MANUFACTURE_ITEMS_SUCCESS',
    LIST_MANUFACTURE_ITEMS_ERROR: 'LIST_MANUFACTURE_ITEMS_ERROR',

    GET_COMPOSITE_ID: 'GET_COMPOSITE_ID',
    GET_COMPOSITE_ID_SUCCESS: 'GET_COMPOSITE_ID_SUCCESS',
    GET_COMPOSITE_ID_ERROR: 'GET_COMPOSITE_ID_ERROR',

    DELETE_MANUFACTURING_ITEM: 'DELETE_MANUFACTURING_ITEM',
    DELETE_MANUFACTURING_ITEM_SUCCESS: 'DELETE_MANUFACTURING_ITEM_SUCCESS',
    DELETE_MANUFACTURING_ITEM_ERROR: 'DELETE_MANUFACTURING_ITEM_ERROR',

    UPDATE_MANUFACTURING_ITEM: 'UPDATE_MANUFACTURING_ITEM',
    UPDATE_MANUFACTURING_ITEM_SUCCESS: 'UPDATE_MANUFACTURING_ITEM_SUCCESS',
    UPDATE_MANUFACTURING_ITEM_ERROR: 'UPDATE_MANUFACTURING_ITEM_ERROR',

    SAVE_MRITEM_STOCK: 'SAVE_MRITEM_STOCK',
    SAVE_MRITEM_STOCK_SUCCESS: 'SAVE_MRITEM_STOCK_SUCCESS',
    SAVE_MRITEM_STOCK_ERROR: 'SAVE_MRITEM_STOCK_ERROR',
    
    LIST_MRITEM_STOCK: 'LIST_MRITEM_STOCK',
    LIST_MRITEM_STOCK_SUCCESS: 'LIST_MRITEM_STOCK_SUCCESS',
    LIST_MRITEM_STOCK_ERROR: 'LIST_MRITEM_STOCK_ERROR',

    UPDATE_MRITEM_STOCK: 'UPDATE_MRITEM_STOCK',
    UPDATE_MRITEM_STOCK_SUCCESS: 'UPDATE_MRITEM_STOCK_SUCCESS',
    UPDATE_MRITEM_STOCK_ERROR: 'UPDATE_MRITEM_STOCK_ERROR',

    DELETE_MRITEM_STOCK: 'DELETE_MRITEM_STOCK',
    DELETE_MRITEM_STOCK_SUCCESS: 'DELETE_MRITEM_STOCK_SUCCESS',
    DELETE_MRITEM_STOCK_ERROR: 'DELETE_MRITEM_STOCK_ERROR',

    LIST_COMPANY: 'LIST_COMPANY',
    LIST_COMPANY_SUCCESS: 'LIST_COMPANY_SUCCESS',
    LIST_COMPANY_ERROR: 'LIST_COMPANY_ERROR',
    LIST_CONTACT: 'LIST_CONTACT',
    LIST_CONTACT_SUCCESS: 'LIST_CONTACT_SUCCESS',
    LIST_CONTACT_ERROR: 'LIST_CONTACT_ERROR',
    LIST_SALESMAN: 'LIST_SALESMAN',
    LIST_SALESMAN_SUCCESS: 'LIST_SALESMAN_SUCCESS',
    LIST_SALESMAN_ERROR: 'LIST_SALESMAN_ERROR',
    SAVE_MANUFACTURING_REQUEST: 'SAVE_MANUFACTURING_REQUEST',
    SAVE_MANUFACTURING_REQUEST_SUCCESS: 'SAVE_MANUFACTURING_REQUEST_SUCCESS',
    SAVE_MANUFACTURING_REQUEST_ERROR: 'SAVE_MANUFACTURING_REQUEST_ERROR',
    LIST_MANUFACTURING_REQUEST: 'LIST_MANUFACTURING_REQUEST',
    LIST_MANUFACTURING_REQUEST_SUCCESS: 'LIST_MANUFACTURING_REQUEST_SUCCESS',
    LIST_MANUFACTURING_REQUEST_ERROR: 'LIST_MANUFACTURING_REQUEST_ERROR',
    UPDATE_MANUFACTURING_REQUEST: 'UPDATE_MANUFACTURING_REQUEST',
    UPDATE_MANUFACTURING_REQUEST_SUCCESS: 'UPDATE_MANUFACTURING_REQUEST_SUCCESS',
    UPDATE_MANUFACTURING_REQUEST_ERROR: 'UPDATE_MANUFACTURING_REQUEST_ERROR',
    GET_MANUFACTURE_ITEMS: 'GET_MANUFACTURE_ITEMS',
    GET_MANUFACTURE_ITEMS_SUCCESS: 'GET_MANUFACTURE_ITEMS_SUCCESS',
    GET_MANUFACTURE_ITEMS_ERROR: 'GET_MANUFACTURE_ITEMS_ERROR',
    DELETE_MANUFACTURE_ITEMS: 'DELETE_MANUFACTURE_ITEMS',
    DELETE_MANUFACTURE_ITEMS_SUCCESS: 'DELETE_MANUFACTURE_ITEMS_SUCCESS',
    DELETE_MANUFACTURE_ITEMS_ERROR: 'DELETE_MANUFACTURE_ITEMS_ERROR'
}; 

// export const listItems = (payload) => {
//     return {
//         type: actionTypes.LIST_ITEMS, payload
//     };
// };
// export const listItemsSuccess = (payload) => {
//     return {
//         type: actionTypes.LIST_ITEMS_SUCCESS,
//         payload
//     };
// };
// export const listItemsError = (payload) => {
//     return {
//         type: actionTypes.LIST_ITEMS_ERROR,
//         payload
//     };
// };

export const saveManufactureItems = (payload) => {
    return {
        type: actionTypes.SAVE_MANUFACTURE_ITEMS, payload
    };
};
export const saveManufactureItemsSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_MANUFACTURE_ITEMS_SUCCESS,
        payload
    };
};
export const saveManufactureItemsError = (payload) => {
    return {
        type: actionTypes.SAVE_MANUFACTURE_ITEMS_ERROR,
        payload
    };
};

export const listManufactureItems = (payload) => {
    return {
        type: actionTypes.LIST_MANUFACTURE_ITEMS, payload
    };
};
export const listManufactureItemsSuccess = (payload) => {
    return {
        type: actionTypes.LIST_MANUFACTURE_ITEMS_SUCCESS,
        payload
    };
};
export const listManufactureItemsError = (payload) => {
    return {
        type: actionTypes.LIST_MANUFACTURE_ITEMS_ERROR,
        payload
    };
};

export const getCompositeId = (payload) => {
    return {
        type: actionTypes.GET_COMPOSITE_ID, payload
    };
};
export const getCompositeIdSuccess = (payload) => {
    return {
        type: actionTypes.GET_COMPOSITE_ID_SUCCESS,
        payload
    };
};
export const getCompositeIdError = (payload) => {
    return {
        type: actionTypes.GET_COMPOSITE_ID_ERROR,
        payload
    };
};

export const deletemanufacturingItem = (payload) => {
    return {
        type: actionTypes.DELETE_MANUFACTURING_ITEM, payload
    };
};
export const deletemanufacturingItemSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_MANUFACTURING_ITEM_SUCCESS,
        payload
    };
};
export const deletemanufacturingItemError = (payload) => {
    return {
        type: actionTypes.DELETE_MANUFACTURING_ITEM_ERROR,
        payload
    };
};

export const updatemanufacturingItem = (payload) => {
    return {
        type: actionTypes.UPDATE_MANUFACTURING_ITEM, payload
    };
};
export const updatemanufacturingItemSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_MANUFACTURING_ITEM_SUCCESS,
        payload
    };
};
export const updatemanufacturingItemError = (payload) => {
    return {
        type: actionTypes.UPDATE_MANUFACTURING_ITEM_ERROR,
        payload
    };
};


export const saveMrItemStock = (payload) => {
    return {
        type: actionTypes.SAVE_MRITEM_STOCK, payload
    };
};
export const saveMrItemStockSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_MRITEM_STOCK_SUCCESS,
        payload
    };
};
export const saveMrItemStockError = (payload) => {
    return {
        type: actionTypes.SAVE_MRITEM_STOCK_ERROR,
        payload
    };
};

export const listMrItemStock = (payload) => {
    return {
        type: actionTypes.LIST_MRITEM_STOCK, payload
    };
};
export const listMrItemStockSuccess = (payload) => {
    return {
        type: actionTypes.LIST_MRITEM_STOCK_SUCCESS,
        payload
    };
};
export const listMrItemStockError = (payload) => {
    return {
        type: actionTypes.LIST_MRITEM_STOCK_ERROR,
        payload
    };
};

export const updateMrItemStock = (payload) => {
    return {
        type: actionTypes.UPDATE_MRITEM_STOCK, payload
    };
};
export const updateMrItemStockSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_MRITEM_STOCK_SUCCESS,
        payload
    };
};
export const updateMrItemStockError = (payload) => {
    return {
        type: actionTypes.UPDATE_MRITEM_STOCK_ERROR,
        payload
    };
};

export const deleteMrItemStock = (payload) => {
    return {
        type: actionTypes.DELETE_MRITEM_STOCK, payload
    };
};
export const deleteMrItemStockSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_MRITEM_STOCK_SUCCESS,
        payload
    };
};
export const deleteMrItemStockError = (payload) => {
    return {
        type: actionTypes.DELETE_MRITEM_STOCK_ERROR,
        payload
    };
};

export const listCompany = (payload) => {
    return {
        type: actionTypes.LIST_COMPANY, payload
    };
};
export const listCompanySuccess = (payload) => {
    return {
        type: actionTypes.LIST_COMPANY_SUCCESS,
        payload
    };
};
export const listCompanyError = (payload) => {
    return {
        type: actionTypes.LIST_COMPANY_ERROR,
        payload
    };
};

export const listContact = (payload) => {
    return {
        type: actionTypes.LIST_CONTACT, payload
    };
};
export const listContactSuccess = (payload) => {
    return {
        type: actionTypes.LIST_CONTACT_SUCCESS,
        payload
    };
};
export const listContactError = (payload) => {
    return {
        type: actionTypes.LIST_CONTACT_ERROR,
        payload
    };
};

export const listSalesman = (payload) => {
    return {
        type: actionTypes.LIST_SALESMAN, payload
    };
};
export const listSalesmanSuccess = (payload) => {
    return {
        type: actionTypes.LIST_SALESMAN_SUCCESS,
        payload
    };
};
export const listSalesmanError = (payload) => {
    return {
        type: actionTypes.LIST_SALESMAN_ERROR,
        payload
    };
};

export const saveManufacturingRequest = (payload) => {
    return {
        type: actionTypes.SAVE_MANUFACTURING_REQUEST, payload
    };
};
export const saveManufacturingRequestSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_MANUFACTURING_REQUEST_SUCCESS,
        payload
    };
};
export const saveManufacturingRequestError = (payload) => {
    return {
        type: actionTypes.SAVE_MANUFACTURING_REQUEST_ERROR,
        payload
    };
};

export const listManufacturingRequest = (payload) => {
    return {
        type: actionTypes.LIST_MANUFACTURING_REQUEST, payload
    };
};
export const listManufacturingRequestSuccess = (payload) => {
    return {
        type: actionTypes.LIST_MANUFACTURING_REQUEST_SUCCESS,
        payload
    };
};
export const listManufacturingRequestError = (payload) => {
    return {
        type: actionTypes.LIST_MANUFACTURING_REQUEST_ERROR,
        payload
    };
};

export const updateManufacturingRequest = (payload) => {
    return {
        type: actionTypes.UPDATE_MANUFACTURING_REQUEST, payload
    };
};
export const updateManufacturingRequestSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_MANUFACTURING_REQUEST_SUCCESS,
        payload
    };
};
export const updateManufacturingRequestError = (payload) => {
    return {
        type: actionTypes.UPDATE_MANUFACTURING_REQUEST_ERROR,
        payload
    };
};


export const getManufactureItem = (payload) => {
    return {
        type: actionTypes.GET_MANUFACTURE_ITEMS, payload
    };
};
export const getManufactureItemSuccess = (payload) => {
    return {
        type: actionTypes.GET_MANUFACTURE_ITEMS_SUCCESS,
        payload
    };
};
export const getManufactureItemError = (payload) => {
    return {
        type: actionTypes.GET_MANUFACTURE_ITEMS_ERROR,
        payload
    };
};

export const deleteManufactureItem = (payload) => {
    return {
        type: actionTypes.DELETE_MANUFACTURE_ITEMS, payload
    };
};
export const deleteManufactureItemSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_MANUFACTURE_ITEMS_SUCCESS,
        payload
    };
};
export const deleteManufactureItemError = (payload) => {
    return {
        type: actionTypes.DELETE_MANUFACTURE_ITEMS_ERROR,
        payload
    };
};

export const listMirForStock = (payload) => {
    return {
        type: 'LIST_MIR_FOR_STOCK', payload
    };
};
export const listMirForStockSuccess = (payload) => {
    return {
        type: 'LIST_MIR_FOR_STOCK_SUCCESS',
        payload
    };
};
export const listMirForStockError = (payload) => {
    return {
        type: 'LIST_MIR_FOR_STOCK_ERROR',
        payload
    };
};