import ApiClient from '@service/ApiClient'


class picklocationService {
    constructor(callback) {
        this.callback = callback
    }

    async fetchCountryDetails(payload) {
        return await ApiClient.post('/Common/Picklocation/country_details',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchRegionalLevelData(payload) {
        return await ApiClient.post('/Common/Picklocation/get_regional_level_data',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchRegionalLevelsByCountryId(payload) {
        return await ApiClient.post('/Common/Picklocation/get_regional_levels_by_country_id',
            payload,
        ).then((response) => {
            return response.data;
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new picklocationService();