
import { actionTypes } from './action';

export const initialState = {
    saveProjectTypeValue: '',
    listProjectTypeValue: [],
    updateProjectTypeValues: '',
    deleteProjectTypeValues: ''
}

const saveProjectTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_PROJECTTYPE:
            return { ...state, saveProjectTypeValue: '', }
        case actionTypes.SAVE_PROJECTTYPE_SUCCESS:
            return { ...state, saveProjectTypeValue: 'SUCCESS' }
        case actionTypes.SAVE_PROJECTTYPE_ERROR:
            return { ...state, saveProjectTypeValue: 'ERROR' }

        case actionTypes.LIST_PROJECTTYPE:
            return { ...state, listProjectTypeValue: [] }
        case actionTypes.LIST_PROJECTTYPE_SUCCESS:
            return {
                ...state, listProjectTypeValue: action.payload,
                saveProjectTypeValue: '',
                updateProjectTypeValues: '',
                deleteProjectTypeValues: ''
            }
        case actionTypes.LIST_PROJECTTYPE_ERROR:
            return { ...state, listProjectTypeValue: [] }

        case actionTypes.UPDATE_PROJECTTYPE:
            return { ...state, updateProjectTypeValues: '' }
        case actionTypes.UPDATE_PROJECTTYPE_SUCCESS:
            return { ...state, updateProjectTypeValues: 'SUCCESS' }
        case actionTypes.UPDATE_PROJECTTYPE_ERROR:
            return { ...state, updateProjectTypeValues: 'ERROR' }

        case actionTypes.DELETE_PROJECTTYPE:
            return { ...state, deleteProjectTypeValues: '' }
        case actionTypes.DELETE_PROJECTTYPE_SUCCESS:
            return { ...state, deleteProjectTypeValues: 'SUCCESS' }
        case actionTypes.DELETE_PROJECTTYPE_ERROR:
            return { ...state, deleteProjectTypeValues: 'ERROR' }

        default:
            return state
    }
}

export default saveProjectTypeReducer