
export const actionTypes = {
    SAVE_PROJECTTYPE: 'SAVE_PROJECTTYPE',
    SAVE_PROJECTTYPE_SUCCESS: 'SAVE_PROJECTTYPE_SUCCESS',
    SAVE_PROJECTTYPE_ERROR: 'SAVE_PROJECTTYPE_ERROR',
    LIST_PROJECTTYPE: 'LIST_PROJECTTYPE',
    LIST_PROJECTTYPE_SUCCESS: 'LIST_PROJECTTYPE_SUCCESS',
    LIST_PROJECTTYPE_ERROR: 'LIST_PROJECTTYPE_ERROR',
    UPDATE_PROJECTTYPE: 'UPDATE_PROJECTTYPE',
    UPDATE_PROJECTTYPE_SUCCESS: 'UPDATE_PROJECTTYPE_SUCCESS',
    UPDATE_PROJECTTYPE_ERROR: 'UPDATE_PROJECTTYPE_ERROR',
    DELETE_PROJECTTYPE: 'DELETE_PROJECTTYPE',
    DELETE_PROJECTTYPE_SUCCESS: 'DELETE_PROJECTTYPE_SUCCESS',
    DELETE_PROJECTTYPE_ERROR: 'DELETE_PROJECTTYPE_ERROR',
};

export const saveprojectType = (payload) => {
    return {
        type: actionTypes.SAVE_PROJECTTYPE, payload
    };
};
export const saveprojectTypeSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_PROJECTTYPE_SUCCESS,
        payload
    };
};
export const saveprojectTypeError = (payload) => {
    return {
        type: actionTypes.SAVE_PROJECTTYPE_ERROR,
        payload
    };
};

export const listProjectType = (payload) => {
    return {
        type: actionTypes.LIST_PROJECTTYPE, payload
    };
};
export const listProjectTypeSuccess = (payload) => {
    return {
        type: actionTypes.LIST_PROJECTTYPE_SUCCESS,
        payload
    };
};
export const listProjectTypeError = (payload) => {
    return {
        type: actionTypes.LIST_PROJECTTYPE_ERROR,
        payload
    };
};

export const updateProjectType = (payload) => {
    return {
        type: actionTypes.UPDATE_PROJECTTYPE, payload
    };
};
export const updateProjectTypeSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_PROJECTTYPE_SUCCESS,
        payload
    };
};
export const updateProjectTypeError = (payload) => {
    return {
        type: actionTypes.UPDATE_PROJECTTYPE_ERROR,
        payload
    };
};


export const deleteProjectType = (payload) => {
    return {
        type: actionTypes.DELETE_PROJECTTYPE, payload
    };
};
export const deleteProjectTypeSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_PROJECTTYPE_SUCCESS,
        payload
    };
};
export const deleteProjectTypeError = (payload) => {
    return {
        type: actionTypes.DELETE_PROJECTTYPE_ERROR,
        payload
    };
};
