import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import currencyMasterService from '@service/admin/masterService/currencyMasterService';
import {
  saveCurrencySuccess, saveCurrencyError,
  listCurrencySuccess, listCurrencyError,
  deleteCurrencySuccess, deleteCurrencyError,
  updateCurrencySuccess, updateCurrencyError,
  saveCurrencyRateSuccess, saveCurrencyRateError,
  deleteCurrencyRateSuccess, deleteCurrencyRateError,
  updateCurrencyRateSuccess, updateCurrencyRateError,
  getDefaultCurrencySuccess, getDefaultCurrencyError
} from './action';

function* saveCurrency({ payload }) {
  try {
    const data = yield call(currencyMasterService.saveCurrency, payload);

    yield put(saveCurrencySuccess(data));
  } catch (error) {
    yield put(saveCurrencyError(error));
  } finally {
  }
}

function* listCurrency({ payload }) {
  try {
    const data = yield call(currencyMasterService.listCurrency, payload);

    yield put(listCurrencySuccess(Array.isArray(data) ? data : []));
  } catch (error) {
    yield put(listCurrencyError(error));
  } finally {
  }
}

function* deleteCurrency({ payload }) {
  try {
    const data = yield call(currencyMasterService.deleteCurrency, payload);

    yield put(deleteCurrencySuccess(data));
  } catch (error) {
    yield put(deleteCurrencyError(error));
  } finally {
  }
}

function* updateCurrency({ payload }) {
  try {
    const data = yield call(currencyMasterService.updateCurrency, payload);

    yield put(updateCurrencySuccess(data));
  } catch (error) {
    yield put(updateCurrencyError(error));
  } finally {
  }
}

function* saveCurrencyRate({ payload }) {
  try {
    const data = yield call(currencyMasterService.saveCurrencyRate, payload);

    yield put(saveCurrencyRateSuccess(data));
  } catch (error) {
    yield put(saveCurrencyRateError(error));
  } finally {
  }
}

function* deleteCurrencyRate({ payload }) {
  try {
    const data = yield call(currencyMasterService.deleteCurrencyRate, payload);

    yield put(deleteCurrencyRateSuccess(data));
  } catch (error) {
    yield put(deleteCurrencyRateError(error));
  } finally {
  }
}

function* updateCurrencyRate({ payload }) {
  try {
    const data = yield call(currencyMasterService.updateCurrencyRate, payload);

    yield put(updateCurrencyRateSuccess(data));
  } catch (error) {
    yield put(updateCurrencyRateError(error));
  } finally {
  }
}

function* getDefaultCurrency({ payload }) {
  try {
    const data = yield call(currencyMasterService.getDefaultCurrency, payload);

    yield put(getDefaultCurrencySuccess(data));
  } catch (error) {
    yield put(getDefaultCurrencyError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_CURRENCY, saveCurrency)]);
  yield all([takeEvery(actionTypes.LIST_CURRENCY, listCurrency)]);
  yield all([takeEvery(actionTypes.DELETE_CURRENCY, deleteCurrency)]);
  yield all([takeEvery(actionTypes.UPDATE_CURRENCY, updateCurrency)]);
  yield all([takeEvery(actionTypes.SAVE_CURRENCY_RATE, saveCurrencyRate)]);
  yield all([takeEvery(actionTypes.DELETE_CURRENCY_RATE, deleteCurrencyRate)]);
  yield all([takeEvery(actionTypes.UPDATE_CURRENCY_RATE, updateCurrencyRate)]);
  yield all([takeEvery(actionTypes.GET_DEFAULT_CURRENCY, getDefaultCurrency)]);

}