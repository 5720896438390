import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import salaryregisterService from '@service/admin/payrollService/salaryregisterService';
import {
    fetchLpSuccess,fetchLpError,
    fetchSalarydetailsSuccess,fetchSalarydetailsError,
    saveSalaryregisterSuccess,saveSalaryregisterError,
    listSalaryregisterSuccess,listSalaryregisterError,
    empSalaryregisterlistSuccess,empSalaryregisterlistError,
    salaryregisterViewSuccess,salaryregisterViewError,
    salarypaymentSuccess,salarypaymentError,
    listEmployeeSuccess,listEmployeeError
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'

function* fetchLp({ payload }) {
    try {
      yield put(startLoader());
      const data = yield call(salaryregisterService.fetchLp, payload);
      yield put(fetchLpSuccess(data));
    } catch (error) {
      yield put(fetchLpError(error));
    } finally {
      yield put(stopLoader());
    }
  }
  function* fetchSalarydetails({ payload }) {
    try {
      yield put(startLoader());
      const data = yield call(salaryregisterService.fetchSalarydetails, payload);
      yield put(fetchSalarydetailsSuccess(data));
    } catch (error) {
      yield put(fetchSalarydetailsError(error));
    } finally {
      yield put(stopLoader());
    }
  }
  
  function* saveSalaryregister({ payload }) {
    try {
      yield put(startLoader());
      const data = yield call(salaryregisterService.saveSalaryregister, payload);
      yield put(saveSalaryregisterSuccess(data));
    } catch (error) {
      yield put(saveSalaryregisterError(error));
    } finally {
      yield put(stopLoader());
    }
  }
  
  function* listSalaryregister({ payload }) {
    try {
      yield put(startLoader());
      const data = yield call(salaryregisterService.listSalaryregister, payload);
      yield put(listSalaryregisterSuccess(data));
    } catch (error) {
      yield put(listSalaryregisterError(error));
    } finally {
      yield put(stopLoader());
    }
  }
  
  function* emplistSalaryregister({ payload }) {
    try {
      yield put(startLoader());
      const data = yield call(salaryregisterService.emplistSalaryregister, payload);
      yield put(empSalaryregisterlistSuccess(data));
    } catch (error) {
      yield put(empSalaryregisterlistError(error));
    } finally {
      yield put(stopLoader());
    }
  }
  
  function* salaryregisterView({ payload }) {
    try {
      yield put(startLoader());
      const data = yield call(salaryregisterService.salaryregisterView, payload);
      yield put(salaryregisterViewSuccess(data));
    } catch (error) {
      yield put(salaryregisterViewError(error));
    } finally {
      yield put(stopLoader());
    }
  }
  function* salarypayment({ payload }) {
    try {
      yield put(startLoader());
      const data = yield call(salaryregisterService.salarypayment, payload);
      yield put(salarypaymentSuccess(data));
    } catch (error) {
      yield put(salarypaymentError(error));
    } finally {
      yield put(stopLoader());
    }
  }
  function* listEmployee({ payload }) {
    try {
      yield put(startLoader());
      const data = yield call(salaryregisterService.listEmployee, payload);
      yield put(listEmployeeSuccess(data));
    } catch (error) {
      yield put(listEmployeeError(error));
    } finally {
      yield put(stopLoader());
    }
  }
  
export default function* rootSaga() {
  yield all([takeEvery(actionTypes.FETCH_LP, fetchLp)]);
  yield all([takeEvery(actionTypes.FETCH_SALARY_DETAILS, fetchSalarydetails)]);
  yield all([takeEvery(actionTypes.SAVE_SALARY, saveSalaryregister)]);
  yield all([takeEvery(actionTypes.LIST_SALARY, listSalaryregister)]);
  yield all([takeEvery(actionTypes.EMP_LIST_SALARY, emplistSalaryregister)]);
  yield all([takeEvery(actionTypes.SALARYREGISTER_VIEW, salaryregisterView)]);
  yield all([takeEvery(actionTypes.SALARY_PAYMENT, salarypayment)]);
  yield all([takeEvery(actionTypes.LIST_EMPLOYEE, listEmployee)]);
}