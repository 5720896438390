import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import equipmentMasterService from '../../../service/admin/masterService/equipmentMasterService';
import {
  saveEquipmentMasterSuccess, saveEquipmentMasterError,
  listEquipmentMasterSuccess, listEquipmentMasterError,
  updateEquipmentMasterSuccess, updateEquipmentMasterError,
  deleteEquipmentMasterSuccess, deleteEquipmentMasterError,
  complaintRequestViewSucces, complaintRequestViewError,
  employeeListForComplaintSucces, employeeListForComplaintError,
  assignTechnicianForComplaintSucces, assignTechnicianForComplaintError,
  addInspectionForComplaintSucces, addInspectionForComplaintError,
  updateInspectionForComplaintSucces, updateInspectionForComplaintError,
  listInspectionForComplaintSucces, listInspectionForComplaintError,
  saveSparepartsForComplaintSucces, saveSparepartsForComplaintError,
  listSparepartsForComplaintSucces, listSparepartsForComplaintError,
  removeSparepartsForComplaintSucces, removeSparepartsForComplaintError,
  changetechnicianstatusForComplaintSucces, changetechnicianstatusForComplaintError,
  getTechnicianTaskByComplaintIdSucces, getTechnicianTaskByComplaintIdError,
  changeEquipmentComplaintRequestStatusSucces, changeEquipmentComplaintRequestStatusError,
  AssetcomplaintsaveSucces, AssetcomplaintsaveError,
  listcomplaintrequestSucces, listcomplaintrequestError,
  listassetcalibrationroosterSucces, listassetcalibrationroosterError,
  listassetserviceroosterSucces, listassetserviceroosterError,
  assetroosterviewidentificationSucces, assetroosterviewidentificationError,
  viewCalibrationDetailsSuccess, viewCalibrationDetailsError,
  viewServiceDetailsSuccess, viewServiceDetailsError,
  fetchComplaintActivityLogSuccess, fetchComplaintActivityLogError,
  technicanlistmasterSuccess, technicanlistmasterError,
  assignedServiceVendorsSuccess, assignedServiceVendorsError,
  removeDepartmentForComplaintSuccess, removeDepartmentForComplaintError,
  assignVendorToServiceSuccess, assignVendorToServiceError,
  viewServiceRoosterSuccess, viewServiceRoosterError,
  tasklistSuccess, tasklistError,
  saveDeliveryNoteSuccess, saveDeliveryNoteError,
  saveReceiptNoteSuccess, saveReceiptNoteError,
  saveEquipmentAllocationSuccess, saveEquipmentAllocationError,
  listEquipmentAllocationSuccess, listEquipmentAllocationError,
  ViewEquipmentAllocationSuccess, ViewEquipmentAllocationError,
  ReturnEquipmentAllocationSuccess, ReturnEquipmentAllocationError,
  listDeliveryNoteSuccess, listDeliveryNoteError,
  ViewDeliveryNoteSuccess, ViewDeliveryNoteError,
  update_service_status_external_vendorSuccess,
  update_service_status_external_vendorError,
  fetchReceiptNoteSuccess, fetchReceiptNoteError,
  fetchTaskReportSuccess, fetchTaskReportError,
  departmentwisebarcodeSuccess, departmentwisebarcodeError,
  generatebarcodebulkSuccess, generatebarcodebulkError
} from './action';
import moment from 'moment';
import { stopLoader, startLoader } from "@store/common/loader/action";


function* saveEquipmentMaster({ payload }) {
  try {
    const data = yield call(equipmentMasterService.saveEquipmentMaster, payload);

    yield put(saveEquipmentMasterSuccess(data));
  } catch (error) {
    yield put(saveEquipmentMasterError(error));
  } finally {
  }
}

function* technicanlistmaster({ payload }) {
  try {
    const data = yield call(equipmentMasterService.technicanlistmaster, payload);

    yield put(technicanlistmasterSuccess(data));
  } catch (error) {
    yield put(technicanlistmasterError(error));
  } finally {
  }
}

function* listEquipmentMaster({ payload }) {
  try {
    const data = yield call(equipmentMasterService.listEquipmentMaster, payload);

    yield put(listEquipmentMasterSuccess(data));
  } catch (error) {
    yield put(listEquipmentMasterError(error));
  } finally {
  }
}

function* updateEquipmentMaster({ payload }) {
  try {
    const data = yield call(equipmentMasterService.updateEquipmentMaster, payload);

    yield put(updateEquipmentMasterSuccess(data));
  } catch (error) {
    yield put(updateEquipmentMasterError(error));
  } finally {
  }
}

function* deleteEquipmentMaster({ payload }) {
  try {
    const data = yield call(equipmentMasterService.deleteEquipmentMaster, payload);

    yield put(deleteEquipmentMasterSuccess(data));
  } catch (error) {
    yield put(deleteEquipmentMasterError(error));
  } finally {
  }
}

function* complaintRequestView({ payload }) {
  console.log('complaint pay', payload);
  try {
    const data = yield call(equipmentMasterService.complaintRequestView, payload);
    console.log('complaint data', data);

    yield put(complaintRequestViewSucces(data));
  } catch (error) {
    yield put(complaintRequestViewError(error));
  } finally {
  }
}

function* employeeListForComplaint({ payload }) {
  try {
    const data = yield call(equipmentMasterService.employeeListForComplaint, payload);

    yield put(employeeListForComplaintSucces(data));
  } catch (error) {
    yield put(employeeListForComplaintError(error));
  } finally {
  }
}

function* assignTechnicianForComplaint({ payload }) {
  try {
    const data = yield call(equipmentMasterService.assignTechnicianForComplaint, payload);

    yield put(assignTechnicianForComplaintSucces(data));
  } catch (error) {
    yield put(assignTechnicianForComplaintError(error));
  } finally {
  }
}

function* addInspectionForComplaint({ payload }) {
  console.log("payload", payload);
  try {
    const data = yield call(equipmentMasterService.addInspectionForComplaint, payload);

    yield put(addInspectionForComplaintSucces(data));
  } catch (error) {
    yield put(addInspectionForComplaintError(error));
  } finally {
  }
}

function* updateInspectionForComplaint({ payload }) {
  try {
    const data = yield call(equipmentMasterService.updateInspectionForComplaint, payload);

    yield put(updateInspectionForComplaintSucces(data));
  } catch (error) {
    yield put(updateInspectionForComplaintError(error));
  } finally {
  }
}

function* listInspectionForComplaint({ payload }) {
  try {
    const data = yield call(equipmentMasterService.listInspectionForComplaint, payload);

    yield put(listInspectionForComplaintSucces(data));
  } catch (error) {
    yield put(listInspectionForComplaintError(error));
  } finally {
  }
}

function* saveSparepartsForComplaint({ payload }) {
  try {
    const data = yield call(equipmentMasterService.saveSparepartsForComplaint, payload);

    yield put(saveSparepartsForComplaintSucces(data));
  } catch (error) {
    yield put(saveSparepartsForComplaintError(error));
  } finally {
  }
}

function* listSparepartsForComplaint({ payload }) {
  try {
    const data = yield call(equipmentMasterService.listSparepartsForComplaint, payload);

    yield put(listSparepartsForComplaintSucces(data));
  } catch (error) {
    yield put(listSparepartsForComplaintError(error));
  } finally {
  }
}

function* removeSparepartsForComplaint({ payload }) {
  try {
    const data = yield call(equipmentMasterService.removeSparepartsForComplaint, payload);

    yield put(removeSparepartsForComplaintSucces(data));
  } catch (error) {
    yield put(removeSparepartsForComplaintError(error));
  } finally {
  }
}

function* changetechnicianstatusForComplaint({ payload }) {
  try {
    const data = yield call(equipmentMasterService.changetechnicianstatusForComplaint, payload);

    yield put(changetechnicianstatusForComplaintSucces(data));
  } catch (error) {
    yield put(changetechnicianstatusForComplaintError(error));
  } finally {
  }
}

function* getTechnicianTaskByComplaintId({ payload }) {
  try {
    const data = yield call(equipmentMasterService.getTechnicianTaskByComplaintId, payload);

    yield put(getTechnicianTaskByComplaintIdSucces(data));
  } catch (error) {
    yield put(getTechnicianTaskByComplaintIdError(error));
  } finally {
  }
}

function* changeEquipmentComplaintRequestStatus({ payload }) {
  try {
    const data = yield call(equipmentMasterService.changeEquipmentComplaintRequestStatus, payload);

    yield put(changeEquipmentComplaintRequestStatusSucces(data));
  } catch (error) {
    yield put(changeEquipmentComplaintRequestStatusError(error));
  } finally {
  }
}

function* Assetcomplaintsave({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(equipmentMasterService.Assetcomplaintsave, payload);
    yield put(AssetcomplaintsaveSucces(data));
  } catch (error) {
    yield put(AssetcomplaintsaveError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listcomplaintrequest({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(equipmentMasterService.listcomplaintrequest, payload);

    yield put(listcomplaintrequestSucces(data));
  } catch (error) {
    yield put(listcomplaintrequestError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listassetcalibrationrooster({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(equipmentMasterService.listassetcalibrationrooster, payload);
    // console.log("data",data);
    if (data.length) {
      let service_roosters = []
      data.map(item => {
        service_roosters = [...service_roosters, ...JSON.parse(item.asset_calibration_rooster).map(roostrer => {
          return {
            ...roostrer,
            asset_barcode: item.asset_barcode,
            asset_name: item.asset_name,
            asset_register_id: item.asset_register_id,
            asset_calibration_rooster_id: item.asset_calibration_rooster_id,
          }
        }
        )]
      })
      let today = new Date();
      let priorDate = new Date().setDate(today.getDate() + 30);
      yield put(listassetcalibrationroosterSucces(service_roosters.filter((item => {
        let ser_date = moment(item.service_date).format('x')
        if (ser_date <= priorDate) {
          return item
        }
      }))));
    } else {
      yield put(listassetcalibrationroosterSucces([]));
    }
  } catch (error) {
    yield put(listassetcalibrationroosterError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listassetservicerooster({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(equipmentMasterService.listassetservicerooster, payload);

    if (data.length) {
      let service_roosters = []
      data.map(item => {
        service_roosters = [...service_roosters, ...JSON.parse(item.asset_rooster).map(roostrer => {
          return {
            ...roostrer,
            asset_barcode: item.asset_barcode,
            asset_name: item.asset_name,
            asset_register_id: item.asset_register_id,
            // asset_rooster: item.asset_rooster,
            asset_rooster_id: item.asset_rooster_id,
          }
        }
        )]
      })
      let today = new Date();
      let priorDate = new Date().setDate(today.getDate() + 30);
      yield put(listassetserviceroosterSucces(service_roosters.filter((item => {
        let ser_date = moment(item.service_date).format('x')
        if (ser_date <= priorDate) {
          return item
        }
      }))));
    } else {
      yield put(listassetserviceroosterSucces([]));
    }

  } catch (error) {
    yield put(listassetserviceroosterError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* assetroosterviewidentification({ payload }) {
  try {
    const data = yield call(equipmentMasterService.assetroosterviewidentification, payload);

    yield put(assetroosterviewidentificationSucces(data));
  } catch (error) {
    yield put(assetroosterviewidentificationError(error));
  } finally {
  }
}


function* viewCalibrationDetails({ payload }) {
  try {
    const data = yield call(equipmentMasterService.viewCalibrationDetails, payload);

    yield put(viewCalibrationDetailsSuccess(data));
  } catch (error) {
    yield put(viewCalibrationDetailsError(error));
  } finally {
    yield put(stopLoader());
  }
}


function* viewServiceDetails({ payload }) {
  try {
    const data = yield call(equipmentMasterService.viewServiceDetails, payload);

    yield put(viewServiceDetailsSuccess(data));
  } catch (error) {
    yield put(viewServiceDetailsError(error));
  } finally {
  }
}
function* viewServiceRooster({ payload }) {
  try {
    const data = yield call(equipmentMasterService.viewServiceRooster, payload);

    yield put(viewServiceRoosterSuccess(data));
  } catch (error) {
    yield put(viewServiceRoosterError(error));
  } finally {
  }
}


function* fetchComplaintActivityLog({ payload }) {
  try {
    const data = yield call(equipmentMasterService.fetchComplaintActivityLog, payload);

    yield put(fetchComplaintActivityLogSuccess(data));
  } catch (error) {
    yield put(fetchComplaintActivityLogError(error));
  } finally {
  }
}
function* assignedServiceVendors({ payload }) {
  try {
    const data = yield call(equipmentMasterService.assignedServiceVendors, payload);

    yield put(assignedServiceVendorsSuccess(data));
  } catch (error) {
    yield put(assignedServiceVendorsError(error));
  } finally {
  }
}

function* removeDepartmentForComplaint({ payload }) {
  try {
    const data = yield call(equipmentMasterService.removeDepartmentForComplaint, payload);

    yield put(removeDepartmentForComplaintSuccess(data));
  } catch (error) {
    yield put(removeDepartmentForComplaintError(error));
  } finally {
  }
}

function* assignVendorToService({ payload }) {
  try {
    const data = yield call(equipmentMasterService.assignVendorToService, payload);

    yield put(assignVendorToServiceSuccess(data));
  } catch (error) {
    yield put(assignVendorToServiceError(error));
  } finally {
  }
}

function* tasklist({ payload }) {
  try {
    const data = yield call(equipmentMasterService.tasklist, payload);

    yield put(tasklistSuccess(data));
  } catch (error) {
    yield put(tasklistError(error));
  } finally {
  }
}
function* saveDeliveryNote({ payload }) {
  try {
    const data = yield call(equipmentMasterService.saveDeliveryNote, payload);

    yield put(saveDeliveryNoteSuccess(data));
  } catch (error) {
    yield put(saveDeliveryNoteError(error));
  } finally {
  }
}

function* saveReceiptNote({ payload }) {
  try {
    const data = yield call(equipmentMasterService.saveReceiptNote, payload);

    yield put(saveReceiptNoteSuccess(data));
  } catch (error) {
    yield put(saveReceiptNoteError(error));
  } finally {
  }
}

function* saveEquipmentAllocation({ payload }) {
  try {
    const data = yield call(equipmentMasterService.saveEquipmentAllocation, payload);

    yield put(saveEquipmentAllocationSuccess(data));
  } catch (error) {
    yield put(saveEquipmentAllocationError(error));
  } finally {
  }
}

function* listEquipmentAllocation({ payload }) {
  try {
    const data = yield call(equipmentMasterService.listEquipmentAllocation, payload);

    yield put(listEquipmentAllocationSuccess(data));
  } catch (error) {
    yield put(listEquipmentAllocationError(error));
  } finally {
  }
}

function* ViewEquipmentAllocation({ payload }) {
  try {
    const data = yield call(equipmentMasterService.ViewEquipmentAllocation, payload);

    yield put(ViewEquipmentAllocationSuccess(data));
  } catch (error) {
    yield put(ViewEquipmentAllocationError(error));
  } finally {
  }
}

function* ReturnEquipmentAllocation({ payload }) {
  try {
    const data = yield call(equipmentMasterService.ReturnEquipmentAllocation, payload);

    yield put(ReturnEquipmentAllocationSuccess(data));
  } catch (error) {
    yield put(ReturnEquipmentAllocationError(error));
  } finally {
  }
}

function* listDeliveryNote({ payload }) {
  try {
    const data = yield call(equipmentMasterService.listDeliveryNote, payload);

    yield put(listDeliveryNoteSuccess(data));
  } catch (error) {
    yield put(listDeliveryNoteError(error));
  } finally {
  }
}

function* ViewDeliveryNote({ payload }) {
  try {
    const data = yield call(equipmentMasterService.ViewDeliveryNote, payload);

    yield put(ViewDeliveryNoteSuccess(data));
  } catch (error) {
    yield put(ViewDeliveryNoteError(error));
  } finally {
  }
}

function* update_service_status_external_vendor({ payload }) {
  try {
    const data = yield call(equipmentMasterService.update_service_status_external_vendor, payload);

    yield put(update_service_status_external_vendorSuccess(data));
  } catch (error) {
    yield put(update_service_status_external_vendorError(error));
  } finally {
  }
}

function* fetchReceiptNote({ payload }) {
  try {
    const data = yield call(equipmentMasterService.fetchReceiptNote, payload);

    yield put(fetchReceiptNoteSuccess(data));
  } catch (error) {
    yield put(fetchReceiptNoteError(error));
  } finally {
  }
}

function* fetchTaskReport({ payload }) {
  try {
    const data = yield call(equipmentMasterService.fetchTaskReport, payload);

    yield put(fetchTaskReportSuccess(data));
  } catch (error) {
    yield put(fetchTaskReportError(error));
  } finally {
  }
}

function* departmentwisebarcode({ payload }) {
  try {
    const data = yield call(equipmentMasterService.departmentwisebarcode, payload);

    yield put(departmentwisebarcodeSuccess(data));
  } catch (error) {
    yield put(departmentwisebarcodeError(error));
  } finally {
  }
}

function* generatebarcodebulk({ payload }) {
  try {
    const data = yield call(equipmentMasterService.generatebarcodebulk, payload);

    yield put(generatebarcodebulkSuccess(data));
  } catch (error) {
    yield put(generatebarcodebulkError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_EQUIPMENT_MASTER, saveEquipmentMaster)]);
  yield all([takeEvery(actionTypes.LIST_EQUIPMENT_MASTER, listEquipmentMaster)]);
  yield all([takeEvery(actionTypes.UPDATE_EQUIPMENT_MASTER, updateEquipmentMaster)]);
  yield all([takeEvery(actionTypes.DELETE_EQUIPMENT_MASTER, deleteEquipmentMaster)]);
  yield all([takeEvery(actionTypes.TECHINICAN_LIST_MASTER, technicanlistmaster)]);
  yield all([takeEvery("COMPLAINT_REQUEST_VIEW", complaintRequestView)]);
  yield all([takeEvery("EMPLOYEE_LIST_FOR_COMPLAINT", employeeListForComplaint)]);
  yield all([takeEvery("ASSIGN_TECHNICIAN_FOR_COMPLAINT", assignTechnicianForComplaint)]);
  yield all([takeEvery("ADD_INSPECTION_FOR_COMPLAINT", addInspectionForComplaint)]);
  yield all([takeEvery("UPDATE_INSPECTION_FOR_COMPLAINT", updateInspectionForComplaint)]);
  yield all([takeEvery("LIST_INSPECTION_FOR_COMPLAINT", listInspectionForComplaint)]);
  yield all([takeEvery("SAVE_SPAREPARTS_FOR_COMPLAINT", saveSparepartsForComplaint)]);
  yield all([takeEvery("LIST_SPAREPARTS_FOR_COMPLAINT", listSparepartsForComplaint)]);
  yield all([takeEvery("REMOVE_SPAREPARTS_FOR_COMPLAINT", removeSparepartsForComplaint)]);
  yield all([takeEvery("CHANGE_TECHNICIAN_STATUS_FOR_COMPLAINT", changetechnicianstatusForComplaint)]);
  yield all([takeEvery("GET_TECHNICIAN_TASK_BY_COMPLAINT_ID", getTechnicianTaskByComplaintId)]);
  yield all([takeEvery("CHANGE_EQUIPMENT_COMPLAINT_REQUEST_STATUS", changeEquipmentComplaintRequestStatus)]);
  yield all([takeEvery("ASSET_COMPLAINT_SAVE", Assetcomplaintsave)]);
  yield all([takeEvery("LIST_COMPLAINT_REQUEST", listcomplaintrequest)]);
  yield all([takeEvery("LIST_ASSET_CALIBRATION_ROOSTER", listassetcalibrationrooster)]);
  yield all([takeEvery("LIST_ASSET_SERVICE_ROOSTER", listassetservicerooster)]);
  yield all([takeEvery("ASSET_ROOSTER_VIEW_IDENTIFICATION", assetroosterviewidentification)]);
  yield all([takeEvery("VIEW_CALIBRATION_DETAILS", viewCalibrationDetails)]);
  yield all([takeEvery("VIEW_SERVICE_DETAILS", viewServiceDetails)]);
  yield all([takeEvery("VIEW_SERVICE_ROOSTER", viewServiceRooster)]);
  yield all([takeEvery("FETCH_COMPLAINT_ACTIVITY_LOG", fetchComplaintActivityLog)]);
  yield all([takeEvery("ASSIGNED_SERVICE_VENDORS", assignedServiceVendors)]);
  yield all([takeEvery("REMOVE_DEPAETMENT_FOR_COMPLAINT", removeDepartmentForComplaint)]);
  yield all([takeEvery("ASSIGN_VENDOR_TO_SERVICE", assignVendorToService)]);
  yield all([takeEvery("TASK_LIST", tasklist)]);
  yield all([takeEvery("SAVE_DELIVERY_NOTE", saveDeliveryNote)]);
  yield all([takeEvery("SAVE_RECEIPT_NOTE", saveReceiptNote)]);
  yield all([takeEvery("SAVE_EQUIPMENT_ALLOCATION", saveEquipmentAllocation)]);
  yield all([takeEvery("LIST_EQUIPMENT_ALLOCATION", listEquipmentAllocation)]);
  yield all([takeEvery("VIEW_EQUIPMENT_ALLOCATION", ViewEquipmentAllocation)]);
  yield all([takeEvery("RETURN_EQUIPMENT_ALLOCATION", ReturnEquipmentAllocation)]);
  yield all([takeEvery("LIST_DELIVERY_NOTE", listDeliveryNote)]);
  yield all([takeEvery("VIEW_DELIVERY_NOTE", ViewDeliveryNote)]);
  yield all([takeEvery("UPDATE_EXTERNAL_VENDOR_STATUS", update_service_status_external_vendor)]);
  yield all([takeEvery("FETCH_RECEIPTNOTE", fetchReceiptNote)]);
  yield all([takeEvery("FETCH_TASKREPORT", fetchTaskReport)]);
  yield all([takeEvery("DEPARTMENTWISE_BARCODE", departmentwisebarcode)]);
  yield all([takeEvery("GENERATEBARCODEBULK", generatebarcodebulk)]);
}

