import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes, filterUserError, filterUserSuccess, listUserByDepartmentIdError, listUserByDepartmentIdSuccess } from './action'
import userservice from '@service/admin/masterService/userService';
import {
    jobDepartmentSuccess, jobDepartmentError,
    jobDesignationSuccess, jobDesignationError,
    jobRolesSuccess, jobRolesError,
    saveUserSuccess, saveUserError,
    listJobSuccess, listJobError,
    listUserSuccess, listUserError,
    updateUserSuccess, updateUserError,
    deleteUserSuccess, deleteUserError,
    sendInvitationSuccess, sendInvitationError,
    getUserDetailsSuccess, getUserDetailsError,
    getRemunerationDetailsSuccess, getRemunerationDetailsError,
    insertRemunerationPackageSuccess, insertRemunerationPackageError,
    emailPhoneValidationSuccess, emailPhoneValidationError,
    importuserSuccess,importuserError,
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'

function* jobDepartment({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(userservice.jobDepartment, payload);
        yield put(jobDepartmentSuccess(data));
    } catch (error) {
        yield put(jobDepartmentError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* jobDesignation({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(userservice.jobDesignation, payload);
        yield put(jobDesignationSuccess(data));
    } catch (error) {
        yield put(jobDesignationError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* jobRoles({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(userservice.jobRoles, payload);
        Array.isArray(data) ?
            yield put(jobRolesSuccess(data)) : yield put(jobRolesError([]));
    } catch (error) {
        yield put(jobRolesError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* saveUser({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(userservice.saveUser, payload);

        yield put(saveUserSuccess(data));
    } catch (error) {
        yield put(saveUserError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* listJob({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(userservice.listjob, payload);
        yield put(listJobSuccess(data));
    } catch (error) {
        yield put(listJobError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* listUser({ payload }) {
    yield put(startLoader());
    try {
        yield put(startLoader());
        const data = yield call(userservice.listUser, payload);
        Array.isArray(data) ?
            yield put(listUserSuccess(data)) : yield put(listUserError([]));
    } catch (error) {
        yield put(listUserError(error));
    } finally {
        yield put(stopLoader());

    }
}

function* updateUser({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(userservice.updateUser, payload);
        yield put(updateUserSuccess(data));
    } catch (error) {
        yield put(updateUserError(error));
    } finally {
        yield put(stopLoader());

    }
}

function* deleteUser({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(userservice.deleteUser, payload);
        yield put(deleteUserSuccess(data));
    } catch (error) {
        yield put(deleteUserError(error));
    } finally {
        yield put(stopLoader());

    }
}

function* sendInvitation({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(userservice.sendInvitation, payload);
        yield put(sendInvitationSuccess(data));
    } catch (error) {
        yield put(sendInvitationError(error));
    } finally {
        yield put(stopLoader());

    }
}

function* filterUser({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(userservice.filterUser, payload);
        yield put(filterUserSuccess(data));
    } catch (error) {
        yield put(filterUserError(error));
    } finally {
        yield put(stopLoader());

    }
}

function* getUserDetails({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(userservice.getUserDetails, payload);
        yield put(getUserDetailsSuccess(data));
    } catch (error) {
        yield put(getUserDetailsError(error));
    } finally {
        yield put(stopLoader());

    }
}

function* getRemunerationDetails({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(userservice.getRemunerationDetails, payload);
        yield put(getRemunerationDetailsSuccess(data));
    } catch (error) {
        yield put(getRemunerationDetailsError(error));
    } finally {
        yield put(stopLoader());

    }
}

function* insertRemunerationPackage({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(userservice.insertRemunerationPackage, payload);
        yield put(insertRemunerationPackageSuccess(data));
    } catch (error) {
        yield put(insertRemunerationPackageError(error));
    } finally {
        yield put(stopLoader());

    }
}

function* emailPhoneValidation({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(userservice.emailPhoneValidation, payload);
        yield put(emailPhoneValidationSuccess(data));
    } catch (error) {
        yield put(emailPhoneValidationError(error));
    } finally {
        yield put(stopLoader());

    }
}

function* listUserByDepartmentId({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(userservice.listUserByDepartmentId, payload);
        yield put(listUserByDepartmentIdSuccess(data));
    } catch (error) {
        yield put(listUserByDepartmentIdError(error));
    } finally {
        yield put(stopLoader());

    }
}
function* importuser({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(userservice.importuser, payload);
        yield put(importuserSuccess(data));
    } catch (error) {
        yield put(importuserError(error));
    } finally {
        yield put(stopLoader());

    }
}

export default function* rootSaga() {
    yield all([takeEvery(actionTypes.JOB_DEPARTMENT, jobDepartment)]);
    yield all([takeEvery(actionTypes.JOB_DESIGNATION, jobDesignation)]);
    yield all([takeEvery(actionTypes.JOB_ROLES, jobRoles)]);
    yield all([takeEvery(actionTypes.SAVE_USER, saveUser)]);
    yield all([takeEvery(actionTypes.LIST_JOB, listJob)]);
    yield all([takeEvery(actionTypes.LIST_USER, listUser)]);
    yield all([takeEvery(actionTypes.UPDATE_USER, updateUser)]);
    yield all([takeEvery(actionTypes.DELETE_USER, deleteUser)]);
    yield all([takeEvery(actionTypes.SEND_INVITATION, sendInvitation)]);
    yield all([takeEvery(actionTypes.USER_FILTER, filterUser)]);
    yield all([takeEvery(actionTypes.GET_USER_DETAILS, getUserDetails)]);
    yield all([takeEvery("GET_REMUNERATION_DETAILS", getRemunerationDetails)]);
    yield all([takeEvery("INSERT_REMUNERATION_PACKAGE", insertRemunerationPackage)]);
    yield all([takeEvery("EMAIL_PHONE_VALIDATION", emailPhoneValidation)]);
    yield all([takeEvery("LIST_USER_BY_DEPARTMENT_ID", listUserByDepartmentId)]);
    yield all([takeEvery("IMPORT_USER",importuser )]);

}