
export const actionTypes = {
    
    LIST_DEAL_FOR_QUOTE: 'LIST_DEAL_FOR_QUOTE',
    LIST_DEAL_FOR_QUOTE_SUCCESS: 'LIST_DEAL_FOR_QUOTE_SUCCESS',
    LIST_DEAL_FOR_QUOTE_ERROR: 'LIST_DEAL_FOR_QUOTE_ERROR',
    
    SAVE_QUOTE: 'SAVE_QUOTE',
    SAVE_QUOTE_SUCCESS: 'SAVE_QUOTE_SUCCESS',
    SAVE_QUOTE_ERROR: 'SAVE_QUOTE_ERROR',

    LIST_QUOTE: 'LIST_QUOTE',
    LIST_QUOTE_SUCCESS: 'LIST_QUOTE_SUCCESS',
    LIST_QUOTE_ERROR: 'LIST_QUOTE_ERROR',

    LIST_QUOTE_BY_ID: 'LIST_QUOTE_BY_ID',
    LIST_QUOTE_BY_ID_SUCCESS: 'LIST_QUOTE_BY_ID_SUCCESS',
    LIST_QUOTE_BY_ID_ERROR: 'LIST_QUOTE_BY_ID_ERROR',

    LIST_QUOTE_BY_ID: 'LIST_QUOTE_BY_ID',
    LIST_QUOTE_BY_ID_SUCCESS: 'LIST_QUOTE_BY_ID_SUCCESS',
    LIST_QUOTE_BY_ID_ERROR: 'LIST_QUOTE_BY_ID_ERROR',

    UPDATE_QUOTE: 'UPDATE_QUOTE',
    UPDATE_QUOTE_SUCCESS: 'UPDATE_QUOTE_SUCCESS',
    UPDATE_QUOTE_ERROR: 'UPDATE_QUOTE_ERROR',

    GET_TERMS_AND_CONDITION: 'GET_TERMS_AND_CONDITION',
    GET_TERMS_AND_CONDITION_SUCCESS: 'GET_TERMS_AND_CONDITION_SUCCESS',
    GET_TERMS_AND_CONDITION_ERROR: 'GET_TERMS_AND_CONDITION_ERROR',

    DELETE_QUOTE: 'DELETE_QUOTE',
    DELETE_QUOTE_SUCCESS: 'DELETE_QUOTE_SUCCESS',
    DELETE_QUOTE_ERROR: 'DELETE_QUOTE_ERROR',
};


export const listDealForQuote = (payload) => {
    console.log(payload);
    return {
        type: actionTypes.LIST_DEAL_FOR_QUOTE, payload
    };
};
export const listDealForQuoteSuccess = (payload) => {
    return {
        type: actionTypes.LIST_DEAL_FOR_QUOTE_SUCCESS,
        payload
    };
};
export const listDealForQuoteError = (payload) => {
    return {
        type: actionTypes.LIST_DEAL_FOR_QUOTE_ERROR,
        payload
    };
};

export const saveQuote = (payload) => {
    console.log(payload);
    return {
        type: actionTypes.SAVE_QUOTE, payload
    };
};
export const saveQuoteSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_QUOTE_SUCCESS,
        payload
    };
};
export const saveQuoteError = (payload) => {
    return {
        type: actionTypes.SAVE_QUOTE_ERROR,
        payload
    };
};

export const listQuote = (payload) => {
    return {
        type: actionTypes.LIST_QUOTE, payload
    };
};
export const listQuoteSuccess = (payload) => {
    return {
        type: actionTypes.LIST_QUOTE_SUCCESS,
        payload
    };
};
export const listQuoteError = (payload) => {
    return {
        type: actionTypes.LIST_QUOTE_ERROR,
        payload
    };
};

export const fetchQuoteById = (payload) => {
    return {
        type: actionTypes.LIST_QUOTE_BY_ID, payload
    };
};
export const fetchQuoteByIdSuccess = (payload) => {
    return {
        type: actionTypes.LIST_QUOTE_BY_ID_SUCCESS,
        payload
    };
};
export const fetchQuoteByIdError = (payload) => {
    return {
        type: actionTypes.LIST_QUOTE_BY_ID_ERROR,
        payload
    };
};

export const updateQuote = (payload) => {
    return {
        type: actionTypes.UPDATE_QUOTE, payload
    };
};
export const updateQuoteSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_QUOTE_SUCCESS,
        payload
    };
};
export const updateQuoteError = (payload) => {
    return {
        type: actionTypes.UPDATE_QUOTE_ERROR,
        payload
    };
};

export const getTermsAndCondition = (payload) => {
    return {
        type: actionTypes.GET_TERMS_AND_CONDITION, payload
    };
};
export const getTermsAndConditionSuccess = (payload) => {
    return {
        type: actionTypes.GET_TERMS_AND_CONDITION_SUCCESS,
        payload
    };
};
export const getTermsAndConditionError = (payload) => {
    return {
        type: actionTypes.GET_TERMS_AND_CONDITION_ERROR,
        payload
    };
};

export const deleteQuote = (payload) => {
    return {
        type: actionTypes.DELETE_QUOTE, payload
    };
};
export const deleteQuoteSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_QUOTE_SUCCESS,
        payload
    };
};
export const deleteQuoteError = (payload) => {
    return {
        type: actionTypes.DELETE_QUOTE_ERROR,
        payload
    };
};