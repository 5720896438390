import { takeEvery, put, call, all } from 'redux-saga/effects';
import leaveService from '@service/admin/masterService/leaveService';

import {
    saveLeaveRequestSuccess, saveLeaveRequestError,
    fetchMyLeaveRequestSuccess, fetchMyLeaveRequestError,
    fetchLeaveTypeForRequestSuccess, fetchLeaveTypeForRequestError,
    updateLeaveRequestSuccess, updateLeaveRequestError,
    cancelRequestSuccess, cancelRequestError,
    fetchPmRequestSuccess, fetchPmRequestError,
    approveRequestSuccess, approveRequestError,
    rejectRequestSuccess, rejectRequestError,
    handleLeaveParametersSuccess, handleLeaveParametersError,
    fetchLeaveDashboardSuccess, fetchLeaveDashboardError,
} from './action';
import { stopLoader, startLoader } from '@store/common/loader/action'

function* updateLeaveRequest({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(leaveService.updateLeaveRequest, payload);
        yield put(updateLeaveRequestSuccess(data));
    } catch (error) {
        yield put(updateLeaveRequestError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* saveLeaveRequest({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(leaveService.saveLeaveRequest, payload);
        yield put(saveLeaveRequestSuccess(data));
    } catch (error) {
        yield put(saveLeaveRequestError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* fetchMyLeaveRequest({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(leaveService.fetchMyLeaveRequest, payload);
        yield put(fetchMyLeaveRequestSuccess(data));
    } catch (error) {
        yield put(fetchMyLeaveRequestError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* fetchLeaveTypeForRequest({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(leaveService.fetchLeaveTypeForRequest, payload);
        yield put(fetchLeaveTypeForRequestSuccess(data));
    } catch (error) {
        yield put(fetchLeaveTypeForRequestError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* cancelRequest({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(leaveService.cancelRequest, payload);
        yield put(cancelRequestSuccess(data));
    } catch (error) {
        yield put(cancelRequestError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* fetchPmRequest({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(leaveService.fetchPmRequest, payload);
        yield put(fetchPmRequestSuccess(data));
    } catch (error) {
        yield put(fetchPmRequestError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* approveRequest({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(leaveService.approveRequest, payload);
        yield put(approveRequestSuccess(data));
    } catch (error) {
        yield put(approveRequestError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* rejectRequest({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(leaveService.rejectRequest, payload);
        yield put(rejectRequestSuccess(data));
    } catch (error) {
        yield put(rejectRequestError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* handleLeaveParameters({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(leaveService.handleLeaveParameters, payload);
        yield put(handleLeaveParametersSuccess(data));
    } catch (error) {
        yield put(handleLeaveParametersError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* fetchLeaveDashboard({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(leaveService.fetchLeaveDashboard, payload);
        yield put(fetchLeaveDashboardSuccess(data));
    } catch (error) {
        yield put(fetchLeaveDashboardError(error));
    } finally {
        yield put(stopLoader());
    }
}

export default function* rootSaga() {
    yield all([takeEvery('SAVE_LEAVE_REQUEST', saveLeaveRequest)]);
    yield all([takeEvery('UPDATE_LEAVE_REQUEST', updateLeaveRequest)]);
    yield all([takeEvery('FETCH_MY_LEAVE_REQUEST', fetchMyLeaveRequest)]);
    yield all([takeEvery('FETCH_LEAVE_TYPE_FOR_REQUEST', fetchLeaveTypeForRequest)]);
    yield all([takeEvery('CANCEL_LEAVE_REQUEST', cancelRequest)]);
    yield all([takeEvery('FETCH_PM_LEAVE_REQUEST', fetchPmRequest)]);
    yield all([takeEvery('APPROVE_LEAVE_REQUEST', approveRequest)]);
    yield all([takeEvery('REJECT_LEAVE_REQUEST', rejectRequest)]);
    yield all([takeEvery('CHECK_LEAVE_PARAMETERS', handleLeaveParameters)]);
    yield all([takeEvery('FETCH_LEAVE_DASHBOARD', fetchLeaveDashboard)]);
}