
import { actionTypes } from './action';

export const initialState = {
    saveBrandStatus: '',
    listBrandValue: [],
    updateStatus: '',
    deleteStatus: ''
}

const saveBrandReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_BRAND:
            return { ...state, saveBrandStatus: '' }
        case actionTypes.SAVE_BRAND_SUCCESS:
            return { ...state, saveBrandStatus: action.payload }
        case actionTypes.SAVE_BRAND_ERROR:
            return { ...state, saveBrandStatus: '' }

        case actionTypes.LIST_BRAND:
            return { ...state, listBrandValue: [], saveBrandStatus: '', updateStatus: '', deleteStatus: '' }
        case actionTypes.LIST_BRAND_SUCCESS:
            return {
                ...state,
                listBrandValue: action.payload,
                saveBrandStatus: '',
                updateStatus: '',
                deleteStatus: ''
            }
        case actionTypes.LIST_BRAND_ERROR:
            return {
                ...state, listBrandValue: [],
                saveBrandStatus: '',
                updateStatus: '',
                deleteStatus: ''
            }

        case actionTypes.UPDATE_BRAND:
            return { ...state, updateStatus: '' }
        case actionTypes.UPDATE_BRAND_SUCCESS:
            return { ...state, updateStatus: action.payload }
        case actionTypes.UPDATE_BRAND_ERROR:
            return { ...state, updateStatus: '' }

        case actionTypes.DELETE_BRAND:
            return { ...state, deleteStatus: '' }
        case actionTypes.DELETE_BRAND_SUCCESS:
            return { ...state, deleteStatus: action.payload }
        case actionTypes.DELETE_BRAND_ERROR:
            return { ...state, deleteStatus: 'error' }

        default:
            return state
    }
}

export default saveBrandReducer