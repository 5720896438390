import { takeEvery, put, call, all } from 'redux-saga/effects';
import meetingservice from '../../../service/admin/meetingService/meetingService';
import {
  FetchParticipantsSuccess, FetchParticipantsError,
  saveMeetingSuccess, saveMeetingError,
  FetchMeetingSuccess, FetchMeetingError,
  removeMeetingSuccess, removeMeetingError,
  fetchMeetingByIDSuccess, fetchMeetingByIDError,
  updateMeetingSuccess, updateMeetingError
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'


function* saveMeeting({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(meetingservice.addMeeting, payload);
    yield put(saveMeetingSuccess(data));
  } catch (error) {
    yield put(saveMeetingError(error));
  } finally {
    yield put(stopLoader());
  }
}


function* FetchParticipants({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(meetingservice.fetchParticipants, payload);
    Array.isArray(data) ?
      yield put(FetchParticipantsSuccess(data)) :
      yield put(FetchParticipantsError([]));
  } catch (error) {
    yield put(FetchParticipantsError(error));
  } finally {
    yield put(stopLoader());
  }
}


function* FetchMeeting({ payload }) {
  try {

    yield put(startLoader());
    const data = yield call(meetingservice.fetchMeetinglist, payload);
    Array.isArray(data) ?
      yield put(FetchMeetingSuccess(data)) : yield put(FetchMeetingError([]));

  } catch (error) {
    yield put(FetchMeetingError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* deleteMeeting({ payload }) {
  try {

    yield put(startLoader());
    const data = yield call(meetingservice.deleteMeeting, payload);

    yield put(removeMeetingSuccess(data))

  } catch (error) {
    yield put(removeMeetingError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* getMeetingByID({ payload }) {
  try {

    yield put(startLoader());
    const data = yield call(meetingservice.getMeetingByID, payload);

    yield put(fetchMeetingByIDSuccess(data))

  } catch (error) {
    yield put(fetchMeetingByIDError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* editMeeting({ payload }) {
  try {

    yield put(startLoader());
    const data = yield call(meetingservice.editMeeting, payload);

    yield put(updateMeetingSuccess(data))

  } catch (error) {
    yield put(updateMeetingError(error));
  } finally {
    yield put(stopLoader());
  }
}


export default function* rootSaga() {
  yield all([takeEvery('FETCH_PARTICIPANTS', FetchParticipants)]);
  yield all([takeEvery('ADD_MEETING', saveMeeting)]);
  yield all([takeEvery('FETCH_MEETING', FetchMeeting)]);
  yield all([takeEvery('DELETE_MEETING', deleteMeeting)]);
  yield all([takeEvery('FETCH_MEETING_BYID', getMeetingByID)]);
  yield all([takeEvery('UPDATE_MEETING', editMeeting)]);
}