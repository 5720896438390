import axios from '@service/ApiClient'

class generalSettingService {
    constructor(callback) {
        this.callback = callback
    }
    async saveGeneralSettings(payload) {
        return await axios.post('/Organization/Generalsettings/save_general_settings', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listGeneralSettings(payload) {
        return await axios.post('/Organization/Generalsettings/list_general_settings', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updateGeneralSettings(payload) {
        return await axios.post('/Organization/Generalsettings/update_general_settings', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listAreaName(payload) {
        return await axios.post('/Organization/Generalsettings/get_data_for_settings', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listTimeZone(payload) {
        return await axios.post('/Organization/Generalsettings/get_timezone_by_area', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new generalSettingService();