import { takeEvery, put, call, all } from 'redux-saga/effects';
import itemService from '@service/admin/masterService/itemService';
import { actionTypes, fetchItemByIDError, fetchItemByIDSuccess } from './action'
import {
    saveItemSuccess, saveItemError,
    listItemSuccess, listItemError,
    deleteItemSuccess, deleteItemError,
    updateItemSuccess, updateItemError,
    listCategorySuccess, listCategoryError,
    listSubCategorySuccess, listSubCategoryError,
    listBrandSuccess, listBrandError,
    getitemByCategoryIdError, getitemByCategoryIdSuccess,
    importitemSuccess,importitemError,
} from './action';

function* saveItem({ payload }) {
    try {
        const data = yield call(itemService.saveItem, payload);
        yield put(saveItemSuccess(data));
    } catch (error) {
        yield put(saveItemError(error));
    } finally {
    }
}


function* listItem({ payload }) {
    // console.log(payload);
    try {
        const data = yield call(itemService.listItem, payload);
        Array.isArray(data) ?
            yield put(listItemSuccess(data)) : yield put(listItemError([]));
    } catch (error) {
        yield put(listItemError(error));
    } finally {
    }
}

function* updateItem({ payload }) {
    try {
        const data = yield call(itemService.updateItem, payload);

        yield put(updateItemSuccess(data));
    } catch (error) {
        yield put(updateItemError(error));
    } finally {
    }
}

function* deleteItem({ payload }) {
    try {
        const data = yield call(itemService.deleteItem, payload);

        yield put(deleteItemSuccess(data));
    } catch (error) {
        yield put(deleteItemError(error));
    } finally {
    }
}

function* listCategory({ payload }) {
    try {
        const data = yield call(itemService.listCategory, payload);
        Array.isArray(data) ?
            yield put(listCategorySuccess(data)) :
            yield put(listCategorySuccess([]));
    } catch (error) {
        yield put(listCategoryError(error));
    } finally {
    }
}

function* listSubCategory({ payload }) {
    try {
        const data = yield call(itemService.listSubCategory, payload);
        Array.isArray(data) ?
            yield put(listSubCategorySuccess(data)) :
            yield put(listSubCategorySuccess([]));
    } catch (error) {
        yield put(listSubCategoryError(error));
    } finally {
    }
}

function* listBrand({ payload }) {
    try {
        const data = yield call(itemService.listBrand, payload);
        Array.isArray(data) ?
            yield put(listBrandSuccess(data)) :
            yield put(listBrandSuccess(data));
    } catch (error) {
        yield put(listBrandError(error));
    } finally {
    }
}

function* fetchItemByID({ payload }) {
    try {
        const data = yield call(itemService.fetchItemByID, payload);

        yield put(fetchItemByIDSuccess(data));
    } catch (error) {
        yield put(fetchItemByIDError(error));
    } finally {
    }
}

function* getitemByCategoryId({ payload }) {
    try {
        const data = yield call(itemService.getitemByCategoryId, payload);

        yield put(getitemByCategoryIdSuccess(data));
    } catch (error) {
        yield put(getitemByCategoryIdError(error));
    } finally {
    }
}

function* importitem({ payload }) {
    try {
     
      const data = yield call(itemService.importitem, payload);
    //   console.log("payload",payload);
      yield put(importitemSuccess(data));
    } catch (error) {
      yield put(importitemError(error));
    } finally {
     
    }
  }
export default function* rootSaga() {
    yield all([takeEvery(actionTypes.SAVE_ITEM, saveItem)]);
    yield all([takeEvery(actionTypes.LIST_ITEMS, listItem)]);
    yield all([takeEvery(actionTypes.UPDATE_ITEM, updateItem)]);
    yield all([takeEvery(actionTypes.DELETE_ITEM, deleteItem)]);
    yield all([takeEvery(actionTypes.LIST_ITEM_CATEGORY, listCategory)]);
    yield all([takeEvery(actionTypes.LIST_ITEM_SUBCATEGORY, listSubCategory)]);
    yield all([takeEvery(actionTypes.LIST_ITEM_BRAND, listBrand)]);
    yield all([takeEvery(actionTypes.FETCH_ITEM_BY_ID, fetchItemByID)]);
    yield all([takeEvery(actionTypes.GET_ITEM_BY_CATEGORY_ID, getitemByCategoryId)]);
    yield all([takeEvery('IMPORT_ITEM', importitem)]);

}