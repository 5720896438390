import { actionTypes } from './action';

export const initialState = {
    countryList: '',
    regionalLevelData: [],
    regionalLevel: [],
};

function reducer(state = initialState, action) {
    switch (action.type) {

        case actionTypes.FETCH_COUNTRY_DETAILS:

            return {
                ...state, countryList: [], error: null, isLoading: true,
            };

        case actionTypes.FETCH_COUNTRY_DETAILS_SUCCESS:
            return {
                ...state,
                countryList: action.data,
                error: null,
                isLoading: false,
            };

        case actionTypes.FETCH_COUNTRY_DETAILS_ERROR:
            return {
                ...state,
                countryList: [],
                error: action.error,
                isLoading: false
            };


        case actionTypes.FETCH_REGIONAL_LEVEL_DATA:

            return {
                ...state, regionalLevelData: [], error: null, isLoading: true,
            };

        case actionTypes.FETCH_REGIONAL_LEVEL_DATA_SUCCESS:
            return {
                ...state,
                regionalLevelData: action.data,
                error: null,
                isLoading: false,
            };

        case actionTypes.FETCH_REGIONAL_LEVEL_DATA_ERROR:
            return {
                ...state,
                regionalLevelData: [],
                error: action.error,
                isLoading: false
            };

        case actionTypes.FETCH_REGIONAL_LEVEL_BY_COUNTRY_ID:

            return {
                ...state, regionalLevel: [], error: null, isLoading: true,
            };

        case actionTypes.FETCH_REGIONAL_LEVEL_BY_COUNTRY_ID_SUCCESS:
            return {
                ...state,
                regionalLevel: action.data,
                error: null,
                isLoading: false,
            };

        case actionTypes.FETCH_REGIONAL_LEVEL_BY_COUNTRY_ID_ERROR:
            return {
                ...state,
                regionalLevel: [],
                error: action.error,
                isLoading: false
            };

        default:
            return state;
    }
}

export default reducer;
