import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import weekendMasterService from '@service/admin/masterService/weekendMasterService';

import {
  saveWeekendSuccess, saveWeekendError,
  deleteWeekendSuccess, deleteWeekendError,
  updateWeekendSuccess, updateWeekendError,
  listWeekendSuccess, listWeekendError
} from './action';

function* saveWeekend({ payload }) {
  try {
    const data = yield call(weekendMasterService.saveWeekend, payload);

    yield put(saveWeekendSuccess(data));
  } catch (error) {
    yield put(saveWeekendError(error));
  } finally {
  }
}

function* listWeekend({ payload }) {
  try {
    const data = yield call(weekendMasterService.listWeekend, payload);

    yield put(listWeekendSuccess(data));
  } catch (error) {
    yield put(listWeekendError(error));
  } finally {
  }
}

function* deleteWeekend({ payload }) {
  try {
    const data = yield call(weekendMasterService.deleteWeekend, payload);

    yield put(deleteWeekendSuccess(data));
  } catch (error) {
    yield put(deleteWeekendError(error));
  } finally {
  }
}

function* updateWeekend({ payload }) {
  try {
    const data = yield call(weekendMasterService.updateWeekend, payload);

    yield put(updateWeekendSuccess(data));
  } catch (error) {
    yield put(updateWeekendError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_WEEKEND, saveWeekend)]);
  yield all([takeEvery(actionTypes.DELETE_WEEKEND, deleteWeekend)]);
  yield all([takeEvery(actionTypes.UPDATE_WEEKEND, updateWeekend)]);
  yield all([takeEvery(actionTypes.LIST_WEEKEND, listWeekend)]);

}