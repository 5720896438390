import axios from '@service/ApiClient'
class salaryallowanceService {
    constructor(callback) {
        this.callback = callback
    }

    async fetchBuParameter(payload) {
        return await axios.post('/Payroll/Salarymaster/get_all_parameters_by_bu_type', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listSalaryParameter(payload) {
        return await axios.post('/Payroll/Salarymaster/list_all_salaryallowancemaster', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async saveSalaryParameter(payload) {
        return await axios.post('/Payroll/Salarymaster/insert_salarymaster', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchSalaryParameter(payload) {
        return await axios.post('/Payroll/Salarymaster/fetch_rates_by_designationid', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new salaryallowanceService();