import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import familyDetailsService from '@service/admin/masterService/familyDetailsService';
import {
  saveFamilyDetailsSuccess, saveFamilyDetailsError,
  listFamilyDetailsSuccess, listFamilyDetailsError,
  updateFamilyDetailsSuccess, updateFamilyDetailsError,
  deleteFamilyDetailsSuccess, deleteFamilyDetailsError,
} from './action';

function* saveFamilyDetails({ payload }) {
  try {
    const data = yield call(familyDetailsService.saveFamilyDetails, payload);

    yield put(saveFamilyDetailsSuccess(data));
  } catch (error) {
    yield put(saveFamilyDetailsError(error));
  } finally {
  }
}

function* listFamilyDetails({ payload }) {
  try {
    const data = yield call(familyDetailsService.listFamilyDetails, payload);

    yield put(listFamilyDetailsSuccess(data));
  } catch (error) {
    yield put(listFamilyDetailsError(error));
  } finally {
  }
}

function* updateFamilyDetails({ payload }) {
  try {
    const data = yield call(familyDetailsService.updateFamilyDetails, payload);

    yield put(updateFamilyDetailsSuccess(data));
  } catch (error) {
    yield put(updateFamilyDetailsError(error));
  } finally {
  }
}

function* deleteFamilyDetails({ payload }) {
  try {
    const data = yield call(familyDetailsService.deleteFamilyDetails, payload);

    yield put(deleteFamilyDetailsSuccess(data));
  } catch (error) {
    yield put(deleteFamilyDetailsError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_FAMILYDETAILS, saveFamilyDetails)]);
  yield all([takeEvery(actionTypes.LIST_FAMILYDETAILS, listFamilyDetails)]);
  yield all([takeEvery(actionTypes.UPDATE_FAMILYDETAILS, updateFamilyDetails)]);
  yield all([takeEvery(actionTypes.DELETE_FAMILYDETAILS, deleteFamilyDetails)]);

}