import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import employmentDetailsService from '@service/admin/masterService/employmentDetailsService';
import {
  saveEmploymentDetailsSuccess, saveEmploymentDetailsError,
  listEmploymentDetailsSuccess, listEmploymentDetailsError,
  updateEmploymentDetailsSuccess, updateEmploymentDetailsError,
  deleteEmploymentDetailsSuccess, deleteEmploymentDetailsError,
} from './action';

function* saveEmploymentDetails({ payload }) {
  try {
    const data = yield call(employmentDetailsService.saveEmploymentDetails, payload);

    yield put(saveEmploymentDetailsSuccess(data));
  } catch (error) {
    yield put(saveEmploymentDetailsError(error));
  } finally {
  }
}

function* listEmploymentDetails({ payload }) {
  try {
    const data = yield call(employmentDetailsService.listEmploymentDetails, payload);

    yield put(listEmploymentDetailsSuccess(data));
  } catch (error) {
    yield put(listEmploymentDetailsError(error));
  } finally {
  }
}

function* updateEmploymentDetails({ payload }) {
  try {
    const data = yield call(employmentDetailsService.updateEmploymentDetails, payload);

    yield put(updateEmploymentDetailsSuccess(data));
  } catch (error) {
    yield put(updateEmploymentDetailsError(error));
  } finally {
  }
}

function* deleteEmploymentDetails({ payload }) {
  try {
    const data = yield call(employmentDetailsService.deleteEmploymentDetails, payload);

    yield put(deleteEmploymentDetailsSuccess(data));
  } catch (error) {
    yield put(deleteEmploymentDetailsError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_EMPLOYMENTDETAILS, saveEmploymentDetails)]);
  yield all([takeEvery(actionTypes.LIST_EMPLOYMENTDETAILS, listEmploymentDetails)]);
  yield all([takeEvery(actionTypes.UPDATE_EMPLOYMENTDETAILS, updateEmploymentDetails)]);
  yield all([takeEvery(actionTypes.DELETE_EMPLOYMENTDETAILS, deleteEmploymentDetails)]);

}