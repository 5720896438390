import axios from '../../ApiClient'
// import { siteUrl } from './config'

class shiftservice {
    constructor(callback) {
        this.callback = callback
    }

    async listShift(payload) {
        return await axios.post('/HR/Shiftdetails/list_shift', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async saveShift(payload) {
        return await axios.post('/HR/Shiftdetails/insert_shift_details', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateShift(payload) {
        return await axios.post('/HR/Shiftdetails/update_shift_details', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async getShift(payload) {
        return await axios.post('/HR/Shiftdetails/get_shift_by_id', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteShift(payload) {
        return await axios.post('/HR/Shiftdetails/delete_shift', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    // Hr/ Leave type page

    async fetchDesignation(payload) {
        return await axios.post('/organization/Designation/list_designation', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async saveLeaveType(payload) {
        return await axios.post('/HR/Leave/add_leave_type_master', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchLeave(payload) {
        return await axios.post('/HR/Leave/list_leave_type', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateLeaveType(payload) {
        return await axios.post('/HR/Leave/update_leave_type_master', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteLeave(payload) {
        return await axios.post('/HR/Leave/delete_leavetype', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchLeaveid(payload) {
        return await axios.post('/HR/Leave/edit_leavetype', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    // RULES
    async listLeavetype(payload) {
        return await axios.post('/HR/Leave/list_leave_type', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async saveRule(payload) {
        return await axios.post('/HR/Leave/create_leave_type_rule', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateRule(payload) {
        return await axios.post('/HR/Leave/edit_leavetype', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async editRule(payload) {
        return await axios.post('/HR/Leave/update_leave_type_rule', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    //  leaveRequest 
    async saveLeaverequest(payload) {
        return await axios.post('/HR/Leave/insert_leave_request', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    
    async fetchLeaverequest(payload) {
        return await axios.post('/HR/Leave/list_my_leave_request', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
     async fetchLeavetype(payload) {
        return await axios.post('/HR/Leave/get_leave_type_master_for_request', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
     async editLeavetype(payload) {
        return await axios.post('/HR/Leave/fetch_leaverequest_by_id_edit', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
     async updateLeavetype(payload) {
        return await axios.post('/HR/Leave/updateleaverequest', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
     async deleteLeavetype(payload) {
        return await axios.post('/HR/Leave/delete_leave_request', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
     async cancelLeaverequest(payload) {
        return await axios.post('/HR/Leave/cancel_leave', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
      async updaterequestleavestatus(payload) {
        return await axios.post('/HR/Leave/update_leave_request_status', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
     async fetchrequest(payload) {
        return await axios.post('/HR/Leaveapproval/view_request', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
     async approverequest(payload) {
        return await axios.post('/HR/Leaveapproval/approve_leave_request', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async rejectRequest(payload) {
        return await axios.post('/HR/Leaveapproval/reject_leave_request', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new shiftservice();