
import { actionTypes } from './action';

export const initialState = {
    listPrefixValues: [],
    updatePrefixValues: ''
}

const PrefixReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.LIST_PREFIX:
            return { ...state, listPrefixValues: [], updatePrefixValues: '' }
        case actionTypes.LIST_PREFIX_SUCCESS:
            return { ...state, listPrefixValues: action.payload, updatePrefixValues: '' }
        case actionTypes.LIST_PREFIX_ERROR:
            return { ...state, listPrefixValues: [], updatePrefixValues: '' }
        case actionTypes.UPDATE_PREFIX:
            return { ...state, updatePrefixValues: '', listPrefixValues: [] }
        case actionTypes.UPDATE_PREFIX_SUCCESS:
            return { ...state, updatePrefixValues: action.payload, listPrefixValues: [] }
        case actionTypes.UPDATE_PREFIX_ERROR:
            return { ...state, updatePrefixValues: '', listPrefixValues: [] }

        default:
            return state
    }
}

export default PrefixReducer