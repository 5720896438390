import axios from '@service/ApiClient'

class listItemService {
    constructor(callback) {
        this.callback = callback
    }
    async listItem(payload) {
        return await axios.post('Inventory/Item/list_items', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async savePurchaseRequest(payload) {
        return await axios.post('Inventory/PurchaseRequest/save_purchase_request', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async listPurchaseRequest(payload) {
        return await axios.post('Inventory/PurchaseRequest/list_purchaselist', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updatePurchaseRequest(payload) {
        return await axios.post('Inventory/PurchaseRequest/update_pr_details', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deletePurchaseRequest(payload) {
        return await axios.post('Inventory/PurchaseRequest/delete_purchaselist', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchPrById(payload) {
        return await axios.post('Inventory/PurchaseRequest/get_pr_detals_by_prid', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new listItemService();