import { takeEvery, put, call, all } from 'redux-saga/effects';
import masterservice from '@service/admin/serviceMaster/masterservice';
import {
    actionTypes,
    assignEmployeeServiceError,
    assignEmployeeServiceSuccess,
    fetchServiceMasterError,
    fetchServiceMasterSuccess,
    fetchServiceRoosterError,
    fetchServiceRoosterSuccess,
    handleServiceRoosterError,
    handleServiceRoosterSuccess,
    removeServiceRoosterError,
    removeServiceRoosterSuccess,
} from './action';

function* listServiceMaster({ payload }) {
    try {
        const data = yield call(masterservice.listServiceMaster, payload);
        Array.isArray(data) ?
            yield put(fetchServiceMasterSuccess(data)) :
            yield put(fetchServiceMasterSuccess([]))
    } catch (error) {
        yield put(fetchServiceMasterError(error));
    } finally {
    }
}

function* saveServiceRooster({ payload }) {
    try {
        const data = yield call(masterservice.saveServiceRooster, payload);
        yield put(handleServiceRoosterSuccess(data))
    } catch (error) {
        yield put(handleServiceRoosterError(error));
    } finally {
    }
}

function* listServiceRooster({ payload }) {
    try {
        const data = yield call(masterservice.listServiceRooster, payload);
        Array.isArray(data) ?
            yield put(fetchServiceRoosterSuccess(data)) :
            yield put(fetchServiceRoosterSuccess([]))
    } catch (error) {
        yield put(fetchServiceRoosterError(error));
    } finally {
    }
}

function* deleteServiceRooster({ payload }) {
    try {
        const data = yield call(masterservice.deleteServiceRooster, payload);

        yield put(removeServiceRoosterSuccess(data))
    } catch (error) {
        yield put(removeServiceRoosterError(error));
    } finally {
    }
}

function* assignEmployeeService({ payload }) {
    try {
        const data = yield call(masterservice.assignEmployeeService, payload);
        yield put(assignEmployeeServiceSuccess(data))
    } catch (error) {
        yield put(assignEmployeeServiceError(error));
    } finally {
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actionTypes.FETCH_SERVICE_MASTER, listServiceMaster)]);
    yield all([takeEvery(actionTypes.SAVE_SERVICE_ROOSTER, saveServiceRooster)]);
    yield all([takeEvery(actionTypes.FETCH_SERVICE_ROOSTER, listServiceRooster)]);
    yield all([takeEvery(actionTypes.DELETE_SERVICE_ROOSTER, deleteServiceRooster)]);
    yield all([takeEvery(actionTypes.ASSIGN_EMPLOYEE_SERVICE, assignEmployeeService)]);

}