export const actionTypes = {
  ADD_BUILDINGS: "ADD_BUILDINGS",
  ADD_BUILDINGS_SUCCESS: "ADD_BUILDINGS_SUCCESS",
  ADD_BUILDINGS_ERROR: "ADD_BUILDINGS_ERROR",

  LIST_BUILDINGS: "LIST_BUILDINGS",
  LIST_BUILDINGS_SUCCESS: "LIST_BUILDINGS_SUCCESS",
  LIST_BUILDINGS_ERROR: "LIST_BUILDINGS_ERROR",

  LIST_FLOORS: "LIST_FLOORS",
  LIST_FLOORS_SUCCESS: "LIST_FLOORS_SUCCESS",
  LIST_FLOORS_ERROR: "LIST_FLOORS_ERROR",

  SAVE_FLOOR: "SAVE_FLOOR",
  SAVE_FLOOR_SUCCESS: "SAVE_FLOOR_SUCCESS",
  SAVE_FLOOR_ERROR: "SAVE_FLOOR_ERROR",

  LIST_LOC_TYPE: "LIST_LOC_TYPE",
  LIST_LOC_TYPE_SUCCESS: "LIST_LOC_TYPE_SUCCESS",
  LIST_LOC_TYPE_ERROR: "LIST_LOC_TYPE_ERROR",

  SAVE_LOC_TYPE: "SAVE_LOC_TYPE",
  SAVE_LOC_TYPE_SUCCESS: "SAVE_LOC_TYPE_SUCCESS",
  SAVE_LOC_TYPE_ERROR: "SAVE_LOC_TYPE_ERROR",
  LIST_LOC_TYPE_ERROR: "LIST_LOC_TYPE_ERROR",

  SAVE_LOCATION_NAME: "SAVE_LOCATION_NAME",
  SAVE_LOCATION_NAME_SUCCESS: "SAVE_LOCATION_NAME_SUCCESS",
  SAVE_LOCATION_NAME_ERROR: "SAVE_LOCATION_NAME_ERROR",

  LIST_LOCATION_NAME: "LIST_LOCATION_NAME",
  LIST_LOCATION_NAME_SUCCESS: "LIST_LOCATION_NAME_SUCCESS",
  LIST_LOCATION_NAME_ERROR: "LIST_LOCATION_NAME_ERROR",

  LIST_LOCATION_BUILDING_FLOOR: "LIST_LOCATION_BUILDING_FLOOR",
  LIST_LOCATION_BUILDING_FLOOR_SUCCESS: "LIST_LOCATION_BUILDING_FLOOR_SUCCESS",
  LIST_LOCATION_BUILDING_FLOOR_ERROR: "LIST_LOCATION_BUILDING_FLOOR_ERROR",

  ADD_ASSET_TO_LOCATION: "ADD_ASSET_TO_LOCATION",
  ADD_ASSET_TO_LOCATION_SUCCESS: "ADD_ASSET_TO_LOCATION_SUCCESS",
  ADD_ASSET_TO_LOCATION_ERROR: "ADD_ASSET_TO_LOCATION_ERROR",


  GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
  GET_DASHBOARD_DATA_SUCCESS: "GET_DASHBOARD_DATA_SUCCESS",
  GET_DASHBOARD_DATA_ERROR: "GET_DASHBOARD_DATA_ERROR",

  GET_TOP_ASSET: "GET_TOP_ASSET",
  GET_TOP_ASSET_SUCCESS: "GET_TOP_ASSET_SUCCESS",
  GET_TOP_ASSET_ERROR: "GET_TOP_ASSET_ERROR",

  BUSINESS_RULE: "BUSINESS_RULE",
  BUSINESS_RULE_SUCCESS: "BUSINESS_RULE_SUCCESS",
  BUSINESS_RULE_ERROR: "BUSINESS_RULE_ERROR",
};

export const addbuildings = (payload) => {
  return {
    type: actionTypes.ADD_BUILDINGS,
    payload,
  };
};
export const addbuildingsSucces = (payload) => {
  return {
    type: actionTypes.ADD_BUILDINGS_SUCCESS,
    payload,
  };
};
export const addbuildingsError = (payload) => {
  return {
    type: actionTypes.ADD_BUILDINGS_ERROR,
    payload,
  };
};

export const listbuildings = (payload) => {
  return {
    type: actionTypes.LIST_BUILDINGS,
    payload,
  };
};
export const listbuildingsSucces = (payload) => {
  return {
    type: actionTypes.LIST_BUILDINGS_SUCCESS,
    payload,
  };
};
export const listbuildingsError = (payload) => {
  return {
    type: actionTypes.LIST_BUILDINGS_ERROR,
    payload,
  };
};

export const listFloors = (payload) => {
  return {
    type: actionTypes.LIST_FLOORS,
    payload,
  };
};
export const listFloorsSucces = (payload) => {
  return {
    type: actionTypes.LIST_FLOORS_SUCCESS,
    payload,
  };
};
export const listFloorsError = (payload) => {
  return {
    type: actionTypes.LIST_FLOORS_ERROR,
    payload,
  };
};

export const saveFloor = (payload) => {
  return {
    type: actionTypes.SAVE_FLOOR,
    payload,
  };
};
export const saveFloorSucces = (payload) => {
  return {
    type: actionTypes.SAVE_FLOOR_SUCCESS,
    payload,
  };
};
export const saveFloorError = (payload) => {
  return {
    type: actionTypes.SAVE_FLOOR_ERROR,
    payload,
  };
};

export const saveLocType = (payload) => {
  return {
    type: actionTypes.SAVE_LOC_TYPE,
    payload,
  };
};
export const saveLocTypeSucces = (payload) => {
  return {
    type: actionTypes.SAVE_LOC_TYPE_SUCCESS,
    payload,
  };
};
export const saveLocTypeError = (payload) => {
  return {
    type: actionTypes.SAVE_LOC_TYPE_ERROR,
    payload,
  };
};

export const listLocType = (payload) => {
  return {
    type: actionTypes.LIST_LOC_TYPE,
    payload,
  };
};
export const listLocTypeSucces = (payload) => {
  return {
    type: actionTypes.LIST_LOC_TYPE_SUCCESS,
    payload,
  };
};
export const listLocTypeError = (payload) => {
  return {
    type: actionTypes.LIST_LOC_TYPE_ERROR,
    payload,
  };
};

export const saveLocationName = (payload) => {
  return {
    type: actionTypes.SAVE_LOCATION_NAME,
    payload,
  };
};
export const saveLocationNameSucces = (payload) => {
  return {
    type: actionTypes.SAVE_LOCATION_NAME_SUCCESS,
    payload,
  };
};
export const saveLocationNameError = (payload) => {
  return {
    type: actionTypes.SAVE_LOCATION_NAME_ERROR,
    payload,
  };
};
export const getDashboardData = (payload) => {
  return {
    type: actionTypes.GET_DASHBOARD_DATA,
    payload,
  };
};
export const getDashboardDataSucces = (payload) => {
  return {
    type: actionTypes.GET_DASHBOARD_DATA_SUCCESS,
    payload,
  };
};
export const getDashboardDataError = (payload) => {
  return {
    type: actionTypes.GET_DASHBOARD_DATA_ERROR,
    payload,
  };
};
export const getTopAsset = (payload) => {

  

  return {
    type: actionTypes.GET_TOP_ASSET,
    payload,
  };
};
export const getTopAssetSucces = (payload) => {
  return {
    type: actionTypes.GET_TOP_ASSET_SUCCESS,
    payload,
  };
};
export const getTopAssetError = (payload) => {
  return {
    type: actionTypes.GET_TOP_ASSET_ERROR,
    payload,
  };
};
export const businessrule = (payload) => {

  

  return {
    type: actionTypes.BUSINESS_RULE,
    payload,
  };
};
export const businessruleSucces = (payload) => {
  return {
    type: actionTypes.BUSINESS_RULE_SUCCESS,
    payload,
  };
};
export const businessruleError = (payload) => {
  return {
    type: actionTypes.BUSINESS_RULE_ERROR,
    payload,
  };
};

export const deleteLocationName = (payload) => {
  return {
    type: 'DELETE_LOCATION_NAME',
    payload,
  };
};
export const deleteLocationNameSucces = (payload) => {
  return {
    type: 'DELETE_LOCATION_NAME_SUCCESS',
    payload,
  };
};
export const deleteLocationNameError = (payload) => {
  return {
    type: 'DELETE_LOCATION_NAME_ERROR',
    payload,
  };
};

export const updateLocationName = (payload) => {
  return {
    type: 'UPDATE_LOCATION_NAME',
    payload,
  };
};
export const updateLocationNameSucces = (payload) => {
  return {
    type: 'UPDATE_LOCATION_NAME_SUCCESS',
    payload,
  };
};
export const updateLocationNameError = (payload) => {
  return {
    type: 'UPDATE_LOCATION_NAME_ERROR',
    payload,
  };
};

export const listLocationName = (payload) => {
  return {
    type: actionTypes.LIST_LOCATION_NAME,
    payload,
  };
};
export const listLocationNameSucces = (payload) => {
  return {
    type: actionTypes.LIST_LOCATION_NAME_SUCCESS,
    payload,
  };
};
export const listLocationNameError = (payload) => {
  return {
    type: actionTypes.LIST_LOCATION_NAME_ERROR,
    payload,
  };
};

export const listLocationBuldingFloor = (payload) => {
  return {
    type: actionTypes.LIST_LOCATION_BUILDING_FLOOR,
    payload,
  };
};
export const listLocationBuldingFloorSucces = (payload) => {
  return {
    type: actionTypes.LIST_LOCATION_BUILDING_FLOOR_SUCCESS,
    payload,
  };
};
export const listLocationBuldingFloorError = (payload) => {
  return {
    type: actionTypes.LIST_LOCATION_BUILDING_FLOOR_ERROR,
    payload,
  };
};

export const addAssetToLocation = (payload) => {
  return {
    type: actionTypes.ADD_ASSET_TO_LOCATION,
    payload,
  };
};
export const addAssetToLocationSucces = (payload) => {
  return {
    type: actionTypes.ADD_ASSET_TO_LOCATION_SUCCESS,
    payload,
  };
};
export const addAssetToLocationError = (payload) => {
  return {
    type: actionTypes.ADD_ASSET_TO_LOCATION_ERROR,
    payload,
  };
};

export const addEquipmentCategory = (payload) => {
  return {
    type: "ADD_EQUIPMENT_CATEGORY",
    payload,
  };
};
export const addEquipmentCategorySucces = (payload) => {
  return {
    type: "ADD_EQUIPMENT_CATEGORY_SUCCESS",
    payload,
  };
};
export const addEquipmentCategoryError = (payload) => {
  return {
    type: "ADD_EQUIPMENT_CATEGORY_ERROR",
    payload,
  };
};

export const listEquipmentCategory = (payload) => {
  return {
    type: "LIST_EQUIPMENT_CATEGORY",
    payload,
  };
};
export const listEquipmentCategorySucces = (payload) => {
  return {
    type: "LIST_EQUIPMENT_CATEGORY_SUCCESS",
    payload,
  };
};
export const listEquipmentCategoryError = (payload) => {
  return {
    type: "LIST_EQUIPMENT_CATEGORY_ERROR",
    payload,
  };
};

export const getLocationByCatidOrFloorid = (payload) => {
  return {
    type: "GET_LOCATION_BY_CATID_FLOORID",
    payload,
  };
};
export const getLocationByCatidOrFlooridSucces = (payload) => {
  return {
    type: "GET_LOCATION_BY_CATID_FLOORID_SUCCESS",
    payload,
  };
};
export const getLocationByCatidOrFlooridError = (payload) => {
  return {
    type: "GET_LOCATION_BY_CATID_FLOORID_ERROR",
    payload,
  };
};

export const saveEquipmentMaster = (payload) => {
  return {
    type: "SAVE_EQUIPMENT_MASTERS",
    payload,
  };
};
export const saveEquipmentMasterSucces = (payload) => {
  return {
    type: "SAVE_EQUIPMENT_MASTERS_SUCCESS",
    payload,
  };
};
export const saveEquipmentMasterError = (payload) => {
  return {
    type: "SAVE_EQUIPMENT_MASTERS_ERROR",
    payload,
  };
};

export const deleteEquipmentCategory = (payload) => {
  return {
    type: "DELETE_EQUIPMENT_CATEGORY",
    payload,
  };
};
export const deleteEquipmentCategorySucces = (payload) => {
  return {
    type: "DELETE_EQUIPMENT_CATEGORY_SUCCESS",
    payload,
  };
};
export const deleteEquipmentCategoryError = (payload) => {
  return {
    type: "DELETE_EQUIPMENT_CATEGORY_ERROR",
    payload,
  };
};

export const listEquipmentMaster = (payload) => {
  return {
    type: "LIST_EQUIPMENT_MASTERS",
    payload,
  };
};
export const listEquipmentMasterSucces = (payload) => {
  return {
    type: "LIST_EQUIPMENT_MASTERS_SUCCESS",
    payload,
  };
};
export const listEquipmentMasterError = (payload) => {
  return {
    type: "LIST_EQUIPMENT_MASTERS_ERROR",
    payload,
  };
};

export const deleteEquipmentMaster = (payload) => {
  return {
    type: "DELETE_EQUIPMENT_MASTERS",
    payload,
  };
};
export const deleteEquipmentMasterSucces = (payload) => {
  return {
    type: "DELETE_EQUIPMENT_MASTERS_SUCCESS",
    payload,
  };
};
export const deleteEquipmentMasterError = (payload) => {
  return {
    type: "DELETE_EQUIPMENT_MASTERS_ERROR",
    payload,
  };
};

export const viewEquipmentCategory = (payload) => {
  return {
    type: "VIEW_EQUIPMENT_CATEGORY",
    payload,
  };
};
export const viewEquipmentCategorySucces = (payload) => {
  return {
    type: "VIEW_EQUIPMENT_CATEGORY_SUCCESS",
    payload,
  };
};
export const viewEquipmentCategoryError = (payload) => {
  return {
    type: "VIEW_EQUIPMENT_CATEGORY_ERROR",
    payload,
  };
};

export const updateEquipmentCategory = (payload) => {
  return {
    type: "UPDATE_EQUIPMENT_CATEGORY",
    payload,
  };
};
export const updateEquipmentCategorySucces = (payload) => {
  return {
    type: "UPDATE_EQUIPMENT_CATEGORY_SUCCESS",
    payload,
  };
};
export const updateEquipmentCategoryError = (payload) => {
  return {
    type: "UPDATE_EQUIPMENT_CATEGORY_ERROR",
    payload,
  };
};


export const viewEquipmentMaster = (payload) => {
  return {
    type: "VIEW_EQUIPMENT_MASTER",
    payload,
  };
};
export const viewEquipmentMasterSucces = (payload) => {
  return {
    type: "VIEW_EQUIPMENT_MASTER_SUCCESS",
    payload,
  };
};
export const viewEquipmentMasterError = (payload) => {
  return {
    type: "VIEW_EQUIPMENT_MASTER_ERROR",
    payload,
  };
};

export const updateEquipmentMaster = (payload) => {
  return {
    type: "UPDATE_EQUIPMENT_MASTER",
    payload,
  };
};
export const updateEquipmentMasterSucces = (payload) => {
  return {
    type: "UPDATE_EQUIPMENT_MASTER_SUCCESS",
    payload,
  };
};
export const updateEquipmentMasterError = (payload) => {
  return {
    type: "UPDATE_EQUIPMENT_MASTER_ERROR",
    payload,
  };
};

export const addComplaint = (payload) => {
  return {
    type: "ADD_COMPLAINT",
    payload,
  };
};
export const addComplaintSucces = (payload) => {
  return {
    type: "ADD_COMPLAINT_SUCCESS",
    payload,
  };
};
export const addComplaintError = (payload) => {
  return {
    type: "ADD_COMPLAINT_ERROR",
    payload,
  };
};

export const listComplaint = (payload) => {
  return {
    type: "LIST_COMPLAINT",
    payload,
  };
};
export const listComplaintSucces = (payload) => {
  return {
    type: "LIST_COMPLAINT_SUCCESS",
    payload,
  };
};
export const listComplaintError = (payload) => {
  return {
    type: "LIST_COMPLAINT_ERROR",
    payload,
  };
};

export const getEquipmentByNameOrUniqueid = (payload) => {
  return {
    type: "GET_EQUIPMENT_BY_NAME_UNIQUEID",
    payload,
  };
};
export const getEquipmentByNameOrUniqueidSucces = (payload) => {
  return {
    type: "GET_EQUIPMENT_BY_NAME_UNIQUEID_SUCCESS",
    payload,
  };
};
export const getEquipmentByNameOrUniqueidError = (payload) => {
  return {
    type: "GET_EQUIPMENT_BY_NAME_UNIQUEID_ERROR",
    payload,
  };
};

export const saveEquipmentComplaintRequest = (payload) => {
  return {
    type: "SAVE_EQUIPMENT_COMPLAINT_REQUEST",
    payload,
  };
  console.log("payload", payload);
};
export const saveEquipmentComplaintRequestSucces = (payload) => {
  return {
    type: "SAVE_EQUIPMENT_COMPLAINT_REQUEST_SUCCESS",
    payload,
  };
};
export const saveEquipmentComplaintRequestError = (payload) => {
  return {
    type: "SAVE_EQUIPMENT_COMPLAINT_REQUEST_ERROR",
    payload,
  };
};

export const listEquipmentComplaintRequest = (payload) => {
  return {
    type: "LIST_EQUIPMENT_COMPLAINT_REQUEST",
    payload,
  };
};
export const listEquipmentComplaintRequestSucces = (payload) => {
  return {
    type: "LIST_EQUIPMENT_COMPLAINT_REQUEST_SUCCESS",
    payload,
  };
};
export const listEquipmentComplaintRequestError = (payload) => {
  return {
    type: "LIST_EQUIPMENT_COMPLAINT_REQUEST_ERROR",
    payload,
  };
};

export const changeEquipmentComplaintRequestStatus = (payload) => {
  return {
    type: "CHANGE_EQUIPMENT_COMPLAINT_REQUEST_STATUS",
    payload,
  };
};
export const changeEquipmentComplaintRequestStatusSucces = (payload) => {
  return {
    type: "CHANGE_EQUIPMENT_COMPLAINT_REQUEST_STATUS_SUCCESS",
    payload,
  };
};
export const changeEquipmentComplaintRequestStatusError = (payload) => {
  return {
    type: "CHANGE_EQUIPMENT_COMPLAINT_REQUEST_STATUS_ERROR",
    payload,
  };
};


export const importlocationStatus = (payload) => {
  return {
    type: "IMPORT_LOCATION",
    payload,
  };
};
export const importlocationSucces = (payload) => {
  return {
    type: "IMPORT_LOCATION_SUCCESS",
    payload,
  };
};
export const importlocationError = (payload) => {
  return {
    type: "IMPORT_LOCATION_ERROR",
    payload,
  };
};


export const importcomplaint = (payload) => {
  console.log("payload", payload);
  return {
    type: "IMPORT_COMPLAINT",
    payload,
  };
};
export const importcomplaintSucces = (payload) => {

  return {
    type: "IMPORT_COMPLAINT_SUCCESS",
    payload,
  };
};
export const importcomplaintError = (payload) => {
  return {
    type: "IMPORT_COMPLAINT_ERROR",
    payload,
  };
};


export const importequipmentcategory = (payload) => {
  return {
    type: "IMPORT_EQUIPMENT_CATEGORY",
    payload,
  };
};
export const importequipmentcategorySucces = (payload) => {
  return {
    type: "IMPORT_EQUIPMENT_CATEGORY_SUCCESS",
    payload,
  };
};
export const importequipmentcategoryError = (payload) => {
  return {
    type: "IMPORT_EQUIPMENT_CATEGORY_ERROR",
    payload,
  };
};


export const importequipmentmaster = (payload) => {
  return {
    type: "IMPORT_EQUIPMENT_MASTER",
    payload,
  };
};
export const importequipmentmasterSucces = (payload) => {
  return {
    type: "IMPORT_EQUIPMENT_MASTER_SUCCESS",
    payload,
  };
};
export const importequipmentmasterError = (payload) => {
  return {
    type: "IMPORT_EQUIPMENT_MASTER_ERROR",
    payload,
  };
};





export const getEquipmentDetailsByEquipmentId = (payload) => {
  return {
    type: "GET_EQUIPMENT_DETAILS_BY_EQUIPMENT_ID",
    payload,
  };
};
export const getEquipmentDetailsByEquipmentIdSucces = (payload) => {
  return {
    type: "GET_EQUIPMENT_DETAILS_BY_EQUIPMENT_ID_SUCCESS",
    payload,
  };
};
export const getEquipmentDetailsByEquipmentIdError = (payload) => {
  return {
    type: "GET_EQUIPMENT_DETAILS_BY_EQUIPMENT_ID_ERROR",
    payload,
  };
};



export const complaintlist = (payload) => {
  // console.log("payload",payload);
  return {
    type: "COMPLAINT_LIST",
    payload,
  };
};
export const complaintlistSucces = (payload) => {

  return {
    type: "COMPLAINT_LIST_SUCCESS",
    payload,
  };
};
export const complaintlistError = (payload) => {
  return {
    type: "COMPLAINT_LIST_ERROR",
    payload,
  };
};

export const viewEquipmentDetailsByBarcode = (payload) => {
  return {
    type: "VIEW_EQUIPMENT_DETAILS_BY_BARCODE",
    payload,
  };
};
export const viewEquipmentDetailsByBarcodeSucces = (payload) => {
  return {
    type: "VIEW_EQUIPMENT_DETAILS_BY_BARCODE_SUCCESS",
    payload,
  };
};
export const viewEquipmentDetailsByBarcodeError = (payload) => {
  return {
    type: "VIEW_EQUIPMENT_DETAILS_BY_BARCODE_ERROR",
    payload,
  };
};

// list complaints by equipment
export const listComplaintbyEquipment = (payload) => {
  return {
    type: 'LIST_COMPLAINT_BY_EQUIPMENT',
    payload
  };
};
export const listComplaintbyEquipmentSuccess = (payload) => {
  return {
    type: 'LIST_COMPLAINT_BY_EQUIPMENT_SUCCESS',
    payload
  };
};
export const listComplaintbyEquipmentError = (payload) => {
  return {
    type: 'LIST_COMPLAINT_BY_EQUIPMENT_ERROR',
    payload
  };
};
// equipment history
export const EquipmentHistory = (payload) => {
  return {
    type: 'EQUIPMENT_HISTORY',
    payload
  };
};
export const EquipmentHistorySuccess = (payload) => {
  return {
    type: 'EQUIPMENT_HISTORY_SUCCESS',
    payload
  };
};
export const EquipmentHistoryError = (payload) => {
  return {
    type: 'EQUIPMENT_HISTORY_ERROR',
    payload
  };
};

// equipment Category View
export const EquipmentCategoryView = (payload) => {
  return {
    type: 'EQUIPMENT_CATEGORY_VIEW',
    payload
  };
};
export const EquipmentCategoryViewSuccess = (payload) => {
  return {
    type: 'EQUIPMENT_CATEGORY_VIEW_SUCCESS',
    payload
  };
};
export const EquipmentCategoryViewError = (payload) => {
  return {
    type: 'EQUIPMENT_CATEGORY_VIEW_ERROR',
    payload
  };
};

// check_equipment_activity_exist
export const CheckEquipment_ActivityExist = (payload) => {
  return {
    type: 'CHECKEQUIPMENT_ACTIVITYEXIST',
    payload
  };
};
export const CheckEquipment_ActivityExistSuccess = (payload) => {
  return {
    type: 'CHECKEQUIPMENT_ACTIVITYEXIST_SUCCESS',
    payload
  };
};
export const CheckEquipment_ActivityExistError = (payload) => {
  return {
    type: 'CHECKEQUIPMENT_ACTIVITYEXIST_ERROR',
    payload
  };
};
// update equipment master
export const updateEquipmentmaster = (payload) => {
  return {
    type: 'UPDATE_EQUIPMENTMASTER',
    payload
  };
};
export const updateEquipmentmasterSuccess = (payload) => {
  return {
    type: 'UPDATE_EQUIPMENTMASTER_SUCCESS',
    payload
  };
};
export const updateEquipmentmasterError = (payload) => {
  return {
    type: 'UPDATE_EQUIPMENTMASTER_ERROR',
    payload
  };
};
// list qc  template in category master
export const fecthQctemplate = (payload) => {
 
  return {
    type: 'FETCH_QCTEMPLATE',
    payload
  };
};
export const fecthQctemplateSuccess = (payload) => {
  return {
    type: 'FETCH_QCTEMPLATE_SUCCESS',
    payload
  };
};
export const fecthQctemplateError = (payload) => {
  return {
    type: 'FETCH_QCTEMPLATE_ERROR',
    payload
  };
};
//attach qc with equipment in service
export const attachQc_inService = (payload) => {
  return {
    type: 'ATTACHQC_INSERVICE',
    payload
  };
};
export const attachQc_inServiceSuccess = (payload) => {
  return {
    type: 'ATTACHQC_INSERVICE_SUCCESS',
    payload
  };
};
export const attachQc_inServiceError = (payload) => {
  return {
    type: 'ATTACHQC_INSERVICE_ERROR',
    payload
  };
};

//save task qc details in service

export const save_qcDetails = (payload) => {
  return {
    type: 'SAVE_QC_DETAILS',
    payload
  };
};
export const save_qcDetailsSuccess = (payload) => {
  return {
    type: 'SAVE_QC_DETAILS_SUCCESS',
    payload
  };
};
export const save_qcDetailsError = (payload) => {
  return {
    type: 'SAVE_QC_DETAILS_ERROR',
    payload
  };
};

// qc details in service
export const get_task_qc_details = (payload) => {
  return {
    type: 'GET_TASK_QC_DETAILS',
    payload
  };
};
export const get_task_qc_detailsSuccess = (payload) => {
  return {
    type: 'GET_TASK_QC_DETAILS_SUCCESS',
    payload
  };
};
export const get_task_qc_detailsError = (payload) => {
  return {
    type: 'GET_TASK_QC_DETAILS_ERROR',
    payload
  };
};

// delete qc master
export const deleteQcMaster = (payload) => {
  return {
    type: 'DELETE_QCMASTER',
    payload
  };
};
export const deleteQcMasterSuccess = (payload) => {
  return {
    type: 'DELETE_QCMASTER_SUCCESS',
    payload
  };
};
export const deleteQcMasterError = (payload) => {
  return {
    type: 'DELETE_QCMASTER_ERROR',
    payload
  };
};

// generate barcode
export const GenerateBarcode = (payload) => {
  return {
    type: 'GENERATE_BARCODE',
    payload
  };
};
export const GenerateBarcodeSuccess = (payload) => {
  return {
    type: 'GENERATE_BARCODE_SUCCESS',
    payload
  };
};
export const GenerateBarcodeError = (payload) => {
  return {
    type: 'GENERATE_BARCODE_ERROR',
    payload
  };
};

// active inactive button

export const ActiveInactive = (payload) =>{
  return{
    type:'ACTIVE_INACTIVE',
    payload
  };
};
export const ActiveInactiveSuccess = (payload) =>{
  return{
    type:'ACTIVE_INACTIVE_SUCCESS',
    payload
  };
};
export const ActiveInactiveError = (payload) =>{
  return{
    typr:'ACTIVE_INACTIVE_ERROR',
    payload
  };
};

// delete equipment master
export const DeleteEquipmentMaster = (payload) =>{
  return{
    type:'DELETE_EQUIPMENT_MASTER',
    payload
  };
};
export const DeleteEquipmentMasterSuccess = (payload) =>{
  return{
    type:'DELETE_EQUIPMENT_MASTER_SUCCESS',
    payload
  };
};
export const DeleteEquipmentMasterError = (payload) =>{
  return{
    typr:'DELETE_EQUIPMENT_MASTER_ERROR',
    payload
  };                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
};
// check equipment activity exist
export const CheckEquipmentActivityExist = (payload) =>{
  console.log('payload',payload);
  return{
    type:'CHECK_EQUIPMENT_ACTIVITY_LOG',
    payload
  };
};
export const CheckEquipmentActivityExistSuccess = (payload) =>{
  return{
    type:'CHECK_EQUIPMENT_ACTIVITY_LOG_SUCCESS',
    payload
  };
};
export const CheckEquipmentActivityExistError = (payload) =>{
  return{
    typr:'CHECK_EQUIPMENT_ACTIVITY_LOG_ERROR',
    payload
  };                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
};