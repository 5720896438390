import axios from '../../ApiClient'
// import { siteUrl } from './config'

class brochureservice {
    constructor(callback) {
        this.callback = callback
    }

    async addBrochure(payload) {
        return await axios.post('Inventory/DocumentManagement/save_document', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchBrochurelist(payload) {
        return await axios.post('Inventory/DocumentManagement/fetch_documents', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchVendor(payload) {
        return await axios.post('Inventory/Vendor/list_vendors', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchItem(payload) {
        return await axios.post('Inventory/Item/list_items', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async fetchBrand(payload) {
        return await axios.post('Inventory/Item_brand/list_item_brands', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async FetchBrochureByType(payload) {
        return await axios.post('Inventory/DocumentManagement/fetch_documents_by_type', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteBrochure(payload) {
        return await axios.post('Inventory/DocumentManagement/delete_document', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async editBrochure(payload) {
        return await axios.post('Inventory/DocumentManagement/update_document', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

}

export default new brochureservice();

