import axios from '@service/ApiClient'

class reportService {
    constructor(callback) {
        this.callback = callback
    }
    async complaintreport(payload) {
        return await axios.post('Report/Report/complaints_report', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async sparepartsreport(payload) {
        return await axios.post('Report/Report/spare_parts_report', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async amcexpiryreport(payload) {
        return await axios.post('Report/Report/equipments_report', payload
        ).then((response) => {
            console.log("respone",response.data);
            return response.data
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async departmentsummaryreport(payload) {
        return await axios.post('Report/Report/department_summary_report', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async equipmentauditreport(payload) {
        return await axios.post('Report/Report/equipments_audit_report', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listEquipment(payload) {
        return await axios.post('Assetmanagement/Asset_master/list_asset_register', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async Equipmentcategory(payload) {
        return await axios.post('Assetmanagement/Asset_master/list_asset_master', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async Equipmentlocation(payload) {
        return await axios.post('Report/Report/equipments_locations', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async ExternalDnReport(payload) {
        console.log('payload ExternalDnReport',payload);
        return await axios.post('Report/Report/deliverery_note_report', payload
        ).then((response) => {
            console.log('payload response',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async ExternalRnReport(payload) {
        console.log('payload ExternalRnReport',payload);
        return await axios.post('Report/Report/receipt_note_report', payload
        ).then((response) => {
            console.log('payload response',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async QcReport(payload) {
        // console.log('payload QcReport',payload);
        return await axios.post('Report/Report/qc_report', payload
        ).then((response) => {
            // console.log('payload response',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

}

export default new reportService();