import { combineReducers } from 'redux';

const initialState = {
    departmentList: [],
    saveStatus: '',
    updateStatus: '',
    deleteStatus: '',
}

const departmentReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'SAVE_DEPARTMENT':
            return { ...state, saveStatus: '' }
        case 'SAVE_DEPARTMENT_SUCCESS':
            return { ...state, saveStatus: action.payload }
        case 'SAVE_DEPARTMENT_ERROR':
            return { ...state, saveStatus: '' }

        case 'LIST_DEPARTMENT':
            return { ...state, departmentList: [], saveStatus: '', deleteStatus: '', updateStatus: '' }
        case 'LIST_DEPARTMENT_SUCCESS':
            return { ...state, departmentList: action.payload }
        case 'LIST_DEPARTMENT_ERROR':
            return { ...state, departmentList: [] }

        case 'DELETE_DEPARTMENT':
            return { ...state, deleteStatus: '' }
        case 'DELETE_DEPARTMENT_SUCCESS':
            return { ...state, deleteStatus: action.payload }
        case 'DELETE_DEPARTMENT_ERROR':
            return { ...state, deleteStatus: 'error' }

        case 'UPDATE_DEPARTMENT':
            return { ...state, updateStatus: '' }
        case 'UPDATE_DEPARTMENT_SUCCESS':
            return { ...state, updateStatus: action.payload }
        case 'UPDATE_DEPARTMENT_ERROR':
            return { ...state, updateStatus: '' }
        default:
            return state
    }
}
export default departmentReducer