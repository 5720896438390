//save brochure

export const AddBrochure = (payload) => {
    return {
        type: 'ADD_BROCHURE',
        payload
    };
};
export const AddBrochureSuccess = (payload) => {
    return {
        type: 'ADD_BROCHURE_SUCCESS',
        payload
    };
};
export const AddBrochureError = (payload) => {
    return {
        type: 'ADD_BROCHURE_ERROR',
        payload
    };
};

// list brochure

export const FetchBrochure = (payload) => {
    return {
        type: 'FETCH_BROCHURE',
        payload

    };
};
export const FetchBrochureSuccess = (payload) => {
    return {
        type: 'FETCH_BROCHURE_SUCCESS',
        payload
    };
};
export const FetchBrochureError = (payload) => {
    return {
        type: 'FETCH_BROCHURE_ERROR',
        payload
    };
};

// document vendor


export const Fetchvendor = (payload) => {
    return {
        type: 'FETCH_VENDOR',
        payload

    };
};
export const FetchvendorSuccess = (payload) => {
    return {
        type: 'FETCH_VENDOR_SUCCESS',
        payload
    };
};
export const FetchvendorError = (payload) => {
    return {
        type: 'FETCH_VENDOR_ERROR',
        payload
    };
};

// document Item

export const Fetchitems = (payload) => {
    return {
        type: 'FETCH_ITEMS',
        payload

    };
};
export const FetchitemsSuccess = (payload) => {
    return {
        type: 'FETCH_ITEMS_SUCCESS',
        payload
    };
};
export const FetchitemsError = (payload) => {
    return {
        type: 'FETCH_ITEMS_ERROR',
        payload
    };
};

// document Item Brand


export const FetchBrand = (payload) => {
    return {
        type: 'FETCH_BRAND',
        payload

    };
};
export const FetchBrandSuccess = (payload) => {
    return {
        type: 'FETCH_BRAND_SUCCESS',
        payload
    };
};
export const FetchBrandError = (payload) => {
    return {
        type: 'FETCH_BRAND_ERROR',
        payload
    };
};

export const FetchBrochureByType = (payload) => {
    return {
        type: 'FETCH_BROCHURE_BY_TYPE',
        payload

    };
};
export const FetchBrochureByTypeSuccess = (payload) => {
    return {
        type: 'FETCH_BROCHURE_BY_TYPE_SUCCESS',
        payload
    };
};
export const FetchBrochureByTypeError = (payload) => {
    return {
        type: 'FETCH_BROCHURE_BY_TYPE_ERROR',
        payload
    };
};

export const deleteBrochure = (payload) => {
    return {
        type: 'DELETE_BROCHURE',
        payload

    };
};
export const deleteBrochureSuccess = (payload) => {
    return {
        type: 'DELETE_BROCHURE_SUCCESS',
        payload
    };
};
export const deleteBrochureError = (payload) => {
    return {
        type: 'DELETE_BROCHURE_ERROR',
        payload
    };
};

export const updateBrochure = (payload) => {
    return {
        type: 'UPDATE_BROCHURE',
        payload

    };
};
export const updateBrochureSuccess = (payload) => {
    return {
        type: 'UPDATE_BROCHURE_SUCCESS',
        payload
    };
};
export const updateBrochureError = (payload) => {
    return {
        type: 'UPDATE_BROCHURE_ERROR',
        payload
    };
};


