import axios from '@service/ApiClient'
class familyDetailsService {
    constructor(callback) {
        this.callback = callback
    }
    async saveFamilyDetails(payload) {
        return await axios.post('/Crm/User/add_employee_family', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listFamilyDetails(payload) {
        return await axios.post('/Crm/User/list_employee_family', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateFamilyDetails(payload) {
        return await axios.post('/Crm/User/update_employee_family', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteFamilyDetails(payload) {
        return await axios.post('/Crm/User/delete_employee_family', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

}

export default new familyDetailsService();