import { takeEvery, put, call, all } from 'redux-saga/effects';
import roleService from '@service/admin/masterService/roleService';
import {
    actionTypes,
    fetchRoleError,
    fetchRoleSuccess,
    saveRoleError,
    saveRoleSuccess,
    updateRoleSuccess,
    updateRoleError,
    deleteRoleSuccess,
    deleteRoleError,
    fetchRoleAssignedMemberSuccess,
    fetchRoleAssignedMemberError,
    removeRoleAssignedMemberSuccess,
    removeRoleAssignedMemberError,
    fetchPermissionDetailsSuccess,
    fetchPermissionDetailsError,
    setRolePermissionSuccess,
    setRolePermissionError,
} from './action'
import { startLoader, stopLoader } from '@store/common/loader/action'



function* createRole({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(roleService.createRole, payload);
        yield put(saveRoleSuccess(data));
    } catch (error) {
        yield put(saveRoleError(error));
    } finally {
        yield put(stopLoader());
    }
}


function* fetchRole({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(roleService.fetchRole, payload);
        Array.isArray(data) ?
            yield put(fetchRoleSuccess(data)) :
            yield put(fetchRoleError([]));
    } catch (error) {
        yield put(fetchRoleError(error));
    } finally {
        yield put(stopLoader());
    }
}


function* updateRole({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(roleService.updateRole, payload);
        yield put(updateRoleSuccess(data));
    } catch (error) {
        yield put(updateRoleError(error));
    } finally {
        yield put(stopLoader());
    }
}


function* deleteRole({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(roleService.deleteRole, payload);
        yield put(deleteRoleSuccess(data));
    } catch (error) {
        yield put(deleteRoleError(error));
    } finally {
        yield put(stopLoader());
    }
}


function* fetchAssignedRoleMember({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(roleService.fetchAssignedRoleMember, payload);
        yield put(fetchRoleAssignedMemberSuccess(data));
    } catch (error) {
        yield put(fetchRoleAssignedMemberError(error));
    } finally {
        yield put(stopLoader());
    }
}


function* deleteAssignedRoleMember({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(roleService.deleteAssignedRoleMember, payload);
        yield put(removeRoleAssignedMemberSuccess(data));
    } catch (error) {
        yield put(removeRoleAssignedMemberError(error));
    } finally {
        yield put(stopLoader());
    }
}


function* fetchPermissionDetails({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(roleService.fetchPermissionDetails, payload);
        Array.isArray(data) ?
            yield put(fetchPermissionDetailsSuccess(data)) :
            yield put(fetchPermissionDetailsError([]));
    } catch (error) {
        yield put(fetchPermissionDetailsError(error));
    } finally {
        yield put(stopLoader());
    }
}


function* setPermission({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(roleService.setPermission, payload);
        yield put(setRolePermissionSuccess(data));
    } catch (error) {
        yield put(setRolePermissionError(error));
    } finally {
        yield put(stopLoader());
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actionTypes.SAVE_ROLE, createRole)]);
    yield all([takeEvery(actionTypes.LIST_ROLE, fetchRole)]);
    yield all([takeEvery(actionTypes.UPDATE_ROLE, updateRole)]);
    yield all([takeEvery(actionTypes.DELETE_ROLE, deleteRole)]);
    yield all([takeEvery(actionTypes.LIST_ROLE_ASSIGNED_MEMBER, fetchAssignedRoleMember)]);
    yield all([takeEvery(actionTypes.REMOVE_ROLE_ASSIGNED_MEMBER, deleteAssignedRoleMember)]);
    yield all([takeEvery(actionTypes.PERMISSION_DETAILS, fetchPermissionDetails)]);
    yield all([takeEvery(actionTypes.SET_ROLE_PERMISSION, setPermission)]);
}