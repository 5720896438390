
export const actionTypes = {
    
    FETCH_LP: 'FETCH_LP',
    FETCH_LP_SUCCESS: 'FETCH_LP_SUCCESS',
    FETCH_LP_ERROR: 'FETCH_LP_ERROR',
    FETCH_SALARY_DETAILS: 'FETCH_SALARY_DETAILS',
    FETCH_SALARY_DETAILS_SUCCESS: 'FETCH_SALARY_DETAILS_SUCCESS',
    FETCH_SALARY_DETAILS_ERROR: 'FETCH_SALARY_DETAILS_ERROR',
    SAVE_SALARY: 'SAVE_SALARY',
    SAVE_SALARY_SUCCESS: 'SAVE_SALARY_SUCCESS',
    SAVE_SALARY_ERROR: 'SAVE_SALARY_ERROR',
    LIST_SALARY: 'LIST_SALARY',
    LIST_SALARY_SUCCESS: 'LIST_SALARY_SUCCESS',
    LIST_SALARY_ERROR: 'LIST_SALARY_ERROR',
    EMP_LIST_SALARY: 'EMP_LIST_SALARY',
    EMP_LIST_SALARY_SUCCESS: 'EMP_LIST_SALARY_SUCCESS',
    EMP_LIST_SALARY_ERROR: 'EMP_LIST_SALARY_ERROR',
    SALARYREGISTER_VIEW: 'SALARYREGISTER_VIEW',
    SALARYREGISTER_VIEW_SUCCESS: 'SALARYREGISTER_VIEW_SUCCESS',
    SALARYREGISTER_VIEW_ERROR: 'SALARYREGISTER_VIEW_ERROR',
    SALARY_PAYMENT: 'SALARY_PAYMENT',
    SALARY_PAYMENT_SUCCESS: 'SALARY_PAYMENT_SUCCESS',
    SALARY_PAYMENT_ERROR: 'SALARY_PAYMENT_ERROR',
    LIST_EMPLOYEE: 'LIST_EMPLOYEE',
    LIST_EMPLOYEE_SUCCESS: 'LIST_EMPLOYEE_SUCCESS',
    LIST_EMPLOYEE_ERROR: 'LIST_EMPLOYEE_ERROR'
};

export const fetchLp = (payload) => {
    return {
        type: actionTypes.FETCH_LP, payload
    };
};
export const fetchLpSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_LP_SUCCESS,
        payload
    };
};
export const fetchLpError = (payload) => {
    return {
        type: actionTypes.FETCH_LP_ERROR,
        payload
    };
};


export const fetchSalarydetails = (payload) => {
    return {
        type: actionTypes.FETCH_SALARY_DETAILS, payload
    };
};
export const fetchSalarydetailsSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_SALARY_DETAILS_SUCCESS,
        payload
    };
};
export const fetchSalarydetailsError = (payload) => {
    return {
        type: actionTypes.FETCH_SALARY_DETAILS_ERROR,
        payload
    };
};

export const saveSalaryregister = (payload) => {
    return {
        type: actionTypes.SAVE_SALARY, payload
    };
};
export const saveSalaryregisterSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_SALARY_SUCCESS,
        payload
    };
};
export const saveSalaryregisterError = (payload) => {
    return {
        type: actionTypes.SAVE_SALARY_ERROR,
        payload
    };
};

export const listSalaryregister = (payload) => {
    return {
        type: actionTypes.LIST_SALARY, payload
    };
};
export const listSalaryregisterSuccess = (payload) => {
    return {
        type: actionTypes.LIST_SALARY_SUCCESS,
        payload
    };
};
export const listSalaryregisterError = (payload) => {
    return {
        type: actionTypes.LIST_SALARY_ERROR,
        payload
    };
};

export const empSalaryregisterlist = (payload) => {
    return {
        type: actionTypes.EMP_LIST_SALARY, payload
    };
};
export const empSalaryregisterlistSuccess = (payload) => {
    return {
        type: actionTypes.EMP_LIST_SALARY_SUCCESS,
        payload
    }; 
};
export const empSalaryregisterlistError = (payload) => {
    return {
        type: actionTypes.EMP_LIST_SALARY_ERROR,
        payload
    };
};

export const salaryregisterView = (payload) => {
    return {
        type: actionTypes.SALARYREGISTER_VIEW, payload
    };
};
export const salaryregisterViewSuccess = (payload) => {
    return {
        type: actionTypes.SALARYREGISTER_VIEW_SUCCESS,
        payload
    };
};
export const salaryregisterViewError = (payload) => {
    return {
        type: actionTypes.SALARYREGISTER_VIEW_ERROR,
        payload
    };
};

export const salarypayment = (payload) => {
    return {
        type: actionTypes.SALARY_PAYMENT, payload
    };
};
export const salarypaymentSuccess = (payload) => {
    return {
        type: actionTypes.SALARY_PAYMENT_SUCCESS,
        payload
    };
};
export const salarypaymentError = (payload) => {
    return {
        type: actionTypes.SALARY_PAYMENT_ERROR,
        payload
    };
};

export const listEmployee = (payload) => {
    return {
        type: actionTypes.LIST_EMPLOYEE, payload
    };
};
export const listEmployeeSuccess = (payload) => {
    return {
        type: actionTypes.LIST_EMPLOYEE_SUCCESS,
        payload
    };
};
export const listEmployeeError = (payload) => {
    return {
        type: actionTypes.LIST_EMPLOYEE_ERROR,
        payload
    };
};