import { takeEvery, put, call, all } from 'redux-saga/effects';
import brochureservice from '@service/admin/brochureService/brochureService';

import {
  AddBrochureSuccess, AddBrochureError,
  FetchBrochureSuccess, FetchBrochureError,
  FetchvendorSuccess, FetchvendorError,
  FetchitemsSuccess, FetchitemsError,
  FetchBrandSuccess, FetchBrandError,
  FetchBrochureByTypeSuccess, FetchBrochureByTypeError, deleteBrochureSuccess, deleteBrochureError, updateBrochureSuccess, updateBrochureError

} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'



function* AddBrochure({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(brochureservice.addBrochure, payload);
    yield put(AddBrochureSuccess(data));
  } catch (error) {
    yield put(AddBrochureError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* FetchBrochure({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(brochureservice.fetchBrochurelist, payload);
    Array.isArray(data) ?
      yield put(FetchBrochureSuccess(data)) :
      yield put(FetchBrochureSuccess([]));
  } catch (error) {
    yield put(FetchBrochureError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* FetchVendor({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(brochureservice.fetchVendor, payload);
    Array.isArray(data) ?
      yield put(FetchvendorSuccess(data)) :
      yield put(FetchvendorSuccess([]));
  } catch (error) {
    yield put(FetchvendorError(error));
  } finally {
    yield put(stopLoader());
  }
}


function* FetchItems({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(brochureservice.fetchItem, payload);
    Array.isArray(data) ?
      yield put(FetchitemsSuccess(data)) :
      yield put(FetchitemsSuccess([]));
  } catch (error) {
    yield put(FetchitemsError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* FetchBrand({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(brochureservice.fetchBrand, payload);
    Array.isArray(data) ?
      yield put(FetchBrandSuccess(data)) :
      yield put(FetchBrandSuccess([]));
  } catch (error) {
    yield put(FetchBrandError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* FetchBrochureByType({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(brochureservice.FetchBrochureByType, payload);
    Array.isArray(data) ?
      yield put(FetchBrochureByTypeSuccess(data)) :
      yield put(FetchBrochureByTypeSuccess([]));
  } catch (error) {
    yield put(FetchBrochureByTypeError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* deleteBrochure({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(brochureservice.deleteBrochure, payload);
    yield put(deleteBrochureSuccess(data.error ? '' : data))
  } catch (error) {
    yield put(deleteBrochureError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* editBrochure({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(brochureservice.editBrochure, payload);
    yield put(updateBrochureSuccess(data.error ? '' : data))
  } catch (error) {
    yield put(updateBrochureError(error));
  } finally {
    yield put(stopLoader());
  }
}

export default function* rootSaga() {
  yield all([takeEvery('ADD_BROCHURE', AddBrochure)]);
  yield all([takeEvery('FETCH_BROCHURE', FetchBrochure)]);
  yield all([takeEvery('FETCH_VENDOR', FetchVendor)]);
  yield all([takeEvery('FETCH_ITEMS', FetchItems)]);
  yield all([takeEvery('FETCH_BRAND', FetchBrand)]);
  yield all([takeEvery('FETCH_BROCHURE_BY_TYPE', FetchBrochureByType)]);
  yield all([takeEvery('DELETE_BROCHURE', deleteBrochure)]);
  yield all([takeEvery('UPDATE_BROCHURE', editBrochure)]);

}