import { takeEvery, put, call, all } from 'redux-saga/effects';
import picklocationService from '@service/common/picklocationService';
import {
    actionTypes,
    fetchCountryDetailsSuccess,
    fetchCountryDetailsError,
    fetchRegionalLevelDataSuccess,
    fetchRegionalLevelDataError,
    fetchRegionalLevelsByCountryIdSuccess,
    fetchRegionalLevelsByCountryIdError
} from './action'
import { startLoader, stopLoader } from '@store/common/loader/action'


function* fetchCountryDetails({ payload }) {
    try {
        yield put(startLoader());
        const result = yield call(picklocationService.fetchCountryDetails, payload);
        yield put(fetchCountryDetailsSuccess(result));
    } catch (error) {
        yield put(fetchCountryDetailsError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* fetchRegionalLevelData({ payload }) {
    try {
        yield put(startLoader());
        const result = yield call(picklocationService.fetchRegionalLevelData, payload);
        yield put(fetchRegionalLevelDataSuccess(result));
    } catch (error) {
        yield put(fetchRegionalLevelDataError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* fetchRegionalLevelsByCountryId({ payload }) {
    try {
        yield put(startLoader());
        const result = yield call(picklocationService.fetchRegionalLevelsByCountryId, payload);
        yield put(fetchRegionalLevelsByCountryIdSuccess(result));
    } catch (error) {
        yield put(fetchRegionalLevelsByCountryIdError(error));
    } finally {
        yield put(stopLoader());
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actionTypes.FETCH_COUNTRY_DETAILS, fetchCountryDetails)]);
    yield all([takeEvery(actionTypes.FETCH_REGIONAL_LEVEL_DATA, fetchRegionalLevelData)]);
    yield all([takeEvery(actionTypes.FETCH_REGIONAL_LEVEL_BY_COUNTRY_ID, fetchRegionalLevelsByCountryId)]);
}