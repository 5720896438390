
export const actionTypes = {
    SAVE_CURRENCY: 'SAVE_CURRENCY',
    SAVE_CURRENCY_SUCCESS: 'SAVE_CURRENCY_SUCCESS',
    SAVE_CURRENCY_ERROR: 'SAVE_CURRENCY_ERROR',
    LIST_CURRENCY: 'LIST_CURRENCY',
    LIST_CURRENCY_SUCCESS: 'LIST_CURRENCY_SUCCESS',
    LIST_CURRENCY_ERROR: 'LIST_CURRENCY_ERROR',
    DELETE_CURRENCY: 'DELETE_CURRENCY',
    DELETE_CURRENCY_SUCCESS: 'DELETE_CURRENCY_SUCCESS',
    DELETE_CURRENCY_ERROR: 'DELETE_CURRENCY_ERROR',
    UPDATE_CURRENCY: 'UPDATE_CURRENCY',
    UPDATE_CURRENCY_SUCCESS: 'UPDATE_CURRENCY_SUCCESS',
    UPDATE_CURRENCY_ERROR: 'UPDATE_CURRENCY_ERROR',
    SAVE_CURRENCY_RATE: 'SAVE_CURRENCY_RATE',
    SAVE_CURRENCY_RATE_SUCCESS: 'SAVE_CURRENCY_RATE_SUCCESS',
    SAVE_CURRENCY_RATE_ERROR: 'SAVE_CURRENCY_RATE_ERROR',
    DELETE_CURRENCY_RATE: 'DELETE_CURRENCY_RATE',
    DELETE_CURRENCY_RATE_SUCCESS: 'DELETE_CURRENCY_RATE_SUCCESS',
    DELETE_CURRENCY_RATE_ERROR: 'DELETE_CURRENCY_RATE_ERROR',
    UPDATE_CURRENCY_RATE: 'UPDATE_CURRENCY_RATE',
    UPDATE_CURRENCY_RATE_SUCCESS: 'UPDATE_CURRENCY_RATE_SUCCESS',
    UPDATE_CURRENCY_RATE_ERROR: 'UPDATE_CURRENCY_RATE_ERROR',
    GET_DEFAULT_CURRENCY: 'GET_DEFAULT_CURRENCY',
    GET_DEFAULT_CURRENCY_SUCCESS: 'GET_DEFAULT_CURRENCY_SUCCESS',
    GET_DEFAULT_CURRENCY_ERROR: 'GET_DEFAULT_CURRENCY_ERROR'
};

export const saveCurrency = (payload) => {
    return {
        type: actionTypes.SAVE_CURRENCY, payload
    };
};
export const saveCurrencySuccess = (payload) => {
    return {
        type: actionTypes.SAVE_CURRENCY_SUCCESS,
        payload
    };
};
export const saveCurrencyError = (payload) => {
    return {
        type: actionTypes.SAVE_CURRENCY_ERROR,
        payload
    };
};

export const listCurrency = (payload) => {
    return {
        type: actionTypes.LIST_CURRENCY,
        payload
    };
};
export const listCurrencySuccess = (payload) => {
    return {
        type: actionTypes.LIST_CURRENCY_SUCCESS,
        payload
    };
};
export const listCurrencyError = (payload) => {
    return {
        type: actionTypes.LIST_CURRENCY_ERROR,
        payload
    };
};

export const deleteCurrency = (payload) => {
    return {
        type: actionTypes.DELETE_CURRENCY,
        payload
    };
};
export const deleteCurrencySuccess = (payload) => {
    return {
        type: actionTypes.DELETE_CURRENCY_SUCCESS,
        payload
    };
};
export const deleteCurrencyError = (payload) => {
    return {
        type: actionTypes.DELETE_CURRENCY_ERROR,
        payload
    };
};

export const updateCurrency = (payload) => {
    return {
        type: actionTypes.UPDATE_CURRENCY,
        payload
    };
};
export const updateCurrencySuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_CURRENCY_SUCCESS,
        payload
    };
};
export const updateCurrencyError = (payload) => {
    return {
        type: actionTypes.UPDATE_CURRENCY_ERROR,
        payload
    };
};

export const saveCurrencyRate = (payload) => {
    return {
        type: actionTypes.SAVE_CURRENCY_RATE, payload
    };
};
export const saveCurrencyRateSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_CURRENCY_RATE_SUCCESS,
        payload
    };
};
export const saveCurrencyRateError = (payload) => {
    return {
        type: actionTypes.SAVE_CURRENCY_RATE_ERROR,
        payload
    };
};
export const deleteCurrencyRate = (payload) => {
    return {
        type: actionTypes.DELETE_CURRENCY_RATE, payload
    };
};
export const deleteCurrencyRateSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_CURRENCY_RATE_SUCCESS,
        payload
    };
};
export const deleteCurrencyRateError = (payload) => {
    return {
        type: actionTypes.DELETE_CURRENCY_RATE_ERROR,
        payload
    };
};

export const updateCurrencyRate = (payload) => {
    return {
        type: actionTypes.UPDATE_CURRENCY_RATE, payload
    };
};
export const updateCurrencyRateSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_CURRENCY_RATE_SUCCESS,
        payload
    };
};
export const updateCurrencyRateError = (payload) => {
    return {
        type: actionTypes.UPDATE_CURRENCY_RATE_ERROR,
        payload
    };
};

export const getDefaultCurrency = (payload) => {
    return {
        type: actionTypes.GET_DEFAULT_CURRENCY, payload
    };
};
export const getDefaultCurrencySuccess = (payload) => {
    return {
        type: actionTypes.GET_DEFAULT_CURRENCY_SUCCESS,
        payload
    };
};
export const getDefaultCurrencyError = (payload) => {
    return {
        type: actionTypes.GET_DEFAULT_CURRENCY_ERROR,
        payload
    };
};