import axios from '@service/ApiClient'

class equipmenttransferservice {
    constructor(callback) {
        this.callback = callback
    }

    async equipmenttransferAdd(payload) {
        console.log('equipmenttransferAdd--pay',payload);
        return await axios.post('Assetmanagement/Asset_master/equipment_tranfer',
            payload,
        ).then((response) => {
            console.log('equipmenttransferAdd--res',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async listEquipmentTransfer(payload) {
        console.log('listEquipmentTransfer--pay',payload);
        return await axios.post('Assetmanagement/Asset_master/list_equipment_transfer',
            payload,
        ).then((response) => {
            console.log('listEquipmentTransfer--res',response.data);
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

}

export default new equipmenttransferservice();