import axios from '@service/ApiClient'
// import { siteUrl } from './config'

class invoiceService {
    constructor(callback) {
        this.callback = callback
    }

    async listSoForInvoice(payload) {
        return await axios.post('/Inventory/Invoice/list_so_model', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listInvoice(payload) {
        return await axios.post('/Inventory/Invoice/list_invoice', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async saveInvoice(payload) {
        return await axios.post('/Inventory/Invoice/save_invoice', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchInvoiceById(payload) {
        return await axios.post('/Inventory/Invoice/get_invoice_for_view', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async saveDirectInvoice(payload) {
        return await axios.post('/Inventory/Direct_invoice/save_invoice', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new invoiceService();