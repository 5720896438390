// ** Redux, Thunk & Root Reducer Imports
// import thunk from 'redux-thunk'
// import createDebounce from 'redux-debounced'
import rootReducer from './rootReducer'
import rootSaga from './rootSaga'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistReducer } from 'redux-persist';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage';

// ** init middleware
const sagaMiddleware = createSagaMiddleware()

const persistConfig = {
  key: 'icms',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


// const middleware = [thunk, createDebounce()]

// ** Dev Tools
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create store
const store = createStore(persistedReducer,
  process.env.NODE_ENV === 'development'
    ? applyMiddleware(sagaMiddleware, createLogger({collapsed: (getState, action, logEntry) => !logEntry.error}))
    : applyMiddleware(sagaMiddleware),
)

sagaMiddleware.run(rootSaga)

export { store }
