//LIST SALUTATION

export const FetchSalutation = (payload) => {
    return {
        type: 'FETCH_SALUTATION',
        payload
    };
};
export const FetchSalutationSuccess = (payload) => {
    return {
        type: 'FETCH_SALUTATION_SUCCESS',
        payload
    };
};
export const FetchSalutationError = (payload) => {
    return {
        type: 'FFETCH_SALUTATION_ERROR',
        payload
    };
};

//ADD SALUTATION

export const AddSalutation = (payload) => {
    return {
        type: 'ADD_SALUTATION',
        payload
    };
};
export const AddSalutationSuccess = (payload) => {
    return {
        type: 'ADD_SALUTATION_SUCCESS',
        payload
    };
};
export const AddSalutationError = (payload) => {
    return {
        type: 'ADD_SALUTATION_ERROR',
        payload
    };
};

//DELETE SALUTATION

export const DeleteSalutation = (payload) => {
    return {
        type: 'DELETE_SALUTATION',
        payload
    };
};
export const DeleteSalutationSuccess = (payload) => {
    return {
        type: 'DELETE_SALUTATION_SUCCESS',
        payload
    };
};
export const DeleteSalutationError = (payload) => {
    return {
        type: 'DELETE_SALUTATION_ERROR',
        payload
    };
};

//EDIT SALUTATION

export const EditSalutation = (payload) => {
    return {
        type: 'EDIT_SALUTATION',
        payload
    };
};
export const EditSalutationSuccess = (payload) => {
    return {
        type: 'EDIT_SALUTATION_SUCCESS',
        payload
    };
};
export const EditSalutationError = (payload) => {
    return {
        type: 'EDIT_SALUTATION_ERROR',
        payload
    };
};

//UPDATE SALUTATION

export const UpdateSalutation = (payload) => {
    return {
        type: 'UPDATE_SALUTATION',
        payload
    };
};
export const UpdateSalutationSuccess = (payload) => {
    return {
        type: 'UPDATE_SALUTATION_SUCCESS',
        payload
    };
};
export const UpdateSalutationError = (payload) => {
    return {
        type: 'UPDATE_SALUTATION_ERROR',
        payload
    };
};