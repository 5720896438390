import axios from '@service/ApiClient'

class projectService {
    constructor(callback) {
        this.callback = callback
    }
    async saveProject(payload) {
        return await axios.post('/PMS/Project/save_project', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async listProject(payload) {
        return await axios.post('/PMS/Project/list_projects', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateProject(payload) {
        return await axios.post('/PMS/Project/update_project', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteProject(payload) {
        return await axios.post('/PMS/Project/delete_project', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new projectService();