import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import dealStageService from '@service/admin/masterService/dealStageService';
import {
  listDealStageSuccess, listDealStageError,
  editDealStageSuccess, editDealStageError
} from './action';

function* listDealStage({ payload }) {
  try {
    const data = yield call(dealStageService.listDealStage, payload);
    Array.isArray(data) ?
      yield put(listDealStageSuccess(data)) :
      yield put(listDealStageSuccess([]))
  } catch (error) {
    yield put(listDealStageError(error));
  } finally {
  }
}

function* editDealStage({ payload }) {
  try {
    const data = yield call(dealStageService.editDealStage, payload);
    yield put(editDealStageSuccess(data));
  } catch (error) {
    yield put(editDealStageError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.EDIT_DEAL_STAGE, editDealStage)]);
  yield all([takeEvery(actionTypes.LIST_DEAL_STAGE, listDealStage)]);

}