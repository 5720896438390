
export const actionTypes = {
    JOB_DEPARTMENT: 'JOB_DEPARTMENT',
    JOB_DEPARTMENT_SUCCESS: 'JOB_DEPARTMENT_SUCCESS',
    JOB_DEPARTMENT_ERROR: 'JOB_DEPARTMENT_ERROR',

    JOB_DESIGNATION: 'JOB_DESIGNATION',
    JOB_DESIGNATION_SUCCESS: 'JOB_DESIGNATION_SUCCESS',
    JOB_DESIGNATION_ERROR: 'JOB_DESIGNATION_ERROR',

    JOB_ROLES: 'JOB_ROLES',
    JOB_ROLES_SUCCESS: 'JOB_ROLES_SUCCESS',
    JOB_ROLES_ERROR: 'JOB_ROLES_ERROR',

    SAVE_USER: 'SAVE_USER',
    SAVE_USER_SUCCESS: 'SAVE_USER_SUCCESS',
    SAVE_USER_ERROR: 'SAVE_USER_ERROR',

    UPDATE_USER: 'UPDATE_USER',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',

    DELETE_USER: 'DELETE_USER',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_ERROR: 'DELETE_USER_ERROR',

    LIST_JOB: 'LIST_JOB',
    LIST_JOB_SUCCESS: 'LIST_JOB_SUCCESS',
    LIST_JOB_ERROR: 'LIST_JOB_ERROR',

    LIST_USER: 'LIST_USER',
    LIST_USER_SUCCESS: 'LIST_USER_SUCCESS',
    LIST_USER_ERROR: 'LIST_USER_ERROR',

    SEND_INVITATION: 'SEND_INVITATION',
    SEND_INVITATION_SUCCESS: 'SEND_INVITATION_SUCCESS',
    SEND_INVITATION_ERROR: 'SEND_INVITATION_ERROR',

    USER_FILTER: 'USER_FILTER',
    USER_FILTER_SUCCESS: 'USER_FILTER_SUCCESS',
    USER_FILTER_ERROR: 'USER_FILTER_ERROR',

    GET_USER_DETAILS: 'GET_USER_DETAILS',
    GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
    GET_USER_DETAILS_ERROR: 'GET_USER_DETAILS_ERROR'

};


export const jobDepartment = (payload) => {
    return {
        type: actionTypes.JOB_DEPARTMENT, payload
    };
};
export const jobDepartmentSuccess = (payload) => {
    return {
        type: actionTypes.JOB_DEPARTMENT_SUCCESS,
        payload
    };
};
export const jobDepartmentError = (payload) => {
    return {
        type: actionTypes.JOB_DEPARTMENT_ERROR,
        payload
    };
};

export const jobDesignation = (payload) => {
    return {
        type: actionTypes.JOB_DESIGNATION, payload
    };
};
export const jobDesignationSuccess = (payload) => {
    return {
        type: actionTypes.JOB_DESIGNATION_SUCCESS,
        payload
    };
};
export const jobDesignationError = (payload) => {
    return {
        type: actionTypes.JOB_DESIGNATION_ERROR,
        payload
    };
};

export const jobRoles = (payload) => {
    return {
        type: actionTypes.JOB_ROLES, payload
    };
};
export const jobRolesSuccess = (payload) => {
    return {
        type: actionTypes.JOB_ROLES_SUCCESS,
        payload
    };
};
export const jobRolesError = (payload) => {
    return {
        type: actionTypes.JOB_ROLES_ERROR,
        payload
    };
};

export const saveUser = (payload) => {
    return {
        type: actionTypes.SAVE_USER, payload
    };
};
export const saveUserSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_USER_SUCCESS,
        payload
    };
};
export const saveUserError = (payload) => {
    return {
        type: actionTypes.SAVE_USER_ERROR,
        payload
    };
};

export const updateUser = (payload) => {
    return {
        type: actionTypes.UPDATE_USER, payload
    };
};
export const updateUserSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS,
        payload
    };
};
export const updateUserError = (payload) => {
    return {
        type: actionTypes.UPDATE_USER_ERROR,
        payload
    };
};

export const deleteUser = (payload) => {
    return {
        type: actionTypes.DELETE_USER, payload
    };
};
export const deleteUserSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_USER_SUCCESS,
        payload
    };
};
export const deleteUserError = (payload) => {
    return {
        type: actionTypes.DELETE_USER_ERROR,
        payload
    };
};

export const listJob = (payload) => {
    return {
        type: actionTypes.LIST_JOB, payload
    };
};
export const listJobSuccess = (payload) => {
    return {
        type: actionTypes.LIST_JOB_SUCCESS,
        payload
    };
};
export const listJobError = (payload) => {
    return {
        type: actionTypes.LIST_JOB_ERROR,
        payload
    };
};

export const listUser = (payload) => {
    return {
        type: actionTypes.LIST_USER, payload
    };
};
export const listUserSuccess = (payload) => {
    return {
        type: actionTypes.LIST_USER_SUCCESS,
        payload
    };
};
export const listUserError = (payload) => {
    return {
        type: actionTypes.LIST_USER_ERROR,
        payload
    };
};

export const sendInvitation = (payload) => {
    return {
        type: actionTypes.SEND_INVITATION, payload
    };
};
export const sendInvitationSuccess = (payload) => {
    return {
        type: actionTypes.SEND_INVITATION_SUCCESS,
        payload
    };
};
export const sendInvitationError = (payload) => {
    return {
        type: actionTypes.SEND_INVITATION_ERROR,
        payload
    };
};

export const filterUser = (payload) => {
    return {
        type: actionTypes.USER_FILTER, payload
    };
};
export const filterUserSuccess = (payload) => {
    return {
        type: actionTypes.USER_FILTER_SUCCESS,
        payload
    };
};
export const filterUserError = (payload) => {
    return {
        type: actionTypes.USER_FILTER_ERROR,
        payload
    };
};

export const getUserDetails = (payload) => {
    return {
        type: actionTypes.GET_USER_DETAILS, payload
    };
};
export const getUserDetailsSuccess = (payload) => {
    return {
        type: actionTypes.GET_USER_DETAILS_SUCCESS,
        payload
    };
};
export const getUserDetailsError = (payload) => {
    return {
        type: actionTypes.GET_USER_DETAILS_ERROR,
        payload
    };
};

export const getRemunerationDetails = (payload) => {
    return {
        type: "GET_REMUNERATION_DETAILS", payload
    };
};
export const getRemunerationDetailsSuccess = (payload) => {
    return {
        type: "GET_REMUNERATION_DETAILS_SUCCESS",
        payload
    };
};
export const getRemunerationDetailsError = (payload) => {
    return {
        type: "GET_REMUNERATION_DETAILS_ERROR",
        payload
    };
};

export const insertRemunerationPackage = (payload) => {
    return {
        type: "INSERT_REMUNERATION_PACKAGE", payload
    };
};
export const insertRemunerationPackageSuccess = (payload) => {
    return {
        type: "INSERT_REMUNERATION_PACKAGE_SUCCESS",
        payload
    };
};
export const insertRemunerationPackageError = (payload) => {
    return {
        type: "INSERT_REMUNERATION_PACKAGE_ERROR",
        payload
    };
};

export const emailPhoneValidation = (payload) => {
    return {
        type: "EMAIL_PHONE_VALIDATION", payload
    };
};
export const emailPhoneValidationSuccess = (payload) => {
    return {
        type: "EMAIL_PHONE_VALIDATION_SUCCESS",
        payload
    };
};
export const emailPhoneValidationError = (payload) => {
    return {
        type: "EMAIL_PHONE_VALIDATION_ERROR",
        payload
    };
};

export const listUserByDepartmentId = (payload) => {
    return {
        type: "LIST_USER_BY_DEPARTMENT_ID", payload
    };
};
export const listUserByDepartmentIdSuccess = (payload) => {
    return {
        type: "LIST_USER_BY_DEPARTMENT_ID_SUCCESS",
        payload
    };
};
export const listUserByDepartmentIdError = (payload) => {
    return {
        type: "LIST_USER_BY_DEPARTMENT_ID_ERROR",
        payload
    };
};
export const importuser = (payload) => {
    return {
        type: "IMPORT_USER", payload
    };
};
export const importuserSuccess = (payload) => {
    return {
        type: "IMPORT_USER_SUCCESS",
        payload
    };
};
export const importuserError = (payload) => {
    return {
        type: "IMPORT_USER_ERROR",
        payload
    };
};
