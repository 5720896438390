
export const actionTypes = {
    SAVE_EMPLOYMENTDETAILS: 'SAVE_EMPLOYMENTDETAILS',
    SAVE_EMPLOYMENTDETAILS_SUCCESS: 'SAVE_EMPLOYMENTDETAILS_SUCCESS',
    SAVE_EMPLOYMENTDETAILS_ERROR: 'SAVE_EMPLOYMENTDETAILS_ERROR',
    LIST_EMPLOYMENTDETAILS: 'LIST_EMPLOYMENTDETAILS',
    LIST_EMPLOYMENTDETAILS_SUCCESS: 'LIST_EMPLOYMENTDETAILS_SUCCESS',
    LIST_EMPLOYMENTDETAILS_ERROR: 'LIST_EMPLOYMENTDETAILS_ERROR',
    UPDATE_EMPLOYMENTDETAILS: 'UPDATE_EMPLOYMENTDETAILS',
    UPDATE_EMPLOYMENTDETAILS_SUCCESS: 'UPDATE_EMPLOYMENTDETAILS_SUCCESS',
    UPDATE_EMPLOYMENTDETAILS_ERROR: 'UPDATE_EMPLOYMENTDETAILS_ERROR',
    DELETE_EMPLOYMENTDETAILS: 'DELETE_EMPLOYMENTDETAILS',
    DELETE_EMPLOYMENTDETAILS_SUCCESS: 'DELETE_EMPLOYMENTDETAILS_SUCCESS',
    DELETE_EMPLOYMENTDETAILS_ERROR: 'DELETE_EMPLOYMENTDETAILS_ERROR'
};

export const saveEmploymentDetails = (payload) => {
    return {
        type: actionTypes.SAVE_EMPLOYMENTDETAILS, payload
    };
};
export const saveEmploymentDetailsSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_EMPLOYMENTDETAILS_SUCCESS,
        payload
    };
};
export const saveEmploymentDetailsError = (payload) => {
    return {
        type: actionTypes.SAVE_EMPLOYMENTDETAILS_ERROR,
        payload
    };
};

export const listEmploymentDetails = (payload) => {
    return {
        type: actionTypes.LIST_EMPLOYMENTDETAILS, payload
    };
};
export const listEmploymentDetailsSuccess = (payload) => {
    return {
        type: actionTypes.LIST_EMPLOYMENTDETAILS_SUCCESS,
        payload
    };
};
export const listEmploymentDetailsError = (payload) => {
    return {
        type: actionTypes.LIST_EMPLOYMENTDETAILS_ERROR,
        payload
    };
};

export const updateEmploymentDetails = (payload) => {
    return {
        type: actionTypes.UPDATE_EMPLOYMENTDETAILS, payload
    };
};
export const updateEmploymentDetailsSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_EMPLOYMENTDETAILS_SUCCESS,
        payload
    };
};
export const updateEmploymentDetailsError = (payload) => {
    return {
        type: actionTypes.UPDATE_EMPLOYMENTDETAILS_ERROR,
        payload
    };
};

export const deleteEmploymentDetails = (payload) => {
    return {
        type: actionTypes.DELETE_EMPLOYMENTDETAILS, payload
    };
};
export const deleteEmploymentDetailsSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_EMPLOYMENTDETAILS_SUCCESS,
        payload
    };
};
export const deleteEmploymentDetailsError = (payload) => {
    return {
        type: actionTypes.DELETE_EMPLOYMENTDETAILS_ERROR,
        payload
    };
};