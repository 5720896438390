import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import projectTypeService from '@service/admin/masterService/projectTypeService';
import {
  saveprojectTypeSuccess, saveprojectTypeError,
  listProjectTypeSuccess, listProjectTypeError,
  updateProjectTypeSuccess, updateProjectTypeError,
  deleteProjectTypeSuccess, deleteProjectTypeError
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'

function* saveprojectType({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(projectTypeService.saveProjectType, payload);
    yield put(saveprojectTypeSuccess(data));
  } catch (error) {
    yield put(saveprojectTypeError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listProjectType({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(projectTypeService.listProjectType, payload);
    Array.isArray(data) ?
    yield put(listProjectTypeSuccess(data)) :
    yield put(listProjectTypeSuccess([]));
  } catch (error) {
    yield put(listProjectTypeError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* updateProjectType({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(projectTypeService.updateProjectType, payload);
    yield put(updateProjectTypeSuccess(data));
  } catch (error) {
    yield put(updateProjectTypeError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* deleteProjectType({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(projectTypeService.deleteProjectType, payload);
    yield put(deleteProjectTypeSuccess(data));
  } catch (error) {
    yield put(deleteProjectTypeError(error));
  } finally {
    yield put(stopLoader());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_PROJECTTYPE, saveprojectType)]);
  yield all([takeEvery(actionTypes.LIST_PROJECTTYPE, listProjectType)]);
  yield all([takeEvery(actionTypes.UPDATE_PROJECTTYPE, updateProjectType)]);
  yield all([takeEvery(actionTypes.DELETE_PROJECTTYPE, deleteProjectType)]);




}