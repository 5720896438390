import { all } from 'redux-saga/effects'
import common from '@store/common/commonSaga'
import admin from '@store/admin/adminSaga'
import auth from '@store/Auth/saga'

export default function* rootSaga() {
    yield all([
        auth(),
        common(),
        admin()
    ]);
}