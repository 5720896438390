import { combineReducers } from 'redux';
const initialState = {
    brochurevalues: false,
    brochurelistvalues: [],
    vendorvalues: [],
    itemvalues: [],
    brandvalues: [],
    brochurelistByType: [],
    deleteBrochure: '',
    updateBrochure: ''
}

const brochureReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'ADD_BROCHURE':
            return { ...state, brochurevalues: false }
        case 'ADD_BROCHURE_SUCCESS':
            return { ...state, brochurevalues: action.payload }
        case 'ADD_BROCHURE_ERROR':
            return { ...state, brochurevalues: false }

        case 'FETCH_BROCHURE':
            return { ...state, brochurelistvalues: [] }
        case 'FETCH_BROCHURE_SUCCESS':
            return { ...state, brochurelistvalues: action.payload }
        case 'FETCH_BROCHURE_ERROR':
            return { ...state, brochurelistvalues: [] }

        case 'FETCH_VENDOR':
            return { ...state, vendorvalues: [] }
        case 'FETCH_VENDOR_SUCCESS':
            return { ...state, vendorvalues: action.payload }
        case 'FETCH_VENDOR_ERROR':
            return { ...state, vendorvalues: [] }

        case 'FETCH_ITEMS':
            return { ...state, itemvalues: [] }
        case 'FETCH_ITEMS_SUCCESS':
            return { ...state, itemvalues: action.payload }
        case 'FETCH_ITEMS_ERROR':
            return { ...state, itemvalues: [] }

        case 'FETCH_BRAND':
            return { ...state, brandvalues: [] }
        case 'FETCH_BRAND_SUCCESS':
            return { ...state, brandvalues: action.payload }
        case 'FETCH_BRAND_ERROR':
            return { ...state, brandvalues: [] }

        case 'FETCH_BROCHURE_BY_TYPE':
            return { ...state, brochurelistByType: [] }
        case 'FETCH_BROCHURE_BY_TYPE_SUCCESS':
            return { ...state, brochurelistByType: action.payload }
        case 'FETCH_BROCHURE_BY_TYPE_ERROR':
            return { ...state, brochurelistByType: [] }

        case 'DELETE_BROCHURE':
            return { ...state, deleteBrochure: '' }
        case 'DELETE_BROCHURE_SUCCESS':
            return { ...state, deleteBrochure: 'success' }
        case 'DELETE_BROCHURE_ERROR':
            return { ...state, deleteBrochure: 'error' }

        case 'UPDATE_BROCHURE':
            return { ...state, updateBrochure: '' }
        case 'UPDATE_BROCHURE_SUCCESS':
            return { ...state, updateBrochure: 'success' }
        case 'UPDATE_BROCHURE_ERROR':
            return { ...state, updateBrochure: 'error' }


        default:
            return state
    }
}
export default brochureReducer