import axios from '@service/ApiClient'

class identityDetailsService {
    constructor(callback) {
        this.callback = callback
    }
    async saveIdentityDetails(payload) {
        return await axios.post('/Crm/User/add_emp_identity_details', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listIdentityDetails(payload) {
        return await axios.post('/Crm/User/list_emp_identity_details', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateIdentityDetails(payload) {
        return await axios.post('/Crm/User/update_emp_identity_details', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteIdentityDetails(payload) {
        return await axios.post('/Crm/User/delete_emp_identity_details', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
   
}

export default new identityDetailsService();