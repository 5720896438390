import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import shiftToEmployeeService from '@service/admin/masterService/shiftToEmployeeService';
import {
  listEmployeesSuccess, listEmployeesError,
  // listShiftSuccess, listShiftError,
  assignEmployeeShiftSuccess, assignEmployeeShiftError
} from './action';

function* assignShift({ payload }) {
  try {
    const data = yield call(shiftToEmployeeService.assignShift, payload);

    yield put(assignEmployeeShiftSuccess(data));
  } catch (error) {
    yield put(assignEmployeeShiftError(error));
  } finally {
  }
}

function* listEmployees({ payload }) {
  try {
    const data = yield call(shiftToEmployeeService.listEmployees, payload);

    yield put(listEmployeesSuccess(data));
  } catch (error) {
    yield put(listEmployeesError(error));
  } finally {
  }
}

// function* listShift({ payload }) {
//   try {
//     const data = yield call(shiftToEmployeeService.listShift, payload);

//     yield put(listShiftSuccess(data));
//   } catch (error) {
//     yield put(listShiftError(error));
//   } finally {
//   }
// }
export default function* rootSaga() {
  yield all([takeEvery(actionTypes.LIST_EMPLOYEES, listEmployees)]);
  // yield all([takeEvery(actionTypes.LIST_SHIFT, listShift)]);
  yield all([takeEvery(actionTypes.ASSIGN_EMPLOYEE_SHIFT, assignShift)]);

}