
import { actionTypes } from './action';

export const initialState = {
    listDealStageValues: [],
    editDealStageValues: ''
}

const dealStageReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.LIST_DEAL_STAGE:
            return { ...state, listDealStageValues: [],
                editDealStageValues: '' }
        case actionTypes.LIST_DEAL_STAGE_SUCCESS:
            return { ...state, listDealStageValues: action.payload,
                editDealStageValues: '' }
        case actionTypes.LIST_DEAL_STAGE_ERROR:
            return { ...state, listDealStageValues: [],
                editDealStageValues: '' }

        case actionTypes.EDIT_DEAL_STAGE:
            return { ...state, editDealStageValues: '', listDealStageValues: [] }
        case actionTypes.EDIT_DEAL_STAGE_SUCCESS:
            return { ...state, editDealStageValues: action.payload, listDealStageValues: [] }
        case actionTypes.EDIT_DEAL_STAGE_ERROR:
            return { ...state, editDealStageValues: '', listDealStageValues: [] }

        default:
            return state
    }
}

export default dealStageReducer