import { actionTypes } from './action';


export const initialState = {
    contactvalues: [],
    assignvalues: [],
    contactOrgValues: [],
    contactList: [],
    saveStatus: '',
    updateStatus: '',
    deleteStatus: '',
    contactData: {},
    crmFunnelSaveStatus: '',
    crmFunnelData: '',
}

const contactReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SAVE_CONTACT:
            return { ...state, saveStatus: '' }
        case actionTypes.SAVE_CONTACT_SUCCESS:

            return { ...state, saveStatus: action.payload }
        case actionTypes.SAVE_CONTACT_ERROR:
            return { ...state, saveStatus: 'error' }

        case actionTypes.ASSIGN_CONTACT:
            return { ...state, assignvalues: [] }
        case actionTypes.ASSIGN_CONTACT_SUCCESS:
            return { ...state, assignvalues: action.payload }
        case actionTypes.ASSIGN_CONTACT_ERROR:
            return { ...state, assignvalues: [] }

        case actionTypes.CONTACT_ORGANISATION:
            return { ...state, contactOrgValues: [] }
        case actionTypes.CONTACT_ORGANISATION_SUCCESS:
            return { ...state, contactOrgValues: action.payload }
        case actionTypes.CONTACT_ORGANISATION_ERROR:
            return { ...state, contactOrgValues: [] }

        case actionTypes.LIST_CONTACT:
            return { ...state, contactList: [], saveStatus: '', deleteStatus: '', updateStatus: '' }
        case actionTypes.LIST_CONTACT_SUCCESS:
            return { ...state, contactList: action.payload }
        case actionTypes.LIST_CONTACT_ERROR:
            return { ...state, contactList: [] }

        case actionTypes.DELETE_CONTACT:
            return { ...state, deleteStatus: '' }
        case actionTypes.DELETE_CONTACT_SUCCESS:
            return { ...state, deleteStatus: action.payload }
        case actionTypes.DELETE_CONTACT_ERROR:
            return { ...state, deleteStatus: 'error' }

        case actionTypes.UPDATE_CONTACT:
            return { ...state, updateStatus: '', }
        case actionTypes.UPDATE_CONTACT_SUCCESS:
            return { ...state, updateStatus: action.payload }
        case actionTypes.UPDATE_CONTACT_ERROR:
            return { ...state, updateStatus: 'error' }

        case actionTypes.FETCH_CONTACTS_BY_ID:
            return { ...state, contactData: {} }
        case actionTypes.FETCH_CONTACTS_BY_ID_SUCCESS:
            return { ...state, contactData: action.payload }
        case actionTypes.FETCH_CONTACTS_BY_ID_ERROR:
            return { ...state, contactData: {} }

        case 'FETCH_CRM_FUNNEL_DATA':
            return { ...state, crmFunnelData: '' }
        case 'FETCH_CRM_FUNNEL_DATA_SUCCESS':
            return { ...state, crmFunnelData: action.payload }
        case 'FETCH_CRM_FUNNEL_DATA_ERROR':
            return { ...state, crmFunnelData: '' }

        case 'SAVE_CRM_FUNNEL_DATA':
            return { ...state, crmFunnelSaveStatus: '' }
        case 'SAVE_CRM_FUNNEL_DATA_SUCCESS':
            return { ...state, crmFunnelSaveStatus: action.payload }
        case 'SAVE_CRM_FUNNEL_DATA_ERROR':
            return { ...state, crmFunnelSaveStatus: '' }

        default:
            return state
    }
}

export default contactReducer
