import { combineReducers } from 'redux';
const initialState = {
    myLeaveRequestList: [],
    leaveTypeListForReq: [],
    pmLeaveRequestList: [],
    leaveRequestSave: '',
    leaveRequestUpdate: '',
    cancelStatus: '',
    approveStatus: '',
    rejectStatus: '',
    leaveParameters: [],
    leaveDashboardData:[]
}


const leaveReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'SAVE_LEAVE_REQUEST':
            return { ...state, leaveRequestSave: '' }
        case 'SAVE_LEAVE_REQUEST_SUCCESS':
            return { ...state, leaveRequestSave: action.payload }
        case 'SAVE_LEAVE_REQUEST_ERROR':
            return { ...state, leaveRequestSave: 'error' }

        case 'UPDATE_LEAVE_REQUEST':
            return { ...state, leaveRequestUpdate: '' }
        case 'UPDATE_LEAVE_REQUEST_SUCCESS':
            return { ...state, leaveRequestUpdate: action.payload }
        case 'UPDATE_LEAVE_REQUEST_ERROR':
            return { ...state, leaveRequestUpdate: 'error' }

        case 'FETCH_MY_LEAVE_REQUEST':
            return { ...state, myLeaveRequestList: [] }
        case 'FETCH_MY_LEAVE_REQUEST_SUCCESS':
            return { ...state, myLeaveRequestList: action.payload, cancelStatus: '', leaveRequestUpdate: '', leaveRequestSave: '',leaveParameters:[] }
        case 'FETCH_MY_LEAVE_REQUEST_ERROR':
            return { ...state, myLeaveRequestList: [] }

        case 'FETCH_LEAVE_TYPE_FOR_REQUEST':
            return { ...state, leaveTypeListForReq: [] }
        case 'FETCH_LEAVE_TYPE_FOR_REQUEST_SUCCESS':
            return { ...state, leaveTypeListForReq: action.payload }
        case 'FETCH_LEAVE_TYPE_FOR_REQUEST_ERROR':
            return { ...state, leaveTypeListForReq: [] }

        case 'CANCEL_LEAVE_REQUEST':
            return { ...state, cancelStatus: '' }
        case 'CANCEL_LEAVE_REQUEST_SUCCESS':
            return { ...state, cancelStatus: action.payload }
        case 'CANCEL_LEAVE_REQUEST_ERROR':
            return { ...state, cancelStatus: 'error' }

        case 'FETCH_PM_LEAVE_REQUEST':
            return { ...state, pmLeaveRequestList: '' }
        case 'FETCH_PM_LEAVE_REQUEST_SUCCESS':
            return { ...state, pmLeaveRequestList: action.payload }
        case 'FETCH_PM_LEAVE_REQUEST_ERROR':
            return { ...state, pmLeaveRequestList: 'error' }

        case 'APPROVE_LEAVE_REQUEST':
            return { ...state, approveStatus: '' }
        case 'APPROVE_LEAVE_REQUEST_SUCCESS':
            return { ...state, approveStatus: action.payload }
        case 'APPROVE_LEAVE_REQUEST_ERROR':
            return { ...state, approveStatus: 'error' }

        case 'REJECT_LEAVE_REQUEST':
            return { ...state, rejectStatus: '' }
        case 'REJECT_LEAVE_REQUEST_SUCCESS':
            return { ...state, rejectStatus: action.payload }
        case 'REJECT_LEAVE_REQUEST_ERROR':
            return { ...state, rejectStatus: 'error' }

        case 'CHECK_LEAVE_PARAMETERS':
            return { ...state, leaveParameters: [] }
        case 'CHECK_LEAVE_PARAMETERS_SUCCESS':
            return { ...state, leaveParameters: action.payload }
        case 'CHECK_LEAVE_PARAMETERS_ERROR':
            return { ...state, leaveParameters: [] }

        case 'FETCH_LEAVE_DASHBOARD':
            return { ...state, leaveDashboardData: [] }
        case 'FETCH_LEAVE_DASHBOARD_SUCCESS':
            return { ...state, leaveDashboardData: action.payload }
        case 'FETCH_LEAVE_DASHBOARD_ERROR':
            return { ...state, leaveDashboardData: [] }

        default:
            return state
    }
}
export default leaveReducer