
export const actionTypes = {
    LIST_DEAL_STAGE: 'LIST_DEAL_STAGE',
    LIST_DEAL_STAGE_SUCCESS: 'LIST_DEAL_STAGE_SUCCESS',
    LIST_DEAL_STAGE_ERROR: 'LIST_DEAL_STAGE_ERROR',
    EDIT_DEAL_STAGE: 'EDIT_DEAL_STAGE',
    EDIT_DEAL_STAGE_SUCCESS: 'EDIT_DEAL_STAGE_SUCCESS',
    EDIT_DEAL_STAGE_ERROR: 'EDIT_DEAL_STAGE_ERROR',
};

export const listDealStage = (payload) => {
    return {
        type: actionTypes.LIST_DEAL_STAGE,
        payload
    };
};
export const listDealStageSuccess = (payload) => {
    return {
        type: actionTypes.LIST_DEAL_STAGE_SUCCESS,
        payload
    };
};
export const listDealStageError = (payload) => {
    return {
        type: actionTypes.LIST_DEAL_STAGE_ERROR,
        payload
    };
};

export const editDealStage = (payload) => {
    return {
        type: actionTypes.EDIT_DEAL_STAGE,
        payload
    };
};
export const editDealStageSuccess = (payload) => {
    return {
        type: actionTypes.EDIT_DEAL_STAGE_SUCCESS,
        payload
    };
};
export const editDealStageError = (payload) => {
    return {
        type: actionTypes.EDIT_DEAL_STAGE_ERROR,
        payload
    };
};