import { combineReducers } from 'redux';

const initialState = {
    adavancepaymentstatus: [],
    fetchadpayment: [],
    getadvanceamt: {}
}

const salaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SALARY_ADVANCE_PAYMENT':
            return { ...state, adavancepaymentstatus: [] }
        case 'SALARY_ADVANCE_PAYMENT_SUCCESS':
            return { ...state, adavancepaymentstatus: action.payload }
        case 'SALARY_ADVANCE_PAYMENT_ERROR':
            return { ...state, adavancepaymentstatus: [] }

        case 'FETCH_ADVANCE_PAYMENT':
            return { ...state, fetchadpayment: [] }
        case 'FETCH_ADVANCE_PAYMENT_SUCCESS':
            return { ...state, fetchadpayment: action.payload }
        case 'FETCH_ADVANCE_PAYMENT_ERROR':
            return { ...state, fetchadpayment: [] }

        case 'GET_ADVANCE_AMOUNT':
            return { ...state, getadvanceamt: {} }
        case 'GET_ADVANCE_AMOUNT_SUCCESS':
            return { ...state, getadvanceamt: action.payload }
        case 'GET_ADVANCE_AMOUNT_ERROR':
            return { ...state, getadvanceamt: {} }

        default:
            return state
    }
}
export default salaryReducer