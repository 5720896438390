module.exports = {
    // siteUrl: 'https://bixss.bixsspro.com/manage/index.php/',
    // baseUrl: 'https://bixss.bixsspro.com/',
    // imageUrl: 'https://bixss.bixsspro.com/images/'

    // siteUrl: 'https://mstage.bixss.com/manage/index.php/',
    // baseUrl: 'https://mstage.bixss.com/manage/',
    // imageUrl: 'https://mstage.bixss.com/manage/images/',
    

    // siteUrl: 'https://eassetstage.losmat.com/manage/index.php/',
    // baseUrl: 'https://eassetstage.losmat.com/',
    // imageUrl: 'https://eassetstage.losmat.com/manage/images/',
    // docUrl: 'https://eassetstage.losmat.com/manage/',

    siteUrl: 'https://eassets.bixss.com/manage/index.php/',
    baseUrl: 'https://eassets.bixss.com/',
    imageUrl: 'https://eassets.bixss.com/manage/images/',
    docUrl: 'https://eassets.bixss.com/manage/',


    // siteUrl: 'https://bixss.com/manage/index.php/',
    // baseUrl: 'https://bixss.com/',
    // imageUrl: 'https://bixss.com/manage/images/',
    // docUrl: 'https://bixss.com/manage/',

    // siteUrl: `http://localhost/bixsssasrepo/index.php/`,
    // baseUrl: 'http://localhost/bixsssasrepo/',
    // imageUrl: 'http://localhost/bixsssasrepo/images/'

    // siteUrl: `https://leeassociates.in/manage/index.php/`,
    // baseUrl: 'https://leeassociates.in/',
    // imageUrl: 'https://leeassociates.in/manage/images/'
};








                



// module.exports = {
    


//     siteUrl: 'https://122.187.94.238:8325/manage/index.php/',
//     baseUrl: 'https://122.187.94.238:8325/manage/',
//     // siteUrl: 'https://eassets.bixss.com/manage/index.php/',
//     // baseUrl: 'https://eassets.bixss.com/',
//     imageUrl: 'https://122.187.94.238:8325/manage/images/',
//     docUrl: 'https://122.187.94.238:8325/manage/',

//     // siteUrl: 'https://eassetsdemo.bixss.com/manage/index.php/',
//     // baseUrl: 'https://eassetsdemo.bixss.com/',
//     // imageUrl: 'https://eassetsdemo.bixss.com/manage/images/',
//     // docUrl: 'https://eassetsdemo.bixss.com/manage/',

//     // siteUrl: 'https://eassets.edavaka.com/manage/index.php/',
//     // baseUrl: 'https://eassets.edavaka.com/',
//     // imageUrl: 'https://eassets.edavaka.com/manage/images/',
//     // docUrl: 'https://eassets.edavaka.com/manage/',
   
// };








                


