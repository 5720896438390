import axios from '@service/ApiClient'

class storeMasterService {
    constructor(callback) {
        this.callback = callback
    }
    async saveStore(payload) {
        return await axios.post('/Inventory/Stores/save_store', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updateStore(payload) {
        return await axios.post('/Inventory/Stores/update_store', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listStore(payload) {
        return await axios.post('/Inventory/Stores/fetch_all_store', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listSubStore(payload) {
        return await axios.post('/Inventory/Stores/fetch_parent_sub_store', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteStore(payload) {
        return await axios.post('/Inventory/Stores/delete_store', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new storeMasterService();