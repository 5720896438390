import { takeEvery, put, call, all } from 'redux-saga/effects';
import contactservice from '@service/admin/masterService/contactservice';
import { actionTypes } from './action'
import {
    saveContactSuccess, saveContactError,
    assignContactSuccess, assignContactError,
    contactOrganisationSuccess, contactOrganisationError,
    listContactSuccess, listContactError,
    deleteContactSuccess, deleteContactError,
    updateContactSuccess, updateContactError,
    fetchContactsByIdSuccess, fetchContactsByIdError,
    fetchCrmFunnelDataSuccess, fetchCrmFunnelDataError,
    saveCrmFunnelDataSuccess, saveCrmFunnelDataError,
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'


function* saveContact({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(contactservice.saveContact, payload);
        yield put(saveContactSuccess(data));
    } catch (error) {
        yield put(saveContactError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* assignContact({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(contactservice.assignContact, payload);
        Array.isArray(data) ?
            yield put(assignContactSuccess(data)) : yield put(assignContactError([]));
    } catch (error) {
        yield put(assignContactError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* contactOrganisation({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(contactservice.listOrganization, payload);
        Array.isArray(data) ?
            yield put(contactOrganisationSuccess(data)) : yield put(contactOrganisationError([]));
    } catch (error) {
        yield put(contactOrganisationError(error));
    } finally {
        yield put(stopLoader());
    }
}
function* listContact({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(contactservice.listContact, payload);
        Array.isArray(data) ?
            yield put(listContactSuccess(data)) : yield put(listContactError([]));
    } catch (error) {
        yield put(listContactError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* updateContact({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(contactservice.updateContact, payload);

        yield put(updateContactSuccess(data));
    } catch (error) {
        yield put(updateContactError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* deleteContact({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(contactservice.deleteContact, payload);

        yield put(deleteContactSuccess(data));
    } catch (error) {
        yield put(deleteContactError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* fetchContactsById({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(contactservice.fetchContactsById, payload);

        yield put(fetchContactsByIdSuccess(data));
    } catch (error) {
        yield put(fetchContactsByIdError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* fetchCrmFunnelData({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(contactservice.fetchCrmFunnelData, payload);

        yield put(fetchCrmFunnelDataSuccess(data));
    } catch (error) {
        yield put(fetchCrmFunnelDataError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* saveCrmFunnelData({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(contactservice.saveCrmFunnelData, payload);

        yield put(saveCrmFunnelDataSuccess(data));
    } catch (error) {
        yield put(saveCrmFunnelDataError(error));
    } finally {
        yield put(stopLoader());
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actionTypes.SAVE_CONTACT, saveContact)]);
    yield all([takeEvery(actionTypes.ASSIGN_CONTACT, assignContact)]);
    yield all([takeEvery(actionTypes.CONTACT_ORGANISATION, contactOrganisation)]);
    yield all([takeEvery(actionTypes.LIST_CONTACT, listContact)]);
    yield all([takeEvery(actionTypes.UPDATE_CONTACT, updateContact)]);
    yield all([takeEvery(actionTypes.DELETE_CONTACT, deleteContact)]);
    yield all([takeEvery(actionTypes.FETCH_CONTACTS_BY_ID, fetchContactsById)]);
    yield all([takeEvery('FETCH_CRM_FUNNEL_DATA', fetchCrmFunnelData)]);
    yield all([takeEvery('SAVE_CRM_FUNNEL_DATA', saveCrmFunnelData)]);
}