import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import leadsService from '@service/admin/masterService/leadsService';
import {
  listIndustriesSuccess, listIndustriesError,
  saveLeadsSuccess, saveLeadsError,
  listLeadsSuccess, listLeadsError,
  updateLeadsSuccess, updateLeadsError,
  deleteLeadsSuccess, deleteLeadsError,
  fetchLeadsByIdSuccess, fetchLeadsByIdError,
  fetchAddressLeadsByIdSuccess, fetchAddressLeadsByIdError,
  convertLeadSuccess, convertLeadError,
  fetchDashboardDataSuccess, fetchDashboardDataError,
  fetchDashboardFeedsSuccess, fetchDashboardFeedsError,
  fetchTodayTasksSuccess, fetchTodayTasksError,
  fetchCurrentUserTaskSuccess, fetchCurrentUserTaskError,
  fetchTasksByDateSuccess, fetchTasksByDateError,
  fetchTeamMemberTasksSuccess, fetchTeamMemberTasksError,
  fetchupcomingTasksSuccess, fetchupcomingTasksError, fetchupcomingTasks,
} from './action';
import { stopLoader, startLoader } from '@store/common/loader/action'
import moment from 'moment';

function* listIndustries({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leadsService.listIndustries, payload);

    yield put(listIndustriesSuccess(data));
  } catch (error) {
    yield put(listIndustriesError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* saveLeads({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leadsService.saveLeads, payload);

    yield put(saveLeadsSuccess(data));
  } catch (error) {
    yield put(saveLeadsError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listLeads({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leadsService.listLeads, payload);
    Array.isArray(data) ?
      yield put(listLeadsSuccess(data)) : yield put(listLeadsError([]));
  } catch (error) {
    yield put(listLeadsError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* updateLeads({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leadsService.updateLeads, payload);

    yield put(updateLeadsSuccess(data));
  } catch (error) {
    yield put(updateLeadsError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* deleteLeads({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leadsService.deleteLeads, payload);

    yield put(deleteLeadsSuccess(data));
  } catch (error) {
    yield put(deleteLeadsError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* fetchLeadsById({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leadsService.fetchLeadsById, payload);
    yield put(fetchLeadsByIdSuccess(data));
  } catch (error) {
    yield put(fetchLeadsByIdError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* fetchAddressLeadsById({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leadsService.fetchAddressLeadsById, payload);
    yield put(fetchAddressLeadsByIdSuccess(data));
  } catch (error) {
    yield put(fetchAddressLeadsByIdError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* convertLead({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leadsService.convertLead, payload);
    yield put(convertLeadSuccess(data));
  } catch (error) {
    yield put(convertLeadError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* fetchDashboardData({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leadsService.fetchDashboardData, payload);
    yield put(fetchDashboardDataSuccess(data));
  } catch (error) {
    yield put(fetchDashboardDataError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* fetchDashboardFeeds({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leadsService.fetchDashboardFeeds, payload);
    let tempData = data.user_data ? JSON.parse(data.user_data) : []
   
    yield put(fetchDashboardFeedsSuccess({
      lead_feed: tempData.filter(item => item.lead_id).reverse(),
      company_feed: tempData.filter(item => item.comapny_id).reverse(),
      contact_feed: tempData.filter(item => item.contact_id).reverse(),
      task_feed: tempData.filter(item => {
        if(item.task_id){
          let c_date=item.created_datetime||item.edited_datetime
          if(c_date.split(' ')[0] === moment().format('YYYY-MM-DD'))
          return item
        }
      }).reverse(),
      // task_feed: tempData.filter(item => item.task_id && item.created_datetime.split(' ')[0] === moment().format('YYYY-MM-DD')).reverse(),
      crm_data: tempData.filter(item => ((item.lead_id || item.comapny_id || item.contact_id) && item.created_date.split(' ')[0] == moment().format('YYYY-MM-DD'))).reverse()
    }));
    yield put(fetchupcomingTasks({ organization_id: payload.organization_id, business_unit_id: payload.business_unit_id }))
  } catch (error) {
    console.log(error);
    yield put(fetchDashboardFeedsError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* fetchCurrentUserTask({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leadsService.fetchCurrentUserTask, payload);
    yield put(fetchCurrentUserTaskSuccess({
      // taskList:data,
      open_task: data.filter(item => item.task_status === 'open').length,
      high_count: data.filter(item => item.task_priority === 'high').length
    }));
  } catch (error) {
    yield put(fetchCurrentUserTaskError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* fetchTodayTasks({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leadsService.fetchTodayTasks, payload);
    yield put(fetchTodayTasksSuccess(data.reverse()));
  } catch (error) {
    yield put(fetchTodayTasksError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* fetchTasksByDate({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leadsService.fetchTasksByDate, payload);
    yield put(fetchTasksByDateSuccess(data.reverse()));
  } catch (error) {
    yield put(fetchTasksByDateError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* fetchupcomingTask({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leadsService.fetchupcomingTasks, payload);
    yield put(fetchupcomingTasksSuccess(data.reverse()));
  } catch (error) {
    yield put(fetchupcomingTasksError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* fetchTeamMemberTasks({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(leadsService.fetchTeamMemberTasks, payload);
    yield put(fetchTeamMemberTasksSuccess(data.reverse()));
  } catch (error) {
    yield put(fetchTeamMemberTasksError(error));
  } finally {
    yield put(stopLoader());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.LIST_INDUSTRIES, listIndustries)]);
  yield all([takeEvery(actionTypes.SAVE_LEADS, saveLeads)]);
  yield all([takeEvery(actionTypes.LIST_LEADS, listLeads)]);
  yield all([takeEvery(actionTypes.DELETE_LEADS, deleteLeads)]);
  yield all([takeEvery(actionTypes.UPDATE_LEADS, updateLeads)]);
  yield all([takeEvery(actionTypes.FETCH_LEADS_BY_ID, fetchLeadsById)]);
  yield all([takeEvery(actionTypes.FETCH_ADDRESS_LEADS_BY_ID, fetchAddressLeadsById)]);
  yield all([takeEvery(actionTypes.CONVERT_LEAD, convertLead)]);
  yield all([takeEvery('FETCH_DASHBOARD_DATA', fetchDashboardData)]);
  yield all([takeEvery('FETCH_DASHBOARD_FEEDS', fetchDashboardFeeds)]);
  yield all([takeEvery('FETCH_CURRENT_USER_TASK', fetchCurrentUserTask)]);
  yield all([takeEvery('FETCHTODAY_TASKS', fetchTodayTasks)]);
  yield all([takeEvery('FETCH_TASKS_BYDATE', fetchTasksByDate)]);
  yield all([takeEvery('FETCH_UPCOMING_TASKS', fetchupcomingTask)]);
  yield all([takeEvery('FETCH_TEAM_MEMBER_TASKS', fetchTeamMemberTasks)]);
}