import { actionTypes } from './action';

export const initialState = {
    saveStatus: '',
    deleteStatus: '',
    updateStatus: '',
    poList: [],
    poDetails: '',
    invalidBarcode: [],
    storeList: [],
    mrvList: [],
    mrvDetails: [],
    barcodenumber:''
}
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LIST_MRV_PO:
            return { ...state, poList: [] }
        case actionTypes.LIST_MRV_PO_SUCCESS:
            return { ...state, poList: action.payload }
        case actionTypes.LIST_MRV_PO_ERROR:
            return { ...state, poList: [] }

        case actionTypes.FETCH_PO_BY_ID:
            return { ...state, poDetails: '' }
        case actionTypes.FETCH_PO_BY_ID_SUCCESS:
            return { ...state, poDetails: action.payload }
        case actionTypes.FETCH_PO_BY_ID_ERROR:
            return { ...state, poDetails: '' }

        case actionTypes.FETCH_MRV_BY_ID:
            return { ...state, mrvDetails: [] }
        case actionTypes.FETCH_MRV_BY_ID_SUCCESS:
            return { ...state, mrvDetails: action.payload }
        case actionTypes.FETCH_MRV_BY_ID_ERROR:
            return { ...state, mrvDetails: [] }

        case actionTypes.CHECK_BARCODE:
            return { ...state, invalidBarcode: [] }
        case actionTypes.CHECK_BARCODE_SUCCESS:
            return { ...state, invalidBarcode: action.payload }
        case actionTypes.CHECK_BARCODE_ERROR:
            return { ...state, invalidBarcode: [] }

        case actionTypes.LIST_STORE_MRV:
            return { ...state, storeList: [] }
        case actionTypes.LIST_STORE_MRV_SUCCESS:
            return { ...state, storeList: action.payload }
        case actionTypes.LIST_STORE_MRV_ERROR:
            return { ...state, storeList: [] }

        case actionTypes.SAVE_MRV:
            return { ...state, saveStatus: '' }
        case actionTypes.SAVE_MRV_SUCCESS:
            return { ...state, saveStatus: action.payload }
        case actionTypes.SAVE_MRV_ERROR:
            return { ...state, saveStatus: 'error' }

        case actionTypes.LIST_MRV:
            return { ...state, mrvList: [], saveStatus: '', updateStatus: '', deleteStatus: '', poDetails: [] }
        case actionTypes.LIST_MRV_SUCCESS:
            return { ...state, mrvList: action.payload }
        case actionTypes.LIST_MRV_ERROR:
            return { ...state, mrvList: [] }

        case actionTypes.DELETE_MRV:
            return { ...state, deleteStatus: '' }
        case actionTypes.DELETE_MRV_SUCCESS:
            return { ...state, deleteStatus: action.payload }
        case actionTypes.DELETE_MRV_ERROR:
            return { ...state, deleteStatus: 'error' }

        case 'BARCODE_GENERATE':
            return { ...state, barcodenumber: '' }
        case 'BARCODE_GENERATE_SUCCESS':
            return { ...state, barcodenumber: action.payload }
        case 'BARCODE_GENERATE_ERROR':
            return { ...state, barcodenumber: '' }
        default:
            return state
    }
}

export default userReducer
