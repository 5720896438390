export const salaryadavncepayment= (payload) => {
    return {
        type: 'SALARY_ADVANCE_PAYMENT',
        payload
    };
};
export const salaryadavncepaymentSuccess = (payload) => {
    return {
        type: 'SALARY_ADVANCE_PAYMENT_SUCCESS',
        payload
    };
};
export const salaryadavncepaymentError = (payload) => {
    return {
        type: 'SALARY_ADVANCE_PAYMENT_ERROR',
        payload
    };
};

export const fetchadavncepayment= (payload) => {
    return {
        type: 'FETCH_ADVANCE_PAYMENT',
        payload
    };
};
export const fetchadavncepaymentSuccess = (payload) => {
    return {
        type: 'FETCH_ADVANCE_PAYMENT_SUCCESS',
        payload
    };
};
export const fetchadavncepaymentError = (payload) => {
    return {
        type: 'FETCH_ADVANCE_PAYMENT_ERROR',
        payload
    };
};

export const getadvancesalaryamount= (payload) => {
    return {
        type: 'GET_ADVANCE_AMOUNT',
        payload
    };
};
export const getadvancesalaryamountSuccess = (payload) => {
    return {
        type: 'GET_ADVANCE_AMOUNT_SUCCESS',
        payload
    };
};
export const getadvancesalaryamountError = (payload) => {
    return {
        type: 'GET_ADVANCE_AMOUNT_ERROR',
        payload
    };
};