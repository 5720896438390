import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import vendorService from '@service/admin/masterService/vendorService';
import {
    saveVendorSuccess, saveVendorError,
    listVendorSuccess, listVendorError,
    updateVendorSuccess, updateVendorError,
    deleteVendorSuccess, deleteVendorError,
    importvendorSuccess,importvendorError
} from './action';

function* saveVendor({ payload }) {
    try {
        const data = yield call(vendorService.saveVendor, payload);
        yield put(saveVendorSuccess(data));
    } catch (error) {
        yield put(saveVendorError(error));
    } finally {
    }
}

function* listVendor({ payload }) {
    try {
        const data = yield call(vendorService.listVendor, payload);
        Array.isArray(data) ?
            yield put(listVendorSuccess(data)) :
            yield put(listVendorError([]));
    } catch (error) {
        yield put(listVendorError(error));
    } finally {
    }
}

function* updateVendor({ payload }) {
    try {
        const data = yield call(vendorService.updateVendor, payload);
        yield put(updateVendorSuccess(data));
    } catch (error) {
        yield put(updateVendorError(error));
    } finally {
    }
}

function* deleteVendor({ payload }) {
    try {
        const data = yield call(vendorService.deleteVendor, payload);
        yield put(deleteVendorSuccess(data));
    } catch (error) {
        yield put(deleteVendorError(error));
    } finally {
    }
}

function* importvendor({ payload }) {
    try {
     
      const data = yield call(vendorService.importvendor, payload);
      yield put(importvendorSuccess(data));
    } catch (error) {
      yield put(importvendorError(error));
    } finally {
     
    }
  }
  

export default function* rootSaga() {
    yield all([takeEvery(actionTypes.SAVE_VENDOR, saveVendor)]);
    yield all([takeEvery(actionTypes.LIST_VENDOR, listVendor)]);
    yield all([takeEvery(actionTypes.UPDATE_VENDOR, updateVendor)]);
    yield all([takeEvery(actionTypes.DELETE_VENDOR, deleteVendor)]);
    yield all([takeEvery('IMPORT_VENDOR', importvendor)]);
}



