import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import generalSettingService from '@service/admin/masterService/generalSettingService';

import {
  saveGeneralSettingsSuccess, saveGeneralSettingsError,
  listGeneralSettingsSuccess, listGeneralSettingsError,
  updateGeneralSettingsSuccess, updateGeneralSettingsError,
  listAreaNameSuccess, listAreaNameError,
  listTimeZoneSuccess, listTimeZoneError
} from './action';

function* saveGeneralSettings({ payload }) {
  try {
    const data = yield call(generalSettingService.saveGeneralSettings, payload);

    yield put(saveGeneralSettingsSuccess(data));
  } catch (error) {
    yield put(saveGeneralSettingsError(error));
  } finally {
  }
}

function* listGeneralSettings({ payload }) {
  try {
    const data = yield call(generalSettingService.listGeneralSettings, payload);
    yield put(listGeneralSettingsSuccess(data.error ? {} : data));
  } catch (error) {
    yield put(listGeneralSettingsError(error));
  } finally {
  }
}

function* updateGeneralSettings({ payload }) {
  try {
    const data = yield call(generalSettingService.updateGeneralSettings, payload);

    yield put(updateGeneralSettingsSuccess(data));
  } catch (error) {
    yield put(updateGeneralSettingsError(error));
  } finally {
  }
}

function* listAreaName({ payload }) {
  try {
    const data = yield call(generalSettingService.listAreaName, payload);

    yield put(listAreaNameSuccess(data));
  } catch (error) {
    yield put(listAreaNameError(error));
  } finally {
  }
}

function* listTimeZone({ payload }) {
  try {
    const data = yield call(generalSettingService.listTimeZone, payload);

    yield put(listTimeZoneSuccess(data));
  } catch (error) {
    yield put(listTimeZoneError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_GENERAL_SETTINGS, saveGeneralSettings)]);
  yield all([takeEvery(actionTypes.LIST_GENERAL_SETTINGS, listGeneralSettings)]);
  yield all([takeEvery(actionTypes.UPDATE_GENERAL_SETTINGS, updateGeneralSettings)]);
  yield all([takeEvery(actionTypes.LIST_AREANAME, listAreaName)]);
  yield all([takeEvery(actionTypes.LIST_TIMEZONE, listTimeZone)]);

}