
export const actionTypes = {
    LIST_PREFIX: 'LIST_PREFIX',
    LIST_PREFIX_SUCCESS: 'LIST_PREFIX_SUCCESS',
    LIST_PREFIX_ERROR: 'LIST_PREFIX_ERROR',
    UPDATE_PREFIX: 'UPDATE_PREFIX',
    UPDATE_PREFIX_SUCCESS: 'UPDATE_PREFIX_SUCCESS',
    UPDATE_PREFIX_ERROR: 'UPDATE_PREFIX_ERROR',
};

export const listPrefix = (payload) => {
    return {
        type: actionTypes.LIST_PREFIX,
        payload
    };
};
export const listPrefixSuccess = (payload) => {
    return {
        type: actionTypes.LIST_PREFIX_SUCCESS,
        payload
    };
};
export const listPrefixError = (payload) => {
    return {
        type: actionTypes.LIST_PREFIX_ERROR,
        payload
    };
};

export const updatePrefix = (payload) => {
    return {
        type: actionTypes.UPDATE_PREFIX,
        payload
    };
};
export const updatePrefixSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_PREFIX_SUCCESS,
        payload
    };
};
export const updatePrefixError = (payload) => {
    return {
        type: actionTypes.UPDATE_PREFIX_ERROR,
        payload
    };
};