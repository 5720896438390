import { actionTypes } from './action';

export const initialState = {
    saveStatus: '',
    deleteStatus: '',
    updateStatus: '',
    dealList: [],
    stageList: [],
    projectList: [],
    dealData: {}
}
const dealReducer = (state = initialState, action) => {
    switch (action.type) {
        
        case actionTypes.SAVE_DEAL:
            return { ...state, saveStatus: '' }
        case actionTypes.SAVE_DEAL_SUCCESS:
            return { ...state, saveStatus: action.payload }
        case actionTypes.SAVE_DEAL_ERROR:
            return { ...state, saveStatus: 'error' }

        case actionTypes.UPDATE_DEAL:
            return { ...state, updateStatus: '' }
        case actionTypes.UPDATE_DEAL_SUCCESS:
            return { ...state, updateStatus: action.payload }
        case actionTypes.UPDATE_DEAL_ERROR:
            return { ...state, updateStatus: 'error' }

        case actionTypes.DELETE_DEAL:
            return { ...state, deleteStatus: '' }
        case actionTypes.DELETE_DEAL_SUCCESS:
            return { ...state, deleteStatus: action.payload }
        case actionTypes.DELETE_DEAL_ERROR:
            return { ...state, deleteStatus: 'error' }

        case actionTypes.FETCH_DEAL_DATA_BY_ID:
            return { ...state, dealData: {} }
        case actionTypes.FETCH_DEAL_DATA_BY_ID_SUCCESS:
            return { ...state, dealData: action.payload }
        case actionTypes.FETCH_DEAL_DATA_BY_ID_ERROR:
            return { ...state, dealData: {} }

        case actionTypes.LIST_DEAL:
            return { ...state, dealList: [], saveStatus: '', deleteStatus: '', updateStatus: '', dealData: {} }
        case actionTypes.LIST_DEAL_SUCCESS:
            return { ...state, dealList: action.payload }
        case actionTypes.LIST_DEAL_ERROR:
            return { ...state, dealList: [] }

        case actionTypes.LIST_STAGE:
            return { ...state, stageList: [] }
        case actionTypes.LIST_STAGE_SUCCESS:
            return { ...state, stageList: action.payload }
        case actionTypes.LIST_STAGE_ERROR:
            return { ...state, stageList: [] }

        case actionTypes.GET_PROJECT_LIST:
            return { ...state, projectList: [] }
        case actionTypes.GET_PROJECT_LIST_SUCCESS:
            return { ...state, projectList: action.payload }
        case actionTypes.GET_PROJECT_LIST_ERROR:
            return { ...state, projectList: [] }

        default:
            return state
    }
}

export default dealReducer
