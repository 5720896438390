import axios from '../../ApiClient'

class salutationservice {
    constructor(callback) {
        this.callback = callback
    }

    async fetch_salutation(payload) {
        const response = await axios.post('/Organization/Salutation/list_salutation', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async save_salutation(payload) {
        const response = await axios.post('/Organization/Salutation/save_salutation', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async delete_salutation(payload) {
        const response = await axios.post('/Organization/Salutation/delete_salutation', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async edit_salutation(payload) {
        const response = await axios.post('/Organization/Salutation/edit_salutation', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async update_salutation(payload) {
        const response = await axios.post('/Organization/Salutation/update_salutation', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
}

export default new salutationservice();