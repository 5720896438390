import { takeEvery, put, call, all } from 'redux-saga/effects';
import shiftservice from '@service/admin/masterService/shiftservice';

import {
  ListShiftSuccess, ListShiftError,
  SaveShiftSuccess, SaveShiftError,
  UpdateShiftSuccess, UpdateShiftError,
  DeleteShiftSuccess, DeleteShiftError,
  ListShiftIdSuccess, ListShiftIdError,

  // FetchDesignationSuccess, FetchDesignationError,
  // // SaveLeaveTypeSuccess, SaveLeaveTypeError,
  // // UpdateLeavetypeSuccess, UpdateLeavetypeError,
  // // FetchLeaveSuccess, FetchLeaveError,
  // // DeleteLeaveSuccess, DeleteLeaveError,
  // FetchLeaveIdSuccess, FetchLeaveIdError,

  // ListLeaveTypeSuccess, ListLeaveTypeError,
  // // SaveRuleSuccess, SaveRuleError,
  // // UpdateRuleSuccess, UpdateRuleError,
  // // EditRuleSuccess, EditRuleError,

  // SaveLeaveRequestSuccess, SaveLeaveRequestError,
  // // FetchLeaveRequestSuccess, FetchLeaveRequestError,
  // FetchLeaveTypeSuccess, FetchLeaveTypeError,
  // EditLeaveTypeSuccess, EditLeaveTypeError,
  // UpdateLeaveTypeSuccess, UpdateLeaveTypeError,
  // DeleteLeaveTypeSuccess, DeleteLeaveTypeError,
  // CancelLeaveRequestSuccess, CancelLeaveRequestError,
  // UpdateLeaveStatusSuccess, UpdateLeaveStatusError,

  // FetchRequestSuccess, FetchRequestError,
  // ApproveRequestSuccess, ApproveRequestError,
  // RejectRequestSuccess, RejectRequestError

} from './action';

// HR/ Shift Page
function* ListShift({ payload }) {
  try {

    const data = yield call(shiftservice.listShift, payload);
    yield put(ListShiftSuccess(data));
  } catch (error) {
    yield put(ListShiftError(error));
  } finally {
    // yield put(stopLoader());
  }
}

function* SaveShift({ payload }) {
  try {

    const data = yield call(shiftservice.saveShift, payload);
    yield put(SaveShiftSuccess(data));
  } catch (error) {
    yield put(SaveShiftError(error));
  } finally {
    // yield put(stopLoader());
  }
}

function* GetShiftId({ payload }) {
  try {

    const data = yield call(shiftservice.getShift, payload);
    yield put(ListShiftIdSuccess(data));
    console.log('data', data);
  } catch (error) {
    yield put(ListShiftIdError(error));
  } finally {
    // yield put(stopLoader());
  }
}

function* UpdateShift({ payload }) {
  try {

    const data = yield call(shiftservice.updateShift, payload);
    yield put(UpdateShiftSuccess(data));
    console.log('data', data);
  } catch (error) {
    yield put(UpdateShiftError(error));
  } finally {
    // yield put(stopLoader());
  }
}
function* DeleteShift({ payload }) {
  try {

    const data = yield call(shiftservice.deleteShift, payload);
    yield put(DeleteShiftSuccess(data));
  } catch (error) {
    yield put(DeleteShiftError(error));
  } finally {
    // yield put(stopLoader());
  }
}

// HR / Leave Type

// function* FetchDesignation({ payload }) {
//   try {

//     const data = yield call(shiftservice.fetchDesignation, payload);
//     yield put(FetchDesignationSuccess(data));
//   } catch (error) {
//     yield put(FetchDesignationError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// Save leave

// function* SaveLeaveType({ payload }) {
//   try {

//     const data = yield call(shiftservice.saveLeaveType, payload);
//     console.log('data', data);
//     yield put(SaveLeaveTypeSuccess(data));
//   } catch (error) {
//     yield put(SaveLeaveTypeError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// update leave
// function* UpdateLeavetype({ payload }) {
//   try {

//     const data = yield call(shiftservice.updateLeaveType, payload);
//     yield put(UpdateLeavetypeSuccess(data));
//   } catch (error) {
//     yield put(UpdateLeavetypeError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// list leave 

// function* FetchLeave({ payload }) {
//   try {

//     const data = yield call(shiftservice.fetchLeave, payload);
//     yield put(FetchLeaveSuccess(data));
//   } catch (error) {
//     yield put(FetchLeaveError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }
// list by id

// function* FetchLeaveId({ payload }) {
//   try {

//     const data = yield call(shiftservice.fetchLeaveid, payload);
//     yield put(FetchLeaveIdSuccess(data));
//   } catch (error) {
//     yield put(FetchLeaveIdError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }
// delete leave

// function* DeleteLeave({ payload }) {
//   try {

//     const data = yield call(shiftservice.deleteLeave, payload);
//     yield put(DeleteLeaveSuccess(data));
//   } catch (error) {
//     yield put(DeleteLeaveError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }
// RULES
// fetch leavetype
// function* ListLeaveType({ payload }) {
//   try {

//     const data = yield call(shiftservice.listLeavetype, payload);
//     yield put(ListLeaveTypeSuccess(data));
//   } catch (error) {
//     yield put(ListLeaveTypeError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }
// function* SaveRule({ payload }) {
//   try {

//     const data = yield call(shiftservice.saveRule, payload);
//     yield put(SaveRuleSuccess(data));
//   } catch (error) {
//     yield put(SaveRuleError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// function* UpdateRule({ payload }) {
//   try {

//     const data = yield call(shiftservice.updateRule, payload);
//     yield put(UpdateRuleSuccess(data));
//   } catch (error) {
//     yield put(UpdateRuleError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// function* EditRule({ payload }) {
//   try {

//     const data = yield call(shiftservice.editRule, payload);
//     yield put(EditRuleSuccess(data));
//   } catch (error) {
//     yield put(EditRuleError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }
//leavevrequest
// function* SaveLeaveRequest({ payload }) {
//   try {

//     const data = yield call(shiftservice.saveLeaverequest, payload);
//     yield put(SaveLeaveRequestSuccess(data));
//   } catch (error) {
//     // yield put(FetchLeaveRequestError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// function* FetchLeaveRequest({ payload }) {
//   try {

//     const data = yield call(shiftservice.fetchLeaverequest, payload);
//     yield put(FetchLeaveRequestSuccess(data));
//   } catch (error) {
//     yield put(SaveLeaveRequestError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// function* FetchLeaveType({ payload }) {
//   try {

//     const data = yield call(shiftservice.fetchLeavetype, payload);
//     yield put(FetchLeaveTypeSuccess(data));
//   } catch (error) {
//     yield put(FetchLeaveTypeError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// function* EditLeaveType({ payload }) {
//   try {

//     const data = yield call(shiftservice.editLeavetype, payload);
//     yield put(EditLeaveTypeSuccess(data));
//   } catch (error) {
//     yield put(EditLeaveTypeError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// function* UpdateLeaveType({ payload }) {
//   try {

//     const data = yield call(shiftservice.updateLeavetype, payload);
//     yield put(UpdateLeaveTypeSuccess(data));
//   } catch (error) {
//     yield put(UpdateLeaveTypeError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// function* DeleteLeaveType({ payload }) {
//   try {

//     const data = yield call(shiftservice.deleteLeavetype, payload);
//     yield put(DeleteLeaveTypeSuccess(data));
//   } catch (error) {
//     yield put(DeleteLeaveTypeError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// function* CancelleaveRequest({ payload }) {
//   try {

//     const data = yield call(shiftservice.cancelLeaverequest, payload);
//     yield put(CancelLeaveRequestSuccess(data));
//     console.log(data, 'data');
//   } catch (error) {
//     yield put(CancelLeaveRequestError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }
// function* UpdateRequestStatus({ payload }) {
//   try {

//     const data = yield call(shiftservice.updaterequestleavestatus, payload);
//     yield put(UpdateLeaveStatusSuccess(data));
//   } catch (error) {
//     yield put(UpdateLeaveStatusError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// // leave approval

// function* RequestStatus({ payload }) {
//   try {

//     const data = yield call(shiftservice.fetchrequest, payload);
//     yield put(FetchRequestSuccess(data));
//   } catch (error) {
//     yield put(FetchRequestError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// function* ApproveStatus({ payload }) {
//   try {

//     const data = yield call(shiftservice.approverequest, payload);
//     yield put(ApproveRequestSuccess(data));
//   } catch (error) {
//     yield put(ApproveRequestError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

// function* RejectStatus({ payload }) {
//   try {

//     const data = yield call(shiftservice.rejectRequest, payload);
//     yield put(RejectRequestSuccess(data));
//   } catch (error) {
//     yield put(RejectRequestError(error));
//   } finally {
//     // yield put(stopLoader());
//   }
// }

export default function* rootSaga() {
  yield all([takeEvery('LIST_SHIFT', ListShift)]);
  yield all([takeEvery('SAVE_SHIFT', SaveShift)]);
  yield all([takeEvery('UPDATE_SHIFT', UpdateShift)]);
  yield all([takeEvery('LIST_SHIFT_ID', GetShiftId)]);
  yield all([takeEvery('DELETE_SHIFT', DeleteShift)]);
  // yield all([takeEvery('FETCH_DESIGNATION', FetchDesignation)]);
  // yield all([takeEvery('SAVE_LEAVETYPE', SaveLeaveType)]);
  // // yield all([takeEvery('UPDATE_LEAVE_TYPE', UpdateLeavetype)]);
  // // yield all([takeEvery('FETCH_LEAVE', FetchLeave)]);
  // yield all([takeEvery('FETCH_LEAVE_ID', FetchLeaveId)]);
  // // yield all([takeEvery('DELETE_LEAVE', DeleteLeave)]);
  // yield all([takeEvery('LIST_LEAVE_TYPE', ListLeaveType)]);
  // // yield all([takeEvery('SAVE_RULE', SaveRule)]);
  // // yield all([takeEvery('UPDATE_RULE', UpdateRule)]);
  // // yield all([takeEvery('EDIT_RULE', EditRule)]);
  // yield all([takeEvery('SAVE_LEAVEREQUEST', SaveLeaveRequest)]);
  // // yield all([takeEvery('FETCH_LEAVE_REQUEST', FetchLeaveRequest)]);
  // yield all([takeEvery('FETCH_LEAVETYPE', FetchLeaveType)]);
  // yield all([takeEvery('EDIT_LEAVETYPE', EditLeaveType)]);
  // yield all([takeEvery('UPDATE_LEAVETYPE', UpdateLeaveType)]);
  // yield all([takeEvery('DELETE_LEAVETYPE', DeleteLeaveType)]);
  // yield all([takeEvery('CANCEL_LEAVEREQUEST', CancelleaveRequest)]);
  // yield all([takeEvery('UPDATE_STATUS', UpdateRequestStatus)]);
  // yield all([takeEvery('FETCH_REQUEST', RequestStatus)]);
  // yield all([takeEvery('APPROVE_REQUEST', ApproveStatus)]);
  // yield all([takeEvery('REJECT_REQUEST', RejectStatus)]);
}