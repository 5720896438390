import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import salesOrderService from '@service/admin/masterService/salesOrderService';
import {
    listQuoteForSoSuccess, listQuoteForSoError,
    saveSoSuccess, saveSoError,
    listSoSuccess, listSoError,
    fetchSoByIdSuccess, fetchSoByIdError,
    updateSoSuccess, updateSoError,
    deleteSoSuccess, deleteSoError,
} from './action';
import { stopLoader, startLoader } from '@store/common/loader/action'


function* listQuoteForSo({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(salesOrderService.listQuoteForSo, payload);
        Array.isArray(data) ?
            yield put(listQuoteForSoSuccess(data)) :
            yield put(listQuoteForSoSuccess([]));
    } catch (error) {
        yield put(listQuoteForSoError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* saveSo({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(salesOrderService.saveSo, payload);
        yield put(saveSoSuccess(data));
    } catch (error) {
        yield put(saveSoError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* updateSo({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(salesOrderService.updateSo, payload);
        yield put(updateSoSuccess(data));
    } catch (error) {
        yield put(updateSoError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* deleteSo({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(salesOrderService.deleteSo, payload);
        yield put(deleteSoSuccess(data));
    } catch (error) {
        yield put(deleteSoError(error));
    } finally {
        yield put(stopLoader());
    }
}


function* listSo({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(salesOrderService.listSo, payload);
        Array.isArray(data) ?
            yield put(listSoSuccess(data)) :
            yield put(listSoSuccess([]));
    } catch (error) {
        yield put(listSoError(error));
    } finally {
        yield put(stopLoader());
    }
}

function* fetchSoById({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(salesOrderService.fetchSoById, payload);
        if (Object.keys(data).length > 0) {
            yield put(fetchSoByIdSuccess({
                ...data,
                item_details: data.item_details ? data.item_details.map(item => {
                    return ({
                        ...item,
                        amount: (parseFloat(item.amount) * parseFloat(data.actual_rate)).toFixed(2),
                        discount_amount: (parseFloat(item.discount_amount) * parseFloat(data.actual_rate)).toFixed(2),
                        selling_price: (parseFloat(item.selling_price) * parseFloat(data.actual_rate)).toFixed(2),
                    })
                }) : [],
                work_details: data.work_details ? data.work_details.map(item => {
                    return ({
                        ...item,
                        charge: (parseFloat(item.charge) * parseFloat(data.actual_rate)).toFixed(2),
                        disc_amount: (parseFloat(item.disc_amount) * parseFloat(data.actual_rate)).toFixed(2),
                        wrktotal: (parseFloat(item.wrktotal) * parseFloat(data.actual_rate)).toFixed(2),
                    })
                }) : [],
                service_details: data.service_details ? data.service_details.map(item => {
                    return ({
                        ...item,
                        selling_price_ser: (parseFloat(item.selling_price_ser) * parseFloat(data.actual_rate)).toFixed(2),
                        discount_amount_ser: (parseFloat(item.discount_amount_ser) * parseFloat(data.actual_rate)).toFixed(2),
                        amount_ser: (parseFloat(item.amount_ser) * parseFloat(data.actual_rate)).toFixed(2),
                    })
                }) : [],
                project_details: data.project_details ? data.project_details.map(item => {
                    return ({
                        ...item,
                        selling_price_project: (parseFloat(item.selling_price_project) * parseFloat(data.actual_rate)).toFixed(2),
                        discount_amount_project: (parseFloat(item.discount_amount_project) * parseFloat(data.actual_rate)).toFixed(2),
                        amount_project: (parseFloat(item.amount_project) * parseFloat(data.actual_rate)).toFixed(2),
                        project_id: data.project_id || data.project_master_id
                    })
                }) : [],
                c_discount_amount: (parseFloat(data.c_discount_amount) * parseFloat(data.actual_rate)).toFixed(2),
                net_amount: (parseFloat(data.net_amount) * parseFloat(data.actual_rate)).toFixed(2),
                total_amount_products: (parseFloat(data.total_amount_products) * parseFloat(data.actual_rate)).toFixed(2),
                expected_revenue: (parseFloat(data.expected_revenue) * parseFloat(data.actual_rate)).toFixed(2),
            }));
        }
    } catch (error) {
        yield put(fetchSoByIdError(error));
    } finally {
        yield put(stopLoader());
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actionTypes.LIST_QUOTE_FOR_SO, listQuoteForSo)]);
    yield all([takeEvery(actionTypes.SAVE_SO, saveSo)]);
    yield all([takeEvery(actionTypes.LIST_SO, listSo)]);
    yield all([takeEvery(actionTypes.LIST_SO_BY_ID, fetchSoById)]);
    yield all([takeEvery(actionTypes.UPDATE_SO, updateSo)]);
    yield all([takeEvery(actionTypes.DELETE_SO, deleteSo)]);
}