import { takeEvery, put, call, all } from 'redux-saga/effects';
import timesheetService from '@service/admin/timesheetService/timesheetService';
import { startLoader, stopLoader } from '@store/common/loader/action'
import {
    actionTypes, fetchEmployeeByPMError, fetchEmployeeByPMSuccess,
    fetchLeaveTypesError, fetchLeaveTypesSuccess,
    fetchRejectedTimesheetError, fetchRejectedTimesheetSuccess,
    fetchResendTimesheetError, fetchResendTimesheetSuccess,
    fetchTimesheetError, fetchTimesheetSuccess,
    fetchWrkHrDependancyError, fetchWrkHrDependancySuccess,
    getShiftDetailsByEmpError, getShiftDetailsByEmpSuccess,
    handleBulkAttendanceLabourDetailsError,
    handleBulkAttendanceLabourDetailsSuccess,
    handleLabourBulkAttendanceError,
    handleLabourBulkAttendanceSuccess,
    handlepmAddLeaveRequestError, handlepmAddLeaveRequestSuccess,
    handleTimesheetDetailedViewError, handleTimesheetDetailedViewSuccess,
    handleTimesheetError, handleTimesheetSuccess,
    handleTimeslotReviewError, handleTimeslotReviewSuccess,
    multipleApproveTimesheetError, multipleApproveTimesheetSuccess,
    rejectTimesheetError, rejectTimesheetSuccess,
    resendTimesheetError, resendTimesheetSuccess,
    singleApproveTimesheetError, singleApproveTimesheetSuccess,
    getClockinDetailsError, getClockinDetailsSuccess,
    getCurrentClockinDetailsError, getCurrentClockinDetailsSuccess,
    handleClockinError, handleClockinSuccess,
    handleClockoutError, handleClockoutSuccess,
} from './action';

function* fetchEmpShiftDetails({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.fetchEmpShiftDetails, payload);
        yield put(getShiftDetailsByEmpSuccess(data));
    } catch (error) {
        yield put(getShiftDetailsByEmpError());
    } finally {
        yield put(stopLoader());
    }
}

function* getWorkHourDependency({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.getWorkHourDependency, payload);
        yield put(fetchWrkHrDependancySuccess(data));
    } catch (error) {
        yield put(fetchWrkHrDependancyError());
    } finally {
        yield put(stopLoader());
    }
}

function* getLeaveTypes({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.getLeaveTypes, payload);
        yield put(fetchLeaveTypesSuccess(data));
    } catch (error) {
        yield put(fetchLeaveTypesError());
    } finally {
        yield put(stopLoader());
    }
}

function* insertTimesheet({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.insertTimesheet, payload);
        yield put(handleTimesheetSuccess(data));
    } catch (error) {
        yield put(handleTimesheetError());
    } finally {
        yield put(stopLoader());
    }
}

function* getTimesheet({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.getTimesheet, payload);
        yield put(fetchTimesheetSuccess(data));
    } catch (error) {
        yield put(fetchTimesheetError());
    } finally {
        yield put(stopLoader());
    }
}

function* resendTimesheet({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.resendTimesheet, payload);
        yield put(resendTimesheetSuccess(data));
    } catch (error) {
        yield put(resendTimesheetError());
    } finally {
        yield put(stopLoader());
    }
}

function* MultiApproveTimesheet({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.MultiApproveTimesheet, payload);
        yield put(multipleApproveTimesheetSuccess(data));
    } catch (error) {
        yield put(multipleApproveTimesheetError());
    } finally {
        yield put(stopLoader());
    }
}

function* singleApproveTimesheet({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.singleApproveTimesheet, payload);
        yield put(singleApproveTimesheetSuccess(data));
    } catch (error) {
        yield put(singleApproveTimesheetError());
    } finally {
        yield put(stopLoader());
    }
}

function* getResendTimesheet({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.getResendTimesheet, payload);
        yield put(fetchResendTimesheetSuccess(data));
    } catch (error) {
        yield put(fetchResendTimesheetError());
    } finally {
        yield put(stopLoader());
    }
}

function* getRejectTimesheet({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.getRejectTimesheet, payload);
        yield put(fetchRejectedTimesheetSuccess(data));
    } catch (error) {
        yield put(fetchRejectedTimesheetError());
    } finally {
        yield put(stopLoader());
    }
}

function* rejectTimesheet({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.rejectTimesheet, payload);
        yield put(rejectTimesheetSuccess(data));
    } catch (error) {
        yield put(rejectTimesheetError());
    } finally {
        yield put(stopLoader());
    }
}

function* addpmLeaveRequest({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.addpmLeaveRequest, payload);
        yield put(handlepmAddLeaveRequestSuccess(data));
    } catch (error) {
        yield put(handlepmAddLeaveRequestError());
    } finally {
        yield put(stopLoader());
    }
}

function* getTimesheetDetailedData({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.getTimesheetDetailedData, payload);
        yield put(handleTimesheetDetailedViewSuccess(data));
    } catch (error) {
        yield put(handleTimesheetDetailedViewError());
    } finally {
        yield put(stopLoader());
    }
}

function* getEmployeeByPM({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.getEmployeeByPM, payload);
        yield put(fetchEmployeeByPMSuccess(data));
    } catch (error) {
        yield put(fetchEmployeeByPMError());
    } finally {
        yield put(stopLoader());
    }
}

function* getTimeslotReview({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.getTimeslotReview, payload);
        yield put(handleTimeslotReviewSuccess(data));
    } catch (error) {
        yield put(handleTimeslotReviewError());
    } finally {
        yield put(stopLoader());
    }
}

function* getBulkAttendanceLabourDetails({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.getBulkAttendanceLabourDetails, payload);
        yield put(handleBulkAttendanceLabourDetailsSuccess(data));
    } catch (error) {
        yield put(handleBulkAttendanceLabourDetailsError());
    } finally {
        yield put(stopLoader());
    }
}

function* saveLabourAttendance({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.saveLabourAttendance, payload);
        yield put(handleLabourBulkAttendanceSuccess(data));
    } catch (error) {
        yield put(handleLabourBulkAttendanceError());
    } finally {
        yield put(stopLoader());
    }
}

function* getCurrentClockinDetails({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.getCurrentClockinDetails, payload);
        yield put(getCurrentClockinDetailsSuccess(data));
    } catch (error) {
        yield put(getCurrentClockinDetailsError());
    } finally {
        yield put(stopLoader());
    }
}

function* getClockinDetails({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.getClockinDetails, payload);
        yield put(getClockinDetailsSuccess(data));
    } catch (error) {
        yield put(getClockinDetailsError());
    } finally {
        yield put(stopLoader());
    }
}

function* handleClockin({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.handleClockin, payload);
        yield put(handleClockinSuccess(data));
    } catch (error) {
        yield put(handleClockinError());
    } finally {
        yield put(stopLoader());
    }
}

function* handleClockout({ payload }) {
    try {
        yield put(startLoader());
        const data = yield call(timesheetService.handleClockout, payload);
        yield put(handleClockoutSuccess(data));
    } catch (error) {
        yield put(handleClockoutError());
    } finally {
        yield put(stopLoader());
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actionTypes.GET_SHIFT_DETAILS_BY_EMP, fetchEmpShiftDetails)]);
    yield all([takeEvery(actionTypes.GET_WORK_HOUR_DEPENDENCY, getWorkHourDependency)]);
    yield all([takeEvery(actionTypes.GET_LEAVE_TYPES, getLeaveTypes)]);
    yield all([takeEvery(actionTypes.SAVE_TIMESHEET, insertTimesheet)]);
    yield all([takeEvery(actionTypes.GET_TIMESHEET, getTimesheet)]);
    yield all([takeEvery(actionTypes.RESEND_TIMESHEET, resendTimesheet)]);
    yield all([takeEvery(actionTypes.MULTIPLE_APPROVE_TIMESHEET, MultiApproveTimesheet)]);
    yield all([takeEvery(actionTypes.SINGLE_APPROVE_TIMESHEET, singleApproveTimesheet)]);
    yield all([takeEvery(actionTypes.GET_RESEND_TIMESHEET, getResendTimesheet)]);
    yield all([takeEvery(actionTypes.GET_REJECTED_TIMESHEET, getRejectTimesheet)]);
    yield all([takeEvery(actionTypes.REJECT_TIMESHEET, rejectTimesheet)]);
    yield all([takeEvery(actionTypes.PM_ADD_LEAVE_REQUEST, addpmLeaveRequest)]);
    yield all([takeEvery(actionTypes.TIMESHEET_DETAILED_VIEW, getTimesheetDetailedData)]);
    yield all([takeEvery(actionTypes.GET_EMPLOYEE_BY_PM, getEmployeeByPM)]);
    yield all([takeEvery(actionTypes.TIMESLOT_REVIEW, getTimeslotReview)]);
    yield all([takeEvery(actionTypes.BULK_ATTENDANCE_LABOUR_DETAILS, getBulkAttendanceLabourDetails)]);
    yield all([takeEvery(actionTypes.SAVE_LABOUR_BULK_ATTENDANCE, saveLabourAttendance)]);
    yield all([takeEvery(actionTypes.CLOCKIN_COCKOUT_DETAILS, getClockinDetails)]);
    yield all([takeEvery(actionTypes.CURRENT_CLOCKIN_COCKOUT_DETAILS, getCurrentClockinDetails)]);
    yield all([takeEvery(actionTypes.HANDLE_CLOCK_IN, handleClockin)]);
    yield all([takeEvery(actionTypes.HANDLE_CLOCK_OUT, handleClockout)]);
}