import axios from '@service/ApiClient'

class timesheetService {
    constructor(callback) {
        this.callback = callback
    }

    async fetchEmpShiftDetails(payload) {
        return await axios.post('/Attendence/Attendance/get_shift_details_by_emp', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async getWorkHourDependency(payload) {
        return await axios.post('/Attendence/Attendance/fetch_dependancy', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async getLeaveTypes(payload) {
        return await axios.post('/Attendence/Attendance/list_leavetypes', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async insertTimesheet(payload) {
        return await axios.post('/Attendence/Attendance/save_timesheet', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async getTimesheet(payload) {
        return await axios.post('/Attendence/Attendance/list_timesheets_approval', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async resendTimesheet(payload) {
        return await axios.post('/Attendence/Attendance/resend_for_marking_timesheet', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async MultiApproveTimesheet(payload) {
        return await axios.post('/Attendence/Attendance/approve_multiple_timesheet', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async singleApproveTimesheet(payload) {
        return await axios.post('/Attendence/Attendance/approve_timesheet', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async getResendTimesheet(payload) {
        return await axios.post('/Attendence/Attendance/fetch_resend', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async getRejectTimesheet(payload) {
        return await axios.post('/Attendence/Attendance/rejected_timesheet', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async rejectTimesheet(payload) {
        return await axios.post('/Attendence/Attendance/reject_timesheet', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async addpmLeaveRequest(payload) {
        return await axios.post('/HR/Leave/insert_leave_by_pm', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async getTimesheetDetailedData(payload) {
        return await axios.post('/Attendence/Attendance/list_timesheets_employee_view', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async getEmployeeByPM(payload) {
        return await axios.post('/Attendence/Attendance/list_employees_by_pm', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async getTimeslotReview(payload) {
        return await axios.post('/Attendence/Attendance/timesheet_view', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async getBulkAttendanceLabourDetails(payload) {
        return await axios.post('/Attendence/Attendance/bulk_attendance_add', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async saveLabourAttendance(payload) {
        return await axios.post('/Attendence/Attendance/save_bulk_attendance', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async getClockinDetails(payload) {
        return await axios.post('/Attendence/Attendance/get_clockin_clockout_dtls', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async getCurrentClockinDetails(payload) {
        return await axios.post('/Attendence/Attendance/get_current_clockin_clockout_dtls', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }

    async handleClockin(payload) {
        return await axios.post('/Attendence/Attendance/clockin_clockout', payload)
            .then((response) => {
                return response.data;
            })
            .catch((error) => ({
                error: JSON.stringify(error)
            }));
    }
}

export default new timesheetService();