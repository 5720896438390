// ** Redux Imports
import { combineReducers } from 'redux'

import loader from './loader/reducer'
import pickLocation from './pickLocation/reducer'


const commonReducer = combineReducers({
    loader,
    pickLocation
})

export default commonReducer