
export const actionTypes = {
  SAVE_CONTACT: 'SAVE_CONTACT',
  SAVE_CONTACT_SUCCESS: 'SAVE_CONTACT_SUCCESS',
  SAVE_CONTACT_ERROR: 'SAVE_CONTACT_ERROR',
  ASSIGN_CONTACT: 'ASSIGN_CONTACT',
  ASSIGN_CONTACT_SUCCESS: 'ASSIGN_CONTACT_SUCCESS',
  ASSIGN_CONTACT_ERROR: 'ASSIGN_CONTACT_ERROR',
  CONTACT_ORGANISATION: 'CONTACT_ORGANISATION',
  CONTACT_ORGANISATION_SUCCESS: 'CONTACT_ORGANISATION_SUCCESS',
  CONTACT_ORGANISATION_ERROR: 'CONTACT_ORGANISATION_ERROR',
  LIST_CONTACT:'LIST_CONTACT',
  LIST_CONTACT_SUCCESS:'LIST_CONTACT_SUCCESS',
  LIST_CONTACT_ERROR:'LIST_CONTACT_ERROR',
  UPDATE_CONTACT:'UPDATE_CONTACT',
  UPDATE_CONTACT_SUCCESS:'UPDATE_CONTACT_SUCCESS',
  UPDATE_CONTACT_ERROR:'UPDATE_CONTACT_ERROR',
  DELETE_CONTACT:'DELETE_CONTACT',
  DELETE_CONTACT_SUCCESS:'DELETE_CONTACT_SUCCESS',
  DELETE_CONTACT_ERROR:'DELETE_CONTACT_ERROR',
  
  FETCH_CONTACTS_BY_ID: 'FETCH_CONTACTS_BY_ID',
  FETCH_CONTACTS_BY_ID_SUCCESS: 'FETCH_CONTACTS_BY_ID_SUCCESS',
  FETCH_CONTACTS_BY_ID_ERROR: 'FETCH_CONTACTS_BY_ID_ERROR',
};


export const saveContact = (payload) => {
    return {
        type: actionTypes.SAVE_CONTACT,payload
         };
};
export const saveContactSuccess = (payload) => {
    return {
        type:actionTypes.SAVE_CONTACT_SUCCESS,
        payload
    };
};
export const saveContactError = (payload) => {
    return {
        type: actionTypes.SAVE_CONTACT_ERROR,
        payload
    };
};


export const assignContact = (payload) => {
    return {
        type:actionTypes.ASSIGN_CONTACT,payload
       
    };
};
export const assignContactSuccess = (payload) => {
    return {
        type: actionTypes.ASSIGN_CONTACT_SUCCESS,
        payload
    };
};
export const assignContactError = (payload) => {
    return {
        type: actionTypes.ASSIGN_CONTACT_ERROR,
        payload
    };
};

export const contactOrganisation = (payload) => {
    return {
        type: actionTypes.CONTACT_ORGANISATION,payload
           };
};
export const contactOrganisationSuccess = (payload) => {
    return {
        type: actionTypes.CONTACT_ORGANISATION_SUCCESS,
        payload
    };
};
export const contactOrganisationError = (payload) => {
    return {
        type: actionTypes.CONTACT_ORGANISATION_ERROR,
        payload
    };
};

export const listContact = (payload) => {
    return {
        type: actionTypes.LIST_CONTACT,payload
           };
};
export const listContactSuccess = (payload) => {
    return {
        type: actionTypes.LIST_CONTACT_SUCCESS,
        payload
    };
};
export const listContactError = (payload) => {
    return {
        type: actionTypes.LIST_CONTACT_ERROR,
        payload
    };
};

export const updateContact = (payload) => {
    return {
        type: actionTypes.UPDATE_CONTACT,payload
           };
};
export const updateContactSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_CONTACT_SUCCESS,
        payload
    };
};
export const updateContactError = (payload) => {
    return {
        type: actionTypes.UPDATE_CONTACT_ERROR,
        payload
    };
};

export const deleteContact = (payload) => {
    return {
        type: actionTypes.DELETE_CONTACT,payload
           };
};
export const deleteContactSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_CONTACT_SUCCESS,
        payload
    };
};
export const deleteContactError = (payload) => {
    return {
        type: actionTypes.DELETE_CONTACT_ERROR,
        payload
    };
};


export const fetchContactsById = (payload) => {
    return {
        type: actionTypes.FETCH_CONTACTS_BY_ID, payload
    };
};
export const fetchContactsByIdSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_CONTACTS_BY_ID_SUCCESS,
        payload
    };
};
export const fetchContactsByIdError = (payload) => {
    return {
        type: actionTypes.FETCH_CONTACTS_BY_ID_ERROR,
        payload
    };
};

export const fetchCrmFunnelData = (payload) => {
    return {
        type: 'FETCH_CRM_FUNNEL_DATA', payload
    };
};
export const fetchCrmFunnelDataSuccess = (payload) => {
    return {
        type: 'FETCH_CRM_FUNNEL_DATA_SUCCESS',
        payload
    };
};
export const fetchCrmFunnelDataError = (payload) => {
    return {
        type: 'FETCH_CRM_FUNNEL_DATA_ERROR',
        payload
    };
};

export const saveCrmFunnelData = (payload) => {
    return {
        type: 'SAVE_CRM_FUNNEL_DATA', payload
    };
};
export const saveCrmFunnelDataSuccess = (payload) => {
    return {
        type: 'SAVE_CRM_FUNNEL_DATA_SUCCESS',
        payload
    };
};
export const saveCrmFunnelDataError = (payload) => {
    return {
        type: 'SAVE_CRM_FUNNEL_DATA_ERROR',
        payload
    };
};