

export const actionTypes = {
    EVENTMASTER_CONTACT: 'EVENTMASTER_CONTACT',
    EVENTMASTER_CONTACT_SUCCESS: 'EVENTMASTER_CONTACT_SUCCESS',
    EVENTMASTER_CONTACT_ERROR: 'EVENTMASTER_CONTACT_ERROR',

};



export const eventMasterContact = (payload) => {
    return {
        type: actionTypes.EVENTMASTER_CONTACT,payload
    };
};
export const eventMasterContactSuccess = (payload) => {
    return {
        type: actionTypes.EVENTMASTER_CONTACT_SUCCESS,
        payload
    };
};
export const eventMasterContactError = (payload) => {
    return {
        type: actionTypes.EVENTMASTER_CONTACT_ERROR,
        payload
    };
};