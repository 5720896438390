
import { actionTypes } from './action';

export const initialState = {
    saveCurrencyValues: '',
    listCurrencyValues: [],
    deleteCurrencyValues: '',
    updateCurrencyValues: '',
    saveCurrencyRateValues: '',
    deleteCurrencyRateValues: '',
    updateCurrencyRateValues: '',
    defaultCurrency:{}
}
const saveCurrencyReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SAVE_CURRENCY:
            return {
                ...state, saveCurrencyValues: '', listCurrencyValues: [],
                deleteCurrencyValues: '', updateCurrencyValues: '', saveCurrencyRateValues: '', deleteCurrencyRateValues: '', updateCurrencyRateValues: ''
            }
        case actionTypes.SAVE_CURRENCY_SUCCESS:
            return {
                ...state, saveCurrencyValues: action.payload, listCurrencyValues: [],
                deleteCurrencyValues: '', updateCurrencyValues: '', saveCurrencyRateValues: '', deleteCurrencyRateValues: '', updateCurrencyRateValues: ''
            }
        case actionTypes.SAVE_CURRENCY_ERROR:
            return {
                ...state, saveCurrencyValues: '', listCurrencyValues: [],
                deleteCurrencyValues: '', updateCurrencyValues: '', saveCurrencyRateValues: '', deleteCurrencyRateValues: '', updateCurrencyRateValues: ''
            }


        case actionTypes.LIST_CURRENCY:
            return {
                ...state, listCurrencyValues: [], saveCurrencyValues: '',
                deleteCurrencyValues: '', updateCurrencyValues: '', saveCurrencyRateValues: '', deleteCurrencyRateValues: '', updateCurrencyRateValues: ''
            }
        case actionTypes.LIST_CURRENCY_SUCCESS:
            return {
                ...state, listCurrencyValues: action.payload, saveCurrencyValues: '',
                deleteCurrencyValues: '', updateCurrencyValues: '', saveCurrencyRateValues: '', deleteCurrencyRateValues: '', updateCurrencyRateValues: ''
            }
        case actionTypes.LIST_CURRENCY_ERROR:
            return {
                ...state, listCurrencyValues: [], saveCurrencyValues: '',
                deleteCurrencyValues: '', updateCurrencyValues: '', saveCurrencyRateValues: '', deleteCurrencyRateValues: '', updateCurrencyRateValues: ''
            }


        case actionTypes.DELETE_CURRENCY:
            return {
                ...state, deleteCurrencyValues: '', listCurrencyValues: [],
                saveCurrencyValues: '', updateCurrencyValues: '', saveCurrencyRateValues: '', deleteCurrencyRateValues: '', updateCurrencyRateValues: ''
            }
        case actionTypes.DELETE_CURRENCY_SUCCESS:
            return {
                ...state, deleteCurrencyValues: action.payload, listCurrencyValues: [],
                saveCurrencyValues: '', updateCurrencyValues: '', saveCurrencyRateValues: '', deleteCurrencyRateValues: '', updateCurrencyRateValues: ''
            }
        case actionTypes.DELETE_CURRENCY_ERROR:
            return {
                ...state, deleteCurrencyValues: '', listCurrencyValues: [],
                saveCurrencyValues: '', updateCurrencyValues: '', saveCurrencyRateValues: '', deleteCurrencyRateValues: '', updateCurrencyRateValues: ''
            }


        case actionTypes.UPDATE_CURRENCY:
            return {
                ...state, updateCurrencyValues: '', listCurrencyValues: [],
                saveCurrencyValues: '', deleteCurrencyValues: '', saveCurrencyRateValues: '', deleteCurrencyRateValues: '', updateCurrencyRateValues: ''
            }
        case actionTypes.UPDATE_CURRENCY_SUCCESS:
            return {
                ...state, updateCurrencyValues: action.payload, listCurrencyValues: [],
                saveCurrencyValues: '', deleteCurrencyValues: '', saveCurrencyRateValues: '', deleteCurrencyRateValues: '', updateCurrencyRateValues: ''
            }
        case actionTypes.UPDATE_CURRENCY_ERROR:
            return {
                ...state, updateCurrencyValues: '', listCurrencyValues: [],
                saveCurrencyValues: '', deleteCurrencyValues: '', saveCurrencyRateValues: '', deleteCurrencyRateValues: '', updateCurrencyRateValues: ''
            }


        case actionTypes.SAVE_CURRENCY_RATE:
            return {
                ...state, saveCurrencyRateValues: '', listCurrencyValues: [],
                saveCurrencyValues: '', deleteCurrencyValues: '', updateCurrencyValues: '', deleteCurrencyRateValues: '', updateCurrencyRateValues: ''
            }
        case actionTypes.SAVE_CURRENCY_RATE_SUCCESS:
            return {
                ...state, saveCurrencyRateValues: action.payload, listCurrencyValues: [],
                saveCurrencyValues: '', deleteCurrencyValues: '', updateCurrencyValues: '', deleteCurrencyRateValues: '', updateCurrencyRateValues: ''
            }
        case actionTypes.SAVE_CURRENCY_RATE_ERROR:
            return {
                ...state, saveCurrencyRateValues: '', listCurrencyValues: [],
                saveCurrencyValues: '', deleteCurrencyValues: '', updateCurrencyValues: '', deleteCurrencyRateValues: '', updateCurrencyRateValues: ''
            }


        case actionTypes.DELETE_CURRENCY_RATE:
            return {
                ...state, deleteCurrencyRateValues: '', listCurrencyValues: [],
                saveCurrencyValues: '', deleteCurrencyValues: '', updateCurrencyValues: '', saveCurrencyRateValues: '', updateCurrencyRateValues: ''
            }
        case actionTypes.DELETE_CURRENCY_RATE_SUCCESS:
            return {
                ...state, deleteCurrencyRateValues: action.payload, listCurrencyValues: [],
                saveCurrencyValues: '', deleteCurrencyValues: '', updateCurrencyValues: '', saveCurrencyRateValues: '', updateCurrencyRateValues: ''
            }
        case actionTypes.DELETE_CURRENCY_RATE_ERROR:
            return {
                ...state, deleteCurrencyRateValues: '', listCurrencyValues: [],
                saveCurrencyValues: '', deleteCurrencyValues: '', updateCurrencyValues: '', saveCurrencyRateValues: '', updateCurrencyRateValues: ''
            }

            
        case actionTypes.UPDATE_CURRENCY_RATE:
            return {
                ...state, updateCurrencyRateValues: '', listCurrencyValues: [],
                saveCurrencyValues: '', deleteCurrencyValues: '', updateCurrencyValues: '', saveCurrencyRateValues: '', deleteCurrencyRateValues: ''
            }
        case actionTypes.UPDATE_CURRENCY_RATE_SUCCESS:
            return {
                ...state, updateCurrencyRateValues: action.payload, listCurrencyValues: [],
                saveCurrencyValues: '', deleteCurrencyValues: '', updateCurrencyValues: '', saveCurrencyRateValues: '', deleteCurrencyRateValues: ''
            }
        case actionTypes.UPDATE_CURRENCY_RATE_ERROR:
            return {
                ...state, updateCurrencyRateValues: '', listCurrencyValues: [],
                saveCurrencyValues: '', deleteCurrencyValues: '', updateCurrencyValues: '', saveCurrencyRateValues: '', deleteCurrencyRateValues: ''
            }

            
        case actionTypes.GET_DEFAULT_CURRENCY:
            return {
                ...state, defaultCurrency:{}
            }
        case actionTypes.GET_DEFAULT_CURRENCY_SUCCESS:
            return {
                ...state, defaultCurrency:action.payload
            }
        case actionTypes.GET_DEFAULT_CURRENCY_ERROR:
            return {
                ...state, defaultCurrency:{}
            }

        default:
            return state
    }
}

export default saveCurrencyReducer