export const actionTypes = {
    LIST_EMPLOYEES: 'LIST_EMPLOYEES',
    LIST_EMPLOYEES_SUCCESS: 'LIST_EMPLOYEES_SUCCESS',
    LIST_EMPLOYEES_ERROR: 'LIST_EMPLOYEES_ERROR',
    LIST_SHIFT: 'LIST_SHIFT',
    LIST_SHIFT_SUCCESS: 'LIST_SHIFT_SUCCESS',
    LIST_SHIFT_ERROR: 'LIST_SHIFT_ERROR',
    ASSIGN_EMPLOYEE_SHIFT: 'ASSIGN_EMPLOYEE_SHIFT',
    ASSIGN_EMPLOYEE_SHIFT_SUCCESS: 'ASSIGN_EMPLOYEE_SHIFT_SUCCESS',
    ASSIGN_EMPLOYEE_SHIFT_ERROR: 'ASSIGN_EMPLOYEE_SHIFT_ERROR',
};

export const assignEmployeeShift = (payload) => {
    return {
        type: actionTypes.ASSIGN_EMPLOYEE_SHIFT, payload
    };
};
export const assignEmployeeShiftSuccess = (payload) => {
    return {
        type: actionTypes.ASSIGN_EMPLOYEE_SHIFT_SUCCESS,
        payload
    };
};
export const assignEmployeeShiftError = (payload) => {
    return {
        type: actionTypes.ASSIGN_EMPLOYEE_SHIFT_ERROR,
        payload
    };
};
export const listEmployees = (payload) => {
    return {
        type: actionTypes.LIST_EMPLOYEES, payload
    };
};
export const listEmployeesSuccess = (payload) => {
    return {
        type: actionTypes.LIST_EMPLOYEES_SUCCESS,
        payload
    };
};
export const listEmployeesError = (payload) => {
    return {
        type: actionTypes.LIST_EMPLOYEES_ERROR,
        payload
    };
};

// export const listShift = (payload) => {
//     return {
//         type: actionTypes.LIST_SHIFT, payload
//     };
// };
// export const listShiftSuccess = (payload) => {
//     return {
//         type: actionTypes.LIST_SHIFT_SUCCESS,
//         payload
//     };
// };
// export const listShiftError = (payload) => {
//     return {
//         type: actionTypes.LIST_SHIFT_ERROR,
//         payload
//     };
// };
