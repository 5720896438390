import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import reportService from '@service/admin/report/reportService';
import {
  complaintreportSuccess, complaintreportError,
  sparepartsreportSuccess, sparepartsreportError,
  amcexpiryreportSuccess, amcexpiryreportError,
  departmentsummaryreportSuccess, departmentsummaryreportError,
  equipmentauditreportSuccess, equipmentauditreportError,
  listEquipmentSuccess, listEquipmentError,
  EquipmentcategorySuccess, EquipmentcategoryError,
  EquipmentlocationSuccess, EquipmentlocationError,
  ExternalDnReportSuccess, ExternalDnReportError,
  ExternalRnReportSuccess,ExternalRnReportError,
  QcReportSuccess,QcReportError
} from './action';
import { stopLoader, startLoader } from "@store/common/loader/action";


function* complaintreport({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(reportService.complaintreport, payload);
    let t_data = data.map(item => {
      delete item.status;
      return item
    })

    yield put(complaintreportSuccess(t_data));
  } catch (error) {
    yield put(complaintreportError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* sparepartsreport({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(reportService.sparepartsreport, payload);
    let t_data = data.map(item => {
      delete item.item_cover_image;
      return item
    })
    yield put(sparepartsreportSuccess(t_data));
  } catch (error) {
    yield put(sparepartsreportError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* amcexpiryreport({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(reportService.amcexpiryreport, payload);
    let t_data = data.map(item => {
      delete item.status;
      return item
    })
    yield put(amcexpiryreportSuccess(t_data));
  } catch (error) {
    yield put(amcexpiryreportError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* departmentsummaryreport({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(reportService.departmentsummaryreport, payload);
    let t_data = data.map(item => {
      delete item.status;
      return item
    })
    yield put(departmentsummaryreportSuccess(t_data));
  } catch (error) {
    yield put(departmentsummaryreportError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* equipmentauditreport({ payload }) {
  try {
    const data = yield call(reportService.equipmentauditreport, payload);
    let t_data = data.map(item => {
      delete item.status;
      return item
    })
    yield put(equipmentauditreportSuccess(t_data));
  } catch (error) {
    yield put(equipmentauditreportError(error));
  } finally {
    yield put(stopLoader());
  }
}


function* listEquipment({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(reportService.listEquipment, payload);
    let t_data = data.map(item => {
      delete item.status;
      return item
    })
    yield put(listEquipmentSuccess(t_data));
  } catch (error) {
    yield put(listEquipmentError(error));
  } finally {
    yield put(stopLoader());
  }
}


function* Equipmentcategory({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(reportService.Equipmentcategory, payload);
    let t_data = data.map(item => {
      delete item.status;
      return item
    })
    yield put(EquipmentcategorySuccess(t_data));
  } catch (error) {
    yield put(EquipmentcategoryError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* Equipmentlocation({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(reportService.Equipmentlocation, payload);
    let t_data = data.map(item => {
      delete item.status;
      return item
    })
    yield put(EquipmentlocationSuccess(t_data));
  } catch (error) {
    yield put(EquipmentlocationError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* ExternalDnReport({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(reportService.ExternalDnReport, payload);
    //   console.log("payload",payload);
    yield put(ExternalDnReportSuccess(data));
  } catch (error) {
    yield put(ExternalDnReportError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* ExternalRnReport({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(reportService.ExternalRnReport, payload);
    //   console.log("payload",payload);
    yield put(ExternalRnReportSuccess(data));
  } catch (error) {
    yield put(ExternalRnReportError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* QcReport({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(reportService.QcReport, payload);
    //   console.log("payload",payload);
    yield put(QcReportSuccess(data));
  } catch (error) {
    yield put(QcReportError(error));
  } finally {
    yield put(stopLoader());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.COMPLAINT_REPORT, complaintreport)]);
  yield all([takeEvery(actionTypes.SPARE_PARTS_REPORT, sparepartsreport)]);
  yield all([takeEvery(actionTypes.AMC_EXPIRY_REPORT, amcexpiryreport)]);
  yield all([takeEvery(actionTypes.DEPARTMENT_SUMMARY_REPORT, departmentsummaryreport)]);
  yield all([takeEvery(actionTypes.EQUIPMENT_AUDIT_REPORT, equipmentauditreport)]);
  yield all([takeEvery(actionTypes.LIST_EQUIPMENT, listEquipment)]);
  yield all([takeEvery(actionTypes.EQUIPMENT_CATEGORY, Equipmentcategory)]);
  yield all([takeEvery(actionTypes.EQUIPMENT_LOCATION, Equipmentlocation)]);
  yield all([takeEvery('EXTERNAL_DN_REPORT', ExternalDnReport)]);
  yield all([takeEvery('EXTERNAL_RN_REPORT', ExternalRnReport)]);
  yield all([takeEvery('QC_REPORT', QcReport)]);
}