
export const actionTypes = {
    SAVE_EQUIPMENT_MASTER: 'SAVE_EQUIPMENT_MASTER',
    SAVE_EQUIPMENT_MASTER_SUCCESS: 'SAVE_EQUIPMENT_MASTER_SUCCESS',
    SAVE_EQUIPMENT_MASTER_ERROR: 'SAVE_EQUIPMENT_MASTER_ERROR',

    LIST_EQUIPMENT_MASTER: 'LIST_EQUIPMENT_MASTER',
    LIST_EQUIPMENT_MASTER_SUCCESS: 'LIST_EQUIPMENT_MASTER_SUCCESS',
    LIST_EQUIPMENT_MASTER_ERROR: 'LIST_EQUIPMENT_MASTER_ERROR',

    UPDATE_EQUIPMENT_MASTER: 'UPDATE_EQUIPMENT_MASTER',
    UPDATE_EQUIPMENT_MASTER_SUCCESS: 'UPDATE_EQUIPMENT_MASTER_SUCCESS',
    UPDATE_EQUIPMENT_MASTER_ERROR: 'UPDATE_EQUIPMENT_MASTER_ERROR',

    DELETE_EQUIPMENT_MASTER: 'DELETE_EQUIPMENT_MASTER',
    DELETE_EQUIPMENT_MASTER_SUCCESS: 'DELETE_EQUIPMENT_MASTER_SUCCESS',
    DELETE_EQUIPMENT_MASTER_ERROR: 'DELETE_EQUIPMENT_MASTER_ERROR',

    TECHINICAN_LIST_MASTER: 'TECHINICAN_LIST_MASTER',
    TECHINICAN_LIST_MASTER_SUCCESS: 'TECHINICAN_LIST_MASTER_SUCCESS',
    TECHINICAN_LIST_MASTER_ERROR: 'TECHINICAN_LIST_MASTER_ERROR'
};

export const saveEquipmentMaster = (payload) => {
    return {
        type: actionTypes.SAVE_EQUIPMENT_MASTER, payload
    };
};
export const saveEquipmentMasterSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_EQUIPMENT_MASTER_SUCCESS,
        payload
    };
};
export const saveEquipmentMasterError = (payload) => {
    return {
        type: actionTypes.SAVE_EQUIPMENT_MASTER_ERROR,
        payload
    };
};
export const technicanlistmaster = (payload) => {
    return {
        type: actionTypes.TECHINICAN_LIST_MASTER, payload
    };
};
export const technicanlistmasterSuccess = (payload) => {
    return {
        type: actionTypes.TECHINICAN_LIST_MASTER_SUCCESS,
        payload
    };
};
export const technicanlistmasterError = (payload) => {
    return {
        type: actionTypes.TECHINICAN_LIST_MASTER_ERROR,
        payload
    };
};

export const listEquipmentMaster = (payload) => {
    return {
        type: actionTypes.LIST_EQUIPMENT_MASTER, payload
    };
};
export const listEquipmentMasterSuccess = (payload) => {
    return {
        type: actionTypes.LIST_EQUIPMENT_MASTER_SUCCESS,
        payload
    };
};
export const listEquipmentMasterError = (payload) => {
    return {
        type: actionTypes.LIST_EQUIPMENT_MASTER_ERROR,
        payload
    };
};

export const updateEquipmentMaster = (payload) => {
    return {
        type: actionTypes.UPDATE_EQUIPMENT_MASTER, payload
    };
};
export const updateEquipmentMasterSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_EQUIPMENT_MASTER_SUCCESS,
        payload
    };
};
export const updateEquipmentMasterError = (payload) => {
    return {
        type: actionTypes.UPDATE_EQUIPMENT_MASTER_ERROR,
        payload
    };
};

export const deleteEquipmentMaster = (payload) => {
    return {
        type: actionTypes.DELETE_EQUIPMENT_MASTER, payload
    };
};
export const deleteEquipmentMasterSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_EQUIPMENT_MASTER_SUCCESS,
        payload
    };
};
export const deleteEquipmentMasterError = (payload) => {
    return {
        type: actionTypes.DELETE_EQUIPMENT_MASTER_ERROR,
        payload
    };
};


export const complaintRequestView = (payload) => {
    return {
        type: "COMPLAINT_REQUEST_VIEW",
        payload,
    };
};
export const complaintRequestViewSucces = (payload) => {
    return {
        type: "COMPLAINT_REQUEST_VIEW_SUCCESS",
        payload,
    };
};
export const complaintRequestViewError = (payload) => {
    return {
        type: "COMPLAINT_REQUEST_VIEW_ERROR",
        payload,
    };
};


export const employeeListForComplaint = (payload) => {
    return {
        type: "EMPLOYEE_LIST_FOR_COMPLAINT",
        payload,
    };
};
export const employeeListForComplaintSucces = (payload) => {
    return {
        type: "EMPLOYEE_LIST_FOR_COMPLAINT_SUCCESS",
        payload,
    };
};
export const employeeListForComplaintError = (payload) => {
    return {
        type: "EMPLOYEE_LIST_FOR_COMPLAINT_ERROR",
        payload,
    };
};

export const assignTechnicianForComplaint = (payload) => {
    return {
        type: "ASSIGN_TECHNICIAN_FOR_COMPLAINT",
        payload,
    };
};
export const assignTechnicianForComplaintSucces = (payload) => {
    return {
        type: "ASSIGN_TECHNICIAN_FOR_COMPLAINT_SUCCESS",
        payload,
    };
};
export const assignTechnicianForComplaintError = (payload) => {
    return {
        type: "ASSIGN_TECHNICIAN_FOR_COMPLAINT_ERROR",
        payload,
    };
};


export const addInspectionForComplaint = (payload) => {
    return {
        type: "ADD_INSPECTION_FOR_COMPLAINT",
        payload,
    };
};
export const addInspectionForComplaintSucces = (payload) => {
    return {
        type: "ADD_INSPECTION_FOR_COMPLAINT_SUCCESS",
        payload,
    };
};
export const addInspectionForComplaintError = (payload) => {
    return {
        type: "ADD_INSPECTION_FOR_COMPLAINT_ERROR",
        payload,
    };
};


export const updateInspectionForComplaint = (payload) => {
    return {
        type: "UPDATE_INSPECTION_FOR_COMPLAINT",
        payload,
    };
};
export const updateInspectionForComplaintSucces = (payload) => {
    return {
        type: "UPDATE_INSPECTION_FOR_COMPLAINT_SUCCESS",
        payload,
    };
};
export const updateInspectionForComplaintError = (payload) => {
    return {
        type: "UPDATE_INSPECTION_FOR_COMPLAINT_ERROR",
        payload,
    };
};

export const listInspectionForComplaint = (payload) => {
    // console.log(payload);
    return {
        type: "LIST_INSPECTION_FOR_COMPLAINT",
        payload,
    };
};
export const listInspectionForComplaintSucces = (payload) => {
    return {
        type: "LIST_INSPECTION_FOR_COMPLAINT_SUCCESS",
        payload,
    };
};
export const listInspectionForComplaintError = (payload) => {
    return {
        type: "LIST_INSPECTION_FOR_COMPLAINT_ERROR",
        payload,
    };
};

export const saveSparepartsForComplaint = (payload) => {
    return {
        type: "SAVE_SPAREPARTS_FOR_COMPLAINT",
        payload,
    };
};
export const saveSparepartsForComplaintSucces = (payload) => {
    return {
        type: "SAVE_SPAREPARTS_FOR_COMPLAINT_SUCCESS",
        payload,
    };
};
export const saveSparepartsForComplaintError = (payload) => {
    return {
        type: "SAVE_SPAREPARTS_FOR_COMPLAINT_ERROR",
        payload,
    };
};
export const listSparepartsForComplaint = (payload) => {
    return {
        type: "LIST_SPAREPARTS_FOR_COMPLAINT",
        payload,
    };
};
export const listSparepartsForComplaintSucces = (payload) => {
    return {
        type: "LIST_SPAREPARTS_FOR_COMPLAINT_SUCCESS",
        payload,
    };
};
export const listSparepartsForComplaintError = (payload) => {
    return {
        type: "LIST_SPAREPARTS_FOR_COMPLAINT_ERROR",
        payload,
    };
};
export const removeSparepartsForComplaint = (payload) => {
    return {
        type: "REMOVE_SPAREPARTS_FOR_COMPLAINT",
        payload,
    };
};
export const removeSparepartsForComplaintSucces = (payload) => {
    return {
        type: "REMOVE_SPAREPARTS_FOR_COMPLAINT_SUCCESS",
        payload,
    };
};
export const removeSparepartsForComplaintError = (payload) => {
    return {
        type: "REMOVE_SPAREPARTS_FOR_COMPLAINT_ERROR",
        payload,
    };
};


export const changetechnicianstatusForComplaint = (payload) => {
    return {
        type: "CHANGE_TECHNICIAN_STATUS_FOR_COMPLAINT",
        payload,
    };
};
export const changetechnicianstatusForComplaintSucces = (payload) => {
    return {
        type: "CHANGE_TECHNICIAN_STATUS_FOR_COMPLAINT_SUCCESS",
        payload,
    };
};
export const changetechnicianstatusForComplaintError = (payload) => {
    return {
        type: "CHANGE_TECHNICIAN_STATUS_FOR_COMPLAINT_ERROR",
        payload,
    };
};

export const getTechnicianTaskByComplaintId = (payload) => {
    return {
        type: "GET_TECHNICIAN_TASK_BY_COMPLAINT_ID",
        payload,
    };
};
export const getTechnicianTaskByComplaintIdSucces = (payload) => {
    return {
        type: "GET_TECHNICIAN_TASK_BY_COMPLAINT_ID_SUCCESS",
        payload,
    };
};
export const getTechnicianTaskByComplaintIdError = (payload) => {
    return {
        type: "GET_TECHNICIAN_TASK_BY_COMPLAINT_ID_ERROR",
        payload,
    };
};



export const changeEquipmentComplaintRequestStatus = (payload) => {
    return {
        type: "CHANGE_EQUIPMENT_COMPLAINT_REQUEST_STATUS",
        payload,
    };
};
export const changeEquipmentComplaintRequestStatusSucces = (payload) => {
    return {
        type: "CHANGE_EQUIPMENT_COMPLAINT_REQUEST_STATUS_SUCCESS",
        payload,
    };
};
export const changeEquipmentComplaintRequestStatusError = (payload) => {
    return {
        type: "CHANGE_EQUIPMENT_COMPLAINT_REQUEST_STATUS_ERROR",
        payload,
    };
};


export const  Assetcomplaintsave= (payload) => {
    return {
        type: "ASSET_COMPLAINT_SAVE",
        payload,
    };
};
export const AssetcomplaintsaveSucces = (payload) => {
    return {
        type: "ASSET_COMPLAINT_SAVE_SUCCESS",
        payload,
    };
};
export const AssetcomplaintsaveError = (payload) => {
    return {
        type: "ASSET_COMPLAINT_SAVE_ERROR",
        payload,
    };
};


export const  listcomplaintrequest= (payload) => {
    return {
        type: "LIST_COMPLAINT_REQUEST",
        payload,
    };
};
export const listcomplaintrequestSucces = (payload) => {
    return {
        type: "LIST_COMPLAINT_REQUEST_SUCCESS",
        payload,
    };
};
export const listcomplaintrequestError = (payload) => {
    return {
        type: "LIST_COMPLAINT_REQUEST_ERROR",
        payload,
    };
};


export const  listassetcalibrationrooster =(payload) =>{
    return{
        type:"LIST_ASSET_CALIBRATION_ROOSTER",
        payload,
    };
};
export const listassetcalibrationroosterSucces = (payload) => {
    return {
        type: "LIST_ASSET_CALIBRATION_ROOSTER_SUCCESS",
        payload,
    };
};
export const listassetcalibrationroosterError = (payload) => {
    return {
        type: "LIST_ASSET_CALIBRATION_ROOSTER_ERROR",
        payload,
    };
};


export const  listassetservicerooster =(payload) =>{
    return{
        type:"LIST_ASSET_SERVICE_ROOSTER",
        payload,
    };
};
export const listassetserviceroosterSucces = (payload) => {
    return {
        type: "LIST_ASSET_SERVICE_ROOSTER_SUCCESS",
        payload,
    };
};
export const listassetserviceroosterError = (payload) => {
    return {
        type: "LIST_ASSET_SERVICE_ROOSTER_ERROR",
        payload,
    };
};


export const  assetroosterviewidentification =(payload) =>{
    return{
        type:"ASSET_ROOSTER_VIEW_IDENTIFICATION",
        payload,
    };
};
export const assetroosterviewidentificationSucces = (payload) => {
    return {
        type: "ASSET_ROOSTER_VIEW_IDENTIFICATION_SUCCESS",
        payload,
    };
};
export const assetroosterviewidentificationError = (payload) => {
    return {
        type: "ASSET_ROOSTER_VIEW_IDENTIFICATION_ERROR",
        payload,
    };
};


export const viewCalibrationDetails = (payload) => {
    return { type: 'VIEW_CALIBRATION_DETAILS', payload };
};
export const viewCalibrationDetailsSuccess = (payload) => {
    return { type: 'VIEW_CALIBRATION_DETAILS_SUCCESS', payload };
};
export const viewCalibrationDetailsError = (payload) => {
    return { type: 'VIEW_CALIBRATION_DETAILS_ERROR', payload };
};

export const viewServiceDetails = (payload) => {
    return { type: 'VIEW_SERVICE_DETAILS', payload };
};
export const viewServiceDetailsSuccess = (payload) => {
    return { type: 'VIEW_SERVICE_DETAILS_SUCCESS', payload };
};
export const viewServiceDetailsError = (payload) => {
    return { type: 'VIEW_SERVICE_DETAILS_ERROR', payload };
};

export const viewServiceRooster = (payload) => {
    return { type: 'VIEW_SERVICE_ROOSTER', payload };
};
export const viewServiceRoosterSuccess = (payload) => {
    return { type: 'VIEW_SERVICE_ROOSTER_SUCCESS', payload };
};
export const viewServiceRoosterError = (payload) => {
    return { type: 'VIEW_SERVICE_ROOSTER_ERROR', payload };
};


export const fetchComplaintActivityLog = (payload) => {
    return { type: 'FETCH_COMPLAINT_ACTIVITY_LOG', payload };
};
export const fetchComplaintActivityLogSuccess = (payload) => {
    return { type: 'FETCH_COMPLAINT_ACTIVITY_LOG_SUCCESS', payload };
};
export const fetchComplaintActivityLogError = (payload) => {
    return { type: 'FETCH_COMPLAINT_ACTIVITY_LOG_ERROR', payload };
};

export const assignedServiceVendors = (payload) => {
    return { type: 'ASSIGNED_SERVICE_VENDORS', payload };
};
export const assignedServiceVendorsSuccess = (payload) => {
    return { type: 'ASSIGNED_SERVICE_VENDORS_SUCCESS', payload };
};
export const assignedServiceVendorsError = (payload) => {
    return { type: 'ASSIGNED_SERVICE_VENDORS_ERROR', payload };
};

export const assignVendorToService = (payload) => {
    return { type: 'ASSIGN_VENDOR_TO_SERVICE', payload };
};
export const assignVendorToServiceSuccess = (payload) => {
    return { type: 'ASSIGN_VENDOR_TO_SERVICE_SUCCESS', payload };
};
export const assignVendorToServiceError = (payload) => {
    return { type: 'ASSIGN_VENDOR_TO_SERVICE_ERROR', payload };
};

export const removeDepartmentForComplaint = (payload) => {
    return { type: 'REMOVE_DEPAETMENT_FOR_COMPLAINT', payload };
};
export const removeDepartmentForComplaintSuccess = (payload) => {
    return { type: 'REMOVE_DEPAETMENT_FOR_COMPLAINT_SUCCESS', payload };
};
export const removeDepartmentForComplaintError = (payload) => {
    return { type: 'REMOVE_DEPAETMENT_FOR_COMPLAINT_ERROR', payload };
};


export const tasklist = (payload) => {
    return { type: 'TASK_LIST', payload };
};
export const tasklistSuccess = (payload) => {
    return { type: 'TASK_LIST_SUCCESS', payload };
};
export const tasklistError = (payload) => {
    return { type: 'TASK_LIST_ERROR', payload };
};

//// delivery note in equipment - ///

export const saveDeliveryNote = (payload) => {
    return { type: 'SAVE_DELIVERY_NOTE', payload };
};
export const saveDeliveryNoteSuccess = (payload) => {
    return { type: 'SAVE_DELIVERY_NOTE_SUCCESS', payload };
};
export const saveDeliveryNoteError = (payload) => {
    return { type: 'SAVE_DELIVERY_NOTE_ERROR', payload };
};

///// receipt note-- ////////////

export const saveReceiptNote = (payload) => {
    return { type: 'SAVE_RECEIPT_NOTE', payload };
};
export const saveReceiptNoteSuccess = (payload) => {
    return { type: 'SAVE_RECEIPT_NOTE_SUCCESS', payload };
};
export const saveReceiptNoteError = (payload) => {
    return { type: 'SAVE_RECEIPT_NOTE_ERROR', payload };
};

// equipment allocation

export const saveEquipmentAllocation = (payload) => {
    return { type: 'SAVE_EQUIPMENT_ALLOCATION', payload };
};
export const saveEquipmentAllocationSuccess = (payload) => {
    return { type: 'SAVE_EQUIPMENT_ALLOCATION_SUCCESS', payload };
};
export const saveEquipmentAllocationError = (payload) => {
    return { type: 'SAVE_EQUIPMENT_ALLOCATION_ERROR', payload };
};

export const listEquipmentAllocation = (payload) => {
    return { type: 'LIST_EQUIPMENT_ALLOCATION', payload };
};
export const listEquipmentAllocationSuccess = (payload) => {
    return { type: 'LIST_EQUIPMENT_ALLOCATION_SUCCESS', payload };
};
export const listEquipmentAllocationError = (payload) => {
    return { type: 'LIST_EQUIPMENT_ALLOCATION_ERROR', payload };
};

export const ViewEquipmentAllocation = (payload) => {
    return { type: 'VIEW_EQUIPMENT_ALLOCATION', payload };
};
export const ViewEquipmentAllocationSuccess = (payload) => {
    return { type: 'VIEW_EQUIPMENT_ALLOCATION_SUCCESS', payload };
};
export const ViewEquipmentAllocationError = (payload) => {
    return { type: 'VIEW_EQUIPMENT_ALLOCATION_ERROR', payload };
};

export const ReturnEquipmentAllocation = (payload) => {
    return { type: 'RETURN_EQUIPMENT_ALLOCATION', payload };
};
export const ReturnEquipmentAllocationSuccess = (payload) => {
    return { type: 'RETURN_EQUIPMENT_ALLOCATION_SUCCESS', payload };
};
export const ReturnEquipmentAllocationError = (payload) => {
    return { type: 'RETURN_EQUIPMENT_ALLOCATION_ERROR', payload };
};
// list Deliverynote
export const listDeliveryNote = (payload) => {
    return { type: 'LIST_DELIVERY_NOTE', payload };
};
export const listDeliveryNoteSuccess = (payload) => {
    return { type: 'LIST_DELIVERY_NOTE_SUCCESS', payload };
};
export const listDeliveryNoteError = (payload) => {
    return { type: 'LIST_DELIVERY_NOTE_ERROR', payload };
};

export const ViewDeliveryNote = (payload) => {
    return { type: 'VIEW_DELIVERY_NOTE', payload };
};
export const ViewDeliveryNoteSuccess = (payload) => {
    return { type: 'VIEW_DELIVERY_NOTE_SUCCESS', payload };
};
export const ViewDeliveryNoteError = (payload) => {
    return { type: 'VIEW_DELIVERY_NOTE_ERROR', payload };
};

// assign external vendor for service


export const update_service_status_external_vendor = (payload) => {
    return { type: 'UPDATE_EXTERNAL_VENDOR_STATUS', payload };
};
export const update_service_status_external_vendorSuccess = (payload) => {
    return { type: 'UPDATE_EXTERNAL_VENDOR_STATUS_SUCCESS', payload };
};
export const update_service_status_external_vendorError = (payload) => {
    return { type: 'UPDATE_EXTERNAL_VENDOR_STATUS_ERROR', payload };
};

// list receipt  note

export const fetchReceiptNote = (payload) => {
    return { type: 'FETCH_RECEIPTNOTE', payload };
};
export const fetchReceiptNoteSuccess = (payload) => {
    return { type: 'FETCH_RECEIPTNOTE_SUCCESS', payload };
};
export const fetchReceiptNoteError = (payload) => {
    return { type: 'FETCH_RECEIPTNOTE_ERROR', payload };
};
// list task report
export const fetchTaskReport = (payload) => {
    return { type: 'FETCH_TASKREPORT', payload };
};
export const fetchTaskReportSuccess = (payload) => {
    return { type: 'FETCH_TASKREPORT_SUCCESS', payload };
};
export const fetchTaskReportError = (payload) => {
    return { type: 'FETCH_TASKREPORT_ERROR', payload };
};
//department wise barcode list for barcode print
export const departmentwisebarcode = (payload) => {
    return { type: 'DEPARTMENTWISE_BARCODE', payload };
};
export const departmentwisebarcodeSuccess = (payload) => {
    return { type: 'DEPARTMENTWISE_BARCODE_SUCCESS', payload };
};
export const departmentwisebarcodeError = (payload) => {
    return { type: 'DEPARTMENTWISE_BARCODE_ERROR', payload };
};
// generate barcode bulk
export const generatebarcodebulk = (payload) => {
    return { type: 'GENERATEBARCODEBULK', payload };
};
export const generatebarcodebulkSuccess = (payload) => {
    return { type: 'GENERATEBARCODEBULK_SUCCESS', payload };
};
export const generatebarcodebulkError = (payload) => {
    return { type: 'GENERATEBARCODEBULK_ERROR', payload };
};