
import { actionTypes } from './action';

export const initialState = {
    eventcontactvalues: []
}

const eventMasterReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.EVENTMASTER_CONTACT:
            return { ...state, eventcontactvalues: [] }
        case actionTypes.EVENTMASTER_CONTACT_SUCCESS:
            return { ...state, eventcontactvalues: action.payload }
        case actionTypes.EVENTMASTER_CONTACT_ERROR:
            return { ...state, eventcontactvalues: [] }
        default:
            return state
    }
}

export default eventMasterReducer