import axios from '@service/ApiClient'

class qualificationDetailsService {
    constructor(callback) {
        this.callback = callback
    }
    async saveQualificationDetails(payload) {
        return await axios.post('/Crm/User/add_employee_qualification', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async listQualificationDetails(payload) {
        return await axios.post('/Crm/User/list_employee_qualification', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateQualificationDetails(payload) {
        return await axios.post('/Crm/User/update_employee_qualification', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async deleteQualificationDetails(payload) {
        return await axios.post('/Crm/User/delete_employee_qualification', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async listQualification(payload) {
        return await axios.post('/Organization/Qualification/list_qualification', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new qualificationDetailsService();