import { takeEvery, put, call, all } from 'redux-saga/effects';
import designationservice from '@service/admin/masterService/designationservice';


import {
  FetchDesignationSuccess, FetchDesignationError,
  AddDesignationSuccess, AddDesignationError,
  DeleteDesignationSuccess, DeleteDesignationError,
  EditDesignationSuccess, EditDesignationError,
  UpdateDesignationSuccess, UpdateDesignationError, AddDesignationRatingSuccess, AddDesignationRatingError, UpdateDesignationRatingSuccess, UpdateDesignationRatingError,
  importdesignationSuccess,importdesignationError
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'


function* FetchDesignation({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(designationservice.fetch_designation, payload);
    yield put(FetchDesignationSuccess(Array.isArray(data) ? data : []));

  } catch (error) {
    yield put(FetchDesignationError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* AddDesignation({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(designationservice.save_designation, payload);
    yield put(AddDesignationSuccess(data));

  } catch (error) {
    yield put(AddDesignationError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* DeleteDesignation({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(designationservice.delete_designation, payload);
    yield put(DeleteDesignationSuccess(data));
  } catch (error) {
    yield put(DeleteDesignationError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* EditDesignation({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(designationservice.edit_designation, payload);
    yield put(EditDesignationSuccess(data));
  } catch (error) {
    yield put(EditDesignationError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* UpdateDesignation({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(designationservice.update_designation, payload);
    yield put(UpdateDesignationSuccess(data));

  } catch (error) {
    yield put(UpdateDesignationError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* AddDesignationRating({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(designationservice.AddDesignationRating, payload);
    yield put(AddDesignationRatingSuccess(data));
  } catch (error) {
    yield put(AddDesignationRatingError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* UpdateDesignationRating({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(designationservice.UpdateDesignationRating, payload);
    yield put(UpdateDesignationRatingSuccess(data));
  } catch (error) {
    yield put(UpdateDesignationRatingError(error));
  } finally {
    yield put(stopLoader());
  }
}


function* importdesignation({ payload }) {
  try {
   // console.log("payload",payload);
    yield put(startLoader());
    const data = yield call(designationservice.importdesignation, payload);
    yield put(importdesignationSuccess(data));
  } catch (error) {
    yield put(importdesignationError(error));
  } finally {
    yield put(stopLoader());
  }
}

export default function* rootSaga() {
  yield all([takeEvery('FETCH_DESIGNATION', FetchDesignation)]);
  yield all([takeEvery('ADD_DESIGNATION', AddDesignation)]);
  yield all([takeEvery('DELETE_DESIGNATION', DeleteDesignation)]);
  yield all([takeEvery('EDIT_DESIGNATION', EditDesignation)]);
  yield all([takeEvery('UPDATE_DESIGNATION', UpdateDesignation)]);
  yield all([takeEvery('ADD_DESIGNATION_RATING', AddDesignationRating)]);
  yield all([takeEvery('UPDATE_DESIGNATION_RATING', UpdateDesignationRating)]);
  yield all([takeEvery('IMPORT_DESIGNATION', importdesignation)]);
}