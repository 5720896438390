//save service master

export const AddService = (payload) => {
    return {
         type: 'ADD_SERVICE' ,
         payload
     };
 };
 export const   AddServiceSuccess = (payload) => {
       return {
         type: 'ADD_SERVICE_SUCCESS',
         payload
     };
 };
 export const   AddServiceError = (payload) => {
     return {
         type: 'ADD_SERVICE_ERROR',
         payload
     };
 };
 

export const listService = (payload) => {
    return {
         type: 'LIST_SERVICE' ,
         payload
     };
 };
 export const   listServiceSuccess = (payload) => {
       return {
         type: 'LIST_SERVICE_SUCCESS',
         payload
     };
 };
 export const   listServiceError = (payload) => {
     return {
         type: 'LIST_SERVICE_ERROR',
         payload
     };
 };

export const deleteService = (payload) => {
    return {
         type: 'DELETE_SERVICE' ,
         payload
     };
 };
 export const   deleteServiceSuccess = (payload) => {
       return {
         type: 'DELETE_SERVICE_SUCCESS',
         payload
     };
 };
 export const   deleteServiceError = (payload) => {
     return {
         type: 'DELETE_SERVICE_ERROR',
         payload
     };
 };
 