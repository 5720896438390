import { takeEvery, put, call, all } from 'redux-saga/effects';
import qualificationservice from '@service/admin/masterService/qualificationservice';


import {
  FetchQualificationSuccess, FetchQualificationError,
  AddQualificationSuccess, AddQualificationError,
  DeleteQualificationSuccess, DeleteQualificationError,
  EditQualificationSuccess, EditQualificationError,
  UpdateQualificationSuccess, UpdateQualificationError,
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'


function* FetchQualification({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(qualificationservice.fetch_qualification, payload);
    Array.isArray(data) ?
      yield put(FetchQualificationSuccess(data)) :
      yield put(FetchQualificationSuccess([]))
  } catch (error) {
    yield put(FetchQualificationError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* AddQualification({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(qualificationservice.save_qualification, payload);
    yield put(AddQualificationSuccess(data));
  } catch (error) {
    yield put(AddQualificationError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* DeleteQualification({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(qualificationservice.delete_qualification, payload);
    yield put(DeleteQualificationSuccess(data));
  } catch (error) {
    yield put(DeleteQualificationError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* EditQualification({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(qualificationservice.edit_qualification, payload);
    yield put(EditQualificationSuccess(data));
  } catch (error) {
    yield put(EditQualificationError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* UpdateQualification({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(qualificationservice.update_qualification, payload);
    yield put(UpdateQualificationSuccess(data));
  } catch (error) {
    yield put(UpdateQualificationError(error));
  } finally {
    yield put(stopLoader());
  }
}

export default function* rootSaga() {
  yield all([takeEvery('FETCH_QUALIFICATION', FetchQualification)]);
  yield all([takeEvery('ADD_QUALIFICATION', AddQualification)]);
  yield all([takeEvery('DELETE_QUALIFICATION', DeleteQualification)]);
  yield all([takeEvery('EDIT_QUALIFICATION', EditQualification)]);
  yield all([takeEvery('UPDATE_QUALIFICATION', UpdateQualification)]);
}