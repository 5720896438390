import axios from '../../ApiClient'
// import { siteUrl } from './config'

class modelservice  {
    constructor(callback) {
        this.callback = callback
    }

    async addModel(payload) {
        return await axios.post('/Inventory/Model/save_model',payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    } 

    async fetchModel(payload) {
        return await axios.post('/Inventory/Model/list_model',payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    } 
    async editModel(payload) {
        return await axios.post('/Inventory/Model/update_model', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
       
    } 
    async deleteModel(payload) {
        return await axios.post('/Inventory/Model/delete_model', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
       
    } 
} 

export default new modelservice ();