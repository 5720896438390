import { takeEvery, put, call, all } from 'redux-saga/effects';
import modelservice from '@service/admin/masterService/modelservice';

import {
  AddModelSuccess, AddModelError,
  FetchModelSuccess, FetchModelError,
  EditModelSuccess, EditModelError,
  DeleteModelSuccess, DeleteModelError
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'



function* AddModel({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(modelservice.addModel, payload);
    yield put(AddModelSuccess(data));
  } catch (error) {
    yield put(AddModelError(error));
  } finally {
    yield put(stopLoader());
  }
}


function* FetchModel({ payload }) {
  try {

    yield put(startLoader());
    const data = yield call(modelservice.fetchModel, payload);
    Array.isArray(data) ?
      yield put(FetchModelSuccess(data)) :
      yield put(FetchModelError([]));
  } catch (error) {
    yield put(FetchModelError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* EditModel({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(modelservice.editModel, payload);
    yield put(EditModelSuccess(data));
  } catch (error) {
    yield put(EditModelError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* DeleteModel({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(modelservice.deleteModel, payload);
    yield put(DeleteModelSuccess(data));
  } catch (error) {
    yield put(DeleteModelError(error));
  } finally {
    yield put(stopLoader());
  }
}

export default function* rootSaga() {
  yield all([takeEvery('ADD_MODEL', AddModel)]);
  yield all([takeEvery('FETCH_MODEL', FetchModel)]);
  yield all([takeEvery('EDIT_MODEL', EditModel)]);
  yield all([takeEvery('DELETE_MODEL', DeleteModel)]);

}