
export const actionTypes = {
    SAVE_WEEKEND: 'SAVE_WEEKEND',
    SAVE_WEEKEND_SUCCESS: 'SAVE_WEEKEND_SUCCESS',
    SAVE_WEEKEND_ERROR: 'SAVE_WEEKEND_ERROR',
    LIST_WEEKEND: 'LIST_WEEKEND',
    LIST_WEEKEND_SUCCESS: 'LIST_WEEKEND_SUCCESS',
    LIST_WEEKEND_ERROR: 'LIST_WEEKEND_ERROR',
    DELETE_WEEKEND: 'DELETE_WEEKEND',
    DELETE_WEEKEND_SUCCESS: 'DELETE_WEEKEND_SUCCESS',
    DELETE_WEEKEND_ERROR: 'DELETE_WEEKEND_ERROR',
    UPDATE_WEEKEND: 'UPDATE_WEEKEND',
    UPDATE_WEEKEND_SUCCESS: 'UPDATE_WEEKEND_SUCCESS',
    UPDATE_WEEKEND_ERROR: 'UPDATE_WEEKEND_ERROR',
};

export const saveWeekend = (payload) => {
    return {
        type: actionTypes.SAVE_WEEKEND, payload
    };
};
export const saveWeekendSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_WEEKEND_SUCCESS,
        payload
    };
};
export const saveWeekendError = (payload) => {
    return {
        type: actionTypes.SAVE_WEEKEND_ERROR,
        payload
    };
};

export const listWeekend = (payload) => {
    return {
        type: actionTypes.LIST_WEEKEND, payload
    };
};
export const listWeekendSuccess = (payload) => {
    return {
        type: actionTypes.LIST_WEEKEND_SUCCESS,
        payload
    };
};
export const listWeekendError = (payload) => {
    return {
        type: actionTypes.LIST_WEEKEND_ERROR,
        payload
    };
};

export const deleteWeekend = (payload) => {
    return {
        type: actionTypes.DELETE_WEEKEND, payload
    };
};
export const deleteWeekendSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_WEEKEND_SUCCESS,
        payload
    };
};
export const deleteWeekendError = (payload) => {
    return {
        type: actionTypes.DELETE_WEEKEND_ERROR,
        payload
    };
};

export const updateWeekend = (payload) => {
    return {
        type: actionTypes.UPDATE_WEEKEND, payload
    };
};
export const updateWeekendSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_WEEKEND_SUCCESS,
        payload
    };
};
export const updateWeekendError = (payload) => {
    return {
        type: actionTypes.UPDATE_WEEKEND_ERROR,
        payload
    };
};