
export const actionTypes = {
    SAVE_FAMILYDETAILS: 'SAVE_FAMILYDETAILS',
    SAVE_FAMILYDETAILS_SUCCESS: 'SAVE_FAMILYDETAILS_SUCCESS',
    SAVE_FAMILYDETAILS_ERROR: 'SAVE_FAMILYDETAILS_ERROR',
    LIST_FAMILYDETAILS: 'LIST_FAMILYDETAILS',
    LIST_FAMILYDETAILS_SUCCESS: 'LIST_FAMILYDETAILS_SUCCESS',
    LIST_FAMILYDETAILS_ERROR: 'LIST_FAMILYDETAILS_ERROR',
    UPDATE_FAMILYDETAILS: 'UPDATE_FAMILYDETAILS',
    UPDATE_FAMILYDETAILS_SUCCESS: 'UPDATE_FAMILYDETAILS_SUCCESS',
    UPDATE_FAMILYDETAILS_ERROR: 'UPDATE_FAMILYDETAILS_ERROR',
    DELETE_FAMILYDETAILS: 'DELETE_FAMILYDETAILS',
    DELETE_FAMILYDETAILS_SUCCESS: 'DELETE_FAMILYDETAILS_SUCCESS',
    DELETE_FAMILYDETAILS_ERROR: 'DELETE_FAMILYDETAILS_ERROR'
};

export const saveFamilyDetails = (payload) => {
    return {
        type: actionTypes.SAVE_FAMILYDETAILS, payload
    };
};
export const saveFamilyDetailsSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_FAMILYDETAILS_SUCCESS,
        payload
    };
};
export const saveFamilyDetailsError = (payload) => {
    return {
        type: actionTypes.SAVE_FAMILYDETAILS_ERROR,
        payload
    };
};

export const listFamilyDetails = (payload) => {
    return {
        type: actionTypes.LIST_FAMILYDETAILS, payload
    };
};
export const listFamilyDetailsSuccess = (payload) => {
    return {
        type: actionTypes.LIST_FAMILYDETAILS_SUCCESS,
        payload
    };
};
export const listFamilyDetailsError = (payload) => {
    return {
        type: actionTypes.LIST_FAMILYDETAILS_ERROR,
        payload
    };
};

export const updateFamilyDetails = (payload) => {
    return {
        type: actionTypes.UPDATE_FAMILYDETAILS, payload
    };
};
export const updateFamilyDetailsSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_FAMILYDETAILS_SUCCESS,
        payload
    };
};
export const updateFamilyDetailsError = (payload) => {
    return {
        type: actionTypes.UPDATE_FAMILYDETAILS_ERROR,
        payload
    };
};

export const deleteFamilyDetails = (payload) => {
    return {
        type: actionTypes.DELETE_FAMILYDETAILS, payload
    };
};
export const deleteFamilyDetailsSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_FAMILYDETAILS_SUCCESS,
        payload
    };
};
export const deleteFamilyDetailsError = (payload) => {
    return {
        type: actionTypes.DELETE_FAMILYDETAILS_ERROR,
        payload
    };
};