
const initialState = {
    salutationLi: [],
    addstatus: '',
    deletestatus: '',
    updatestatus: ''
}

const salutationReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'FETCH_SALUTATION':
            return { ...state, salutationLi: [], addstatus: '', deletestatus: '', updatestatus: '' }
        case 'FETCH_SALUTATION_SUCCESS':
            return { ...state, salutationLi: action.payload }
        case 'FETCH_SALUTATION_ERROR':
            return { ...state, salutationLi: [] }

        case 'ADD_SALUTATION':
            return { ...state, addstatus: '' }
        case 'ADD_SALUTATION_SUCCESS':
            return { ...state, addstatus: 'SUCCESS' }
        case 'ADD_SALUTATION_ERROR':
            return { ...state, addstatus: 'ERROR' }

        case 'DELETE_SALUTATION':
            return { ...state, deletestatus: '' }
        case 'DELETE_SALUTATION_SUCCESS':
            return { ...state, deletestatus: 'SUCCESS' }
        case 'DELETE_SALUTATION_ERROR':
            return { ...state, deletestatus: 'ERROR' }

        case 'UPDATE_SALUTATION':
            return { ...state, updatestatus: '' }
        case 'UPDATE_SALUTATION_SUCCESS':
            return { ...state, updatestatus: 'SUCCESS' }
        case 'UPDATE_SALUTATION_ERROR':
            return { ...state, updatestatus: 'ERROR' }

        default:
            return state
    }
}
export default salutationReducer