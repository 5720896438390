import { actionTypes } from "./action";

export const initialState = {
  saveBuildingStatus: "",
  saveFloorStatus: "",
  buildinglist: '',
  floorlist: '',
  locTypeList: '',
  listLocations: '',
  listLocationsBF: '',
  assetlocationSaveStatus: "",
  addEquipmentCategoryStatus: "",
  saveStatusEquipmentMaster: "",
  equipmentCategoryList: '',
  locationList: '',
  deleteStatusEquipmentCategory: "",
  listEquipmentMaster: '',
  deleteStatusEquipmentMaster: "",
  updateEquipmentCategoryStatus: "",
  viewEquipmentCategoryData: "",
  viewEquipmentMasterData: "",
  updateEquipmentMasterStatus: "",
  saveComplaintStatus: "",
  complaintList: '',
  equipmentListFiltered: '',
  equipmentComplinetList: '',
  saveEquipmentComplaintStatus: "",
  updatedrequestStatus: "",
  deleteLoctionStatus: '',
  updateLoctionStatus: '',
  importlocation: '',
  importcomplaint: '',
  importequimentcategory: '',
  importequimentmaster: '',
  equipmentdetails: '',
  complaintListstatus: '',
  viewequipmentdetails: '',
  dashboard: '',
  topAsset: '',
  businessrule: '',
  equipmentcomplaintlist: '',
  equipmenthistorylist: '',
  equipmentCategoryView: '',
  activityCheck: '',
  updateequipment:'',
  QcList:'',
  QcattachmentView:'',
  qcsaveData:'',
  qctaskList:'',
  deleteqcmaster:'',
  barcodenumber:'',
  activeinactivestatus:'',
  deleteStatus:'',
  activitylog:''
};
const assetReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_BUILDINGS:
      return { ...state, saveBuildingStatus: "" };
    case actionTypes.ADD_BUILDINGS_SUCCESS:
      return { ...state, saveBuildingStatus: action.payload };
    case actionTypes.ADD_BUILDINGS_ERROR:
      return { ...state, saveBuildingStatus: "error" };

    case actionTypes.LIST_BUILDINGS:
      return { ...state, buildinglist: '', saveBuildingStatus: "" };
    case actionTypes.LIST_BUILDINGS_SUCCESS:
      return { ...state, buildinglist: action.payload };
    case actionTypes.LIST_BUILDINGS_ERROR:
      return { ...state, buildinglist: '' };

    case actionTypes.SAVE_FLOOR:
      return { ...state, saveFloorStatus: "" };
    case actionTypes.SAVE_FLOOR_SUCCESS:
      return { ...state, saveFloorStatus: action.payload };
    case actionTypes.SAVE_FLOOR_ERROR:
      return { ...state, saveFloorStatus: "" };

    case actionTypes.LIST_FLOORS:
      return { ...state, floorlist: '', saveFloorStatus: "" };
    case actionTypes.LIST_FLOORS_SUCCESS:
      return { ...state, floorlist: action.payload };
    case actionTypes.LIST_FLOORS_ERROR:
      return { ...state, floorlist: '' };

    case actionTypes.LIST_LOC_TYPE:
      return { ...state, locTypeList: '', saveTypeStatus: "" };
    case actionTypes.LIST_LOC_TYPE_SUCCESS:
      return { ...state, locTypeList: action.payload };
    case actionTypes.LIST_LOC_TYPE_ERROR:
      return { ...state, locTypeList: '' };

    case actionTypes.SAVE_LOC_TYPE:
      return { ...state, saveTypeStatus: "" };
    case actionTypes.SAVE_LOC_TYPE_SUCCESS:
      return { ...state, saveTypeStatus: action.payload };
    case actionTypes.SAVE_LOC_TYPE_ERROR:
      return { ...state, saveTypeStatus: "" };

    case actionTypes.SAVE_LOCATION_NAME:
      return { ...state, saveLoctionStatus: "" };
    case actionTypes.SAVE_LOCATION_NAME_SUCCESS:
      return { ...state, saveLoctionStatus: action.payload };
    case actionTypes.SAVE_LOCATION_NAME_ERROR:
      return { ...state, saveLoctionStatus: "" };

    case actionTypes.LIST_LOCATION_NAME:
      return { ...state, listLocations: '' };
    case actionTypes.LIST_LOCATION_NAME_SUCCESS:
      return { ...state, listLocations: action.payload };
    case actionTypes.LIST_LOCATION_NAME_ERROR:
      return { ...state, listLocations: '' };

    case actionTypes.BUSINESS_RULE:
      return { ...state, businessrule: '' };
    case actionTypes.BUSINESS_RULE_SUCCESS:
      return { ...state, businessrule: action.payload };
    case actionTypes.BUSINESS_RULE_ERROR:
      return { ...state, businessrule: '' };

    case "DELETE_LOCATION_NAME":
      return { ...state, deleteLoctionStatus: '' };
    case "DELETE_LOCATION_NAME_SUCCESS":
      return { ...state, deleteLoctionStatus: action.payload };
    case "DELETE_LOCATION_NAME_ERROR":
      return { ...state, deleteLoctionStatus: '' };

    case "UPDATE_LOCATION_NAME":
      return { ...state, updateLoctionStatus: '' };
    case "UPDATE_LOCATION_NAME_SUCCESS":
      return { ...state, updateLoctionStatus: action.payload };
    case "UPDATE_LOCATION_NAME_ERROR":
      return { ...state, updateLoctionStatus: '' };

    case actionTypes.LIST_LOCATION_BUILDING_FLOOR:
      return { ...state, listLocationsBF: '' };
    case actionTypes.LIST_LOCATION_BUILDING_FLOOR_SUCCESS:
      return { ...state, listLocationsBF: action.payload };
    case actionTypes.LIST_LOCATION_BUILDING_FLOOR_ERROR:
      return { ...state, listLocationsBF: '' };

    case actionTypes.ADD_ASSET_TO_LOCATION:
      return { ...state, assetlocationSaveStatus: "" };
    case actionTypes.ADD_ASSET_TO_LOCATION_SUCCESS:
      return { ...state, assetlocationSaveStatus: action.payload };
    case actionTypes.ADD_ASSET_TO_LOCATION_ERROR:
      return { ...state, assetlocationSaveStatus: "" };


    case actionTypes.GET_DASHBOARD_DATA:
      return { ...state, dashboard: "" };
    case actionTypes.GET_DASHBOARD_DATA_SUCCESS:
      return { ...state, dashboard: action.payload };
    case actionTypes.GET_DASHBOARD_DATA_ERROR:
      return { ...state, dashboard: "" };


    case actionTypes.GET_TOP_ASSET:
      return { ...state, topAsset: "" };
    case actionTypes.GET_TOP_ASSET_SUCCESS:
      return { ...state, topAsset: action.payload };
    case actionTypes.GET_TOP_ASSET_ERROR:
      return { ...state, topAsset: "" };

    case "ADD_EQUIPMENT_CATEGORY":
      return { ...state, addEquipmentCategoryStatus: "" };
    case "ADD_EQUIPMENT_CATEGORY_SUCCESS":
      return { ...state, addEquipmentCategoryStatus: action.payload };
    case "ADD_EQUIPMENT_CATEGORY_ERROR":
      return { ...state, addEquipmentCategoryStatus: "" };

    case "LIST_EQUIPMENT_CATEGORY":
      return { ...state, equipmentCategoryList: '' };
    case "LIST_EQUIPMENT_CATEGORY_SUCCESS":
      return { ...state, equipmentCategoryList: action.payload };
    case "LIST_EQUIPMENT_CATEGORY_ERROR":
      return { ...state, equipmentCategoryList: '' };

    case "GET_LOCATION_BY_CATID_FLOORID":
      return { ...state, locationList: '' };
    case "GET_LOCATION_BY_CATID_FLOORID_SUCCESS":
      return { ...state, locationList: action.payload };
    case "GET_LOCATION_BY_CATID_FLOORID_ERROR":
      return { ...state, locationList: '' };

    case "SAVE_EQUIPMENT_MASTERS":
      return { ...state, saveStatusEquipmentMaster: "" };
    case "SAVE_EQUIPMENT_MASTERS_SUCCESS":
      return { ...state, saveStatusEquipmentMaster: action.payload };
    case "SAVE_EQUIPMENT_MASTERS_ERROR":
      return { ...state, saveStatusEquipmentMaster: "" };

    case "DELETE_EQUIPMENT_CATEGORY":
      return { ...state, deleteStatusEquipmentCategory: "" };
    case "DELETE_EQUIPMENT_CATEGORY_SUCCESS":
      return { ...state, deleteStatusEquipmentCategory: action.payload };
    case "DELETE_EQUIPMENT_CATEGORY_ERROR":
      return { ...state, deleteStatusEquipmentCategory: "" };

    case "LIST_EQUIPMENT_MASTERS":
      return { ...state, listEquipmentMaster: '' };
    case "LIST_EQUIPMENT_MASTERS_SUCCESS":
      return { ...state, listEquipmentMaster: action.payload };
    case "LIST_EQUIPMENT_MASTERS_ERROR":
      return { ...state, listEquipmentMaster: '' };

    case "DELETE_EQUIPMENT_MASTERS":
      return { ...state, deleteStatusEquipmentMaster: "" };
    case "DELETE_EQUIPMENT_MASTERS_SUCCESS":
      return { ...state, deleteStatusEquipmentMaster: action.payload };
    case "DELETE_EQUIPMENT_MASTERS_ERROR":
      return { ...state, deleteStatusEquipmentMaster: "" };

    case "VIEW_EQUIPMENT_CATEGORY":
      return { ...state, viewEquipmentCategoryData: "" };
    case "VIEW_EQUIPMENT_CATEGORY_SUCCESS":
      return { ...state, viewEquipmentCategoryData: action.payload };
    case "VIEW_EQUIPMENT_CATEGORY_ERROR":
      return { ...state, viewEquipmentCategoryData: "" };

    case "UPDATE_EQUIPMENT_CATEGORY":
      return { ...state, updateEquipmentCategoryStatus: "" };
    case "UPDATE_EQUIPMENT_CATEGORY_SUCCESS":
      return { ...state, updateEquipmentCategoryStatus: action.payload };
    case "UPDATE_EQUIPMENT_CATEGORY_ERROR":
      return { ...state, updateEquipmentCategoryStatus: "" };

    case "VIEW_EQUIPMENT_MASTER":
      return { ...state, viewEquipmentMasterData: "" };
    case "VIEW_EQUIPMENT_MASTER_SUCCESS":
      return { ...state, viewEquipmentMasterData: action.payload };
    case "VIEW_EQUIPMENT_MASTER_ERROR":
      return { ...state, viewEquipmentMasterData: "" };

    case "UPDATE_EQUIPMENT_MASTER":
      return { ...state, updateEquipmentMasterStatus: "" };
    case "UPDATE_EQUIPMENT_MASTER_SUCCESS":
      return { ...state, updateEquipmentMasterStatus: action.payload };
    case "UPDATE_EQUIPMENT_MASTER_ERROR":
      return { ...state, updateEquipmentMasterStatus: "" };

    case "ADD_COMPLAINT":
      return { ...state, saveComplaintStatus: "" };
    case "ADD_COMPLAINT_SUCCESS":
      return { ...state, saveComplaintStatus: action.payload };
    case "ADD_COMPLAINT_ERROR":
      return { ...state, saveComplaintStatus: "" };

    case "LIST_COMPLAINT":
      return { ...state, complaintList: '' };
    case "LIST_COMPLAINT_SUCCESS":
      return { ...state, complaintList: action.payload };
    case "LIST_COMPLAINT_ERROR":
      return { ...state, complaintList: '' };

    case "GET_EQUIPMENT_BY_NAME_UNIQUEID":
      return { ...state, equipmentListFiltered: '' };
    case "GET_EQUIPMENT_BY_NAME_UNIQUEID_SUCCESS":
      return { ...state, equipmentListFiltered: action.payload };
    case "GET_EQUIPMENT_BY_NAME_UNIQUEID_ERROR":
      return { ...state, equipmentListFiltered: '' };

    case "SAVE_EQUIPMENT_COMPLAINT_REQUEST":
      return { ...state, saveEquipmentComplaintStatus: "" };
    case "SAVE_EQUIPMENT_COMPLAINT_REQUEST_SUCCESS":
      return { ...state, saveEquipmentComplaintStatus: action.payload };
    case "SAVE_EQUIPMENT_COMPLAINT_REQUEST_ERROR":
      return { ...state, saveEquipmentComplaintStatus: "" };

    case "LIST_EQUIPMENT_COMPLAINT_REQUEST":
      return { ...state, equipmentComplinetList: '' };
    case "LIST_EQUIPMENT_COMPLAINT_REQUEST_SUCCESS":
      return { ...state, equipmentComplinetList: action.payload };
    case "LIST_EQUIPMENT_COMPLAINT_REQUEST_ERROR":
      return { ...state, equipmentComplinetList: '' };

    case "CHANGE_EQUIPMENT_COMPLAINT_REQUEST_STATUS":
      return { ...state, updatedrequestStatus: "" };
    case "CHANGE_EQUIPMENT_COMPLAINT_REQUEST_STATUS_SUCCESS":
      return { ...state, updatedrequestStatus: action.payload };
    case "CHANGE_EQUIPMENT_COMPLAINT_REQUEST_STATUS_ERROR":
      return { ...state, updatedrequestStatus: "" };


    case "IMPORT_LOCATION":
      return { ...state, importlocation: "" };
    case "IMPORT_LOCATION_SUCCESS":
      return { ...state, importlocation: action.payload };
    case "IMPORT_LOCATION_ERROR":
      return { ...state, importlocation: "" };

    case "IMPORT_COMPLAINT":
      return { ...state, importcomplaint: "" };
    case "IMPORT_COMPLAINT_SUCCESS":
      return { ...state, importcomplaint: action.payload };
    case "IMPORT_COMPLAINT_ERROR":
      return { ...state, importcomplaint: "" };


    case "IMPORT_EQUIPMENT_CATEGORY":
      return { ...state, importequimentcategory: "" };
    case "IMPORT_EQUIPMENT_CATEGORY_SUCCESS":
      return { ...state, importequimentcategory: action.payload };
    case "IMPORT_EQUIPMENT_CATEGORY_ERROR":
      return { ...state, importequimentcategory: "" };


    case "IMPORT_EQUIPMENT_MASTER":
      return { ...state, importequimentmaster: "" };
    case "IMPORT_EQUIPMENT_MASTER_SUCCESS":
      return { ...state, importequimentmaster: action.payload };
    case "IMPORT_EQUIPMENT_MASTER_ERROR":
      return { ...state, importequimentmaster: "" };


    case "GET_EQUIPMENT_DETAILS_BY_EQUIPMENT_ID":
      return { ...state, equipmentdetails: "" };
    case "GET_EQUIPMENT_DETAILS_BY_EQUIPMENT_ID_SUCCESS":
      return { ...state, equipmentdetails: action.payload };
    case "GET_EQUIPMENT_DETAILS_BY_EQUIPMENT_ID_ERROR":
      return { ...state, equipmentdetails: "" };


    case "COMPLAINT_LIST":
      return { ...state, complaintListstatus: "" };
    case "COMPLAINT_LIST_SUCCESS":
      return { ...state, complaintListstatus: action.payload };
    case "COMPLAINT_LIST_ERROR":
      return { ...state, complaintListstatus: "" };

    case "VIEW_EQUIPMENT_DETAILS_BY_BARCODE":
      return { ...state, viewequipmentdetails: "" };
    case "VIEW_EQUIPMENT_DETAILS_BY_BARCODE_SUCCESS":
      return { ...state, viewequipmentdetails: action.payload };
    case "VIEW_EQUIPMENT_DETAILS_BY_BARCODE_ERROR":
      return { ...state, viewequipmentdetails: "" };

    case 'LIST_COMPLAINT_BY_EQUIPMENT':
      return { ...state, equipmentcomplaintlist: '' }
    case 'LIST_COMPLAINT_BY_EQUIPMENT_SUCCESS':
      return { ...state, equipmentcomplaintlist: action.payload }
    case 'LIST_COMPLAINT_BY_EQUIPMENT_ERROR':
      return { ...state, equipmentcomplaintlist: '' }

    case 'EQUIPMENT_HISTORY':
      return { ...state, equipmenthistorylist: '' }
    case 'EQUIPMENT_HISTORY_SUCCESS':
      return { ...state, equipmenthistorylist: action.payload }
    case 'EQUIPMENT_HISTORY_ERROR':
      return { ...state, equipmenthistorylist: '' }

    case 'EQUIPMENT_CATEGORY_VIEW':
      return { ...state, equipmentCategoryView: '' }
    case 'EQUIPMENT_CATEGORY_VIEW_SUCCESS':
      return { ...state, equipmentCategoryView: action.payload }
    case 'EQUIPMENT_CATEGORY_VIEW_ERROR':
      return { ...state, equipmentCategoryView: '' }

    case 'CHECKEQUIPMENT_ACTIVITYEXIST':
      return { ...state, activityCheck: '' }
    case 'CHECKEQUIPMENT_ACTIVITYEXIST_SUCCESS':
      return { ...state, activityCheck: action.payload }
    case 'CHECKEQUIPMENT_ACTIVITYEXIST_ERROR':
      return { ...state, activityCheck: '' }

    case 'UPDATE_EQUIPMENTMASTER':
      return { ...state, updateequipment: '' }
    case 'UPDATE_EQUIPMENTMASTER_SUCCESS':
      return { ...state, updateequipment: action.payload }
    case 'UPDATE_EQUIPMENTMASTER_ERROR':
      return { ...state, updateequipment: '' }

    case 'FETCH_QCTEMPLATE':
      return { ...state, QcList: '' }
    case 'FETCH_QCTEMPLATE_SUCCESS':
      return { ...state, QcList: action.payload }
    case 'FETCH_QCTEMPLATE_ERROR':
      return { ...state, QcList: '' }

    case 'ATTACHQC_INSERVICE':
      return { ...state, QcattachmentView: '' }
    case 'ATTACHQC_INSERVICE_SUCCESS':
      return { ...state, QcattachmentView: action.payload }
    case 'ATTACHQC_INSERVICE_ERROR':
      return { ...state, QcattachmentView: '' }

    case 'SAVE_QC_DETAILS':
      return { ...state, qcsaveData: '' }
    case 'SAVE_QC_DETAILS_SUCCESS':
      return { ...state, qcsaveData: action.payload }
    case 'SAVE_QC_DETAILS_ERROR':
      return { ...state, qcsaveData: '' }

    case 'GET_TASK_QC_DETAILS':
      return { ...state, qctaskList: '' }
    case 'GET_TASK_QC_DETAILS_SUCCESS':
      return { ...state, qctaskList: action.payload }
    case 'GET_TASK_QC_DETAILS_ERROR':
      return { ...state, qctaskList: '' }

    case 'DELETE_QCMASTER':
      return { ...state, deleteqcmaster: '' }
    case 'DELETE_QCMASTER_SUCCESS':
      return { ...state, deleteqcmaster: action.payload }
    case 'DELETE_QCMASTER_ERROR':
      return { ...state, deleteqcmaster: '' }

    case 'GENERATE_BARCODE':
      return { ...state, barcodenumber: '' }
    case 'GENERATE_BARCODE_SUCCESS':
      return { ...state, barcodenumber: action.payload }
    case 'GENERATE_BARCODE_ERROR':
      return { ...state, barcodenumber: '' }

    case 'ACTIVE_INACTIVE':
      return{...state,activeinactivestatus:''}
    case 'ACTIVE_INACTIVE_SUCCESS':
      return{...state,activeinactivestatus:action.payload}
    case 'ACTIVE_INACTIVE_ERROR':
      return{...state,activeinactivestatus:''}

    case 'DELETE_EQUIPMENT_MASTER':
      return{...state,deleteStatus:''}
    case 'DELETE_EQUIPMENT_MASTER_SUCCESS':
      return{...state,deleteStatus:action.payload}
    case 'DELETE_EQUIPMENT_MASTER_ERROR':
      return{...state,deleteStatus:''}

    case 'CHECK_EQUIPMENT_ACTIVITY_LOG':
      console.log('payload');
      return{...state,activitylog:''}
    case 'CHECK_EQUIPMENT_ACTIVITY_LOG_SUCCESS':
      console.log('payload1');
      return{...state,activitylog:action.payload}
    case 'CHECK_EQUIPMENT_ACTIVITY_LOG_ERROR':
      return{...state,activitylog:''}


    default:
      return state;
  }
};

export default assetReducer;
