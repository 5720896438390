import axios from '@service/ApiClient'

class leaveService {
    constructor(callback) {
        this.callback = callback
    }

    async fetchLeaveType(payload) {
        return await axios.post('/HR/Leave/list_leave_type', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteLeave(payload) {
        return await axios.post('/HR/Leave/delete_leavetype', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateLeaveType(payload) {
        return await axios.post('/HR/Leave/update_leave_type_master', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async saveRule(payload) {
        return await axios.post('/HR/Leave/create_leave_type_rule', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async editRule(payload) {
        return await axios.post('/HR/Leave/update_leave_type_rule', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchMyLeaveRequest(payload) {
        return await axios.post('/HR/Leave/list_my_leave_request', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchLeaveTypeForRequest(payload) {
        return await axios.post('/HR/Leave/get_leave_type_master_for_request', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async saveLeaveRequest(payload) {
        return await axios.post('/HR/Leave/insert_leave_request', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async updateLeaveRequest(payload) {
        return await axios.post('/HR/Leave/updateleaverequest', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async cancelRequest(payload) {
        return await axios.post('/HR/Leave/cancel_leave', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchPmRequest(payload) {
        return await axios.post('/HR/Leaveapproval/view_request', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async approveRequest(payload) {
        return await axios.post('/HR/Leaveapproval/approve_leave_request', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async rejectRequest(payload) {
        return await axios.post('/HR/Leaveapproval/reject_leave_request', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async handleLeaveParameters(payload) {
        return await axios.post('/HR/Leave/check_leave_parameters', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async fetchLeaveDashboard(payload) {
        return await axios.post('/HR/Leave/employee_leave_dashboard', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async saveLeaveType(payload) {
        return await axios.post('/HR/Leave/add_leave_type_master', payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new leaveService();