//save model

export const AddModel = (payload) => {
    return {
         type: 'ADD_MODEL' ,
         payload
     };
 };
 export const  AddModelSuccess = (payload) => {
       return {
         type: 'ADD_MODEL_SUCCESS',
         payload
     };
 };
 export const  AddModelError = (payload) => {
     return {
         type: 'ADD_MODEL_ERROR',
         payload
     };
 };

 //list model

 export const FetchModel = (payload) => {

    return {
        type: 'FETCH_MODEL',
        payload

    };
};
export const   FetchModelSuccess = (payload) => {
    return {
        type: 'FETCH_MODEL_SUCCESS',
        payload
    };
};
export const   FetchModelError = (payload) => {
    return {
        type: 'FETCH_MODEL_ERROR',
        payload
    };
};

//update model
export const EditModel = (payload) =>{
    return {
        type: 'EDIT_MODEL' ,
        payload
    };
};
export const EditModelSuccess = (payload) =>{
    return {
        type: 'EDIT_MODEL_SUCCESS' ,
        payload
    };
};
export const EditModelError = (payload) =>{
    return {
        type: 'EDIT_MODEL_ERROR' ,
        payload
    };
};

//delete model

export const DeleteModel = (payload) =>{
    return {
        type: 'DELETE_MODEL' ,
        payload
    };
};
export const DeleteModelSuccess = (payload) =>{
    return {
        type: 'DELETE_MODEL_SUCCESS' ,
        payload
    };
};
export const DeleteModelError = (payload) =>{
    return {
        type: 'DELETE_MODEL_ERROR' ,
        payload
    };
};