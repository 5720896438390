import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import parameterService from '@service/admin/payrollService/salaryallowanceService';
import {
  fetchBuParameterSuccess, fetchBuParameterError,
  listSalaryParameterSuccess, listSalaryParameterError,
  saveSalaryParameterSuccess, saveSalaryParameterError,
  updateSalaryParameterSuccess, updateSalaryParameterError,
  fetchSalaryParameterSuccess, fetchSalaryParameterError,
} from './action';

function* fetchBuParameter({ payload }) {
  try {
    const data = yield call(parameterService.fetchBuParameter, payload);
    Array.isArray(data) ?
      yield put(fetchBuParameterSuccess(data)) :
      yield put(fetchBuParameterSuccess([]));
  } catch (error) {
    yield put(fetchBuParameterError(error));
  } finally {
  }
}

function* listSalaryParameter({ payload }) {
  try {
    const data = yield call(parameterService.listSalaryParameter, payload);
    Array.isArray(data) ?
      yield put(listSalaryParameterSuccess(data)) :
      yield put(listSalaryParameterSuccess([]));
  } catch (error) {
    yield put(listSalaryParameterError(error));
  } finally {
  }
}

function* saveSalaryParameter({ payload }) {
  try {
    const data = yield call(parameterService.saveSalaryParameter, payload);

    yield put(saveSalaryParameterSuccess(data));
  } catch (error) {
    yield put(saveSalaryParameterError(error));
  } finally {
  }
}

function* fetchSalaryParameter({ payload }) {
  try {
    const data = yield call(parameterService.fetchSalaryParameter, payload);

    yield put(fetchSalaryParameterSuccess(data));
  } catch (error) {
    yield put(fetchSalaryParameterError(error));
  } finally {
  }
}



export default function* rootSaga() {
  yield all([takeEvery(actionTypes.FETCH_BU_PARAMETERS, fetchBuParameter)]);
  yield all([takeEvery(actionTypes.LIST_SALARY_PARAMETER, listSalaryParameter)]);
  yield all([takeEvery(actionTypes.SAVE_SALARY_PARAMETER, saveSalaryParameter)]);
  yield all([takeEvery(actionTypes.FETCH_SALARY_PARAMETER, fetchSalaryParameter)]);
}