
export const AddSkillset = (payload) => {
    return {
        type: 'ADD_SKILLSET',
        payload
    };
};
export const AddSkillsetSuccess = (payload) => {
    return {
        type: 'ADD_SKILLSET_SUCCESS',
        payload
    };
};
export const AddSkillsetError = (payload) => {
    return {
        type: 'ADD_SKILLSET_ERROR',
        payload
    };
};


//list skillset

export const FetchSkillset = (payload) => {

    return {
        type: 'FETCH_SKILLSET',
        payload

    };
};
export const FetchSkillsetSuccess = (payload) => {

    return {
        type: 'FETCH_SKILLSET_SUCCESS',
        payload
    };
};
export const FetchSkillsetError = (payload) => {
    return {
        type: 'FETCH_SKILLSET_ERROR',
        payload
    };
};

export const UpdateSkillset = (payload) => {
    return {
        type: 'UPDATE_SKILLSET',
        payload
    };
};
export const UpdateSkillsetSuccess = (payload) => {
    return {
        type: 'UPDATE_SKILLSET_SUCCESS',
        payload
    };
};
export const UpdateSkillsetError = (payload) => {
    return {
        type: 'UPDATE_SKILLSET_ERROR',
        payload
    };
};

export const DeleteSkillset = (payload) => {
    return {
        type: 'DELETE_SKILLSET',
        payload
    };
};
export const DeleteSkillsetSuccess = (payload) => {
    return {
        type: 'DELETE_SKILLSET_SUCCESS',
        payload
    };
};
export const DeleteSkillsetError = (payload) => {
    return {
        type: 'DELETE_SKILLSET_ERROR',
        payload
    };
};

export const AddSkillsetRating = (payload) => {
    return {
        type: 'ADD_SKILLSET_RATING',
        payload
    };
};
export const AddSkillsetRatingSuccess = (payload) => {
    return {
        type: 'ADD_SKILLSET_RATING_SUCCESS',
        payload
    };
};
export const AddSkillsetRatingError = (payload) => {
    return {
        type: 'ADD_SKILLSET_RATING_ERROR',
        payload
    };
};

export const UpdateSkillsetRating = (payload) => {
    return {
        type: 'UPDATE_SKILLSET_RATING',
        payload
    };
};
export const UpdateSkillsetRatingSuccess = (payload) => {
    return {
        type: 'UPDATE_SKILLSET_RATING_SUCCESS',
        payload
    };
};
export const UpdateSkillsetRatingError = (payload) => {
    return {
        type: 'UPDATE_SKILLSET_RATING_ERROR',
        payload
    };
};