import axios from '@service/ApiClient'

class dealStageService {
    constructor(callback) {
        this.callback = callback
    }
    async listDealStage(payload) {
        return await axios.post('/Crm/Dealstage/list_dealstage', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
    async editDealStage(payload) {
        return await axios.post('/Crm/Dealstage/update_dealstage', payload
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new dealStageService();