import { takeEvery, put, call, all } from 'redux-saga/effects';
import manufactureservice from '@service/admin/masterService/manufactureservice';

import {
  AddManufactureSuccess, AddManufactureError,
  FetchManufactureSuccess, FetchManufactureError,
  EditManufactureSuccess, EditManufactureError,
  DeleteManufactureSuccess, DeleteManufactureError
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'


function* AddManufacture({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(manufactureservice.addManufacture, payload);
    yield put(AddManufactureSuccess(data));
  } catch (error) {
    yield put(AddManufactureError(error));
  } finally {
    yield put(stopLoader());
  }
}


function* FetchManufacture({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(manufactureservice.fetchManufacture, payload);
    Array.isArray(data) ?
      yield put(FetchManufactureSuccess(data)) :
      yield put(FetchManufactureError([]));
  } catch (error) {
    yield put(FetchManufactureError(error));
  } finally {
    yield put(stopLoader());
  }
}


function* EditManufacture({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(manufactureservice.editManufacture, payload);
    yield put(EditManufactureSuccess(data));
  } catch (error) {
    yield put(EditManufactureError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* DeleteManufacture({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(manufactureservice.deleteManufacture, payload);
    yield put(DeleteManufactureSuccess(data));
  } catch (error) {
    yield put(DeleteManufactureError(error));
  } finally {
    yield put(stopLoader());
  }
}


export default function* rootSaga() {
  yield all([takeEvery('ADD_MANUFACTURE', AddManufacture)]);
  yield all([takeEvery('FETCH_MANUFACTURE', FetchManufacture)]);
  yield all([takeEvery('EDIT_MANUFACTURE', EditManufacture)]);
  yield all([takeEvery('DELETE_MANUFACTURE', DeleteManufacture)]);

}