const initialState = {
    manufacturevalues: '',
    listmanufacturevalues: [],
    editstatus: '',
    deletestatus: ''

}

const manufactureReducer = (state = initialState, action) => {

    switch (action.type) {

        case 'ADD_MANUFACTURE':
            return { ...state, manufacturevalues: '' }
        case 'ADD_MANUFACTURE_SUCCESS':
            return { ...state, manufacturevalues: action.payload }
        case 'ADD_MANUFACTURE_ERROR':
            return { ...state, manufacturevalues: '' }


        case 'FETCH_MANUFACTURE':
            return { ...state, listmanufacturevalues: [] }
        case 'FETCH_MANUFACTURE_SUCCESS':
            return {
                ...state,
                listmanufacturevalues: action.payload,
                manufacturevalues: '',
                editstatus: '',
                deletestatus: ''
            }
        case 'FETCH_MANUFACTURE_ERROR':
            return { ...state, listmanufacturevalues: [] }



        case 'EDIT_MANUFACTURE':
            return { ...state, editstatus: '' }
        case 'EDIT_MANUFACTURE_SUCCESS':
            return { ...state, editstatus: 'SUCCESS' }
        case 'EDIT_MANUFACTURE_ERROR':
            return { ...state, editstatus: 'ERROR' }

        case 'DELETE_MANUFACTURE':
            return { ...state, deletestatus: '' }
        case 'DELETE_MANUFACTURE_SUCCESS':
            return { ...state, deletestatus: 'SUCCESS' }
        case 'DELETE_MANUFACTURE_ERROR':
            return { ...state, deletestatus: 'ERROR' }

        default:
            return state
    }
}
export default manufactureReducer