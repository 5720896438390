
export const actionTypes = {
    LIST_ITEM: 'LIST_ITEM',
    LIST_ITEM_SUCCESS: 'LIST_ITEM_SUCCESS',
    LIST_ITEM_ERROR: 'LIST_ITEM_ERROR',

    SAVE_PURCHASEREQUEST: 'SAVE_PURCHASEREQUEST',
    SAVE_PURCHASEREQUEST_SUCCESS: 'SAVE_PURCHASEREQUEST_SUCCESS',
    SAVE_PURCHASEREQUEST_ERROR: 'SAVE_PURCHASEREQUEST_ERROR',

    LIST_PURCHASEREQUEST: 'LIST_PURCHASEREQUEST',
    LIST_PURCHASEREQUEST_SUCCESS: 'LIST_PURCHASEREQUEST_SUCCESS',
    LIST_PURCHASEREQUEST_ERROR: 'LIST_PURCHASEREQUEST_ERROR',

    UPDATE_PURCHASEREQUEST: 'UPDATE_PURCHASEREQUEST',
    UPDATE_PURCHASEREQUEST_SUCCESS: 'UPDATE_PURCHASEREQUEST_SUCCESS',
    UPDATE_PURCHASEREQUEST_ERROR: 'UPDATE_PURCHASEREQUEST_ERROR',
    
    DELETE_PURCHASEREQUEST: 'DELETE_PURCHASEREQUEST',
    DELETE_PURCHASEREQUEST_SUCCESS: 'DELETE_PURCHASEREQUEST_SUCCESS',
    DELETE_PURCHASEREQUEST_ERROR: 'DELETE_PURCHASEREQUEST_ERROR',

    FETCH_PR_BY_ID: 'FETCH_PR_BY_ID',
    FETCH_PR_BY_ID_SUCCESS: 'FETCH_PR_BY_ID_SUCCESS',
    FETCH_PR_BY_ID_ERROR: 'FETCH_PR_BY_ID_ERROR',
};

export const listItem = (payload) => {
    console.log('ajksdhjksgdfgsedgfjsdgj');
    return {
        type: actionTypes.LIST_ITEM, payload
    };
};
export const listItemSuccess = (payload) => {
    return {
        type: actionTypes.LIST_ITEM_SUCCESS,
        payload
    };
};
export const listItemError = (payload) => {
    return {
        type: actionTypes.LIST_ITEM_ERROR,
        payload
    };
};

export const savePurchaseRequest = (payload) => {
    return {
        type: actionTypes.SAVE_PURCHASEREQUEST, payload
    };
};
export const savePurchaseRequestSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_PURCHASEREQUEST_SUCCESS,
        payload
    };
};
export const savePurchaseRequestError = (payload) => {
    return {
        type: actionTypes.SAVE_PURCHASEREQUEST_ERROR,
        payload
    };
};


export const listPurchaseRequest = (payload) => {
    return {
        type: actionTypes.LIST_PURCHASEREQUEST, payload
    };
};
export const listPurchaseRequestSuccess = (payload) => {
    return {
        type: actionTypes.LIST_PURCHASEREQUEST_SUCCESS,
        payload
    };
};
export const listPurchaseRequestError = (payload) => {
    return {
        type: actionTypes.LIST_PURCHASEREQUEST_ERROR,
        payload
    };
};

export const updatePurchaseRequest = (payload) => {
    return {
        type: actionTypes.UPDATE_PURCHASEREQUEST, payload
    };
};
export const updatePurchaseRequestSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_PURCHASEREQUEST_SUCCESS,
        payload
    };
};
export const updatePurchaseRequestError = (payload) => {
    return {
        type: actionTypes.UPDATE_PURCHASEREQUEST_ERROR,
        payload
    };
};

export const deletePurchaseRequest = (payload) => {
    return {
        type: actionTypes.DELETE_PURCHASEREQUEST, payload
    };
};
export const deletePurchaseRequestSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_PURCHASEREQUEST_SUCCESS,
        payload
    };
};

export const deletePurchaseRequestError = (payload) => {
    return {
        type: actionTypes.DELETE_PURCHASEREQUEST_ERROR,
        payload
    };
};

export const fetchPrById = (payload) => {
    return {
        type: actionTypes.FETCH_PR_BY_ID, payload
    };
};
export const fetchPrByIdSuccess = (payload) => {
    return {
        type: actionTypes.FETCH_PR_BY_ID_SUCCESS,
        payload
    };
};

export const fetchPrByIdError = (payload) => {
    return {
        type: actionTypes.FETCH_PR_BY_ID_ERROR,
        payload
    };
};