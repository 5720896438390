import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import qualificationDetailsService from '@service/admin/masterService/qualificationDetailsService';
import {
  saveQualificationDetailsSuccess, saveQualificationDetailsError,
  listQualificationDetailsSuccess, listQualificationDetailsError,
  updateQualificationDetailsSuccess, updateQualificationDetailsError,
  deleteQualificationDetailsSuccess, deleteQualificationDetailsError,
  listQualificationSuccess, listQualificationError
} from './action';

function* saveQualificationDetails({ payload }) {
  try {
    const data = yield call(qualificationDetailsService.saveQualificationDetails, payload);

    yield put(saveQualificationDetailsSuccess(data));
  } catch (error) {
    yield put(saveQualificationDetailsError(error));
  } finally {
  }
}

function* listQualificationDetails({ payload }) {
  try {
    const data = yield call(qualificationDetailsService.listQualificationDetails, payload);

    yield put(listQualificationDetailsSuccess(data));
  } catch (error) {
    yield put(listQualificationDetailsError(error));
  } finally {
  }
}

function* updateQualificationDetails({ payload }) {
  try {
    const data = yield call(qualificationDetailsService.updateQualificationDetails, payload);

    yield put(updateQualificationDetailsSuccess(data));
  } catch (error) {
    yield put(updateQualificationDetailsError(error));
  } finally {
  }
}
function* deleteQualificationDetails({ payload }) {
  try {
    const data = yield call(qualificationDetailsService.deleteQualificationDetails, payload);

    yield put(deleteQualificationDetailsSuccess(data));
  } catch (error) {
    yield put(deleteQualificationDetailsError(error));
  } finally {
  }
}

function* listQualification({ payload }) {
  try {
    const data = yield call(qualificationDetailsService.listQualification, payload);

    yield put(listQualificationSuccess(data));
  } catch (error) {
    yield put(listQualificationError(error));
  } finally {
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_QUALIFICATIONDETAILS, saveQualificationDetails)]);
  yield all([takeEvery(actionTypes.LIST_QUALIFICATIONDETAILS, listQualificationDetails)]);
  yield all([takeEvery(actionTypes.UPDATE_QUALIFICATIONDETAILS, updateQualificationDetails)]);
  yield all([takeEvery(actionTypes.DELETE_QUALIFICATIONDETAILS, deleteQualificationDetails)]);
  yield all([takeEvery(actionTypes.LIST_QUALIFICATION, listQualification)]);


}