
export const actionTypes = {
    SAVE_EXPENSE: 'SAVE_EXPENSE',
    SAVE_EXPENSE_SUCCESS: 'SAVE_EXPENSE_SUCCESS',
    SAVE_EXPENSE_ERROR: 'SAVE_EXPENSE_ERROR',
    LIST_EXPENSE:'LIST_EXPENSE',
    LIST_EXPENSE_SUCCESS:'LIST_EXPENSE_SUCCESS',
    LIST_EXPENSE_ERROR:'LIST_EXPENSE_ERROR',
    UPDATE_EXPENSE:'UPDATE_EXPENSE',
    UPDATE_EXPENSE_SUCCESS:'UPDATE_EXPENSE_SUCCESS',
    UPDATE_EXPENSE_ERROR:'UPDATE_EXPENSE_ERROR',
    DELETE_EXPENSE:'DELETE_EXPENSE',
    DELETE_EXPENSE_SUCCESS:'DELETE_EXPENSE_SUCCESS',
    DELETE_EXPENSE_ERROR:'DELETE_EXPENSE_ERROR',
    FETCH_TASK:'FETCH_TASK',
    FETCH_TASK_SUCCESS:'FETCH_TASK_SUCCESS',
    FETCH_TASK_ERROR:'FETCH_TASK_ERROR',
    SAVE_EXPENSEMODULE:'SAVE_EXPENSEMODULE',
    SAVE_EXPENSEMODULE_SUCCESS:'SAVE_EXPENSEMODULE_SUCCESS',
    SAVE_EXPENSEMODULE_ERROR:'SAVE_EXPENSEMODULE_ERROR',
    LIST_EXPENSEMODULE:'LIST_EXPENSEMODULE',
    LIST_EXPENSEMODULE_SUCCESS:'LIST_EXPENSEMODULE_SUCCESS',
    LIST_EXPENSEMODULE_ERROR:'LIST_EXPENSEMODULE_ERROR',
    DELETE_EXPENSEMODULE:'DELETE_EXPENSEMODULE',
    DELETE_EXPENSEMODULE_SUCCESS:'DELETE_EXPENSEMODULE_SUCCESS',
    DELETE_EXPENSEMODULE_ERROR:'DELETE_EXPENSEMODULE_ERROR',
    EDIT_EXPENSEMODULE:'EDIT_EXPENSEMODULE',
    EDIT_EXPENSEMODULE_SUCCESS:'EDIT_EXPENSEMODULE_SUCCESS',
    EDIT_EXPENSEMODULE_ERROR:'EDIT_EXPENSEMODULE_ERROR',
    UPDATE_EXPENSEMODULE:'UPDATE_EXPENSEMODULE',
    UPDATE_EXPENSEMODULE_SUCCESS:'UPDATE_EXPENSEMODULE_SUCCESS',
    UPDATE_EXPENSEMODULE_ERROR:'UPDATE_EXPENSEMODULE_ERROR',

  };
  
  
  export const saveExpensetype = (payload) => {
      return {
          type: actionTypes.SAVE_EXPENSE,payload
           };
  };
  export const saveExpensetypeSuccess = (payload) => {
      return {
          type:actionTypes.SAVE_EXPENSE_SUCCESS,
          payload
      };
  };
  export const saveExpensetypeError = (payload) => {
      return {
          type: actionTypes.SAVE_EXPENSE_ERROR,
          payload
      };
  };
  
  
  export const listExpensetype = (payload) => {
      return {
          type:actionTypes.LIST_EXPENSE,payload
         
      };
  };
  export const listExpensetypeSuccess = (payload) => {
      return {
          type: actionTypes.LIST_EXPENSE_SUCCESS,
          payload
      };
  };
  export const listExpensetypeError = (payload) => {
      return {
          type: actionTypes.LIST_EXPENSE_ERROR,
          payload
      };
  };
  
  export const UpdateExpensetype = (payload) => {
      return {
          type: actionTypes.UPDATE_EXPENSE,payload
             };
  };
  export const UpdateExpensetypeSuccess = (payload) => {
      return {
          type: actionTypes.UPDATE_EXPENSE_SUCCESS,
          payload
      };
  };
  export const UpdateExpensetypeError = (payload) => {
      return {
          type: actionTypes.UPDATE_EXPENSE_ERROR,
          payload
      };
  };
  
  export const DeleteExpensetype = (payload) => {
      return {
          type: actionTypes.DELETE_EXPENSE,payload
             };
  };
  export const DeleteExpensetypeSuccess = (payload) => {
      return {
          type: actionTypes.DELETE_EXPENSE_SUCCESS,
          payload
      };
  };
  export const DeleteExpensetypeError = (payload) => {
      return {
          type: actionTypes.DELETE_EXPENSE_ERROR,
          payload
      };
  };
  
  export const FetchTask = (payload) => {
      return {
          type: actionTypes.FETCH_TASK,payload
             };
  };
  export const FetchTaskSuccess = (payload) => {
      return {
          type: actionTypes.FETCH_TASK_SUCCESS,
          payload
      };
  };
  export const FetchTaskError = (payload) => {
      return {
          type: actionTypes.FETCH_TASK_ERROR,
          payload
      };
  };
  
  export const SaveExpenseModule = (payload) => {
      return {
          type: actionTypes.SaveExpenseModule,payload
             };
  };
  export const SaveExpenseModuleSuccess = (payload) => {
      return {
          type: actionTypes.SaveExpenseModule_SUCCESS,
          payload
      };
  };
  export const SaveExpenseModuleError = (payload) => {
      return {
          type: actionTypes.SaveExpenseModule_ERROR,
          payload
      };
  };
  
  
  export const ListExpenseModule = (payload) => {
      return {
          type: actionTypes.LIST_EXPENSEMODULE, payload
      };
  };
  export const ListExpenseModuleSuccess = (payload) => {
      return {
          type: actionTypes.LIST_EXPENSEMODULE_SUCCESS,
          payload
      };
  };
  export const ListExpenseModuleError = (payload) => {
      return {
          type: actionTypes.LIST_EXPENSEMODULE_ERROR,
          payload
      };
  };

  export const DeleteExpenseModule = (payload) => {
    return {
        type: actionTypes.DELETE_EXPENSEMODULE, payload
    };
};
export const DeleteExpenseModuleSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_EXPENSEMODULE_SUCCESS,
        payload
    };
};
export const DeleteExpenseModuleError = (payload) => {
    return {
        type: actionTypes.DELETE_EXPENSEMODULE_ERROR,
        payload
    };
};

export const EditExpenseModule = (payload) => {
    return {
        type: actionTypes.EDIT_EXPENSEMODULE, payload
    };
};
export const EditExpenseModuleSuccess = (payload) => {
    return {
        type: actionTypes.EDIT_EXPENSEMODULE_SUCCESS,
        payload
    };
};
export const EditExpenseModuleError = (payload) => {
    return {
        type: actionTypes.EDIT_EXPENSEMODULE_ERROR,
        payload
    };
};

export const UpdateExpenseModule = (payload) => {
    return {
        type: actionTypes.UPDATE_EXPENSEMODULE, payload
    };
};
export const UpdateExpenseModuleSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_EXPENSEMODULE_SUCCESS,
        payload
    };
};
export const UpdateExpenseModuleError = (payload) => {
    return {
        type: actionTypes.UPDATE_EXPENSEMODULE_ERROR,
        payload
    };
};