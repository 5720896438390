import axios from '../../ApiClient'
// import { siteUrl } from './config'

class departmentservice {
    constructor(callback) {
        this.callback = callback
    }

    async listDepartment(payload) {
        return await axios.post('/Organization/Department/list_department',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async saveDepartment(payload) {
        return await axios.post('/Organization/Department/save_department',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async deleteDepartment(payload) {
        return await axios.post('/Organization/Department/delete_department',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }

    async updateDepartment(payload) {
        return await axios.post('/Organization/Department/update_department',
            payload,
        ).then((response) => {
            return response.data
        }).catch((error) => ({ error: JSON.stringify(error) }));
    }
}

export default new departmentservice();