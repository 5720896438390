
export const actionTypes = {
    SAVE_BANKDETAILS: 'SAVE_BANKDETAILS',
    SAVE_BANKDETAILS_SUCCESS: 'SAVE_BANKDETAILS_SUCCESS',
    SAVE_BANKDETAILS_ERROR: 'SAVE_BANKDETAILS_ERROR',
    LIST_BANKDETAILS: 'LIST_BANKDETAILS',
    LIST_BANKDETAILS_SUCCESS: 'LIST_BANKDETAILS_SUCCESS',
    LIST_BANKDETAILS_ERROR: 'LIST_BANKDETAILS_ERROR',
    UPDATE_BANKDETAILS: 'UPDATE_BANKDETAILS',
    UPDATE_BANKDETAILS_SUCCESS: 'UPDATE_BANKDETAILS_SUCCESS',
    UPDATE_BANKDETAILS_ERROR: 'UPDATE_BANKDETAILS_ERROR',
    DELETE_BANKDETAILS: 'DELETE_BANKDETAILS',
    DELETE_BANKDETAILS_SUCCESS: 'DELETE_BANKDETAILS_SUCCESS',
    DELETE_BANKDETAILS_ERROR: 'DELETE_BANKDETAILS_ERROR'
};

export const saveBankDetails = (payload) => {
    return {
        type: actionTypes.SAVE_BANKDETAILS, payload
    };
};
export const saveBankDetailsSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_BANKDETAILS_SUCCESS,
        payload
    };
};
export const saveBankDetailsError = (payload) => {
    return {
        type: actionTypes.SAVE_BANKDETAILS_ERROR,
        payload
    };
};

export const listBankDetails = (payload) => {
    return {
        type: actionTypes.LIST_BANKDETAILS, payload
    };
};
export const listBankDetailsSuccess = (payload) => {
    return {
        type: actionTypes.LIST_BANKDETAILS_SUCCESS,
        payload
    };
};
export const listBankDetailsError = (payload) => {
    return {
        type: actionTypes.LIST_BANKDETAILS_ERROR,
        payload
    };
};

export const updateBankDetails = (payload) => {
    return {
        type: actionTypes.UPDATE_BANKDETAILS, payload
    };
};
export const updateBankDetailsSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_BANKDETAILS_SUCCESS,
        payload
    };
};
export const updateBankDetailsError = (payload) => {
    return {
        type: actionTypes.UPDATE_BANKDETAILS_ERROR,
        payload
    };
};

export const deleteBankDetails = (payload) => {
    return {
        type: actionTypes.DELETE_BANKDETAILS, payload
    };
};
export const deleteBankDetailsSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_BANKDETAILS_SUCCESS,
        payload
    };
};
export const deleteBankDetailsError = (payload) => {
    return {
        type: actionTypes.DELETE_BANKDETAILS_ERROR,
        payload
    };
};