import { takeEvery, put, call, all } from 'redux-saga/effects';
import skillsetservice from '@service/admin/skillsetService/skillsetservice'
import {
  AddSkillsetSuccess, AddSkillsetError,
  FetchSkillsetSuccess, FetchSkillsetError,
  UpdateSkillsetSuccess, UpdateSkillsetError,
  DeleteSkillsetSuccess, DeleteSkillsetError, AddSkillsetRatingSuccess, AddSkillsetRatingError, UpdateSkillsetRatingSuccess, UpdateSkillsetRatingError
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'


function* AddSkillset({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(skillsetservice.addSkillset, payload);
    yield put(AddSkillsetSuccess(data));
  } catch (error) {
    yield put(AddSkillsetError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* UpdateSkillset({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(skillsetservice.updateSkillset, payload);
    yield put(UpdateSkillsetSuccess(data));
  } catch (error) {
    yield put(UpdateSkillsetError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* DeleteSkillset({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(skillsetservice.deleteSkillset, payload);
    yield put(DeleteSkillsetSuccess(data));
  } catch (error) {
    yield put(DeleteSkillsetError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* AddSkillsetRating({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(skillsetservice.AddSkillsetRating, payload);
    yield put(AddSkillsetRatingSuccess(data));
  } catch (error) {
    yield put(AddSkillsetRatingError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* UpdateSkillsetRating({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(skillsetservice.UpdateSkillsetRating, payload);
    yield put(UpdateSkillsetRatingSuccess(data));
  } catch (error) {
    yield put(UpdateSkillsetRatingError(error));
  } finally {
    yield put(stopLoader());
  }
}


function* FetchSkillset({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(skillsetservice.fetchSkillset, payload);
    Array.isArray(data) ?
      yield put(FetchSkillsetSuccess(data)) :
      yield put(FetchSkillsetError([]));
  } catch (error) {
    yield put(FetchSkillsetError(error));
  } finally {
    yield put(stopLoader());
  }
}

export default function* rootSaga() {
  yield all([takeEvery('ADD_SKILLSET', AddSkillset)]);
  yield all([takeEvery('FETCH_SKILLSET', FetchSkillset)]);
  yield all([takeEvery('UPDATE_SKILLSET', UpdateSkillset)]);
  yield all([takeEvery('DELETE_SKILLSET', DeleteSkillset)]);
  yield all([takeEvery('ADD_SKILLSET_RATING', AddSkillsetRating)]);
  yield all([takeEvery('UPDATE_SKILLSET_RATING', UpdateSkillsetRating)]);
}