import { takeEvery, put, call, all } from 'redux-saga/effects';
import { actionTypes } from './action'
import unitMasterService from '@service/admin/masterService/unitMasterService';
import {
  saveUnitSuccess, saveUnitError,
  listUnitSuccess, listUnitError,
  updateUnitSuccess, updateUnitError,
  deleteUnitSuccess, deleteUnitError
} from './action';
import { startLoader, stopLoader } from '@store/common/loader/action'

function* saveUnit({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(unitMasterService.saveUnit, payload);
    yield put(saveUnitSuccess(data));
  } catch (error) {
    yield put(saveUnitError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listUnit({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(unitMasterService.listUnit, payload);
    Array.isArray(data) ?
      yield put(listUnitSuccess(data)) :
      yield put(listUnitError([]));
  } catch (error) {
    yield put(listUnitError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* updateUnit({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(unitMasterService.updateUnit, payload);
    yield put(updateUnitSuccess(data))
  } catch (error) {
    yield put(updateUnitError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* deleteUnit({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(unitMasterService.deleteUnit, payload);
    yield put(deleteUnitSuccess(data))
  } catch (error) {
    yield put(deleteUnitError(error));
  } finally {
    yield put(stopLoader());
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actionTypes.SAVE_UNIT, saveUnit)]);
  yield all([takeEvery(actionTypes.LIST_UNIT, listUnit)]);
  yield all([takeEvery(actionTypes.UPDATE_UNIT, updateUnit)]);
  yield all([takeEvery(actionTypes.DELETE_UNIT, deleteUnit)]);
}