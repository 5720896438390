
export const actionTypes = {
    SAVE_INDUSTRY: 'SAVE_INDUSTRY',
    SAVE_INDUSTRY_SUCCESS: 'SAVE_INDUSTRY_SUCCESS',
    SAVE_INDUSTRY_ERROR: 'SAVE_INDUSTRY_ERROR',
    LIST_INDUSTRY: 'LIST_INDUSTRY',
    LIST_INDUSTRY_SUCCESS: 'LIST_INDUSTRY_SUCCESS',
    LIST_INDUSTRY_ERROR: 'LIST_INDUSTRY_ERROR',
    DELETE_INDUSTRY: 'DELETE_INDUSTRY',
    DELETE_INDUSTRY_SUCCESS: 'DELETE_INDUSTRY_SUCCESS',
    DELETE_INDUSTRY_ERROR: 'DELETE_INDUSTRY_ERROR',
    EDIT_INDUSTRY: 'EDIT_INDUSTRY',
    EDIT_INDUSTRY_SUCCESS: 'EDIT_INDUSTRY_SUCCESS',
    EDIT_INDUSTRY_ERROR: 'EDIT_INDUSTRY_ERROR'
};

export const saveIndustry = (payload) => {
    return {
        type: actionTypes.SAVE_INDUSTRY, payload
    };
};
export const saveIndustrySuccess = (payload) => {
    return {
        type: actionTypes.SAVE_INDUSTRY_SUCCESS,
        payload
    };
};
export const saveIndustryError = (payload) => {
    return {
        type: actionTypes.SAVE_INDUSTRY_ERROR,
        payload
    };
};

export const listIndustry = (payload) => {
    return {
        type: actionTypes.LIST_INDUSTRY,
        payload
    };
};
export const listIndustrySuccess = (payload) => {
    return {
        type: actionTypes.LIST_INDUSTRY_SUCCESS,
        payload
    };
};
export const listIndustryError = (payload) => {
    return {
        type: actionTypes.LIST_INDUSTRY_ERROR,
        payload
    };
};
export const deleteIndustry = (payload) => {
    return {
        type: actionTypes.DELETE_INDUSTRY,
        payload
    };
};
export const deleteIndustrySuccess = (payload) => {
    return {
        type: actionTypes.DELETE_INDUSTRY_SUCCESS,
        payload
    };
};
export const deleteIndustryError = (payload) => {
    return {
        type: actionTypes.DELETE_INDUSTRY_ERROR,
        payload
    };
};

export const editIndustry = (payload) => {
    return {
        type: actionTypes.EDIT_INDUSTRY,
        payload
    };
};
export const editIndustrySuccess = (payload) => {
    return {
        type: actionTypes.EDIT_INDUSTRY_SUCCESS,
        payload
    };
};
export const editIndustryError = (payload) => {
    return {
        type: actionTypes.EDIT_INDUSTRY_ERROR,
        payload
    };
};
