export const actionTypes = {

    FETCH_COUNTRY_DETAILS: 'FETCH_COUNTRY_DETAILS',
    FETCH_COUNTRY_DETAILS_SUCCESS: 'FETCH_COUNTRY_DETAILS_SUCCESS',
    FETCH_COUNTRY_DETAILS_ERROR: 'FETCH_COUNTRY_DETAILS_ERROR',

    FETCH_REGIONAL_LEVEL_DATA: 'FETCH_REGIONAL_LEVEL_DATA',
    FETCH_REGIONAL_LEVEL_DATA_SUCCESS: 'FETCH_REGIONAL_LEVEL_DATA_SUCCESS',
    FETCH_REGIONAL_LEVEL_DATA_ERROR: 'FETCH_REGIONAL_LEVEL_DATA_ERROR',

    FETCH_REGIONAL_LEVEL_BY_COUNTRY_ID: 'FETCH_REGIONAL_LEVEL_BY_COUNTRY_ID',
    FETCH_REGIONAL_LEVEL_BY_COUNTRY_ID_SUCCESS: 'FETCH_REGIONAL_LEVEL_BY_COUNTRY_ID_SUCCESS',
    FETCH_REGIONAL_LEVEL_BY_COUNTRY_ID_ERROR: 'FETCH_REGIONAL_LEVEL_BY_COUNTRY_ID_ERROR',

};

export function fetchCountryDetails(payload) {
    return { type: actionTypes.FETCH_COUNTRY_DETAILS, payload };
}

export function fetchCountryDetailsSuccess(data) {
    return { type: actionTypes.FETCH_COUNTRY_DETAILS_SUCCESS, data };
}

export function fetchCountryDetailsError(error) {
    return { type: actionTypes.FETCH_COUNTRY_DETAILS_ERROR, error };
}


export function fetchRegionalLevelData(payload) {
    return { type: actionTypes.FETCH_REGIONAL_LEVEL_DATA, payload };
}

export function fetchRegionalLevelDataSuccess(data) {
    return { type: actionTypes.FETCH_REGIONAL_LEVEL_DATA_SUCCESS, data };
}

export function fetchRegionalLevelDataError(error) {
    return { type: actionTypes.FETCH_REGIONAL_LEVEL_DATA_ERROR, error };
}


export function fetchRegionalLevelsByCountryId(payload) {
    return { type: actionTypes.FETCH_REGIONAL_LEVEL_BY_COUNTRY_ID, payload };
}

export function fetchRegionalLevelsByCountryIdSuccess(data) {
    return { type: actionTypes.FETCH_REGIONAL_LEVEL_BY_COUNTRY_ID_SUCCESS, data };
}

export function fetchRegionalLevelsByCountryIdError(error) {
    return { type: actionTypes.FETCH_REGIONAL_LEVEL_BY_COUNTRY_ID_ERROR, error };
}

