
export const actionTypes = {
  LIST_ORGANISATION: 'LIST_ORGANISATION',
  LIST_ORGANISATION_SUCCESS: 'LIST_ORGANISATION_SUCCESS',
  LIST_ORGANISATION_ERROR: 'LIST_ORGANISATION_ERROR',

  DELETE_ORGANISATION: 'DELETE_ORGANISATION',
  DELETE_ORGANISATION_SUCCESS: 'DELETE_ORGANISATION_SUCCESS',
  DELETE_ORGANISATION_ERROR: 'DELETE_ORGANISATION_ERROR',

  SAVE_COMPANY: 'SAVE_COMPANY',
  SAVE_COMPANY_SUCCESS: 'SAVE_COMPANY_SUCCESS',
  SAVE_COMPANY_ERROR: 'SAVE_COMPANY_ERROR',

  UPDATE_COMPANY: 'UPDATE_COMPANY',
  UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_ERROR: 'UPDATE_COMPANY_ERROR',
};

export const listOrganization = (payload) => {
    return {
        type: actionTypes.LIST_ORGANISATION,
        payload };
};
export const listOrganizationSuccess = (payload) => {
    return {
        type: actionTypes.LIST_ORGANISATION_SUCCESS,
        payload
    };
};
export const listOrganizationError = (payload) => {
    return {
        type: actionTypes.LIST_ORGANISATION_ERROR,
        payload
    };
};

export const deleteCompany = (payload) => {

    return {
        type: actionTypes.DELETE_ORGANISATION,
        payload };
};
export const deleteCompanySuccess = (payload) => {
    return {
        type: actionTypes.DELETE_ORGANISATION_SUCCESS,
        payload
    };
};
export const deleteCompanyError = (payload) => {
    return {
        type: actionTypes.DELETE_ORGANISATION_ERROR,
        payload
    };
};

export const saveCompany = (payload) => {

    return {
        type: actionTypes.SAVE_COMPANY,
        payload };
};
export const saveCompanySuccess = (payload) => {
    return {
        type: actionTypes.SAVE_COMPANY_SUCCESS,
        payload
    };
};
export const saveCompanyError = (payload) => {
    return {
        type: actionTypes.SAVE_COMPANY_ERROR,
        payload
    };
};

export const updateCompany = (payload) => {

    return {
        type: actionTypes.UPDATE_COMPANY,
        payload };
};
export const updateCompanySuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_COMPANY_SUCCESS,
        payload
    };
};
export const updateCompanyError = (payload) => {
    return {
        type: actionTypes.UPDATE_COMPANY_ERROR,
        payload
    };
};