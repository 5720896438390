import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  changeEquipmentComplaintRequestStatusError,
  changeEquipmentComplaintRequestStatusSucces,
  actionTypes,
  addComplaintError,
  addComplaintSucces,
  addEquipmentCategoryError,
  addEquipmentCategorySucces,
  deleteEquipmentMasterError,
  deleteEquipmentMasterSucces,
  getEquipmentByNameOrUniqueidError,
  getEquipmentByNameOrUniqueidSucces,
  listComplaintError,
  listComplaintSucces,
  listEquipmentComplaintRequestError,
  listEquipmentComplaintRequestSucces,
  listEquipmentMasterError,
  listEquipmentMasterSucces,
  saveEquipmentComplaintRequestError,
  saveEquipmentComplaintRequestSucces,
  updateEquipmentCategoryError,
  updateEquipmentCategorySucces,
  updateEquipmentMasterError,
  updateEquipmentMasterSucces,
  viewEquipmentCategoryError,
  viewEquipmentCategorySucces,
  viewEquipmentMasterError,
  viewEquipmentMasterSucces,
  deleteLocationNameSucces,
  deleteLocationNameError,
  updateLocationNameSucces,
  updateLocationNameError,
  getDashboardDataSucces,
  getDashboardDataError,
  getTopAssetSucces,
  getTopAssetError,
  businessruleSucces,
  businessruleError,
  GenerateBarcodeSuccess,
  GenerateBarcodeError,
  ActiveInactiveSuccess,
  ActiveInactiveError
} from "./action";
import assetService from "../../../service/admin/masterService/assetService";
import {
  addbuildingsSucces,
  addbuildingsError,
  listbuildingsSucces,
  listbuildingsError,
  listFloorsSucces,
  listFloorsError,
  saveFloorSucces,
  saveFloorError,
  saveLocTypeSucces,
  saveLocTypeError,
  listLocTypeSucces,
  listLocTypeError,
  saveLocationNameSucces,
  saveLocationNameError,
  listLocationNameSucces,
  listLocationNameError,
  listLocationBuldingFloorSucces,
  listLocationBuldingFloorError,
  addAssetToLocationSucces,
  addAssetToLocationError,
  listEquipmentCategorySucces,
  listEquipmentCategoryError,
  getLocationByCatidOrFlooridSucces,
  getLocationByCatidOrFlooridError,
  saveEquipmentMasterSucces,
  saveEquipmentMasterError,
  deleteEquipmentCategorySucces,
  deleteEquipmentCategoryError,
  importlocationSucces, importlocationError,
  importcomplaintSucces, importcomplaintError,
  importequipmentcategorySucces, importequipmentcategoryError,
  importequipmentmasterSucces, importequipmentmasterError,
  getEquipmentDetailsByEquipmentIdSucces, getEquipmentDetailsByEquipmentIdError,
  complaintlistSucces, complaintlistError,
  viewEquipmentDetailsByBarcodeSucces, viewEquipmentDetailsByBarcodeError,
  listComplaintbyEquipmentSuccess, listComplaintbyEquipmentError,
  EquipmentHistorySuccess, EquipmentHistoryError,
  EquipmentCategoryViewSuccess, EquipmentCategoryViewError,
  CheckEquipment_ActivityExistSuccess, CheckEquipment_ActivityExistError,
  updateEquipmentmasterSuccess, updateEquipmentmasterError,
  fecthQctemplateSuccess, fecthQctemplateError,
  attachQc_inServiceSuccess, attachQc_inServiceError,
  save_qcDetailsSuccess, save_qcDetailsError,
  get_task_qc_detailsSuccess, get_task_qc_detailsError,
  deleteQcMasterSuccess, deleteQcMasterError,
  DeleteEquipmentMasterSuccess,DeleteEquipmentMasterError,
  CheckEquipmentActivityExistSuccess,CheckEquipmentActivityExistError
} from "./action";
import { stopLoader, startLoader } from "@store/common/loader/action";

function* addBuildings({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.addBuildings, payload);
    yield put(addbuildingsSucces(data));
  } catch (error) {
    yield put(addbuildingsError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* listbuildings({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.listbuildings, payload);
    if (data.error) {
      yield put(listbuildingsError([]));
    } else {
      yield put(listbuildingsSucces(data));
    }
  } catch (error) {
    yield put(listbuildingsError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* listFloors({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.listFloors, payload);
    if (data.error) {
      yield put(listFloorsError([]));
    } else {
      yield put(listFloorsSucces(data));
    }
  } catch (error) {
    yield put(listFloorsError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* saveFloor({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.saveFloor, payload);
    yield put(saveFloorSucces(data));
  } catch (error) {
    yield put(saveFloorError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* listLocType({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.listLocType, payload);
    yield put(listLocTypeSucces(data));
  } catch (error) {
    yield put(listLocTypeError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* getDashboardData({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.getDashboardData, payload);
    yield put(getDashboardDataSucces(data));
  } catch (error) {
    yield put(getDashboardDataError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* getTopAsset({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.getTopAsset, payload);
    yield put(getTopAssetSucces(data));
  } catch (error) {
    yield put(getTopAssetError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* businessrule({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.businessrule, payload);
    yield put(businessruleSucces(data));
  } catch (error) {
    yield put(businessruleError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}
function* saveLocType({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.saveLocType, payload);
    yield put(saveLocTypeSucces(data));
  } catch (error) {
    yield put(saveLocTypeError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* saveLocationName({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.saveLocationName, payload);
    // const data = yield call(assetService.saveLocationName, payload);
    yield put(saveLocationNameSucces(data));
  } catch (error) {
    yield put(saveLocationNameError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listLocationName({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.listLocationName, payload);
    // const data = yield call(assetService.listLocationName, payload);
    yield put(listLocationNameSucces(data));
  } catch (error) {
    yield put(listLocationNameError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* deleteLocationName({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.deleteLocationName, payload);
    yield put(deleteLocationNameSucces(data));
  } catch (error) {
    yield put(deleteLocationNameError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* updateLocationName({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.updateLocationName, payload);
    yield put(updateLocationNameSucces(data));
  } catch (error) {
    yield put(updateLocationNameError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listLocationBuldingFloor({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.listLocationBuldingFloor, payload);
    // const data = yield call(assetService.listLocationBuldingFloor, payload);
    yield put(listLocationBuldingFloorSucces(data));
  } catch (error) {
    yield put(listLocationBuldingFloorError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* addAssetToLocation({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.addAssetToLocation, payload);
    // const data = yield call(assetService.addAssetToLocation, payload);
    yield put(addAssetToLocationSucces(data));
  } catch (error) {
    yield put(addAssetToLocationError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* addEquipmentCategory({ payload }) {
  console.log("payload", payload);
  try {
    yield put(startLoader());
    const data = yield call(assetService.addEquipmentCategory, payload);
    yield put(addEquipmentCategorySucces(data));
  } catch (error) {
    yield put(addEquipmentCategoryError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listEquipmentCategory({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.listEquipmentCategory, payload);
    yield put(listEquipmentCategorySucces(data));
  } catch (error) {
    yield put(listEquipmentCategoryError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* getLocationByCatidOrFloorid({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.getLocationByCatidOrFloorid, payload);
    yield put(getLocationByCatidOrFlooridSucces(data));
  } catch (error) {
    yield put(getLocationByCatidOrFlooridError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* saveEquipmentMaster({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.saveEquipmentMaster, payload);
    yield put(saveEquipmentMasterSucces(data));
  } catch (error) {
    yield put(saveEquipmentMasterError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* deleteEquipmentCategory({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.deleteEquipmentCategory, payload);
    yield put(deleteEquipmentCategorySucces(data));
  } catch (error) {
    yield put(deleteEquipmentCategoryError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listEquipmentMaster({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.listEquipmentMaster, payload);
    yield put(listEquipmentMasterSucces(data));
  } catch (error) {
    yield put(listEquipmentMasterError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* deleteEquipmentMaster({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.deleteEquipmentMaster, payload);
    yield put(deleteEquipmentMasterSucces(data));
  } catch (error) {
    yield put(deleteEquipmentMasterError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* viewEquipmentCategory({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.viewEquipmentCategory, payload);
    yield put(viewEquipmentCategorySucces(data));
  } catch (error) {
    yield put(viewEquipmentCategoryError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* updateEquipmentCategory({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.updateEquipmentCategory, payload);
    yield put(updateEquipmentCategorySucces(data));
  } catch (error) {
    yield put(updateEquipmentCategoryError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* viewEquipmentMaster({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.viewEquipmentMaster, payload);
    yield put(viewEquipmentMasterSucces(data));
  } catch (error) {
    yield put(viewEquipmentMasterError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* updateEquipmentMaster({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.updateEquipmentMaster, payload);
    yield put(updateEquipmentMasterSucces(data));
  } catch (error) {
    yield put(updateEquipmentMasterError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* addComplaint({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.addComplaint, payload);
    yield put(addComplaintSucces(data));
  } catch (error) {
    yield put(addComplaintError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listComplaint({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.listComplaint, payload);
    yield put(listComplaintSucces(data));
  } catch (error) {
    yield put(listComplaintError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* getEquipmentByNameOrUniqueid({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.getEquipmentByNameOrUniqueid, payload);
    yield put(getEquipmentByNameOrUniqueidSucces(data));
  } catch (error) {
    yield put(getEquipmentByNameOrUniqueidError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* saveEquipmentComplaintRequest({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.saveEquipmentComplaintRequest, payload);
    yield put(saveEquipmentComplaintRequestSucces(data));
  } catch (error) {
    yield put(saveEquipmentComplaintRequestError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listEquipmentComplaintRequest({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.listEquipmentComplaintRequest, payload);
    yield put(listEquipmentComplaintRequestSucces(data));
  } catch (error) {
    yield put(listEquipmentComplaintRequestError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* changeEquipmentComplaintRequestStatus({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.changeEquipmentComplaintRequestStatus, payload);
    yield put(changeEquipmentComplaintRequestStatusSucces(data));
  } catch (error) {
    yield put(changeEquipmentComplaintRequestStatusError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* importlocationStatus({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.importlocationStatus, payload);
    yield put(importlocationSucces(data));
  } catch (error) {
    yield put(importlocationError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* importcomplaint({ payload }) {
  try {
    console.log("payload", payload);
    yield put(startLoader());
    const data = yield call(assetService.importcomplaint, payload);
    yield put(importcomplaintSucces(data));
  } catch (error) {
    yield put(importcomplaintError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* importequipmentcategory({ payload }) {
  console.log('payload importequipmentcategory', payload);
  try {
    yield put(startLoader());
    const data = yield call(assetService.importequipmentcategory, payload);
    yield put(importequipmentcategorySucces(data));
    console.log('data importequipmentcategory', data);

  } catch (error) {
    yield put(importequipmentcategoryError(error));
  } finally {
    yield put(stopLoader());
  }
}
function* importequipmentmaster({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.importequipmentmaster, payload);
    yield put(importequipmentmasterSucces(data));
  } catch (error) {
    yield put(importequipmentmasterError(error));
  } finally {
    yield put(stopLoader());
  }
}
function* getEquipmentDetailsByEquipmentId({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.equipmentmasterdetails, payload);
    yield put(getEquipmentDetailsByEquipmentIdSucces(data));
  } catch (error) {
    yield put(getEquipmentDetailsByEquipmentIdError(error));
  } finally {
    yield put(stopLoader());
  }
}


function* viewEquipmentDetailsByBarcode({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.viewEquipmentDetailsByBarcode, payload);
    yield put(viewEquipmentDetailsByBarcodeSucces(data));
  } catch (error) {
    yield put(viewEquipmentDetailsByBarcodeError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* complaintlist({ payload }) {
  // console.log("payloadsaga",payload);
  try {
    yield put(startLoader());
    const data = yield call(assetService.complaintlist, payload);
    yield put(complaintlistSucces(data));
  } catch (error) {
    yield put(complaintlistError(error));
  } finally {
    yield put(stopLoader());
  }
}

function* listComplaintbyEquipment({ payload }) {
  try {
    const data = yield call(assetService.listComplaintbyEquipment, payload);
    yield put(listComplaintbyEquipmentSuccess(data));
  } catch (error) {
    yield put(listComplaintbyEquipmentError(error));
  }finally {
    yield put(stopLoader());
  }
}

function* EquipmentHistory({ payload }) {
  try {
    const data = yield call(assetService.EquipmentHistory, payload);
    if (!data.error && data.length) {
      let activity_log = []
      data.forEach(element => {
        let parsed_data = JSON.parse(element.activity_log)
       
        activity_log = [...activity_log, ...parsed_data]
      });
      yield put(EquipmentHistorySuccess(activity_log));
    }
  } catch (error) {
    yield put(EquipmentHistoryError(error));
  }
}

function* EquipmentCategoryView({ payload }) {
  try {
    const data = yield call(assetService.EquipmentCategoryView, payload);
    yield put(EquipmentCategoryViewSuccess(data));
  } catch (error) {
    yield put(EquipmentCategoryViewError(error));
  }
}

function* CheckEquipment_ActivityExist({ payload }) {
  try {
    const data = yield call(assetService.CheckEquipment_ActivityExist, payload);
    yield put(CheckEquipment_ActivityExistSuccess(data));
  } catch (error) {
    yield put(CheckEquipment_ActivityExistError(error));
  }
}

function* updateEquipmentmaster({ payload }) {
  try {
    const data = yield call(assetService.updateEquipmentmaster, payload);
    yield put(updateEquipmentmasterSuccess(data));
  } catch (error) {
    yield put(updateEquipmentmasterError(error));
  }
}

function* fecthQctemplate({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.fecthQctemplate, payload);
    yield put(fecthQctemplateSuccess(data));
  } catch (error) {
    yield put(fecthQctemplateError(error));
  }finally {
    yield put(stopLoader());
  }
}

function* attachQc_inService({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.attachQc_inService, payload);
    yield put(attachQc_inServiceSuccess(data));
  } catch (error) {
    yield put(attachQc_inServiceError(error));
  }finally {
    yield put(stopLoader());
  }
}

function* save_qcDetails({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.save_qcDetails, payload);
    yield put(save_qcDetailsSuccess(data));
    console.log('data----------', data);
  } catch (error) {
    yield put(save_qcDetailsError(error));
  }finally {
    yield put(stopLoader());
  }
}

function* get_task_qc_details({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.get_task_qc_details, payload);
    yield put(get_task_qc_detailsSuccess(data));
  } catch (error) {
    yield put(get_task_qc_detailsError(error));
  }finally {
    yield put(stopLoader());
  }
}

function* deleteqcmaster({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.deleteqcmaster, payload);
    yield put(deleteQcMasterSuccess(data));
  } catch (error) {
    yield put(deleteQcMasterError(error));
  }finally {
    yield put(stopLoader());
  }
}

function* GenerateBarcode({ payload }) {
  try {
    const data = yield call(assetService.GenerateBarcode, payload);
    yield put(GenerateBarcodeSuccess(data));
  } catch (error) {
    yield put(GenerateBarcodeError(error));
  }
}

function* ActiveInactive({ payload }) {
  try {
    const data = yield call(assetService.ActiveInactive,payload);
    yield put (ActiveInactiveSuccess(data));
  } catch (error){
    yield put (ActiveInactiveError(error));
  }
}

function* DeleteEquipmentMaster({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(assetService.DeleteEquipmentMaster,payload);
    yield put (DeleteEquipmentMasterSuccess(data));
  } catch (error){
    yield put (DeleteEquipmentMasterError(error));
  }finally {
    yield put(stopLoader());
  }
}

function* CheckEquipmentActivityExist({ payload }) {
  try {
    const data = yield call(assetService.CheckEquipmentActivityExist,payload);
    console.log('data',data);
    yield put (CheckEquipmentActivityExistSuccess(data));
  } catch (error){
    yield put (CheckEquipmentActivityExistError(error));
  }
}



export default function* rootSaga() {
  yield all([takeEvery(actionTypes.ADD_BUILDINGS, addBuildings)]);
  yield all([takeEvery(actionTypes.LIST_BUILDINGS, listbuildings)]);
  yield all([takeEvery(actionTypes.LIST_FLOORS, listFloors)]);
  yield all([takeEvery(actionTypes.SAVE_FLOOR, saveFloor)]);
  yield all([takeEvery(actionTypes.LIST_LOC_TYPE, listLocType)]);
  yield all([takeEvery(actionTypes.SAVE_LOC_TYPE, saveLocType)]);
  yield all([takeEvery(actionTypes.SAVE_LOCATION_NAME, saveLocationName)]);
  yield all([takeEvery(actionTypes.LIST_LOCATION_NAME, listLocationName)]);
  yield all([takeEvery('DELETE_LOCATION_NAME', deleteLocationName)]);
  yield all([takeEvery('UPDATE_LOCATION_NAME', updateLocationName)]);
  yield all([takeEvery(actionTypes.LIST_LOCATION_BUILDING_FLOOR, listLocationBuldingFloor)]);
  yield all([takeEvery(actionTypes.ADD_ASSET_TO_LOCATION, addAssetToLocation)]);
  yield all([takeEvery(actionTypes.GET_DASHBOARD_DATA, getDashboardData)]);
  yield all([takeEvery(actionTypes.GET_TOP_ASSET, getTopAsset)]);
  yield all([takeEvery(actionTypes.BUSINESS_RULE, businessrule)]);
  yield all([takeEvery("ADD_EQUIPMENT_CATEGORY", addEquipmentCategory)]);
  yield all([takeEvery("LIST_EQUIPMENT_CATEGORY", listEquipmentCategory)]);
  yield all([takeEvery("GET_LOCATION_BY_CATID_FLOORID", getLocationByCatidOrFloorid)]);
  yield all([takeEvery("SAVE_EQUIPMENT_MASTERS", saveEquipmentMaster)]);
  yield all([takeEvery("DELETE_EQUIPMENT_CATEGORY", deleteEquipmentCategory)]);
  yield all([takeEvery("LIST_EQUIPMENT_MASTERS", listEquipmentMaster)]);
  yield all([takeEvery("DELETE_EQUIPMENT_MASTERS", deleteEquipmentMaster)]);
  yield all([takeEvery("VIEW_EQUIPMENT_CATEGORY", viewEquipmentCategory)]);
  yield all([takeEvery("UPDATE_EQUIPMENT_CATEGORY", updateEquipmentCategory)]);
  yield all([takeEvery("VIEW_EQUIPMENT_MASTER", viewEquipmentMaster)]);
  yield all([takeEvery("UPDATE_EQUIPMENT_MASTER", updateEquipmentMaster)]);
  yield all([takeEvery("ADD_COMPLAINT", addComplaint)]);
  yield all([takeEvery("LIST_COMPLAINT", listComplaint)]);
  yield all([takeEvery("GET_EQUIPMENT_BY_NAME_UNIQUEID", getEquipmentByNameOrUniqueid)]);
  yield all([takeEvery("SAVE_EQUIPMENT_COMPLAINT_REQUEST", saveEquipmentComplaintRequest)]);
  yield all([takeEvery("LIST_EQUIPMENT_COMPLAINT_REQUEST", listEquipmentComplaintRequest)]);
  yield all([takeEvery("CHANGE_EQUIPMENT_COMPLAINT_REQUEST_STATUS", changeEquipmentComplaintRequestStatus)]);
  yield all([takeEvery("IMPORT_LOCATION", importlocationStatus)]);
  yield all([takeEvery("IMPORT_COMPLAINT", importcomplaint)]);
  yield all([takeEvery("IMPORT_EQUIPMENT_CATEGORY", importequipmentcategory)]);
  yield all([takeEvery("IMPORT_EQUIPMENT_MASTER", importequipmentmaster)]);
  yield all([takeEvery("GET_EQUIPMENT_DETAILS_BY_EQUIPMENT_ID", getEquipmentDetailsByEquipmentId)]);
  yield all([takeEvery("COMPLAINT_LIST", complaintlist)]);
  yield all([takeEvery("VIEW_EQUIPMENT_DETAILS_BY_BARCODE", viewEquipmentDetailsByBarcode)]);
  yield all([takeEvery('LIST_COMPLAINT_BY_EQUIPMENT', listComplaintbyEquipment)]);
  yield all([takeEvery('EQUIPMENT_HISTORY', EquipmentHistory)]);
  yield all([takeEvery('EQUIPMENT_CATEGORY_VIEW', EquipmentCategoryView)]);
  yield all([takeEvery('CHECKEQUIPMENT_ACTIVITYEXIST', CheckEquipment_ActivityExist)]);
  yield all([takeEvery('UPDATE_EQUIPMENTMASTER', updateEquipmentmaster)]);
  yield all([takeEvery('FETCH_QCTEMPLATE', fecthQctemplate)]);
  yield all([takeEvery('ATTACHQC_INSERVICE', attachQc_inService)]);
  yield all([takeEvery('SAVE_QC_DETAILS', save_qcDetails)]);
  yield all([takeEvery('GET_TASK_QC_DETAILS', get_task_qc_details)]);
  yield all([takeEvery('DELETE_QCMASTER', deleteqcmaster)]);
  yield all([takeEvery('GENERATE_BARCODE', GenerateBarcode)]);
  yield all([takeEvery('ACTIVE_INACTIVE',ActiveInactive)]);
  yield all([takeEvery('DELETE_EQUIPMENT_MASTER',DeleteEquipmentMaster)]);
  yield all([takeEvery('CHECK_EQUIPMENT_ACTIVITY_LOG',CheckEquipmentActivityExist)]);
}
