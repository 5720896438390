
export const actionTypes = {
    
    LIST_QUOTE_FOR_SO: 'LIST_QUOTE_FOR_SO',
    LIST_QUOTE_FOR_SO_SUCCESS: 'LIST_QUOTE_FOR_SO_SUCCESS',
    LIST_QUOTE_FOR_SO_ERROR: 'LIST_QUOTE_FOR_SO_ERROR',
    
    SAVE_SO: 'SAVE_SO',
    SAVE_SO_SUCCESS: 'SAVE_SO_SUCCESS',
    SAVE_SO_ERROR: 'SAVE_SO_ERROR',

    LIST_SO: 'LIST_SO',
    LIST_SO_SUCCESS: 'LIST_SO_SUCCESS',
    LIST_SO_ERROR: 'LIST_SO_ERROR',

    LIST_SO_BY_ID: 'LIST_SO_BY_ID',
    LIST_SO_BY_ID_SUCCESS: 'LIST_SO_BY_ID_SUCCESS',
    LIST_SO_BY_ID_ERROR: 'LIST_SO_BY_ID_ERROR',

    UPDATE_SO: 'UPDATE_SO',
    UPDATE_SO_SUCCESS: 'UPDATE_SO_SUCCESS',
    UPDATE_SO_ERROR: 'UPDATE_SO_ERROR',

    DELETE_SO: 'DELETE_SO',
    DELETE_SO_SUCCESS: 'DELETE_SO_SUCCESS',
    DELETE_SO_ERROR: 'DELETE_SO_ERROR',
};


export const listQuoteForSo = (payload) => {
    console.log(payload);
    return {
        type: actionTypes.LIST_QUOTE_FOR_SO, payload
    };
};
export const listQuoteForSoSuccess = (payload) => {
    return {
        type: actionTypes.LIST_QUOTE_FOR_SO_SUCCESS,
        payload
    };
};
export const listQuoteForSoError = (payload) => {
    return {
        type: actionTypes.LIST_QUOTE_FOR_SO_ERROR,
        payload
    };
};

export const saveSo = (payload) => {
    console.log(payload);
    return {
        type: actionTypes.SAVE_SO, payload
    };
};
export const saveSoSuccess = (payload) => {
    return {
        type: actionTypes.SAVE_SO_SUCCESS,
        payload
    };
};
export const saveSoError = (payload) => {
    return {
        type: actionTypes.SAVE_SO_ERROR,
        payload
    };
};

export const updateSo = (payload) => {
    console.log(payload);
    return {
        type: actionTypes.UPDATE_SO, payload
    };
};
export const updateSoSuccess = (payload) => {
    return {
        type: actionTypes.UPDATE_SO_SUCCESS,
        payload
    };
};
export const updateSoError = (payload) => {
    return {
        type: actionTypes.UPDATE_SO_ERROR,
        payload
    };
};

export const deleteSo = (payload) => {
    console.log(payload);
    return {
        type: actionTypes.DELETE_SO, payload
    };
};
export const deleteSoSuccess = (payload) => {
    return {
        type: actionTypes.DELETE_SO_SUCCESS,
        payload
    };
};
export const deleteSoError = (payload) => {
    return {
        type: actionTypes.DELETE_SO_ERROR,
        payload
    };
};

export const listSo = (payload) => {
    return {
        type: actionTypes.LIST_SO, payload
    };
};
export const listSoSuccess = (payload) => {
    return {
        type: actionTypes.LIST_SO_SUCCESS,
        payload
    };
};
export const listSoError = (payload) => {
    return {
        type: actionTypes.LIST_SO_ERROR,
        payload
    };
};

export const fetchSoById = (payload) => {
    return {
        type: actionTypes.LIST_SO_BY_ID, payload
    };
};
export const fetchSoByIdSuccess = (payload) => {
    return {
        type: actionTypes.LIST_SO_BY_ID_SUCCESS,
        payload
    };
};
export const fetchSoByIdError = (payload) => {
    return {
        type: actionTypes.LIST_SO_BY_ID_ERROR,
        payload
    };
};