import { combineReducers } from 'redux';

const initialState = {
    saveStatus: '',
    listEquipments: '',
}

const equipmentReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'SAVE_EQUIPMENT_TRANSFER':
            return { ...state, saveStatus: '' }
        case 'SAVE_EQUIPMENT_TRANSFER_SUCCESS':
            return { ...state, saveStatus: action.payload }
        case 'SAVE_EQUIPMENT_TRANSFER_ERROR':
            return { ...state, saveStatus: '' }
       
        case 'LIST_EQUIPMENT_TRANSFER':
            return { ...state, listEquipments: '' }
        case 'LIST_EQUIPMENT_TRANSFER_SUCCESS':
            return { ...state, listEquipments: action.payload }
        case 'LIST_EQUIPMENT_TRANSFER_ERROR':
            return { ...state, listEquipments: '' }
       
        default:
            return state
    }
}
export default equipmentReducer